import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { callAxios, callCrmApi, convertReadable, convertReadableTime, convertSecondsToMinutes, getIndexBySystemUserId, timeDiffDaysHourMinSec } from "../utils/utils";
import { getCountryCallingCode } from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { render } from "@testing-library/react";
import { environment } from "../environment";
import userData from '../utils/Users.json';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import $ from "jquery";
import AskPop from "./modal/AskPop";


const Card = ({ item, id, user, active, setActive, setCallDetails, setDetailsLoading, setShowCrmUpdate,setTranscriptUrl,setCurrentCallSic, setIsClientWasOnCall,isClientWasOnCall, setSelectedData, setNoteData,Users, pageType, set_Country, set_Timezone, set_Companysize, set_EmpTenure, set_EmpType, set_Description }) => {
  // console.log(id, item.leadid)
 
   const formattedDate = convertReadable(item.createdon) + " " + convertReadableTime(item.createdon);
  const navigate = useNavigate();
  const [handleShow_, setHandleShow] = useState(false)
  const [isAskPopUpShow, setisAskPopUpShow] = useState(false)
  const regionNames = new Intl.DisplayNames(
    ['en'], { type: 'region' }
  );

  // dialer code
  // For Bootstrap Model
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.stopPropagation();
    setShow(true)
  };

  // For Dialpad Calling
  const [isDialpad, setIsDialpad] = useState(true);
  const [isCalling, setIsCalling] = useState(false);

  const startCall = () => {
    setIsCalling(!isCalling);
    setIsDialpad(false);
  }

  const endtCall = () => {
    setIsCalling(false);
    setIsDialpad(true);
  }

  const openDialpad = () => {
    setIsCalling(false);
    setIsDialpad(true);
  }

  //
  const countryCode = item.new_countryonform ? "+" + getCountryCallingCode(item.new_countryonform) : "";
  // console.log(countryCode)

  const AMPM = parseInt(item.createdon.toString().split("T")[1].split(":")[0]) > 12 ? "PM" : "AM"
  const dateTime = item.createdon.toString().split("T")[0] + " " + item.createdon.toString().split("T")[1].split(".")[0] + " " + AMPM;

  useEffect(() => {
    if (id === 0) {
      getCallDetails(id, item.leadOpportunityId, item.topic,item)
    }
  }, [])

  const getCallDetails = async(id, leadid, topic,item) => {
    set_Country(item.country);
    set_Timezone(item.timezone);
    set_Companysize(item.new_companysize);
    set_EmpTenure(item.empTenure);
     set_EmpType(item.empType);
     set_Description(item.new_skillsetjobdescription);
    setNoteData([]);
    setActive(id);
    setDetailsLoading(true);
    callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/get-call-details-by-id-lead", { leadId: leadid, topic: topic, userType: localStorage.getItem("userRole"), email: user?.email }, "post").then((callDetails) => {
      setCallDetails(callDetails.data.details);
      
    })

    let annotationPayload;

    if(pageType === "lead"){
      annotationPayload = JSON.stringify({
        "operation":"fetchannotations",
        "leadid": leadid,
        "opportunityid":""
    })
    } else{
      annotationPayload = JSON.stringify({
        "operation":"fetchannotations",
        "leadid": "",
        "opportunityid":leadid
    })
    }

    

    const annotationResponse = await callCrmApi(annotationPayload)

    setDetailsLoading(false);
    
    const fullData = annotationResponse.data.data;

    if(fullData.length === 0){
      setNoteData([]);
    }
    //console.log("data length:::", fullData)
       
     const TotalEle = fullData.length;
       for(let i=0; i<fullData.length;i++){
         const getArrIndex = getIndexBySystemUserId(Users,fullData[i]._createdby_value);
         // const user = await Users.findOne({ where:{systemuserid: fullData[i]._createdby_value}});
         if(getArrIndex > -1){
           fullData[i]['display_name'] = Users[getArrIndex].display_name;
           fullData[i]['image'] = Users[getArrIndex].image;
          
         }
               if (i + 1 === fullData.length) {
                 ////console.log(TotalEle);
           // Last one.
         } else {
           // Not last one.
           ////console.log(item.createdon, index, index+1,arrayObj[index+1].createdon)
           const diff = timeDiffDaysHourMinSec(new Date(fullData[i].createdon),new Date(fullData[i+1].createdon));
           const totalTime = timeDiffDaysHourMinSec(new Date(fullData[i].createdon),new Date(fullData[TotalEle-1].createdon));
          
           ////console.log("Time Diff::",timeAgo)
           fullData[i]['timeAgo'] = diff;
           fullData[i]['totalTime'] = totalTime;
           
           fullData[i]['leadId'] = leadid;
 
 
         }
        
       }
       //console.log(fullData)
      setNoteData(fullData);
  }

  const dialNumber = (val) => {
    // console.log(val)
    var currentValue = $('#phoneNumber').val();

    // Append the desired value (e.g., ' Appended') to it
    var appendedValue = currentValue + val;
    $("#phoneNumber").val(appendedValue)
  }

  const removeNumber = () => {
    var currentValue = $('#phoneNumber').val();

    // Remove the last character from the string
    var newValue = currentValue.slice(0, -1);

    // Set the updated value back to the input field
    $('#phoneNumber').val(newValue);
  }

  const askHandler = (e) => {
    e.stopPropagation();
    // alert(JSON.stringify(item))
    setSelectedData(item)
    localStorage.setItem("conferenceObject", [])
    if (item.listType === "lead") {
      if (!item.isTelNo && !item.isMobNo && item.leadid === null) {
        toast.error("Client number not found!. Thank you for trying.....", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      else {
        setisAskPopUpShow(true)
        // render (<AskPop showModal={true} listType={item.listType} leadOpportunityId={item.leadOpportunityId} leadid={item.leadid} displayName={user?.displayName} countryCode={countryCode} fullname={item.fullname} email={user?.email} type="client" leadDesc={item.new_skillsetjobdescription} formattedDate={formattedDate} topic={item.topic}/>)
      }
    } else {
      if (item.leadid === null) {
        toast.error("Client number not found!. Thank you for trying.....", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      else {
        // const formattedDate = convertReadable(item.createdon) +" "+convertReadableTime(item.createdon);
        // render (<AskPop showModal={true} listType={item.listType} leadOpportunityId={item.leadOpportunityId} leadid={item.leadid} displayName={user?.displayName} countryCode={countryCode} fullname={item.fullname} email={user?.email} type="client" leadDesc={item.new_skillsetjobdescription} formattedDate={formattedDate} topic={item.topic}/>)
        setisAskPopUpShow(true)
      }
    }

    // console.log("In discard modal")
  }
  return (
    <>
      <div className={`client-card rounded p-3 mt-2 pointer ${id === active ? "selected" : ""}`} key={id} onClick={() => getCallDetails(id, item.leadOpportunityId, item.topic,item)}>
        {/* <ToastContainer /> */}
        <div className="d-flex justify-content-between gap-2 mb-2">
          <div className="left-cover">
            {item.fullname ?
              <div className="">
                <div className="client-name fs-5 text-warning text-capitalize">{item.fullname}</div>
                <div className="description small text-info">{item.topic}</div>
              </div>
            : null}

            {item.createdon ?
              <div className="fs-6 text-white text-opacity-75 text-capitalize">{convertReadable(item.createdon)} <span>{convertReadableTime(item.createdon)}</span></div>
            : null}
          </div>

          {/* <div className="call-icon bg-success d-flex align-items-center justify-content-center rounded-circle d-none" style={{ width: '45px', minWidth: '45px', height: '45px' }} id={"dial-pad-" + item.leadid} onClick={handleShow}>
           <i className="bi bi-grid-3x3-gap-fill"></i>
          </div> */}

          <button className="call-icon bg-success d-flex align-items-center justify-content-center rounded-circle button-call" style={{ width: '45px', minWidth: '45px', height: '45px' }} id={"button-call-" + item.leadOpportunityId} onClick={askHandler} data-cid="" data-whoDialed="">
            <i className="bi bi-telephone-fill text-white"></i>
          </button>
          <div className="call-icon bg-danger d-flex align-items-center justify-content-center rounded-circle button-hangup-outgoing hide" data-id={item.leadOpportunityId} style={{ width: '45px', minWidth: '45px', height: '45px' }} id={"button-hangup-outgoing-" + item.leadOpportunityId}>
            <i className="bi bi-telephone-x"></i>
          </div>
        </div>

        {item.new_skillsetjobdescription ?
          <div className="requrement-wrapper pe-4">
            <div className="client-location fs-6 text-light mb-1">Requirement Details</div>
            <div className="description small text-light text-opacity-75 ">{item.new_skillsetjobdescription}</div>
          </div> : null}
      </div>

      {isAskPopUpShow ?
        <AskPop showModal={isAskPopUpShow} setisAskPopUpShow={setisAskPopUpShow} listType={item.listType} leadOpportunityId={item.leadOpportunityId} leadid={item.leadid} displayName={user?.displayName} countryCode={countryCode} fullname={item.fullname} email={user?.email} type="client" leadDesc={item.new_skillsetjobdescription} formattedDate={formattedDate} topic={item.topic} setShowCrmUpdate={setShowCrmUpdate} setTranscriptUrl={setTranscriptUrl} setCurrentCallSic={setCurrentCallSic} setIsClientWasOnCall={setIsClientWasOnCall} isClientWasOnCall={isClientWasOnCall} />
      : null}
    </>
  )
}

export default Card;