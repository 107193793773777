import React, {useState} from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { callAxios } from '../utils/utils';
import { environment } from '../environment';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const SubmitRequisitionModal = ({ show, close, handleSubmitRequisition,isloading, setIsClientName, setJobTitle,setPrimaryRole,setclientName,setMinimumExp,setDepartment,setJobDescription,setKeySkills,setLocation,setMinimumQualification,setReqiredBy,setEmploymentType,setShiftType,setNatureOfJob,setPreferedLang, setIsloading, formData, setShowConfirm }) => {
    const [isReviewd, setIsReviewed] = useState(false)
    const [afterSubmit, setAfterSubmit] = useState(false)
    
    formData.isReviewd = isReviewd;

    // console.log(formData,isReviewd, isloading)

    let navigate = useNavigate();
    const handleFinalSubmit = async(e) =>{
        e.preventDefault()
        const butnid = document.getElementById("buttonwithData");
        if(butnid){
            butnid.disabled = true;
        }
        setIsloading(true)
        const requisitionResponse = await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/requisitions/add-requisition", formData, "post");

		if (requisitionResponse.data.statusCode === 200) {
			setIsloading(false)
			
			// document.getElementById("requisitionForm").reset();
			setIsClientName(false)
			setJobTitle("");
			setPrimaryRole("");
			setclientName("")
			setMinimumExp("");
			setDepartment("");
			setJobDescription("");
			setKeySkills("");
			setLocation("");
			setMinimumQualification("");
			setReqiredBy("");
			setEmploymentType("");
			setShiftType("");
			setNatureOfJob("");
			setPreferedLang({})
			toast.success("Requisition added successfully!", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
            navigate("/requisitions")
		}
        if (requisitionResponse.data.statusCode === 400) {
            if(butnid){
                butnid.disabled = false;
            }
			setIsloading(false)
			setShowConfirm(false)
            setIsReviewed(false)

			toast.error(requisitionResponse.data.message, {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}
		
    }
    return (
        <Modal show={show} centered onHide={close} size='xl' scrollable backdrop="static" keyboard={false} dialogClassName="mw-auto" contentClassName='rounded overflow-hidden'>
            <Modal.Body className="text-body px-md-4 px-lg-5 py-4">
                    <div className="fs-3 fw-bold text-dark lh-sm mt-3 mb-3">Submit Requisition</div>
                    <Form className="mb-3">
                        <div className='fw-medium mb-3 pb-1'>
                            We recommend thoroughly reviewing all details before submitting your application. You must include a detailed job description, 
                            with a strong focus on the technologies you expect the candidate to work with.
                        </div>
                        <div className='fw-medium mb-3 pb-1'>
                            Once submitted, the application will be forwarded to the higher manager for approval. 
                            Be cautious, as there should be no mistakes in the requisition from your end.
                        </div>
                        <Form.Check id="checkCondition" className="text-dark ms-2">
                            <Form.Check.Input type="checkbox" className="fs-5 border border-2 border-dark rounded-1 shadow-none" onChange={(e)=>{setIsReviewed(e.target.checked)}} />
                            <Form.Check.Label>
                                Yes, I have reviewed all the details of my requisition thoroughly. I have included everything I need, 
                                and there are no mistakes in English or any missing information as per the requirements.
                            </Form.Check.Label>
                        </Form.Check>
                        <div className="btn_wrapper d-flex justify-content-center gap-3 mt-4 pt-xl-3">
                            <Button type="button" variant="secondary" className="fs-18 py-2 px-5" onClick={close}>Cancel</Button>
                            {isReviewd?
                            <Button type="submit"  variant="success" id='buttonwithData' className="fs-18 py-2 px-5" onClick={(e) => { handleFinalSubmit(e) }}>Submit</Button>
                            :
                            <Button type="submit" variant="success" className="fs-18 py-2 px-5 disabled" >Submit</Button>
                            }
                            {isloading ?
												<div style={{ textAlign: 'center' }}>
													<span className="spinner-profile"></span>
												</div>
												: null}
                        </div>
                    </Form>
            </Modal.Body>
        </Modal>
    )
}
