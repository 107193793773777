import React, { useEffect, useMemo, useState } from "react";
import searchIcon from "../resources/images/search.svg";
import notebookPenIcon from "../resources/images/notebook-pen.svg";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database } from "../firebaseConfig";
import { callAxios, convertReadable, convertReadableTime, secondsToHMS } from "../utils/utils";
import { environment } from "../environment";
import { intitializeDevice, log_ } from "../utils/pstn";
import { setUserInfo } from "../store/actions";
import Card from "./Card";
import { CRMUpdates } from "./modal/CRMUpdates";
import { CallPerformanceRating } from "./modal/CallPerformanceRating";
// import DateRange from "../Reports/DateRange";
import SheelaModal from "./modal/SheelaModal";
import $ from "jquery";


import { ToastContainer, toast } from "react-toastify";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CallFeedBack from "./modal/callFeedback";
import { doc, getDoc } from "firebase/firestore";
import { RefreshApp } from "../LeadsListingPage/RefreshApp";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PhoneCallCard from "../Cards/PhoneCallCard";
import AppointmentCard from "../Cards/AppointmentCard";
import EmailCard from "../Cards/EmailCard";
import TaskCard from "../Cards/TaskCard";
import NoteCard from "../Cards/NoteCard";
import DateRange from "../Reports/DateRange";

const AdminLeadCalls = ({ setLoggedinUser }) => {
  // For Sidebar list selected card
  const date = new Date();
const year = date.getFullYear();
const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
const day = String(date.getDate()).padStart(2, '0');

const transurl = `https://nm-pstn-call-files.s3.ap-south-1.amazonaws.com/Realtime-transcription/${year}/${month}/${day}/`
  const [updates, setUpdates] = useState([]);
  const [active, setActive] = useState(null);
  const [isSheelaView, setSheelaView] = useState(false);
  const [callFeedback, setCallBack] = useState(false);
  const [showCrmUpdate, setShowCrmUpdate] = useState(false);
  const [showCallPerformance, setshowCallPerformance] = useState(false);
  const [speakerRaiting, setSpeakerRating] = useState({});
  const [transcript_url, setTranscriptUrl] = useState("");
  const [currentCallSid, setCurrentCallSic] = useState("");
  const [isClientWasOnCall, setIsClientWasOnCall] = useState(false)
  const [leadOpId, setLeadOpId] = useState("")
  const [leadOrOp, setLeadOrOp] = useState(1)
  const [LeadOpTitle, setLeadOpTitle] = useState("")
  const [selectedData, setSelectedData] = useState({})
  const [summary, setSummary] = useState('')
  const [noteData, setNoteData] = useState([]);
  
  // For Read more read less functionality
  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <>
        {isReadMore ? text.slice(0, 300) : text}
        {text.length > 300 &&
          <span onClick={toggleReadMore} className="text-info pointer">
            {isReadMore ? "...Read More" : " Read Less"}
          </span>
        }
      </>
    );
  };


  const [accessToken, setAccessToken] = useState(null);
  const [limit, setLimit] = useState(10);
  const [searchType, setSearchType] = useState("lead");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();
  const [sheelaData, setDataForSheela] = useState({});
  const [callDetails, setCallDetails] = useState([]);
  const [isDetailsLoading, setDetailsLoading] = useState(true);
  const [allData, setData] = useState([]);
  const [isShowCalendar, setIsShowCalendar] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [Users, setUsers] = useState([])

  const [isFeedBackShared, setIsFeedBackShared] = useState([]);

  const [_contry, set_Country] = useState('');
  const [_timezone, set_Timezone] = useState('');
  const [_companysize, set_Companysize] = useState('');
  const [_empTenure, set_EmpTenure] = useState('');
  const [_empType, set_EmpType] = useState('');
  const [_description, set_Description] = useState('');

  
  const navigate = useNavigate()
  const [user, loading, error] = useAuthState(auth);
  // console.log(user)
  $("#CallFeedbackForm").addClass("show")
  $("#CallFeedbackForm").css("display", "block")

  useEffect(() => {
  }, [user])

  const userRoleStore = useSelector((state) => state.userRole);
  const loggedInUserRole = userRoleStore ? userRoleStore : localStorage.getItem("userRole");
  const [currentAppVersion, setAppVersion] = useState("");
  // console.log(currentAppVersion)
  const currentVersion = async () => {
    const myDocRef = doc(database, environment.REACT_APP_VERSION_DB, "zhwbBpi4c6AQFYJmai2Q");
    const myDoc = await getDoc(myDocRef);
    if (myDoc.exists()) {
      setAppVersion(myDoc?.data()?.version);
      if (myDoc.data().version !== localStorage.getItem("appVersion")) {
        const refreshApp = document.getElementById("refreshApp");
        const availableVersion = document.getElementById("available-version");
        //setAppVersion(myDoc.data().version);
        localStorage.setItem("camJoinedLead", null);
        localStorage.setItem("bdmJoinedLead", null);
        if (refreshApp) {
          refreshApp.classList.add("show");
          refreshApp.style.display = "block";
        }
        if (availableVersion) {
          availableVersion.innerHTML = `We are excited to announce that Version ${myDoc.data().version} of the NM App is now available. To enjoy the latest features and improvements, simply click on the update button and get the newest version.</div>

   
</div></div>`
        }
      }
    }

  }
  useEffect(()=>{
    // console.log("calling..")
    currentVersion();
  },[currentAppVersion])
  
  // <div class="updates-list mb-3 text-start"><div class="fw-medium mb-2 text-warning">New Updates</div>
    
  //   <p class="mb-1">1. You can now use the dialer to record messages directly to client voice mails. Simply follow the client's voicemail instructions and press either the * or # key to leave your message.</p>
 
  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };

      setLoggedinUser(userInfo);
      callAxios(environment.REACT_APP_API_URL_Live + "api/v1/crm/get-voice-t", {}, "post").then((token) => {
        setAccessToken(token.data)
      })

      callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/call-feature-feedback", {}, "get").then((isSharedFeedback) => {
        setIsFeedBackShared(isSharedFeedback.data)
      })

      callAxios(environment.REACT_APP_API_URL_Live + "api/v2/crm/get-system-users", {}, "post").then((allUsers) => {
        setUsers(allUsers.data.users)
      })

      const searchType = $("#searchType").val();
      const date = new Date();
      const syear = date.getFullYear();
      const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const sday = String(date.getDate()).padStart(2, '0');
      const formattedStartDate = `${syear}-${smonth}-${sday}`;


      
      setStartDate(formattedStartDate)
      setEndDate(formattedStartDate)

      getDataByDate(formattedStartDate, formattedStartDate, searchType)
    }
  }, [user, loading]);
  useEffect(() => {
   
    if (accessToken)
      intitializeDevice(accessToken, user?.displayName)
  }, [accessToken])

  const analyzeData = (summary) => {
    setDataForSheela(summary)
    //console.log("sheela here")
    document.getElementById("SheelaModal").style.display = "block";
    document.getElementById("SheelaModal").classList.add("show");
    setSheelaView(true);
  }

  const getDataByDate = (startdate, enddate, searchType) => {
    // setStartDate(date)
    setIsLoading(true);
    // console.log(startdate, enddate)
    callAxios(environment.REACT_APP_API_URL_Live + "api/v2/crm/get-leads-admin", { email: user?.email, isDateFilter: true, startdate: startdate, enddate: enddate, searchType: searchType }, "post").then((meetingData) => {
      // console.log("downloadData:::",meetingData.data[0].participants)
      setData(meetingData.data);
      //    console.log(meetingData)
      setIsLoading(false);
      setDetailsLoading(false)
    })
  }

  const handleNameSearch = () => {
    const clientName = document.getElementById("search-by-client-name").value;
    const searchType = $("#searchType").val();
    // console.log(clientName)
    setIsLoading(true);
    callAxios(environment.REACT_APP_API_URL_Live + "api/v2/crm/get-leads-admin", { email: user?.email, isFilterName: true, page: 1, limit: 100, filterValue: clientName, searchType: searchType }, "post").then((meetingData) => {
      setData(meetingData.data);
      //    console.log(meetingData)
      setIsLoading(false);
    })
  }
  const formatDate = (date) =>{
    
    const syear = date.getFullYear();
    const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const sday = String(date.getDate()).padStart(2, '0');
    const formattedStartDate = `${syear}-${smonth}-${sday}`;

    return formattedStartDate;
  }

  const onChange = (ranges) => {
    setIsShowCalendar(false);
    setStartDate(formatDate(ranges.startDate));
    setEndDate(formatDate(ranges.endDate));
    const searchType = $("#searchType").val();
    getDataByDate(formatDate(ranges.startDate),formatDate(ranges.endDate), searchType)
  };

  const showCalendar = () => {
    // setIsShowCalendar(true);
    setIsShowCalendar(!isShowCalendar);
  }
  //console.log(callDetails)
  let callDetailsS;
  if (callDetails && callDetails.length > 0) {
    callDetailsS = callDetails.map((item, index) => {
      return (
        <div className="attn-details-wrapper p-3 mt-3" key={index}>
          <div className="row gx-2 gy-1 mb-2">
            <div className="col-lg-8">
              {item.createdAt ?
                <div className="client-name fs-5 fw-semibold text-light text-capitalize mb-1">{convertReadable(item.createdAt) + ' ' + convertReadableTime(item.createdAt)}</div>
                : null}
              {item.caller_name ?
                <div className="text-capitalize">Called By <span className="attn-name">{item.caller_name}</span></div>
                : null}
            </div>

            <div className="col-lg-4 text-lg-end">
              {item.call_status ?
                <div className="duration text-light text-opacity-75 mb-1">Call Status - <span className="call-time text-warning text-capitalize">{item.call_status}</span></div>
                : null}
              {item.call_duration ?
                <div className="duration text-light text-opacity-75 mb-1">Duration - <span className="call-time">{secondsToHMS(item.call_duration)}</span></div>
                : null}
            </div>
          </div>

          <div className="compose-media-page row align-items-center g-3">
            <div className="col-lg-9">

            </div>

            <div className="col-lg-3">
              <div className="icon-wrapper d-flex gap-3">
                {item.call_type === 1 ?
                  <div className="group-call">
                    <img src={require('../resources/images/group-call.png')} alt="Group Calling" className="img-fluid" style={{ maxWidth: '40px' }} />
                  </div>
                  :
                  <div className="user-call">
                    <img src={require('../resources/images/user-on-call.png')} alt="Single User Call" className="img-fluid" style={{ maxWidth: '28px' }} />
                  </div>
                }
                {item.call_sid && item.is_recording_available === 1 ?
                  <div className="play-icon pointer" onClick={() => { handleMeetingViewAndDownload(item.call_sid) }}>
                    <i className="bi bi-play-circle-fill text-white lh-1" style={{ fontSize: '40px' }}></i>
                  </div>
                  : null}
              </div>
            </div>
          </div>
        </div>
      )
    })
  } else {
    callDetailsS = "No call details found";
  }

  // listen recording
  const closePlayer = () => {
    var audioSrc = document.getElementById("audioSrc");
    audioSrc.pause();
    document.getElementById("audio-player").style.display = "none";
  }

  const handleMeetingViewAndDownload = async (callSid) => {
    const mediaResponse = await callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/twilio/get-recording-by-sid', { cid: callSid }, "post");
    if (mediaResponse.data.statusCode === 400) {
      toast.error("Recording not found!!!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      const url = mediaResponse.data.url;
      var audioSrc = document.getElementById("audioSrc");
      const isSupp = audioSrc.canPlayType("video/mp4");
      if (isSupp == "") {
        audioSrc.src = url;
      } else {
        audioSrc.src = url;
      }
      audioSrc.load();
      audioSrc.play();
      document.getElementById("audio-player").style.display = "block";
    }
  }
  // console.log(callDetails)

  const updateDateFilter = (val) => {
    if (val.length > 0) {
      $("#dateFilterField").hide();
      $("#basic-addon2").removeClass("d-none");
    } else {
      $("#dateFilterField").show();
      $("#basic-addon2").addClass("d-none");
    }
  }


  // console.log(selectedData)

  const [key, setKey] = useState('RequirementDetails');

  const backToMainScreen = () =>{
    navigate("/lead-opp-client-calls");
  }

  const new_EmploymentTenure = {
    "100000000": "Less than 1 Month",
    "100000005": "1 to 3 Months",
    "100000002": "3 to 6 Months",
    "100000003": "6 to 12 Months",
    "100000004": "More than 1 Year",
    "100000006": "Not Sure",
    "100000001": "Looking for Job"
};

const new_CompanySize = {
  "100000001": "Start Up",
  "100000008": "1",
  "100000009": "2",
  "100000002": "3 to 5",
  "100000000": "6 to 10",
  "100000003": "11 to 20",
  "100000004": "21 to 50",
  "100000005": "51 to 100",
  "100000006": "101 to 500",
  "100000007": "More than 500"
}

const new_EmploymentType = {
  "100000000": "Full Time",
  "693980000": "Ad Hoc",
  "100000001": "Part Time"
}

const regionNames = new Intl.DisplayNames(
  ['en'], {type: 'region'}
);

// console.log(Users)
  return (
    <div className="client-listing-page call-history-details py-3">
      {/* <ToastContainer /> */}
      <div className="container-xxl">
        <div className="row align-items-center g-2 mb-3">
          <div className="col-lg-4">
          <span className="pointer" onClick={backToMainScreen}><i class="bi bi-arrow-left"></i> Back</span>
            <div className="main-heading text-white text-opacity-75">Call History Details</div>
          </div>

          <div className="col-lg-8">
            <div className="row justify-content-lg-end g-2">
              

              <div className="col-sm-6 col-md-6 col-lg-5 field-cover">
                <div className="input-group rounded h-100 overflow-hidden">
                  <input type="search" className="form-control" placeholder="Search by Topic, Client name.." id='search-by-client-name' autoComplete='off' onChange={(e) => { updateDateFilter(e.target.value) }} />
                  <span className="input-group-text pointer d-none" id="basic-addon2" onClick={handleNameSearch}><i className="bi bi-search"></i></span>
                </div>
              </div>

              <div className="col-12 col-md-4 field-cover" id="dateFilterField">
                {/* <DatePicker wrapperClassName="w-100"
                  // selected={new Date()}
                  selected={startDate}
                  onChange={(date) => { onChange(date) }}
                  className="wonDatePicker w-100 m-0"
                  id="wonDatePicker"
                  // renderMonthContent={renderMonthContent}
                  showFullMonthYearPicker
                  dateFormat="yyyy-MM-dd"
                  showIcon
                /> */}

<div className="calendarWrapper position-relative">
                            <div className="d-flex flex-wrap align-items-center gap-3">
                            
                          <input placeholder={startDate +' - '+ endDate}  type="text" className="form-control" id="calendar" onClick={showCalendar}  />
                            </div>
                        
                          {isShowCalendar?
                           <div className="position-absolute end-0" style={{zIndex:11}}>
                           <DateRange  onChange={onChange} startDate={startDate} endDate={endDate} />
                           </div>
                           :null
                        }

                        </div>
               
              </div>
            </div>
          </div>
        </div>

        {/* CAM & BDM Client Info */}
        <div className="client-detail-wrapper">
          <div className="row g-3">
            <div className="col-md-5 col-lg-4">
              <div className="client-list-section">
                <div className={`client-card rounded p-3 overflow-auto`} id="log" style={{ height: "150px" }}>
                </div>
                {isLoading ? (
                  <div className="d-flex flex-row-reverse align-items-center justify-content-center gap-3 mt-3" id="lead-list-spinner">
                    <div className="lead-list-spinner"></div>
                    {" "} Please wait ....
                  </div>
                ) :
                  allData && allData.leads.length > 0 ?
                    allData.leads.map((item, id) => {
                      return <Card startDate={startDate} item={item} id={id} user={user} active={active} setActive={setActive} setCallDetails={setCallDetails} setDetailsLoading={setDetailsLoading} setShowCrmUpdate={setShowCrmUpdate} setTranscriptUrl={setTranscriptUrl} setCurrentCallSic={setCurrentCallSic} setIsClientWasOnCall={setIsClientWasOnCall} isClientWasOnCall={isClientWasOnCall} setSelectedData={setSelectedData} setNoteData={setNoteData} Users={Users} pageType={"lead"} set_Country={set_Country} set_Timezone={set_Timezone} set_Companysize={set_Companysize} set_EmpTenure={set_EmpTenure} set_EmpType={set_EmpType} set_Description={set_Description}/>
                    })
                    :
                    <p className="text-light mt-2"> Data not found!!</p>
                }
              </div>
            </div>
            <div className="col-md-7 col-lg-8">
              <div className="client-info-details p-3">
                <div className="fs-4 fw-medium text-warning"> </div>
                <div className="info-card-wrapper">
                <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >

      <Tab eventKey="RequirementDetails" title="Requirement Details">
      {!_contry && !_timezone && !_companysize && !_empTenure && !_empType && !_description?
        <p>No Requirement Details Found!!</p>
        :
       <div className="row g-3 mb-3">
        {_contry?
        <div className="col-6 col-md-4">
              <div className="item-info">
                <div className="label-name fs-14 text-primary">Country</div>
                <div className="label-value">{regionNames.of(_contry)}</div>
              </div>
        </div>
        :null}
        {_timezone?
        <div className="col-6 col-md-4">
              <div className="item-info">
                <div className="label-name fs-14 text-primary">Timezone</div>
                <div className="label-value">{_timezone}</div>
              </div>
        </div>
        :null}
        {_companysize?
        <div className="col-6 col-md-4">
              <div className="item-info">
                <div className="label-name fs-14 text-primary">Company Size</div>
                <div className="label-value">{new_CompanySize[_companysize]}</div>
              </div>
        </div>
        :null}
        {_empTenure?
        <div className="col-6 col-md-4">
              <div className="item-info">
                <div className="label-name fs-14 text-primary">Employment Tenure</div>
                <div className="label-value">{new_EmploymentTenure[_empTenure]}</div>
              </div>
        </div>
        :null}
        {_empType?
        <div className="col-6 col-md-4">
              <div className="item-info">
                <div className="label-name fs-14 text-primary">Employment Type</div>
                <div className="label-value">{new_EmploymentType[_empType]}</div>
              </div>
        </div>
        :null}

       </div>
}
       {_description?
       <div className="item-info">
                <div className="label-name fs-14 text-primary">Requirement Description</div>
                <div className="label-value">{_description}</div>
              </div>
              :null}
      </Tab>
      <Tab eventKey="callHistory" title="Call History Details">
        {isDetailsLoading ? (
                    <div className="d-flex flex-row-reverse align-items-center justify-content-center gap-3 mt-3" id="lead-list-spinner">
                      <div className="lead-list-spinner"></div>
                      {" "} Please wait ....
                    </div>
                  ) : callDetailsS}
      </Tab>
      <Tab eventKey="LeadsNotes" title="Lead Notes">
      {isDetailsLoading
                      ? (
                        <div id="meeting-pointer-Preloader">
                          <div className="meeting-pointer-spinner"></div> Please wait
                          ....
                        </div>
                      )
                      // : noteData?.data?.annotationData.map((item, index) => (
                        :
      noteData.length > 0 ?

noteData?.map((item, index) => (
  <div className="cam-bdm-card d-flex gap-2 gap-lg-3 mt-4">
    <div className="user-wrapper text-center" style={{
          height: "40px",
          width: "40px"
        }}>
      <img
        src={
          item.image
            ? item.image
            : require("../resources/images/Avatar.png")
        }
        alt=""
        className="border border-1 rounded-circle shadow overflow-hidden"
        style={{
          height: "40px",
          width: "40px",
          maxWidth: "40px",
          maxHeight:"40px"
        }}
      />
      {item.timeAgo?
      <div className='time-ago text-white text-opacity-75 mt-1 text-capitalize' style={{fontSize:"9px"}}>{item.timeAgo}</div>
      :null }
      {/* |
      {item.timeAgo?
      <div className='time-ago text-white text-opacity-75 mt-1 text-capitalize' style={{fontSize:"9px"}}>{item.timeAgo}</div>
      :null } */}
    </div>
    {item.activitytypecode === "phonecall"?
   
      <PhoneCallCard item={item} clientName={""} />
      :
      item.activitytypecode === "appointment"?
      <AppointmentCard item={item} clientName={""}  userData={""} />
      :
      item.activitytypecode === "email"?
      <EmailCard item={item} clientName={""}  userData={""} />
      :
      item.activitytypecode === "task"?
      <TaskCard item={item} clientName={""} />
      :
      item.objecttypecode === "lead" || item.objecttypecode === "opportunity"?
      <NoteCard item={item} clientName={""} />
      :
    null}
    {/* <ReadMore item={item} /> */}
 
  </div>
))
:"Notes not found!"
}
      </Tab>
      
    </Tabs>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="audio-player" className="modal" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <video width="400" controls autoPlay id="audioSrc">
                <source src="" type="video/mp4" ></source>
                Your browser does not support HTML video.
              </video>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal" onClick={closePlayer}>Close</button>
            </div>
          </div>
        </div>
      </div>

      <SheelaModal setSheelaView={setSheelaView} isSheelaView={isSheelaView} sheelaData={sheelaData} />
      {isFeedBackShared === 0 ?
        <CallFeedBack showFeedback={false} />
        : null}
        <RefreshApp currentAppVersion={currentAppVersion} />

        {/* CRM UPdates Ready Modal */}
        {showCrmUpdate?
        <CRMUpdates showCRMUpdates={true}  setshowCallPerformance={setshowCallPerformance} displayName={user?.displayName} setShowCrmUpdate={setShowCrmUpdate} setSpeakerRating={setSpeakerRating} transcript_url={transcript_url} currentCallSid={currentCallSid} setCurrentCallSic={setCurrentCallSic} setIsClientWasOnCall={setIsClientWasOnCall} crmData={selectedData} setSelectedData={setSelectedData}/>
        :null}

        {/* Call Performance Rating Modal */}
        {showCallPerformance?
        <CallPerformanceRating showPerformanceRating={true} displayName={user?.displayName} setshowCallPerformance={setshowCallPerformance} speakerRaiting={speakerRaiting}/>
        :null}

    </div >
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedinUser: (user) => dispatch(setUserInfo(user))
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(AdminLeadCalls);
