import React, { useEffect, useState } from "react";
import ChatSection from "./ChatSection/ChatSection";
import LeadDetailsSection from "./LeadSection/LeadDetailsSection";

import "./RoomPage.css";

import { connect } from "react-redux";
import { setFormId, setIdentity, setRoomId, setTwilioAccessToken, setUserInfo, setUserRole } from "../store/actions";
import { getTokenFromTwilio } from "../utils/twilioUtils";
import { useNavigate, useLocation,useSearchParams } from "react-router-dom";
import Overlay from "./Overlay";
import BackgroundSetting from "./BgSettingSection/BackgroundSetting";
import { CallOnMobile } from "./VideoSection/CallOnMobile";
import notificationSound from "../static/NotificationNew.mp3";
import RejoinRoom from "./RejoinRoom";
import VideoSection from "./VideoSectionMeeting/VideoSection";
import ParticipantsSection from "./ParticipantsSectionMeeting/ParticipantsSection";
import moment from 'moment-timezone';
import { callAxios } from "../utils/utils";
import { environment } from "../environment";


 function MeetingRoom(props) {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const displayModal = () =>{
    const meetingModule = document.getElementById("meeting-schedule-module");
  
    if (meetingModule) {
      meetingModule.classList.add("show");
      meetingModule.style.display = "block";
    }
  }
  // async function canJoinMeeting(from, to, timeZone, meetingId) {
  //   // Parse the 'from' and 'to' datetimes into the provided timezone
  //   await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/meetings/check-if-cancel",{meetingId:meetingId},"post").then((res)=>{
  //     console.log(res.data.meetingInfo) 
  //     if(res.data.meetingInfo.is_meeting_canceled){
       
  //       setCheckDateBeforeJoin(false)
  //       setMessage("Meeting has canceled by organizer.");
  //       displayModal();
  //     }
  //   })
  //   const fromDateTime = moment.tz(from, timeZone);  // Meeting start time
  //   const toDateTime = moment.tz(to, timeZone);      // Meeting end time
  
  //   // Subtract 10 minutes from the 'from' datetime
  //   const fromMinus10Minutes = fromDateTime.clone().subtract(10, 'minutes');
  
  //   // Get the current date and time in the same timezone
  //   const currentDateTime = moment.tz(timeZone);
  
  //   // Check if current time is between (from - 10 minutes) and to time
  //   if (currentDateTime.isAfter(fromMinus10Minutes) && currentDateTime.isBefore(toDateTime)) {
  //     setCheckDateBeforeJoin(true)
  //   } else if (currentDateTime.isAfter(toDateTime)) {
  //     setCheckDateBeforeJoin(false)
  //     setMessage("Meeting has ended. You cannot join.");
  //     displayModal();
      
  //   } else {
  //     setCheckDateBeforeJoin(false)
  //     if(meetingCreatedBy === email){
  //       setMessage("You cannot start the meeting yet. Please wait until 10 minutes before the meeting starts.")
  //     }
  //     else{
  //       setMessage("You cannot join the meeting yet. Please wait until 10 minutes before the meeting starts.")
  //     }
      
  //     displayModal();
  //   }
  // }

  async function canJoinMeeting(from, to, timeZone) {
    try {
      // Make the API call using async/await
      const res = await callAxios(
        environment.REACT_APP_API_URL_Live + "api/v1/twilio-voice/check-if-cancel",
        { meetingId: meetingId },
        "post"
      );
  
      // console.log(res.data.meetingInfo);
  
      if (res.data.meetingInfo.is_meeting_canceled) {
        // If the meeting is canceled, display the modal and set the message
        setCheckDateBeforeJoin(false);
        setMessage("Meeting has been canceled by the organizer.");
        displayModal();
        return; // Exit the function early since the meeting is canceled
      }
  
      const fromDateTime = moment.tz(from, timeZone);  // Meeting start time
      const toDateTime = moment.tz(to, timeZone);      // Meeting end time
  
      // Subtract 10 minutes from the 'from' datetime
      const fromMinus10Minutes = fromDateTime.clone().subtract(10, 'minutes');
  
      // Get the current date and time in the same timezone
      const currentDateTime = moment.tz(timeZone);
  
      // Check if current time is between (from - 10 minutes) and to time
      if (currentDateTime.isAfter(fromMinus10Minutes) && currentDateTime.isBefore(toDateTime)) {
        setCheckDateBeforeJoin(true);
      } else if (currentDateTime.isAfter(toDateTime)) {
        setCheckDateBeforeJoin(false);
        setMessage("Meeting has ended. You cannot join.");
        displayModal();
      } else {
        setCheckDateBeforeJoin(false);
        if (meetingCreatedBy === email) {
          setMessage("You cannot start the meeting yet. Please wait until 10 minutes before the meeting starts.");
        } else {
          setMessage("You cannot join the meeting yet. Please wait until 10 minutes before the meeting starts.");
        }
        displayModal();
      }
    } catch (error) { 
      console.error("Error checking meeting cancellation:", error);
      // Handle the error and show a relevant message if needed
      setMessage("An error occurred while checking the meeting status.");
      displayModal();
    }
  }
  

  const [searchParams, setSearchParams] = useSearchParams();
  const state_ = searchParams.get("id");

  const objStr = atob(state_)

  const state_obj = JSON.parse(objStr)
   //console.log(state_obj)

  const camera = state_obj.camera;
  const isMicMuted = state_obj.isMicMuted;
  const isRejoin = state_obj.isRejoin;
  const roomId = state_obj.roomId;
  const formId = state_obj.formID;
  const identity = state_obj.identity;
  const user = state_obj.user;
  const userRole = state_obj.userRole;
  const meetingId = state_obj.meetingId;
  const meetingFrom = state_obj.meetingFrom;
  const email = state_obj.email;
  const meetingTo = state_obj.meetingTo;
  const meetingCreatedBy = state_obj.meetingCreatedBy
  // console.log(email,meetingId)

  const [isDateTimeInRange, setCheckDateBeforeJoin] = useState(false)
  const [message, setMessage] = useState("")
 
  useEffect(()=>{
    canJoinMeeting(meetingFrom, meetingTo, userTimeZone, meetingId)
  },[])
  const { setTwilioAccessTokenAction, showOverlay, setIdentityAction, setRoomIdAction, setFormIdAction, setLoggedinUserAction, setUserRoleAction  } = props;
  //console.log(props)
  
  let navigate = useNavigate();
  const [audio, setAudio] = useState(new Audio(notificationSound));
  // const { state } = useLocation();
  // const { id, camera, isMicMuted, isRejoin} = state;
  
  

  const userInfo = {
    uid: state_obj.uid,
    email: state_obj.email,
    displayName: state_obj.displayName,
    accessToken: state_obj.accessToken,
    idToken: state_obj.idToken,
  };

//console.log(state_obj)

  //
  useEffect(() => {
    if (!identity || !roomId) {
      navigate("/");
    } else {
      getTokenFromTwilio(setTwilioAccessTokenAction, identity);
    }
  }, []);

//console.log(props)

setIdentityAction(identity);
setRoomIdAction(roomId);
setFormIdAction(formId);
setUserInfo(userInfo);
 setUserRoleAction(userRole);

 const handleOK = () =>{
  window.close();
 }

  return (
    <>
      {isDateTimeInRange?
      <>
    
      <VideoSection comingFrom={true} isCam={true} camera={camera} isMicMuted = {isMicMuted} isRejoin={isRejoin} email={email} meetingId={meetingId}/>
      <div
        id="main"
        className="room_container main-section p-lg-3 overflow-hidden"
      >
        <div
          id="shared_screen"
          className="shared_screen_preview d-flex flex-wrap w-100 h-100 overflow-hidden position-relative border border-1 border-info hide"
        ></div>
        <div
          id="videos_portal"
          className="when-normal d-flex flex-wrap h-100 overflow-auto position-relative"
        ></div>
      </div>

      
      <div id="chatSidenav" className="sidebar border-start border-black">
        <ChatSection />
        {showOverlay && <Overlay />}
      </div>

   
      <div id="partcipantSidenav" className="sidebar border-start border-black">
        <ParticipantsSection />
      </div>

    
      {/* <div
        id="LeadDetailsSidenav"
        className="sidebar border-start border-black"
      >
        <LeadDetailsSection />
      </div> */}

      

     
       <div id="BgSettingSidenav" className="sidebar border-start border-black">
        <BackgroundSetting comingFrom={true}/>
      </div>
    

    </>
    :
    <div className='join-call-model'>
            {/* Modal */}
            <div className="modal fade" id="meeting-schedule-module" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: '650px' }}>
                    <div className="modal-content bg-dark border-top border-5 border-primary rounded-0 shadow">
                        <div className="modal-body p-4 p-md-5 text-center">
                            <div className='fs-5 fw-semibold mb-4' ></div>
                            <div className='fs-5 fw-semibold mb-4'>{message}</div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div id="available-version" className='mb-3 text-start'></div>
                                    <button className='btn btn-success' onClick={handleOK} >OK</button>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            
        </div>
    }
    </>
    
  
  );
}



const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setTwilioAccessTokenAction: (token) => dispatch(setTwilioAccessToken(token)),
      setIdentityAction: (identity) => dispatch(setIdentity(identity)),
      setRoomIdAction: (id) => dispatch(setRoomId(id)),
      setFormIdAction: (id) => dispatch(setFormId(id)),
       setUserInfo: (user) => dispatch(setUserInfo(user)),
       setUserRoleAction: (userRole) => dispatch(setUserRole(userRole)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(MeetingRoom);
