import React from 'react';
import { Modal, Form, InputGroup, Button } from 'react-bootstrap';

export const RedeemInsentiveModal = ({ show, close }) => {
    return (
        <Modal show={show} centered onHide={close} size='xl' scrollable backdrop="static" keyboard={false} dialogClassName="redeemInsentiveModal Wallet-page" contentClassName='rounded overflow-hidden'>
            <Modal.Body className="wallet-info-section p-2 p-md-3">
                <InputGroup className="date-wrapper mb-2">
                    <InputGroup.Text className="bg-transparent border-end-0 text-light"><i className="bi bi-search"></i></InputGroup.Text>
                    <Form.Control type="search" className="bg-transparent border-start-0 shadow-none py-2" placeholder="Search to Add" />
                </InputGroup>
                <div className="info-card rounded-3 px-4 py-3">
                    <div className="custom-bg rounded-3">
                        <div className="row g-2">
                            <div className="col-12 col-lg-3 info-box text-capitalize">
                                <div className="label fs-14 text-warning">Opportunity ID</div>
                                <div className="value text-white text-opacity-75 text-break">Xaurl Churay_080110_009</div>
                            </div>
                            <div className="col-6 col-lg-2 info-box text-capitalize">
                                <div className="label fs-14 text-warning">Opportunity Created</div>
                                <div className="value text-white text-opacity-75">08-08-2024</div>
                            </div>
                            <div className="col-6 col-lg-3 info-box text-capitalize">
                                <div className="label fs-14 text-warning">Opportunity Added to Your Wallet</div>
                                <div className="value text-white text-opacity-75">08-08-2024</div>
                            </div>
                            <div className="col-6 col-lg-2 info-box text-capitalize">
                                <div className="label fs-14 text-warning">Opportunity in Other's Wallet</div>
                                <div className="value text-white text-opacity-75">No</div>
                            </div>
                            <div className="col-6 col-lg-2 info-box text-capitalize">
                                <div className="label fs-14 text-warning">Opportunity Status</div>
                                <div className="value fw-medium text-info">In Progress</div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-2 mt-3">
                        <div className="col-12 col-lg-3 info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Type</div>
                            <div className="value text-white text-opacity-75">In-House Team</div>
                        </div>
                        <div className="col-6 col-lg-2 info-box text-capitalize">
                            <div className="label fs-14 text-warning">Client Type</div>
                            <div className="value text-white text-opacity-75">Additional New</div>
                        </div>
                        <div className="col-6 col-lg-3 info-box text-capitalize">
                            <div className="label fs-14 text-warning">Skill Required</div>
                            <div className="value text-white text-opacity-75">Virtual Assistant</div>
                        </div>
                        <div className="col-6 col-lg-2 info-box text-capitalize">
                            <div className="label fs-14 text-warning">Country</div>
                            <div className="value text-white text-opacity-75">Australia</div>
                        </div>
                        <div className="col-6 col-lg-2 info-box text-capitalize">
                            <div className="label fs-14 text-warning">TimeZone</div>
                            <div className="value text-white text-opacity-75">Adelaide Time</div>
                        </div>
                    </div>
                    <hr className="divider border border-top-2 border-secondary border-opacity-75 mt-4 mb-3" />
                    <div className="description">
                        <div className="row gx-2 gy-3">
                            <div className="col-lg-8">
                                <div className="total-insentive fs-5 fw-semibold text-white lh-sm mb-1">Total Insentive: ₹10,000</div>
                                <div className="fs-12 text-white text-opacity-75">Insentive Redemption: The process will be available to you once the Opportunity status turns WON and payment is received from the client.</div>
                            </div>
                            <div className="col-lg-4 text-lg-end">
                                <Button variant="success" className="rounded-1 px-3 py-2" onClick={close}>Add To My Wallet</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
