import React, { useState, useEffect } from "react";
import '../wallet.scss';
import { Button, Form } from 'react-bootstrap'
import SearchIcon from '../../resources/images/search.svg';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { setUserInfo } from "../../store/actions";
import { connect } from "react-redux";

const IncentiveDashboard = ({ setLoggedinUser, userRole }) => {
    const [user, loading, error] = useAuthState(auth);
    let navigate = useNavigate();

    const [detailsTab, setDetailsTab] = useState('All');

    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
    
          const userInfo = {
            uid: user?.uid,
            email: user?.email,
            displayName: user?.displayName,
            accessToken: user?.accessToken,
            idToken: user?.idToken,
          };
    
          setLoggedinUser(userInfo);
        //   loadWallet(userRole);
    
        }
      }, [user, loading, userRole]);

    return (
        <div className="incentives-page Wallet-page py-3">
            <div className="container-fluid px-xxl-5">
                <div className="d-flex flex-wrap flex-column flex-md-row align-items-md-center justify-content-between gap-2 mb-3">
                    <div className="fs-3 fw-semibold text-light lh-sm text-nowrap">Incentive Approval Dashboard</div>
                    <div className="date-wrapper d-flex flex-wrap flex-xl-nowrap align-items-md-center gap-2 gap-md-3 gap-xl-4">
                        <Form.Group className="d-flex flex-column flex-md-row flex-fill gap-1 gap-md-2">
                            <Form.Label className="text-nowrap d-md-none m-0">&nbsp;</Form.Label>
                            <div className="searchfield-wrapper position-relative" controlId="statusType">
                                <img src={SearchIcon} alt='Search Icon' className='position-absolute top-50 start-0 translate-middle-y ms-3' />
                                <Form.Control type="search" className="bg-transparent pe-3 py-2" placeholder="Search with Opportunity ID / Service ID / Invoice ID" style={{paddingLeft:'45px'}} />
                            </div>
                        </Form.Group>
                        <Form.Group className="client-type d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2" controlId="clientType">
                            <Form.Label className="text-nowrap m-0">Client Type</Form.Label>
                            <Form.Select className="bg-transparent py-2" style={{ minWidth: '120px' }} required>
                                <option value="0">Cross-scaling Full Time 1/2</option>
                                <option value="1">Other</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2" controlId="requestedFrom">
                            <Form.Label className="text-nowrap m-0">Requested From</Form.Label>
                            <Form.Control type="date" className="bg-transparent py-2"
                                min={new Date().toJSON().slice(0, 10)}
                                defaultValue={new Date().toJSON().slice(0, 10)}
                            />
                        </Form.Group>
                        <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2" controlId="requestedTo">
                            <Form.Label className="text-nowrap m-0">Requested To</Form.Label>
                            <Form.Control type="date" className="bg-transparent py-2"
                                min={new Date().toJSON().slice(0, 10)}
                                defaultValue={new Date().toJSON().slice(0, 10)}
                            />
                        </Form.Group>
                    </div>
                </div>

                <div className="tabs-wrapper">
                    <Tabs id="controlled-tab-example" activeKey={detailsTab} onSelect={(k) => setDetailsTab(k)} className="bg-transparent rounded-top-3 mb-3">
                        <Tab eventKey="All" title="All">
                            <div className='tab-content-wrapper'>
                                <div className="row g-3">
                                    <div className="col-lg-6">
                                        <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden">
                                            <div className="header-section p-3" style={{ backgroundColor: '#8e7011' }}>
                                                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1">
                                                    <div class="fs-4 fw-semibold text-white lh-sm">₹5,000 <span className="text-white text-opacity-50 fs-6 fw-semibold ms-1">Installment 2 of 3</span></div>
                                                    <div class="text-white text-opacity-50 fs-6">Requested on: 2023-12-06 | <span className="text-white fw-medium">Pending</span></div>
                                                </div>
                                            </div>
                                            <div className="body-section p-3">
                                                <div className="row g-3">
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Requested by</div>
                                                        <div className="value text-white text-opacity-75">Raghuveer Kumar Prasad</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Opportunity ID</div>
                                                        <div className="value text-white text-opacity-75 text-break">Xaurl Churay_080110_009</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Client Type</div>
                                                        <div className="value text-white text-opacity-75">Cross-scaling Full-Time 1/2</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Service Month</div>
                                                        <div className="value text-white text-opacity-75">October 2024</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Service ID</div>
                                                        <div className="value text-white text-opacity-75 text-uppercase text-break">SERV29044404</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Invoice ID</div>
                                                        <div className="value text-white text-opacity-75">VE/2024/Oct-2024</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden">
                                            <div className="header-section p-3" style={{ backgroundColor: '#1a5338' }}>
                                                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1">
                                                    <div class="fs-4 fw-semibold text-white lh-sm">₹5,000 <span className="text-white text-opacity-50 fs-6 fw-semibold ms-1">Installment 2 of 3</span></div>
                                                    <div class="text-white text-opacity-50 fs-6">Requested on: 2023-12-06 | <span className="text-white fw-medium">Approved</span></div>
                                                </div>
                                            </div>
                                            <div className="body-section p-3">
                                                <div className="row g-3">
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Requested by</div>
                                                        <div className="value text-white text-opacity-75">Raghuveer Kumar Prasad</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Opportunity ID</div>
                                                        <div className="value text-white text-opacity-75 text-break">Xaurl Churay_080110_009</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Client Type</div>
                                                        <div className="value text-white text-opacity-75">Cross-scaling Full-Time 1/2</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Service Month</div>
                                                        <div className="value text-white text-opacity-75">October 2024</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Service ID</div>
                                                        <div className="value text-white text-opacity-75 text-uppercase text-break">SERV29044404</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Invoice ID</div>
                                                        <div className="value text-white text-opacity-75">VE/2024/Oct-2024</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden">
                                            <div className="header-section p-3" style={{ backgroundColor: '#7d2930' }}>
                                                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1">
                                                    <div class="fs-4 fw-semibold text-white lh-sm">₹5,000 <span className="text-white text-opacity-50 fs-6 fw-semibold ms-1">Installment 2 of 3</span></div>
                                                    <div class="text-white text-opacity-50 fs-6">Requested on: 2023-12-06 | <span className="text-white fw-medium">Disapproved</span></div>
                                                </div>
                                            </div>
                                            <div className="body-section p-3">
                                                <div className="row g-3">
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Requested by</div>
                                                        <div className="value text-white text-opacity-75">Raghuveer Kumar Prasad</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Opportunity ID</div>
                                                        <div className="value text-white text-opacity-75 text-break">Xaurl Churay_080110_009</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Client Type</div>
                                                        <div className="value text-white text-opacity-75">Cross-scaling Full-Time 1/2</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Service Month</div>
                                                        <div className="value text-white text-opacity-75">October 2024</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Service ID</div>
                                                        <div className="value text-white text-opacity-75 text-uppercase text-break">SERV29044404</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                        <div className="label fs-14 text-warning">Invoice ID</div>
                                                        <div className="value text-white text-opacity-75">VE/2024/Oct-2024</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="Pending" title="Pending(10)">
                            <div className='tab-content-wrapper'>
                                No content available here
                            </div>
                        </Tab>
                        <Tab eventKey="Approved" title="Approved (1.2K)">
                            <div className='tab-content-wrapper'>
                                No content available here
                            </div>
                        </Tab>
                        <Tab eventKey="Disapproved" title="Disapproved(299)">
                            <div className='tab-content-wrapper'>
                                No content available here
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
   
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(IncentiveDashboard);