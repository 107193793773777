import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { callAxios } from '../utils/utils';
import { environment } from '../environment';
import { toast } from 'react-toastify';

export const DeleteConfirmModal = ({ show, close, walletOppInfo,userRole,loadWallet,setShowDeleteModal }) => {
    const [reason, setReason] = useState('');
    // console.log(redeemAmount, walletOppInfo)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    
    const handleDelete = async()=>{ 
        console.log(reason, walletOppInfo)
        if(reason.length > 0){
            setIsDeleteLoading(true)
        
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/delete-op`,{wallet_id:walletOppInfo.wallet_id,userType:userRole,bonus:walletOppInfo.bonus,reason:reason,isDeleted:true, opid:walletOppInfo.id},"post").then((res)=>{
            if(res.status ===  200){

                setIsDeleteLoading(false)
                setShowDeleteModal(false)
                loadWallet(userRole)
                toast.error("Opportunity deleted", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
        })
        } else{
            toast.error("explain reason first", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });

              return false
        }
        
    }
 

    return (
        <Modal show={show} centered onHide={close} size='xl' scrollable backdrop="static" keyboard={false} dialogClassName="redeemAmountModal" contentClassName='rounded overflow-hidden'>
            <Modal.Body className="wallet-info-section text-black px-3 px-lg-5 py-4">
                <div className="fs-3 fw-bold lh-sm mb-2 mb-xl-3">Why are you want to delete this opportunity</div>
               
                <Form >
                    <div className="wrapper mb-3">
                        <div className="lh-sm mb-2 mb-xl-3">Explain Reason in some lines</div>
                        <div className="checks-wrapper">
                        <Form.Group className="mb-3" controlId="addSubject">
                            <Form.Control type="text" id="reason" className="custom-input fw-medium border shadow-none py-2" placeholder="Explain reason" autoComplete="off" required  onChange={(e) => {
                      setReason(e.target.value);
                    
                      
                    }} 
                     
                    />
                        </Form.Group>
                        </div>
                    </div>


                    <div className="btn-wrapper d-flex flex-wrap flex-column flex-sm-row justify-content-center gap-2 gap-sm-3 pt-2">
                        <Button variant='secondary' className='btn-custom px-5 py-2' onClick={close}>Cancel</Button>
                        {isDeleteLoading?
                        <Button variant="success" className="rounded-1 px-3 py-2 loader-add-wallet" ></Button>
                        :
                        <Button variant='success' className='btn-custom px-5 py-2' onClick={handleDelete}>Delete</Button>
                        }
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
