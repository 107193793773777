import React, { useEffect, useMemo, useState } from "react";
import { database, auth } from "../firebaseConfig";
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  onSnapshot,
  orderBy,
  updateDoc,
  limit,
  getDoc,
} from "firebase/firestore";
import notificationSound from "../static/Notification.mp3";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  setIdentity,
  setRoomId,
  setFormId,
  setUserInfo,
  setUserRole,
} from "../store/actions";
import axios from "axios";

import { useNavigate } from "react-router-dom";
// import { v4 as uuidv4 } from "uuid";

//import { db } from '../../../firebase/firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import "./LeadsListingPage.css";
// import Header from "../Header/Header";
import CircularProgressBar from "../CircularProgressBar/CircularProgressBar";
import WhoTookBdmChart from "../Charts/WhoTookBdmChart";

// api call import

import { callAxios } from "../utils/utils";

// env import
import { environment } from "../environment";
import ClientsConnectedChart from "../Charts/ClientsConnectedChart";
import JoinedMissedChart from "../Charts/JoinedMissedChart";

// convertMilisecondsToTime conversion
import { convertMilisecondsToTime } from "../utils/utils";

// BDMCAM list import
import { BdmCamList } from "./BdmCamList";
import { JoinNowPopup } from "./JoinNowPopup";

// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import preloader css
import "./LeadsListingPage.css";
import { RefreshApp } from "./RefreshApp";
import notificationSoundS from "../static/reloadRing.mp3";
import { WelcomeScreenPopup } from "./WelcomeScreenPopup";
import NotificationSoundForOther from "../static/NotificationSoundForOther.mp3";

const LeadsDescriptionPage = (props) => {
  const [audioT, setAudioT] = useState(new Audio(notificationSoundS));
  const [otherSound, setOtherAudio] = useState(new Audio(NotificationSoundForOther));
  localStorage.getItem("appVersion") ? localStorage.getItem("appVersion") : localStorage.setItem("appVersion", "");
  const {
    setRoomIdAction,
    setIdentityAction,
    roomId,
    setFormIdAction,
    setLoggedinUser,
    setUserRoleAction
  } = props;

  const [user, loading, error] = useAuthState(auth);
  let navigate = useNavigate();

  const [isGoLive, setGoLive] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [allData, setAllDate] = useState([]);
  const [userRole, setUserRole_] = useState('');
  const [isLoading, setIsloading] = useState(true);
  const [onlineBDM, setOnlineUsers] = useState([]);
  const [audio, setAudio] = useState(new Audio(notificationSound));
  const [isNotify, setNotify] = useState(false);

  // modal state
  const [question1, setQuestion1] = useState("");
  const [question2, setQuestion2] = useState("");
  const [question3, setQuestion3] = useState("");
  const [question1Description, setQuestion1Description] = useState("");
  const [lastQuestion2Description, setLastQuestionDescription] = useState("");
  const [RoomId, setRoomIdForSave] = useState("");
  // end

  // geting online BDM
  const [onlineBdm, setOnlineBdm] = useState([]);
  const [relavancyList, setRelavancyList] = useState([]);
  
  //console.log("user current version:", localStorage.getItem("appVersion"));
  const userRoleStore = useSelector((state) => state.userRole);
  const loggedInUserRole = userRoleStore ? userRoleStore : localStorage.getItem("userRole");
  const [currentAppVersion, setAppVersion] = useState("");
  const currentVersion = async () => {
    const myDocRef = doc(database, environment.REACT_APP_VERSION_DB, "zhwbBpi4c6AQFYJmai2Q");
    const myDoc = await getDoc(myDocRef);
    if (myDoc.exists()) {
      setAppVersion(myDoc?.data()?.version);
      if (myDoc.data().version !== localStorage.getItem("appVersion")) {
        const refreshApp = document.getElementById("refreshApp");
        const availableVersion = document.getElementById("available-version");
        //setAppVersion(myDoc.data().version);
        localStorage.setItem("camJoinedLead", null);
        localStorage.setItem("bdmJoinedLead", null);
        if (refreshApp) {
          refreshApp.classList.add("show");
          refreshApp.style.display = "block";
        }
        if (availableVersion) {
          availableVersion.innerHTML = `<p>We’re excited to announce that <span class="text-warning">Version ${myDoc.data().version} of the NM App is now available!</span> Click the update button to enjoy the latest features and improvements.</p>`;
        }
      }
    }

    if (loggedInUserRole === "CAM" || loggedInUserRole === "BDM") {
      const welcomeScreenPopupElement = document.getElementById('welcome-screen-pop-up');
      const welcomeScreenPopup = document.getElementById('welcome-screen-pop-up');
      if (localStorage.getItem("checkAudio") == null || localStorage.getItem("checkAudio") == 'null') {
        if (welcomeScreenPopup) {
          welcomeScreenPopupElement.classList.add("show");
          welcomeScreenPopupElement.style.display = "block";
        }
      }
      const dateOld = new Date(localStorage.getItem("checkAudio")).toLocaleDateString();

      if (dateOld !== new Date().toLocaleDateString()) {
        if (welcomeScreenPopup) {
          welcomeScreenPopupElement.classList.add("show");
          welcomeScreenPopupElement.style.display = "block";
        }
      }
    }

  }
  currentVersion();

  const queryConstraintsBdm = [];
  const bdmQuery = query(
    collection(database, environment.REACT_APP_USERS_DB),
    ...queryConstraintsBdm
  );

  useEffect(() => {
    const getBDMList = async () => {
      onSnapshot(bdmQuery, (querySnapshot) => {
        let bdmUsers = [];
        querySnapshot.forEach((doc) => {
          //console.log(doc.data().email)
          const data = doc.data();
          data["email"] = doc.email;

          if (
            data.status &&
            data.status == "Online" &&
            data.userType === "BDM"
          ) {
            bdmUsers.push(doc.data().email);
          }
        });
        //console.log(bdmUsers)
        setOnlineBdm(bdmUsers);
      });
    };
    getBDMList();
  }, []);
  //
  //console.log(answers)

  var startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);
  var endOfToday = new Date();
  endOfToday.setHours(23, 59, 59, 999);
  const queryConstraints = [];
  queryConstraints.push(where("timeStamp", ">=", startOfToday));
  queryConstraints.push(where("timeStamp", "<=", endOfToday));
  queryConstraints.push(where("callStatus", "==", 1));
  queryConstraints.push(orderBy("timeStamp", "desc"));
  const q = query(
    collection(database, environment.REACT_APP_FIREBASE_DB),
    ...queryConstraints
  );
  const getUserRole = async (email) => {
    //console.log("getting role")

    const q = query(
      collection(database, environment.REACT_APP_USERS_DB),
      where("email", "==", email),
      orderBy("timeStamp"),
      limit(1)
    );
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        data["id"] = doc.id;
        //setUserRole(data);
        setUserRoleAction(data?.userType);
        setUserRole_(data?.userType);
        localStorage.setItem("userRole", data?.userType);
      });
    });

  };
  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };
      // saveUserLogged(userInfo);
      getUserRole(user?.email);
      setLoggedinUser(userInfo);

      // const getQuestios = async () => {
      //   await callAxios(
      //     environment.REACT_APP_API_URL_Live + "api/v1/questions/get-questions",
      //     {},
      //     "post"
      //   ).then((questions) => {
      //     setQuestions(questions);
      //   });
      // };
      // getQuestios();
      const getAnswers = async () => {
        await callAxios(
          environment.REACT_APP_API_URL_Live + "api/v1/questions/get-answers",
          { uid: user?.uid },
          "post"
        ).then((answers) => {
          setAnswers(answers);
        });
      };

      getAnswers();
    }
  }, [user, loading]);
  //console.log("questions:",questions)
  // console.log("answers:",answers)


  // console.log(allData.length)

  let vlisting;

  if (allData?.length > 0) {
    vlisting = allData.map((item, index) => {
      const missedBy = [];
      // // console.log(onlineBDM)
      // const Index = getIndex(onlineBDM, item.roomId);
      // //console.log(Index, item.roomId)
      // onlineBDM[Index]?.names
      //   .toString()
      //   .split(",")
      // if(item?.missedBy){
      //   item?.missedBy.map((callMissed, index) => {
      //     if (item.attendantBdmName !== callMissed) {
      //       missedBy.push(callMissed);
      //     }
      //   });
      // }

      if (item?.missedBy) {
        for (let x = 0; x < item?.missedBy?.length; x++) {
          if (item.attendantBdmName !== item.missedBy[x].name) {
            missedBy.push(item.missedBy[x]);
          }
        }
      }
      let relevantNotrelevant = [];
      let filterCriteria;
      let missedByUpdated = [];
      if (item.relevant && item.notRelevant) {
        relevantNotrelevant = item.relevant.concat(item.notRelevant);

      } else if (item.relevant) {
        relevantNotrelevant = item.relevant;
      } else if (item.notRelevant) {
        relevantNotrelevant = item.notRelevant;
      } else {
        relevantNotrelevant = [];
      }

      if (relevantNotrelevant && relevantNotrelevant.length > 0) {
        filterCriteria = relevantNotrelevant.map((itemElement) => {
          return itemElement.email;
        });
        missedByUpdated = missedBy.filter(item => !filterCriteria.includes(item.email));

      } else {
        missedByUpdated = missedBy
      }
      // const relavancyDataIndex = getIndex(relavancyList, item.roomId);
      // //console.log(item.name,item.roomId)
      const shouldShowEvalutionButton = answers?.data?.some(
        (relevance) =>
          relevance.roomId === item.roomId && user?.uid === relevance.uid
      );
      //console.log(item.name," | ",item.roomId)
      // if(item.RoomStatus === 'in-progress'){
      // }
      let total = 0;
      total += item.name !== "" ? 1 : 0;
      total += item.email !== "" ? 1 : 0;
      total += item.phone !== "" ? 1 : 0;
      total += item.companyweb !== "" ? 1 : 0;
      total += item.projduration !== "" ? 1 : 0;
      total += item.description !== "" ? 1 : 0;

      const percentage = parseFloat((total * 100) / 6).toFixed(0);

      const fireBaseTime = new Date(
        item.timeStamp.seconds * 1000 + item.timeStamp.nanoseconds / 1000000
      );
      const dateNew = fireBaseTime.toDateString();
      const atTime = fireBaseTime.toLocaleTimeString();
      let timeStampfor = dateNew + " " + atTime;
      const ppcPramUrl = item.ppcurl.split("?")[1];
      const urlParams = new URLSearchParams(ppcPramUrl);
      return (
        <>
          <div className="info-card rounded-2 mt-3" key={"lead-description-key-" + index}>
            {/*urlParams.get("source") ? (
              <div className="lead-source bg-secondary fs-6 d-flex justify-content-between gap-3 text-nowrap overflow-auto text-light rounded-top p-3">
                {urlParams.get("device") ? (
                  <div className="text-capitalize">
                    Lead From:{" "}
                    <span className="fw-normal">
                      {urlParams.get("device") === "c" ? "Desktop" : "Mobile"}
                    </span>
                  </div>
                ) : null}

                { {urlParams.get("source")?
          <div className="text-capitalize">Lead Source: <span className="fw-normal">{urlParams.get("source")}</span></div>
          :null }
          
          {urlParams.get("medium") ?
          <div className="text-capitalize">Medium: <span className="fw-normal text-uppercase">{urlParams.get("medium")}</span></div>
          :null } }

                {urlParams.get("campaign") ? (
                  <div className="text-capitalize">
                    Lead Campaign:{" "}
                    <span className="fw-normal">
                      {urlParams.get("campaign").replace(/_/g, " ")}
                    </span>
                  </div>
                ) : null}

                {urlParams.get("adgroup") ? (
                  <div className="text-capitalize">
                    Lead Ad Group:{" "}
                    <span className="fw-normal">
                      {urlParams.get("adgroup").replace(/_/g, " ")}
                    </span>
                  </div>
                ) : null}
              </div>
                ) : ( */}
            {urlParams.get("adgroup") ?
              <div className="lead-source bg-secondary fs-6 d-flex justify-content-between gap-3 text-nowrap overflow-auto text-light rounded-top p-3">
                <div className="text-capitalize">
                  Expected Need: <span className="fw-normal"> {urlParams.get("adgroup").replace(/_/g, " ")}</span>
                </div>
              </div>
              :
              <div className="lead-source bg-secondary fs-6 d-flex justify-content-between gap-3 text-nowrap overflow-auto text-light rounded-top p-3">
                <div className="text-capitalize">
                  Lead Source: <span className="fw-normal"> Organic</span>
                </div>
              </div>
            }
            {/* )} */}
            <div className="info-card-details overflow-auto p-3">
              <div className="row gx-3">
                <div className="col-lg">
                  {item.name ? (
                    <div className="client-name text-capitalize mb-1">
                      {item.name}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg">
                  {item?.participants && item?.participants?.length - 1 > 0 ? (
                    <div className="d-flex align-items-center total-participants active text-capitalize lh-1">
                      <span>
                        <i className="bi bi-people-fill me-2"></i>
                      </span>{" "}
                      Total Participants{" "}
                      {item?.participants ? item?.participants?.length - 1 : 0}
                    </div>
                  ) : (
                    <div className="d-flex align-items-center total-participants text-capitalize lh-1">
                      <span>
                        <i className="bi bi-people-fill me-2"></i>
                      </span>{" "}
                      Total Participants 0
                    </div>
                  )}
                </div>
                <div className="col-lg mt-2 mt-lg-0">
                  {item.timeStamp ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      Client Started Filling Form AT:{" "}
                      <span className="text-nowrap">
                        {item.timeStamp
                          ? convertMilisecondsToTime(
                            item.timeStamp.seconds,
                            item.timeStamp.nanoseconds
                          )
                          : ""}
                      </span>
                    </div>
                  ) : null}

                  {item.camJoinedAt ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      CAM Joined AT:{" "}
                      <span className="text-nowrap">
                        {item.camJoinedAt
                          ? convertMilisecondsToTime(
                            item.camJoinedAt.seconds,
                            item.camJoinedAt.nanoseconds
                          )
                          : ""}
                      </span>
                    </div>
                  ) : null}

                  {item.clientSubmitTime ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      Client Submitted Form AT:{" "}
                      <span className="text-nowrap">
                        {item.clientSubmitTime
                          ? convertMilisecondsToTime(
                            item.clientSubmitTime.seconds,
                            item.clientSubmitTime.nanoseconds
                          )
                          : ""}
                      </span>
                    </div>
                  ) : null}

                  {item.camClickedMobileButtonTime ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      CAM Contacted Client's Mobile AT:{" "}
                      <span className="text-nowrap">
                        {item.camClickedMobileButtonTime
                          ? convertMilisecondsToTime(
                            item.camClickedMobileButtonTime.seconds,
                            item.camClickedMobileButtonTime.nanoseconds
                          )
                          : ""}
                      </span>
                    </div>
                  ) : null}

                  {item.CallStatus ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      Client's Mobile Call Status:{" "}
                      <span className="text-nowrap">
                        {item.CallStatus ? item.CallStatus : ""}
                      </span>
                    </div>
                  ) : null}

                  {item.clientJoinedLiveTime ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      Client Click On Join Live Button AT:{" "}
                      <span className="text-nowrap">
                        {item.clientJoinedLiveTime
                          ? convertMilisecondsToTime(
                            item.clientJoinedLiveTime.seconds,
                            item.clientJoinedLiveTime.nanoseconds
                          )
                          : ""}
                      </span>
                    </div>
                  ) : null}

                  {item.clientLeftTime ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      Client Left Video Room AT:{" "}
                      <span className="text-nowrap">
                        {item.clientLeftTime
                          ? convertMilisecondsToTime(
                            item.clientLeftTime.seconds,
                            item.clientLeftTime.nanoseconds
                          )
                          : ""}
                      </span>
                    </div>
                  ) : null}

                  {item.camLeftAt ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      CAM LEFT Video Room AT:{" "}
                      <span className="text-nowrap">
                        {item.camLeftAt
                          ? convertMilisecondsToTime(
                            item.camLeftAt.seconds,
                            item.camLeftAt.nanoseconds
                          )
                          : ""}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className="col-lg mt-3 mt-lg-0 text-lg-center">
                  <div className="client-progress-status d-lg-flex justify-content-lg-center">
                    <div className="progressbar-label text-white text-opacity-75 mb-1 d-lg-none">
                      Client's Form Filling Status
                    </div>
                    <CircularProgressBar percentage={percentage} />
                  </div>
                </div>
                <div className="col-lg mt-3 mt-lg-0 text-lg-end order-5 order-lg-0">
                  <div className="date-time-state mb-1">{timeStampfor}</div>
                  {item.RoomStatus == "in-progress" && !item.camLeftAt ? (
                    <div className="department-name text-capitalize mb-1">
                      CAM {item.attendantName} already in the group
                    </div>
                  ) : null}
                  {item.country ? (
                    <div className="country-name text-capitalize text-warning text-opacity-75 mb-1">
                      Client Location {item.country}
                    </div>
                  ) : null}
                  {item.attendantBdmName ? (
                    <div className="department-name text-capitalize mb-1">
                      {item?.attendantBdmName && item?.attendantBdmName != ""
                        ? "Attn By :-" + item?.attendantBdmName
                        : ""}
                    </div>
                  ) : null}

                  {/* <button
                      onClick={() =>
                        handleHostMeeting(
                          item.id,
                          "_BDM_" + user?.displayName,
                          item.roomId
                        )
                      }
                      className="btn btn-success btn-custom btn-next rounded-1 mt-1"
                    >
                      Go Live With CAM
                    </button> */}

                  {item?.callStatus == 1 &&
                    item.RoomStatus === 'in-progress' &&
                    !item.camLeftAt &&
                    item.description &&
                    user?.displayName !== item?.attendantBdmName ? (
                    !answers ? (
                      <button
                        className="btn btn-success btn-custom btn-next rounded-1 mt-1 me-2"
                        data-bs-toggle="modal"
                        data-bs-target="#relevanceModal"
                        onClick={(e) => {
                          setRoomIdForSave(item.roomId);
                        }}
                      >
                        Evaluate Relevance
                      </button>
                    ) : answers ? (
                      shouldShowEvalutionButton ? null : (
                        <button
                          className="btn btn-success btn-custom btn-next rounded-1 mt-1 me-2"
                          data-bs-toggle="modal"
                          data-bs-target="#relevanceModal"
                          onClick={(e) => {
                            setRoomIdForSave(item.roomId);
                          }}
                        >
                          Evaluate Relevance
                        </button>
                      )
                    ) : null
                  ) : null}
                  {item?.callStatus == 1 &&
                    item?.RoomStatus === 'in-progress' &&
                    !item.bdmJoinedAt &&
                    !item.camLeftAt &&
                    item.description ? (
                    <button
                      onClick={() =>
                        handleHostMeeting(
                          item.id,
                          "_BDM_" + user?.displayName,
                          item.roomId
                        )
                      }
                      className="btn btn-success btn-custom btn-next rounded-1 mt-1"
                    >
                      Go Live With CAM
                    </button>
                  ) : item.bdmJoinedAt ? (
                    <button
                      onClick={() => { }}
                      className="btn btn-warning btn-custom btn-next rounded-1 mt-1 "
                    >
                      BDM Joined
                    </button>
                  ) : item.camJoinedAt && !item.description ? (
                    <div
                      style={{
                        color: "orangered",
                        textTransform: "capitalize !important",
                        fontWeight: "bold",
                      }}
                    >
                      After description, you'll get join button with CAM.
                    </div>
                  ) : (
                    <button
                      onClick={() => { }}
                      className="btn btn-danger btn-custom btn-next rounded-1 mt-1"
                    >
                      Missed
                    </button>
                  )}
                </div>
              </div>
              {/* Show BDMs Notice */}
              {item?.callStatus == 1 &&
                item.RoomStatus == "in-progress" &&
                !item.camLeftAt &&
                item.description ? (
                <>
                  <hr className="mb-3" />
                  <div className="alert alert-success label-notice">
                    <strong>
                      Attention: All online Business Development Managers (BDMs)
                      are expected to provide their relevancy on all leads,
                      regardless of whether a particular BDM has joined and you
                      didn't.
                    </strong>
                    Each online BDM is obligated to contribute their relevancy.
                    Please note that the relevancy button will only be
                    accessible during an active call. Therefore, all active BDMs
                    must submit their relevancy in real-time.
                    <strong>
                      Failure to do so, will result in your placement in the
                      'Missed Call' section.
                    </strong>
                  </div>
                </>
              ) : null}
              <div className="requirement-wrapper">
                <hr className="mb-2" />
                {item.description ? (
                  <div className="requrement-label fs-6 text-white text-opacity-75 mb-1">
                    Requirement Details
                  </div>
                ) : (
                  <div className="requrement-label fs-6 text-danger mb-1">
                    Client didn't fill the requirement yet
                  </div>
                )}
                {item.description ? (
                  <div className="client-requrement fs-14">
                    {item.description ? item.description : null}
                  </div>
                ) : null}
              </div>
              <hr className="my-2" />
              <div className="cam-wrapper">
                {item.missedBy ? (
                  <div className="fs-6 fw-medium mb-3 text-light">
                    When this Lead arrived,{" "}
                    <span className="text-warning text-capitalize">
                      {item.missedBy.length} BDMs
                    </span>{" "}
                    were online in total.
                  </div>
                ) : null}
                {/* BDM on Call */}

                {item.attendantName ? (
                  <div className="d-flex flex-wrap align-items-center gap-2 mt-3">
                    <div className="label text-nowrap text-uppercase fw-semibold text-primary">
                      CAM Joined:
                    </div>
                    <div className="d-flex flex-wrap text-nowrap gap-2">
                      <button className="btn btn-primary text-capitalize">
                        {" "}
                        {item.attendantName}
                      </button>
                    </div>
                  </div>
                ) : null}

                {/* Lead also relevant to */}

                {item.relevant ?
                  item.relevant.length > 0 ? (
                    <div className="d-flex flex-wrap align-items-center gap-2 mt-3">
                      <div className="label text-nowrap text-uppercase fw-semibold text-success">
                        Lead Also Relevant To:
                      </div>
                      <div className="d-flex flex-wrap text-nowrap gap-2">
                        {item.relevant.map(
                          (relevant, index) => {
                            return (
                              <button className="btn btn-success text-capitalize">
                                {relevant.display_name}
                              </button>
                            );
                          }
                        )}
                      </div>
                    </div>
                  ) : null
                  : null
                }

                {/* Lead not relevant to */}
                {item.notRelevant ?
                  item.notRelevant.length > 0 ? (
                    <div className="d-flex flex-wrap align-items-center gap-2 mt-3">
                      <div className="label text-nowrap text-uppercase fw-semibold text-secondary">
                        Lead Not Relevant To:
                      </div>
                      <div className="d-flex flex-wrap text-nowrap gap-2">
                        {item.notRelevant.map(
                          (notRelevantname, index) => {
                            return (
                              <button className="btn btn-secondary text-capitalize">
                                {notRelevantname.display_name}
                              </button>
                            );
                          }
                        )}
                      </div>
                    </div>
                  ) : null
                  : null}

                {/* Call missed by */}
                {missedByUpdated && missedByUpdated.length > 0 && item?.description ? (
                  <div className="d-flex flex-wrap align-items-center gap-2 mt-3">
                    <div className="label text-nowrap text-uppercase fw-semibold text-danger">
                      Call Missed By:
                    </div>
                    <div className="d-flex flex-wrap text-nowrap gap-2">
                      {missedByUpdated.map((missedBdm, index) => {
                        return (
                          <button className="btn btn-danger text-capitalize">
                            {missedBdm.name}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      );
    });
  } else {
    vlisting =
      "There isn't any data available at the moment because NM-Live resets the previous day's data at 00:00, and we have not yet received any leads for today. If you wish to review your call details from previous days, you can access this information directly from your profile. Please note that this list maintains data for each day only until 23:59:59.";
  }

  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const userInfo = useSelector((state) => state.userInfo);
  const availableRoles = ["CAM", "BDM"];
  useEffect(() => {
    onSnapshot(q, (querySnapshot) => {
      let forms = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data["id"] = doc.id;
        if (data.formstatus > 0) forms.push({ ...doc.data(), id: doc.id });
        if (data?.RoomStatus === "in-progress" && !data?.isNotified) {
          // playSoundOnce(doc.id);
          console.log("before alerting bdm:::")
          if ((localStorage.getItem("bdmJoinedLead") == null || localStorage.getItem("bdmJoinedLead") == 'null') && availableRoles.includes(loggedInUserRole)) {
            console.log("alerting bdm:::")
            playSoundOnce(doc.id);
          }
          else if (loggedInUserRole === "SSA" || loggedInUserRole === "SA") {
            playSoundForOther();
          } else {

          }
        }
      });
      setAllDate(forms);
      setIsloading(false);

    });
  }, []);

  const playSoundOnce = async (dataID) => {
    const formRef = doc(database, environment.REACT_APP_FIREBASE_DB, dataID);
    await updateDoc(formRef, {
      isNotified: true,
    });
    audio.play();
  };

  const playSoundForOther = () => {
    otherSound.play();
  };

  const playSound = () => {
    audio.play();
  };
  const updateBdmJoined = async (formID) => {

    const formRef = doc(database, environment.REACT_APP_FIREBASE_DB, formID);
    await updateDoc(formRef, {
      bdmEmailJoined: user?.email
    });
  }
  const handleHostMeeting = async (dataID, nameValue, roomId) => {
    setGoLive(true);
    setIdentityAction(nameValue);
    setRoomIdAction(roomId);

    //   environment.REACT_APP_API_URL_Live + "api/v1/calls/record-calls-logs",
    //   {
    //     email: userInfo?.email,
    //     joinedCount: 1,
    //     type: "joined",
    //     roomId: roomId,
    //   },
    //   "post"
    // );
    setFormIdAction(dataID);
    document.getElementById("p-box").click();
    //updateBdmJoined(dataID)

  };

  let connectedBdm = 0;
  let notConnectedBdm = 0;
  let joinedWithClient = 0;
  let notJoinedWithClient = 0;

  for (let i = 0; i < allData.length; i++) {
    if (allData[i].bdmJoinedAt && allData[i].description) {
      connectedBdm += 1;
    }
    if (
      allData[i].camJoinedAt &&
      !allData[i].bdmJoinedAt &&
      allData[i].description
    ) {
      notConnectedBdm += 1;
    }
    if (
      allData[i]?.clientLeftTime &&
      allData[i].bdmJoinedAt &&
      allData[i].description
    ) {
      joinedWithClient += 1;
    }
    if (
      allData[i]?.participants?.length > 2 &&
      !allData[i].bdmJoinedAt &&
      allData[i].description
    ) {
      notJoinedWithClient += 1;
    }
  }
  // end graph data implementation

  //modal logic

  const handleHideShow = (value, type) => {
    if (type === "first") {
      if (value === "no") {
        document.getElementById("firstQuestionNo").classList.remove("d-none");
        document.getElementById("furtherQuestionArea").classList.add("d-none");
      } else {
        document.getElementById("firstQuestionNo").classList.add("d-none");
        document
          .getElementById("furtherQuestionArea")
          .classList.remove("d-none");
      }
    }

    if (type === "last") {
      if (value === "no") {
        document.getElementById("lastQuestionField").classList.add("d-none");
      } else {
        document.getElementById("lastQuestionField").classList.remove("d-none");
      }
    }
  };

  const checkValidation = (question, val) => {
    if (question === "q1" && val !== "") {
      document.getElementById("firstQuestionCss").style.border = "none";
    }
    if (question === "q2" && val !== "") {
      document.getElementById("secondQuestionCss").style.border = "none";
    }
    if (question === "q3" && val !== "") {
      document.getElementById("thirdQuestionCss").style.border = "none";
    }
    if (question === "q1-desc" && val !== "") {
      document.getElementById("firstQuestionNo").style.border = "none";
    }
    if (question === "q3-desc" && val !== "") {
      document.getElementById("lastQuestionField").style.border = "none";
    }
  };

  const updateLeadRelevantAndNotRelevant = async (roomId, type, object) => {
    const formData = query(
      collection(database, environment.REACT_APP_FIREBASE_DB),
      where("roomId", "==", roomId)
    );
    const querySnapshot = await getDocs(formData);
    querySnapshot.forEach(async (docData) => {
      // console.log(docData.data().relevant);
      const relevant = docData.data().relevant ? [...docData.data().relevant, object] : [object];
      const notRelevant = docData.data().notRelevant ? [...docData.data().notRelevant, object] : [object];
      //console.log(relevant);
      const formRef = doc(
        database,
        environment.REACT_APP_FIREBASE_DB,
        docData.id
      );
      if (type === "yes") {
        updateDoc(formRef, {
          //relevant: [...docData.data().relevant ,{display_name:name,email:email}],
          relevant: relevant
        })
          .then(() => {
            // console.log("clientJoined successfully");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        updateDoc(formRef, {

          // notRelevant: docData.data().notRelevant +','+name,
          //notRelevant: [...docData.data().relevant ,{display_name:name,email:email}],
          notRelevant: notRelevant
        })
          .then(() => {
            //console.log("bdm Joined successfully");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
  const submitEvalutionForm = async (e) => {
    e.preventDefault();
    const btnDisable = document.getElementById("disableEvalutionFormBtn");
    btnDisable.disabled = true;
    // return false;
    document.getElementById("relevance-preloader").classList.remove("d-none");
    // console.log("question1:",question1, " | question2:",question2, " | question3:",question3," | description 1:",question1Description, " | last questionDesc:",lastQuestion2Description, RoomId);
    if (question1 === "") {
      document.getElementById("firstQuestionCss").style.border =
        "1px solid red";
      btnDisable.disabled = false;
      return false;
    }

    if (question1 === "yes") {
      updateLeadRelevantAndNotRelevant(RoomId, "yes", { display_name: user?.displayName, email: user?.email })
      if (question2 === "") {
        document.getElementById("secondQuestionCss").style.border =
          "1px solid red";
        btnDisable.disabled = false;
        return false;
      }
      if (question3 === "") {
        document.getElementById("thirdQuestionCss").style.border =
          "1px solid red";
        btnDisable.disabled = false;
        return false;
      } else {
        if (question3 === "yes" && lastQuestion2Description === "") {
          document.getElementById("lastQuestionField").style.border =
            "1px solid red";
          btnDisable.disabled = false;
          return false;
        }
      }
    } else {
      updateLeadRelevantAndNotRelevant(RoomId, "no", { display_name: user?.displayName, email: user?.email })
      if (question1Description === "") {
        document.getElementById("firstQuestionNo").style.border =
          "1px solid red";
        btnDisable.disabled = false;
        return false;
      }
    }

    //console.log("saving data");
    const formData = {
      question1: question1,
      question2: question2,
      question3: question3,
      question1Description: question1Description,
      question3Description: lastQuestion2Description,
      roomId: RoomId,
      uid: user?.uid,
      email: user?.email,
      onlineBdm: onlineBdm.join(","),
    };
    //console.log(onlineBdm.join(","))


    btnDisable.disabled = true;
    const response = await callAxios(
      environment.REACT_APP_API_URL_Live +
      "api/v1/questions/store-evaluation-answer",
      formData,
      "post"
    );

    //console.log("response is:", response.data)
    if (response?.data?.status === 200) {
      resetForm();
      btnDisable.disabled = false;
      toast.success("Evalution relevance saved!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleCancel = async () => {
    const btnDisable = document.getElementById("disableEvalutionFormBtn");
    btnDisable.disabled = false;
  }
  //

  const resetForm = async () => {
    //console.log("In reset form");
    setQuestion1("");
    setQuestion2("");
    setQuestion3("");
    setQuestion1Description("");
    setLastQuestionDescription("");
    setRoomIdForSave("");
    await callAxios(
      environment.REACT_APP_API_URL_Live + "api/v1/questions/get-answers",
      { uid: user?.uid },
      "post"
    ).then((answers) => {
      setAnswers(answers);
    });
    document.getElementById("evalutionForm").reset();
    document.getElementById("firstQuestionNo").classList.add("d-none");
    document.getElementById("lastQuestionField").classList.add("d-none");
    document.getElementById("closeEvalutionModal").click();
    document.getElementById("relevance-preloader").classList.add("d-none");
  };
  return (
    <>
      {/* <ToastContainer /> */}
      <div className="container">
        <div className="live-listing-wrapper lead-description-wrapper py-3">
          <div className="d-flex flex-column flex-md-row gap-md-3 align-items-md-center mb-3">
            <div className="coll text-nowrap">
              <div className="main-heading text-white text-opacity-75">BDM Live Lead Listing</div>
            </div>
            <div className="coll text-nowrap">
              <div className="w-100 fs-6 text-light lh-sm">
                <marquee width="100%" direction="left" style={{ paddingTop: "5px" }}>
                  <span className="text-warning fw-semibold">
                    NOTICE FOR CAM & BDM:
                  </span>{" "}
                  Please remember to logout from NM-LiveCalls System when you're
                  away or at the end of your shift to avoid showing as available
                  to others.
                </marquee>
              </div>
            </div>
            <div className="coll text-nowrap">
              <button
                type="button"
                onClick={() => {
                  setNotify(true);
                  playSound();
                }}
                className={
                  isNotify
                    ? "btn btn-success mt-2 mt-md-0 rounded-1"
                    : "btn btn-warning mt-2 mt-md-0 rounded-1"
                }
              >
                {isNotify ? "Activated" : "Activate Notification"}
              </button>
            </div>
          </div>

          {/* BDM And CAM Listing */}
          <BdmCamList pageType="lead-description" />

          {/* Join Now Model Popup */}
          {useMemo(() => (
            <JoinNowPopup user={user} isGoLive={isGoLive} to="bdmroom" data={allData} userRole={userRole} />
          ), [isGoLive])}
          {/* <JoinNowPopup user={user} isGoLive={isGoLive} to="bdmroom" data={allData} /> */}

          <WelcomeScreenPopup />

          <RefreshApp currentAppVersion={currentAppVersion} />

          {allData.length > 0 ? (
            <div className="amchart-wrapper mb-2">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed bg-primary text-white p-3"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      Today Analysis
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body rounded-bottom p-3">
                      <div
                        className="alert alert-success alert-dismissible fade show small text-md-center"
                        role="alert"
                      >
                        <strong>Note: </strong>Currently, we are excluding any
                        test data that was generated from India (IN).
                        <button
                          type="button"
                          id="closeButton"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="row g-3">
                        <div className="col-sm-6 col-lg-3">
                          <div className="chart-heading">
                            How many BDM connected with client vs not?
                          </div>
                          <JoinedMissedChart
                            joined={joinedWithClient}
                            notJoined={notJoinedWithClient}
                            label1={"BDM joined with client"}
                            label2={"BDM not joined with client"}
                          />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                          <div className="chart-heading">
                            How many BDM connected with CAM today vs not?
                          </div>
                          <ClientsConnectedChart
                            connected={connectedBdm}
                            notConnected={notConnectedBdm}
                          />
                        </div>

                        {/* <div className="col-sm-6 col-lg-3">
                          <div className="chart-heading">Total Missed By CAM vs Joined By CAM Today?</div>
                          <JoinedMissedChart joined={joined} notJoined={notJoined} />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                          <div className="chart-heading">How many leads submit to CRM vs Not?</div>
                          <FormSubmitChart submitted={submitted} notSubmitted={notSubmitted} />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {allData.length > 0 ? (
            <div className="amchart-wrapper mb-lg-2">
              <div className="accordion" id="accordionExample-bar">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="heading1">
                    <button
                      className="accordion-button collapsed bg-primary text-white p-3"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseBar"
                      aria-expanded="false"
                      aria-controls="collapseBar"
                    >
                      Who took how many Calls Today
                    </button>
                  </h2>
                  <div
                    id="collapseBar"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading1"
                    data-bs-parent="#accordionExample-bar"
                  >
                    <div className="accordion-body rounded-bottom p-3">
                      <div
                        className="alert alert-warning alert-dismissible fade show small text-md-center"
                        role="alert"
                      >
                        <strong>Note: </strong>This data is taking test data,
                        too, into consideration in these reports. In the coming
                        days, it will get filtered.
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div>
                      {/* <div className="chart-heading text-center mb-3 small">Who took how many Calls Today</div> */}
                      <WhoTookBdmChart data={allData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="meeting-details-page rounded-3">
            <div
              className="list-header small fw-semibold text-white rounded-3 p-3 mb-3 d-none d-lg-block"
              style={{
                background: "linear-gradient(168.68deg,#0052c9 1.12%,#0a91db)",
              }}
            >
              <div className="row gx-3">
                <div className="col">Name</div>
                <div className="col">Participants</div>
                <div className="col">Time Stats</div>
                <div className="col text-lg-center">Client Form Status</div>
                <div className="col text-lg-end">Action</div>

              </div>
            </div>
            {/* <div>{vlisting}</div> */}
            {isLoading ? (
              <div className="d-flex flex-row-reverse align-items-center justify-content-center gap-3" id="lead-description-page-spinner">
                <div className="lead-list-spinner"></div>
                {" "} Please wait ....
              </div>
            ) : (
              <div>{vlisting}</div>
            )}
          </div>
        </div>
        {/* Evaluate Relevance Modal */}

        <div className="modal fade" id="relevanceModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="relevanceModalLabel" aria-hidden="true" >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content text-body border-0 border-5 border-top border-primary">
              <div className="modal-body px-4 px-md-5" style={{ padding: "35px" }}>
                <div className="modal-title fs-4 fw-semibold mb-4">Relevance Details</div>

                <form id="evalutionForm">
                  {/* If the first condition is true */}
                  <div className="mb-3" id="firstQuestionCss">
                    <label htmlFor="" className="form-label text-muted small">
                      Does the client's requirement align with your team's expertise?
                    </label>
                    <div className="check-wrapper">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="ques1"
                          onClick={(e) => {
                            setQuestion1(e.target.value);
                            handleHideShow("yes", "first");
                          }}
                          id="yes1" value="yes"
                          onChange={(e) => {
                            checkValidation("q1", e.target.value);
                          }}
                          required
                        />
                        <label className="form-check-label" htmlFor="yes1">Yes</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="ques1"
                          onClick={(e) => {
                            setQuestion1(e.target.value);
                            handleHideShow("no", "first");
                          }}
                          id="no1" value="no"
                          onChange={(e) => {
                            checkValidation("q1", e.target.value);
                          }}
                          required
                        />
                        <label className="form-check-label" htmlFor="no1">No</label>
                      </div>
                    </div>
                  </div>
                  <div className="d-none" id="furtherQuestionArea">
                    <div className="mb-3" id="secondQuestionCss">
                      <label htmlFor="" className="form-label text-muted small">
                        Does your team have available resources to fulfill the client's requirement at this time?
                      </label>
                      <div className="check-wrapper">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="ques2" id="yes2" value="yes"
                            onChange={(e) => {
                              checkValidation("q2", e.target.value);
                            }}
                            onClick={(e) => {
                              setQuestion2(e.target.value);
                            }}
                            required
                          />
                          <label className="form-check-label" htmlFor="yes2">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="ques2" id="no2" value="no"
                            onChange={(e) => {
                              checkValidation("q2", e.target.value);
                            }}
                            onClick={(e) => {
                              setQuestion2(e.target.value);
                            }}
                            required
                          />
                          <label className="form-check-label" htmlFor="no2">No</label>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3" id="thirdQuestionCss">
                      <label htmlFor="" className="form-label text-muted small">Has your team previously worked on a similar kind of project?</label>
                      <div className="check-wrapper">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="ques3" id="yes3"
                            onClick={(e) => {
                              setQuestion3(e.target.value);
                              handleHideShow("yes", "last");
                            }}
                            value="yes"
                            onChange={(e) => {
                              checkValidation("q3", e.target.value);
                            }}
                            required
                          />
                          <label className="form-check-label" htmlFor="yes3">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="ques3"
                            onClick={(e) => {
                              setQuestion3(e.target.value);
                              handleHideShow("no", "last");
                            }}
                            id="no3"
                            value="no"
                            onChange={(e) => {
                              checkValidation("q3", e.target.value);
                            }}
                            required
                          />
                          <label className="form-check-label" htmlFor="no3">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-none mb-3" id="lastQuestionField">
                      <textarea
                        className="form-control"
                        style={{ fontSize: "14px" }}
                        name="lastQuestionYes"
                        id="textarea1"
                        rows="4"
                        placeholder="Please briefly explain the project, the client, an…involved in a similar project you have completed."
                        onChange={(e) => {
                          setLastQuestionDescription(e.target.value);
                          checkValidation("q3-desc", e.target.value);
                        }}
                        required
                      ></textarea>
                    </div>
                  </div>
                  {/* If the first condition is false */}
                  <div className="mb-4 d-none" id="firstQuestionNo">
                    <textarea
                      className="form-control"
                      name="firstQuestionNo"
                      style={{ fontSize: "14px" }}
                      id="firstQuestionDescription"
                      rows="4"
                      placeholder="Please briefly explain why the client requirement does not match your team's skills or expertise."
                      onChange={(e) => {
                        setQuestion1Description(e.target.value);
                        checkValidation("q1-desc", e.target.value);
                      }}
                      required
                    ></textarea>
                  </div>
                </form>
                <div className="btn-wrapper">
                  <button
                    type="button"
                    id="closeEvalutionModal"
                    className="btn btn-secondary bg-gradient rounded-1 me-2"
                    data-bs-dismiss="modal"
                    style={{ minWidth: "80px" }}
                    onClick={handleCancel}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary bg-gradient rounded-1"
                    style={{ minWidth: "80px" }}
                    id="disableEvalutionFormBtn"
                    onClick={submitEvalutionForm}
                  >
                    Submit
                  </button>
                  <div className="d-none" id="relevance-preloader">
                    <div
                      style={{ marginLeft: "12px", float: "right" }}
                      className="spinner-relevance"
                    ></div>{" "}
                    Please wait ....
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <button
        style={{ display: "none" }}
        id="p-box"
        data-bs-toggle="modal"
        data-bs-target="#joinCall"
        className="btn btn-warning btn-custom btn-next rounded-1 mt-1"
      ></button>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    setRoomIdAction: (id) => dispatch(setRoomId(id)),
    setFormIdAction: (id) => dispatch(setFormId(id)),
    setLoggedinUser: (user) => dispatch(setUserInfo(user)),
    setUserRoleAction: (userRole) => dispatch(setUserRole(userRole)),
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(LeadsDescriptionPage);