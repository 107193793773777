import React, { useEffect, useState } from 'react';
import './CEOUpdate.scss';
import { Button } from 'react-bootstrap'
import { NewNoticeModal } from './NewNoticeModal';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserInfo } from '../store/actions';
import { callAxios, convertReadableTime } from '../utils/utils';
import { environment } from '../environment';
import { getShortName } from '../utils/emailUtils';
import { EditNotice } from './EditNotice';

const CEOUpdate = ({ setLoggedinUser, userRole }) => {
    // console.log(environment.CEO_UPDATE_USER)
    const [user, loading, error] = useAuthState(auth);
    let navigate = useNavigate();

    // New notice modal
    const [showNoticeModal, setShowNoticeModal] = useState(false);
    const closeNoticeModal = () => setShowNoticeModal(false);
    const openNoticeModal = () => setShowNoticeModal(true);

    // Edit notice modal
    const [showEditNoticeModal, setShowEditNoticeModal] = useState(false);
    const closeEditNoticeModal = () => setShowEditNoticeModal(false);
    const openEditNoticeModal = () => setShowEditNoticeModal(true);

    const [isLoading, setIsloading] = useState(true);

    const [currentItem, setCurrentItem] = useState({});

    const [isVisible, setIsVisible] = useState(false);

    const handleToggle = (id) => {
      
      setIsVisible(isVisible === id ? null : id); 
    };


    const [list, setList] = useState([]);

    const formatAMPM = (date) => {
        //console.log("LOCAL TIME:",date)
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
      };

    const getUpdates = () =>{
        setIsloading(true)
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/ceo-update`,{},"get").then((res)=>{
            if(res.status === 200){
                setIsloading(false)
                setList(res.data.oData);
                // setCurrentItem(res.data.oData[0])
            }
        })
    }
    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
    
          const userInfo = {
            uid: user?.uid,
            email: user?.email,
            displayName: user?.displayName,
            accessToken: user?.accessToken,
            idToken: user?.idToken,
          };
    
          setLoggedinUser(userInfo);
          getUpdates()
    
        }
      }, [user, loading]);

      const handleDelete = (id)=>{
        setIsloading(true)
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/ceo-update/delete-update`,{id:id},"post").then((res)=>{
            if(res.status === 200){
                getUpdates()
            }
        })
      }

      const updateListHtml = list && list.getUpdateList && list.getUpdateList.length >0 && list.getUpdateList.map((item,index)=>{
        return(
            <div className="info-card dark-theme-bg2 rounded-3 p-3" key={index}>
                                <div className="d-flex flex-wrap align-items-md-center flex-sm-nowrap gap-2">
                                    <div className="user-img bg-info bg-opacity-75 rounded-circle d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '50px', width: '50px', minWidth: '50px' }}>
                                        {/* <img src={require('../resources/images/Avatar.png')} alt="User" className="img-fluid" /> */}
                                        <div className="user-sortname text-white fs-6 fw-semibold text-uppercase">{getShortName(list.userInfo.display_name)}</div>
                                    </div>
                                    <div className="ceo-email-info w-100">
                                        <div className="row align-items-center g-3">
                                            <div className="col-md-5">
                                                <div className="user-name fs-6 fw-medium text-white text-truncate text-capitalize">{list.userInfo.display_name}</div>
                                                <div className="user-team fs-14 text-white text-opacity-75 text-capitalize">CEO</div>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="d-flex flex-wrap align-items-center justify-content-md-end row-gap-1 column-gap-2">
                                                <div className="button-container position-relative d-inline-block">
                            <Button variant="primary" id="readButton" className="btn-sm px-4 text-white" onClick={()=>{handleToggle(item.id)}}>Read by ({item.readBy.length})</Button >
                            {isVisible === item.id && item.readBy.length>0 &&(
                            <div className="name-list fs-14 text-body bg-white position-absolute top-100 start-0 border rounded overflow-hidden py-1 z-1" id="nameList" style={{ width: '150px'}}>
                              <div className='wrapper overflow-auto' style={{ maxHeight:"250px" }} >
                              {item?.readBy && item?.readBy?.length>0 && item.readBy.map((visitorInfo)=>(
                                <p className="text-truncate pointer m-0 px-2 py-1">{visitorInfo?.user?.display_name}</p>
                                
                              ))}
                              

                              </div>
                                
                            </div>
                             )}
                        </div>
                                                    <Button variant='outline-warning' className='pointer' size='sm' onClick={()=>{openEditNoticeModal();setCurrentItem(item)}} ><i className='bi bi-pencil'></i></Button>
                                                    <Button variant='outline-danger' className='pointer' size='sm' onClick={()=>{handleDelete(item.id)}}><i className='bi bi-trash3'></i></Button>
                                                    <div className="text-white fs-14 fw-medium d-flex flex-wrap ms-2">
                                                        <span className="name text-uppercase">{new Date(item.createdAt).toLocaleString("default", {
              day:"2-digit",
            }) +
            "/" +
            new Date(item.createdAt).toLocaleString("default", {
              month:"2-digit",
            }) +
            "/" +
            new Date(item.createdAt).getFullYear() }</span>,
                                                        <span className="time text-uppercase ms-1">{convertReadableTime(item.createdAt)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ceo-details mt-4">
                                    <div className="subject-heading fs-6 fw-semibold text-light mb-3">Sub: {item.subject}</div>
                                    <div className="description-wrapper fs-14 text-white text-opacity-75 mb-3" dangerouslySetInnerHTML={{__html:item.update}}>
                                       
                                    </div>

                                    
                                </div>
                            </div>
        )
      })
    return (
        <div className='CEOUpdate-page py-3'>
            {isLoading? <div className="loading">Loading&#8230;</div>:null}
            <div className="container-fluid px-xxl-5">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mb-4 mb-xxl-5">
                    <div className="fs-3 fw-semibold text-light">CEO's Update</div>
                    {environment.CEO_UPDATE_USER.includes(user?.email)?
                    <Button variant='light' className="rounded px-3" onClick={openNoticeModal}><i className="bi bi-plus-circle text-success me-1"></i> Write a New Notice</Button>
                    :null}
                </div>

                <div className="custom-width-wrapper mx-auto" style={{ maxWidth: '1140px' }}>
                    <div className="ceo-updates-section w-100 d-flex flex-column gap-2">
                        {list && list.getUpdateList && list.getUpdateList.length===0?<span className='text-warning text-center fs-18'>Updates not found!</span>:updateListHtml}
                    </div>
                </div>
            </div>

            {/* New Notice Modal */}
            <NewNoticeModal show={showNoticeModal} close={closeNoticeModal} user={user} userRole={userRole} setShowNoticeModal={setShowNoticeModal} getUpdates={getUpdates} setIsloading={setIsloading}/>

            {/* eidt Notice Modal */}
            <EditNotice show={showEditNoticeModal} close={closeEditNoticeModal} user={user} userRole={userRole} setShowNoticeModal={setShowEditNoticeModal} getUpdates={getUpdates} setIsloading={setIsloading} currentItem={currentItem}/>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
  
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(CEOUpdate);
