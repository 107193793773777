import React, { useEffect, useState } from "react";
import { app, database, auth } from "../firebaseConfig";
import { connect, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import "../LeadsListingPage/LeadsListingPage.css";


import { useAuthState } from "react-firebase-hooks/auth";


// env import
import { environment } from '../environment';

import { setUserInfo } from "../store/actions";
import PstnCallChart from "./PstnCallChart";
import { callAxios } from "../utils/utils";
import PstnCamCharts from "./PstnCamChart";

//datepicker import
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CallWithReasonChart from "./CallWithReasonChart";
import DateRange from "../Reports/DateRange";

const CallReport = (props) => {

  const { setLoggedinUser, pageType } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [user, loading, error] = useAuthState(auth);
  const [chartData, setChartData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [isShowCalendar, setIsShowCalendar] = useState("");

  const [endDate, setEndDate] = useState();

  let navigate = useNavigate();

  // console.log(pageType)

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
      console.log("calling useeffect")
      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };
      setLoggedinUser(userInfo);


    }
  }, [user, loading]);
  useEffect(() => {
    const date = new Date();
    const syear = date.getFullYear();
    const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const sday = String(date.getDate()).padStart(2, '0');
    const formattedStartDate = `${syear}-${smonth}-${sday}`;
    setStartDate(formattedStartDate)
    setEndDate(formattedStartDate)
    callChartData(formattedStartDate)
  }, [])
  const callChartData = async (formattedStartDate,formattedEndDate) => {
    setIsLoading(true);
    callAxios(environment.REACT_APP_API_URL_Live + "api/v1/reports/call-report", { selectedDate: formattedStartDate,formattedEndDate:formattedEndDate, pageType:pageType }, "post").then((chartData) => {

      setChartData(chartData.data)
      setIsLoading(false);
    })
  }
  // const onchagneDate = (selectedDate) => {
  //   // const date = new Date();
  //   setStartDate(selectedDate);
  //   const syear = selectedDate.getFullYear();
  //   const smonth = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  //   const sday = String(selectedDate.getDate()).padStart(2, '0');
  //   const formattedStartDate = `${syear}-${smonth}-${sday}`;

  //   callChartData(formattedStartDate)
  // }
  // const renderMonthContent = (month, shortMonth, longMonth) => {
  //   const tooltipText = `Tooltip for month: ${longMonth}`;
  //   return <span title={tooltipText}>{shortMonth}</span>;
  // };
  const formatDate = (date) =>{
    
    const syear = date.getFullYear();
    const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const sday = String(date.getDate()).padStart(2, '0');
    const formattedStartDate = `${syear}-${smonth}-${sday}`;

    return formattedStartDate;
  }
  const onChange = (ranges) => {
    setIsShowCalendar(false);
    setStartDate(formatDate(ranges.startDate));
    setEndDate(formatDate(ranges.endDate));
    // getReportData_(ranges.startDate, ranges.endDate);

   //console.log(ranges.startDate, ranges.endDate);

    callChartData(formatDate(ranges.startDate),formatDate(ranges.endDate))
  };

  const showCalendar = () =>{
    setIsShowCalendar(true);
   // console.log(isShowCalendar)
  }
  const backToMainScreen = () =>{
    navigate("/call-report");
  }
  return (
    <>
      {/* <Header user={userInfo} /> */}
      <div className="container">
        <div className="live-listing-wrapper py-4">
          <div className="row align-items-center g-2 mb-3">
            <div className="col-lg-5">
            <span className="pointer" onClick={backToMainScreen}><i class="bi bi-arrow-left"></i> Back</span> 
              <div className="fs-3 fw-semibold text-white text-opacity-75 lh-sm">{pageType==="camReport"?"CAM":"CEM"} Call Report</div>
            </div>
            <div className="col-lg-7 text-lg-end">
              <div className="d-flex d-lg-inline-flex flex-column flex-sm-row gap-2">
              
                   {/* <div class="custom-date-range" id="dateFilterField">
                   <label className="small">Filter By Date:</label>
            <DatePicker
              // selected={new Date()}
              selected={startDate}
              onChange={(date) => { onchagneDate(date) }}
              className="wonDatePicker mb-2 ms-2"
              id="wonDatePicker"
              renderMonthContent={renderMonthContent}
              showFullMonthYearPicker
              dateFormat="yyyy-MM-dd"
            />
                </div> */}

<div className="calendarWrapper position-relative">
                            <div className="d-flex flex-wrap align-items-center gap-3">
                            <label className="text-no-wrap"><b></b></label>
                          <input placeholder={startDate +' - '+ endDate}  type="text" className="form-control" id="calendar" onClick={showCalendar}  />
                            </div>
                        
                          {isShowCalendar?
                           <div className="position-absolute end-0" style={{zIndex:11}}>
                           <DateRange  onChange={onChange} startDate={startDate} endDate={endDate} />
                           </div>
                           :null
                        }

                        </div>
              
            </div>
          </div>
          </div>
          {/* <div className="d-flex flex-column flex-md-row gap-md-3 align-items-md-center mb-3 mt-2">

            <div className="main-heading text-white text-opacity-75">
              Call Report
            </div>




            <label className="small">Filter By Date:</label>
            <DatePicker
              // selected={new Date()}
              selected={startDate}
              onChange={(date) => { onchagneDate(date) }}
              className="wonDatePicker mb-2 ms-2"
              id="wonDatePicker"
              renderMonthContent={renderMonthContent}
              showFullMonthYearPicker
              dateFormat="yyyy-MM-dd"
            />

          </div> */}



          {/* Am Charts Section */}
          {!isLoading ?
            <>
              <div className="amchart-wrapper mb-3">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button collapsed bg-primary text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        Today Call Analysis
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body rounded-bottom p-3">
                        
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="chart-heading">How many calls happened? [Total Calls : {chartData.totalCallGraph.totalCalls}]<br></br><br></br></div>

                            <PstnCallChart chartData={chartData?.totalCallGraph} />


                          </div>



                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div className="amchart-wrapper mb-lg-3">
                <div className="accordion" id="accordionExample-bar">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header" id="heading1">
                      <button className="accordion-button collapsed bg-primary text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBar" aria-expanded="false" aria-controls="collapseBar">
                        Who took how many Calls Today
                      </button>
                    </h2>
                    <div id="collapseBar" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample-bar">
                      <div className="accordion-body rounded-bottom p-3">
                        

                        <PstnCamCharts chartData={chartData?.callByCam} />
                      </div>

                    </div>

                  </div>
                </div>
              </div>

              <div className="amchart-wrapper mb-lg-3">
                <div className="accordion" id="accordionExample-bar">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header" id="heading1">
                      <button className="accordion-button collapsed bg-primary text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBar" aria-expanded="false" aria-controls="collapseBar">
                        What was the reason behind call?
                      </button>
                    </h2>
                    <div id="collapseBar" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample-bar">
                      <div className="accordion-body rounded-bottom p-3">
                        

                        <CallWithReasonChart chartData={chartData?.chartByReason} />
                      </div>

                    </div>

                  </div>
                </div>
              </div>


            </>
            : null}

          {isLoading ?
            <div className="" id="lead-list-spinner" style={{
              position: "absolute",
              margin: "21px 0px 0px 0px",
              left: "44%"
            }}
            >
              <div
                style={{ marginLeft: "12px", float: "right" }}
                className="lead-list-spinner"
              ></div>{" "}
              Please wait ....
            </div>
            : null}

        </div>
      </div>

    </>
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedinUser: (user) => dispatch(setUserInfo(user))
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(CallReport);