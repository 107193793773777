import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationMeeting = ({ currentPage, totalPages, onPageChange, adjacents }) => {
    // console.log("pagination component ::",currentPage, totalPages, adjacents)
  const renderPageNumbers = () => {
    const start = Math.max(1, currentPage - adjacents);
    const end = Math.min(totalPages, currentPage + adjacents);

    const pages = [];
    for (let i = start; i <= end; i++) {

      pages.push(
            <Pagination.Item active={i === currentPage}  onClick={() => onPageChange(i)}>{i}</Pagination.Item>
      );
    }
    return pages;
  };
  

  return (
    <Pagination className="justify-content-center gap-1 text-light mt-3 mb-0">
        <Pagination.First onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}/>
         {/* <Pagination.Ellipsis /> */}
         {renderPageNumbers()}
        <Pagination.Last onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />
    </Pagination>
    
  );
};

export default PaginationMeeting;
