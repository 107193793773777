import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { app, database, auth } from "../../firebaseConfig";


// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callAxios } from "../../utils/utils";
import { environment } from "../../environment";
import { addMoreParticipant, makeOutgoingCall } from "../../utils/pstn";
import $ from "jquery";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


export const BeforeCallModal = ({ leadid, displayName, countryCode, fullname, userEmail, handleShow_, type, setShow1, cid, setInProgressConference, setModalShow, setIsDialpad, leadDesc, formattedDate, topic, leadOpportunityId, listType, setBeforeCallModal, setDialingBy }) => {
  const [show, setShow] = useState(handleShow_);


  const handleClose = () => {
    setShow(false);
    setBeforeCallModal(false)
  }
  const handleShow = () => setShow(true);
  const [user, loading, error] = useAuthState(auth);
  const [answer, setAnswer] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isRequired, setIsrequired] = useState(false)
  const [isDisabled, setDisabled] = useState(true)
  // console.log(cid)
  const whyCallingHandler = (e) => {
    e.preventDefault();
    setIsLoading(true)
    const cid = $("#button-call-" + leadOpportunityId).attr('data-cid');
    const whoDialed = $("#button-call-" + leadOpportunityId).attr("data-whoDialed");
    callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/before-call-question-answer", { leadId: leadOpportunityId, callerEmail: userEmail, clientName: fullname, question: 'What is the primary purpose of your call to the client today?', answer: answer, description: description, callerName: displayName, call_sid: cid, caller_type: localStorage.getItem("userRole"), lead_description: leadDesc, formatted_date: formattedDate, topic: topic }, "post").then((response) => {
      if (response.status === 200) {
        if (cid.length > 0) {
          console.log("adding client to with ve member")
          addMoreParticipant(fullname, leadid, cid, setShow, whoDialed, countryCode, userEmail, leadOpportunityId, listType, setDialingBy)
          const beforeCallingCancel = document.getElementById("beforeCallingCancel")
          if (beforeCallingCancel)
            beforeCallingCancel.click();
          setIsLoading(false)
          $("#connected-user-area").hide()
          $("#in-progressPop").show()
        } else {
          console.log("calling directly to client")
          makeOutgoingCall(leadid, user?.displayName, countryCode, fullname, userEmail, response.data.id, leadDesc, formattedDate, topic, leadOpportunityId, listType, setDialingBy)
          const beforeCallingCancel = document.getElementById("beforeCallingCancel")
          if (beforeCallingCancel)
            beforeCallingCancel.click();
          setIsLoading(false)
          setModalShow(false)
          setShow1(true)
          $("#in-progressPop").show()

        }

      }
    })
  }
  useEffect(() => {
    if (answer && answer === "Other") {
      $("#call-description").removeClass("d-none")
      setIsrequired(true)
    } else {
      $("#call-description").addClass("d-none")
      setIsrequired(false)
    }
  }, [answer])


  return (
    <>
      {/* <ToastContainer /> */}
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Body>
          <form id="beforePSTNCAllModal" className="d-flex flex-column overflow-hidden" method="post" onSubmit={whyCallingHandler}>
            <div className="modal-header bg-primary text-white px-lg-4 py-2">
              <div>
              </div>
              {isLoading ?
                <div
                  className="meeting-pointer-spinner"
                  style={{ marginRight: "10px" }}
                ></div>
                : null}
              <button type="submit" id="call-to-client" className="btn btn-success border-white" style={{ minWidth: "80px" }} disabled={isDisabled}>Call</button>
            </div>
            <div className="modal-body px-lg-4 py-4 small">
              <div className="mb-3">
                <label htmlFor="noting" className="form-label text-muted">
                  What is the primary purpose of your call to the client today?
                </label>
                {/* <div className="check-wrapper">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="callQans"
                          value="Very first call to introduce our company and services to the client"
                          required 
                          onClick={(e) => {
                            setAnswer(e.target.value);
                            setDisabled(false)
                          }}
                        />
                        <label className="form-check-label">
                        Very first call to introduce our company and services to the client
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="callQans"
                          value="To address specific client queries or concerns"
                          required 
                          onClick={(e) => {
                            setAnswer(e.target.value);
                            setDisabled(false)
                          }}
                        />
                        <label className="form-check-label">
                        To address specific client queries or concerns
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="callQans"
                          value="To introduce or pitch additional services to the client"
                          required 
                          onClick={(e) => {
                            setAnswer(e.target.value);
                            setDisabled(false)
                          }}
                        />
                        <label className="form-check-label">
                        To introduce or pitch additional services to the client
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="callQans"
                          value="To discuss the renewal of contracts or services"
                          required 
                          onClick={(e) => {
                            setAnswer(e.target.value);
                            setDisabled(false)
                          }}
                        />
                        <label className="form-check-label">
                        To discuss the renewal of contracts or services
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="callQans"
                          value="To gather client feedback or conduct a satisfaction survey"
                          required 
                          onClick={(e) => {
                            setAnswer(e.target.value);
                            setDisabled(false)
                          }}
                        />
                        <label className="form-check-label">
                        To gather client feedback or conduct a satisfaction survey
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="callQans"
                          value="To resolve an issue or complaint"
                          required 
                          onClick={(e) => {
                            setAnswer(e.target.value);
                            setDisabled(false)
                          }}
                        />
                        <label className="form-check-label">
                        To resolve an issue or complaint
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="callQans"
                          value="Other"
                          required 
                          onClick={(e) => {
                            setAnswer(e.target.value);
                            setDisabled(false)
                          }}
                        />
                        <label className="form-check-label">
                        Other (please specify)
                        </label>
                      </div>
                     
                    </div> */}
                <div className="check-wrapper">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="callQans"
                      value="Very first call to introduce our company and services to the client"
                      required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">Very first call to introduce our company and services to the client</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="callQans" value="To address specific client queries or concerns" required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">To address specific client queries or concerns</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="callQans" value="To introduce or pitch additional services to the client" required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">To introduce or pitch additional services to the client</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="callQans" value="To discuss the renewal of contracts or services" required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">To discuss the renewal of contracts or services</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="callQans" value="To gather client feedback or conduct a satisfaction survey" required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">To gather client feedback or conduct a satisfaction survey</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="callQans" value="To resolve an issue or complaint" required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">To resolve an issue or complaint
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callQans"
                      value="Follow up due to non-responsiveness from the client"
                      required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">
                      Follow up due to non-responsiveness from the client
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callQans"
                      value="Follow up for additional information or availability for a meeting"
                      required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">
                      Follow up for additional information or availability for a meeting
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callQans"
                      value="To request client to sign the agreement or documents"
                      required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">
                      To request client to sign the agreement or documents
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callQans"
                      value="To discuss or request details about the NDA"
                      required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">
                      To discuss or request details about the NDA
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callQans"
                      value="To check or request availability for rescheduling a meeting"
                      required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">
                      To check or request availability for rescheduling a meeting
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callQans"
                      value="To gather feedback on shared candidate profiles or resumes"
                      required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">
                      To gather feedback on shared candidate profiles or resumes
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callQans"
                      value="To request an update or status on previous communications or services"
                      required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">
                      To request an update or status on previous communications or services
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callQans"
                      value="To confirm agreement details or provide updates on policies"
                      required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">
                      To confirm agreement details or provide updates on policies
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callQans"
                      value="To discuss or offer discounted rates"
                      required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">
                      To discuss or offer discounted rates
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callQans"
                      value="To explain external hiring options or policies"
                      required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">
                      To explain external hiring options or policies
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callQans"
                      value="To request payment or discuss invoice issues"
                      required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">
                      To request payment or discuss invoice issues
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callQans"
                      value="Reminder for scheduled interviews or meetings"
                      required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">
                      Reminder for scheduled interviews or meetings
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callQans"
                      value="To request or confirm availability for initial or follow-up meetings"
                      required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">
                      To request or confirm availability for initial or follow-up meetings
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="callQans"
                      value="Other"
                      required
                      onClick={(e) => {
                        setAnswer(e.target.value);
                        setDisabled(false)
                      }}
                    />
                    <label className="form-check-label">
                      Other (please specify)
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-3 d-none" id="call-description">
                <p><span className="text-danger text-small">Note to CAM Members:</span>
                  &nbsp; Please select the most appropriate option that accurately reflects the topic of your call. The "Other" option should be used sparingly and only if you believe your call topic is not covered by the provided list.
                  <br></br>
                  If you do select "Other," ensure you provide a detailed description of the call topic. Avoid using generic or one-word responses. Clear and specific details help us understand and address client needs effectively.</p>
                <textarea
                  className="form-control"
                  rows="4"
                  placeholder=""
                  required={isRequired}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setDisabled(false)
                  }}
                ></textarea>
              </div>

            </div>
          </form>

          <Button variant="secondary" className="" onClick={handleClose} id="beforeCallingCancel">
            Close
          </Button>
        </Modal.Body>


      </Modal>
    </>
  );
};
