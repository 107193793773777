import React, { useEffect, useState } from "react";
import './wallet.scss';
import { Accordion, Button, Form, InputGroup } from 'react-bootstrap'
import SearchIcon from '../resources/images/search.svg';
import { RedeemInsentiveModal } from "./RedeemInsentiveModal";
import { RedeemAmountModal } from "./RedeemAmountModal";
import { setUserInfo } from "../store/actions";
import { connect } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { callAxios, formatDateToMonthYear } from "../utils/utils";
import { environment } from "../environment";
import { new_ClientType, new_opportunitytype, statuscode } from "../utils/codes";
import { toast } from "react-toastify";
import { Inprogress } from "./cards/Inprogress";
import { IncentivePolicy } from "../utils/IncentivePolicy";

const Wallet = ({ setLoggedinUser, userRole }) => {
    const [user, loading, error] = useAuthState(auth);
    let navigate = useNavigate();
    const [activeKey, setActiveKey] = useState(null);
    const [addingLoader, setAddingLoader] = useState(false)
     const [isLoading, setIsLoading] = useState(true);
     const [bonus, setBonus] = useState({})
     const [incentivetype, setIncentiveType] = useState("")
     const [searchLoading, setSearchLoading] = useState(false);

    const [walletInfo, setWalletInfo] = useState({});
    const [myOpportunity, setMyOpportunity] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [topic, setTopic] = useState("");
    const [opRealTime, setOpRealTimeData] = useState([]);

    const [isToggled, setIsToggled] = useState(false);


    // Redeem modal
    const [showRedeemModal, setShowRedeemModal] = useState(false);
    const closeRedeemModal = () => setShowRedeemModal(false);
    const openRedeemModal = () => setShowRedeemModal(true);

    // Redeem amount modal
    const [showRedeemAmountModal, setShowRedeemAmountModal] = useState(false);
    const closeRedeemAmountModal = () => setShowRedeemAmountModal(false);
    const openRedeemAmountModal = () => setShowRedeemAmountModal(true);

    const handleToggle = () => {
        setIsToggled((prev) => !prev);
    };

    // useEffect(() => {
    //     if (isToggled) {
    //         document.body.classList.add('toggled-class'); // Add class
    //     } else {
    //         document.body.classList.remove('toggled-class'); // Remove class
    //     }

    //     // Clean up the effect on unmount
    //     return () => {
    //         document.body.classList.remove('toggled-class');
    //     };
    // }, [isToggled]);

    // const myOpportunity
    const setPolicy = (type, userRole) =>{
        //  console.log(type, userRole)
        const getBonus = IncentivePolicy[0][userRole][type];
        // console.log(getBonus)
        setIncentiveType(type)
        setBonus(getBonus)
    }
    const loadWallet = (userRole) =>{
        setIsLoading(true)
        setMyOpportunity([])

        setOpRealTimeData([])
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/create-wallet`,{userType:userRole,startDate, endDate},"post").then((res)=>{
            setIsLoading(false)
            if(res.data.OpportunityList.length === 0){
                setWalletInfo(res.data.oData)
            } else{
                setWalletInfo(res.data.OpportunityList[0].__wallet)
            }
           
            setAddingLoader(false)
            const groupedData = res.data.oData.wallet_opportunities.reduce((acc, item) => {
                const monthYear = formatDateToMonthYear(item.createdAt); // You can also use updatedAt
                if (!acc[monthYear]) {
                    acc[monthYear] = [];
                }
                acc[monthYear].push(item);
                return acc;
            }, {});
            
            setMyOpportunity(groupedData)

            setOpRealTimeData(res.data.OpportunityList)

          })
    }
    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
    
          const userInfo = {
            uid: user?.uid,
            email: user?.email,
            displayName: user?.displayName,
            accessToken: user?.accessToken,
            idToken: user?.idToken,
          };
    
          setLoggedinUser(userInfo);
        //   loadWallet(userRole);
    
        }
      }, [user, loading, userRole]);

      useEffect(()=>{
        loadWallet(userRole);
      },[userRole])

      const handleLookup = () =>{
        if(topic.length > 0){
            setSearchLoading(true)
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/search-opportunity`,{searchString:topic},"post").then((res)=>{
            // console.log(res.data.oData)
            document.body.classList.add('toggled-class');
            setSearchResults(res.data.oData)
            setSearchLoading(false)
        })
        } else{
            toast.error("Enter Opportunity ID first...", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
        
      }
    //   console.log("type length:::", incentivetype)

    const resetSearch = () =>{
        document.body.classList.remove('toggled-class');
        setSearchResults([])
        setSearchLoading(false)
        setBonus({})
        document.getElementById("search-op-incentive").value = "";
      }

      let searchHtml;

      searchHtml =  searchResults && searchResults.length > 0 && searchResults.map((item)=>{
        return(
        <div className="info-card w-100 rounded-3 px-4 py-3">
            <div className="text-end mb-2 text-white pointer" onClick={resetSearch}><i className="bi bi-x-lg"></i></div>
            <div className="custom-bg rounded-3">
                <div className="row g-2">
                    <div className="col-12 col-lg-4 info-box text-capitalize">
                        <div className="label fs-14 text-warning">Opportunity ID</div>
                        <div className="value text-white text-opacity-75 text-break">{item.new_opportunityid}</div>
                    </div>
                    <div className="col-6 col-lg-4 info-box text-capitalize">
                        <div className="label fs-14 text-warning">Opportunity Created</div>
                        <div className="value text-white text-opacity-75">{item.createdon}</div>
                    </div>
                    {/* <div className="col-6 col-lg-3 info-box text-capitalize">
                        <div className="label fs-14 text-warning"></div>
                        <div className="value text-white text-opacity-75"></div>
                    </div>
                    <div className="col-6 col-lg-2 info-box text-capitalize">
                        <div className="label fs-14 text-warning"></div>
                        <div className="value text-white text-opacity-75"></div>
                    </div> */}
                    <div className="col-6 col-lg-4 info-box text-capitalize">
                        <div className="label fs-14 text-warning">Opportunity Status</div>
                        <div className="value fw-medium text-info">{statuscode[item.statuscode]}</div>
                    </div>
                </div>
            </div>
            <div className="row g-2 mt-3">
                <div className="col-12 col-lg-3 info-box text-capitalize">
                    <div className="label fs-14 text-warning">Opportunity Type</div>
                    <div className="value text-white text-opacity-75">{new_opportunitytype[item.new_opportunitytype]}</div>
                </div>
                <div className="col-6 col-lg-2 info-box text-capitalize">
                    <div className="label fs-14 text-warning">Client Type</div>
                    <div className="value text-white text-opacity-75">{new_ClientType[item.new_clienttype]}</div>
                </div>
                <div className="col-6 col-lg-3 info-box text-capitalize">
                    <div className="label fs-14 text-warning">Skill Required</div>
                    <div className="value text-white text-opacity-75">{item.skills}</div>
                </div>
                <div className="col-6 col-lg-2 info-box text-capitalize">
                    <div className="label fs-14 text-warning">Country</div>
                    <div className="value text-white text-opacity-75">{item.country}</div>
                </div>
                <div className="col-6 col-lg-2 info-box text-capitalize">
                    <div className="label fs-14 text-warning">TimeZone</div>
                    <div className="value text-white text-opacity-75">{item.timezone}</div>
                </div>
            </div>
            <hr className="divider border border-top-2 border-secondary border-opacity-75 mt-4 mb-3" />
            <div className="description">
                <div className="row gx-2 gy-3">
                    <div className="col-lg-6">
                        { bonus && Object.entries(bonus).length > 0?
                        <>
                        <div className="total-insentive fs-5 fw-semibold text-white lh-sm mb-1">Total Insentive: ₹{bonus.total_bonus}</div>
                        <div className="fs-12 text-white text-opacity-75">Insentive Redemption: The process will be available to you once the Opportunity status turns WON and payment is received from the client.</div>
                        </>
                        
                        : null}
                    </div>
                    <div className="col-lg-3">
                        <select className="form-select dark-theme-bg text-white py-2" onChange={(e)=>{setPolicy(e.target.value,userRole)}}>
                            <option value={""}>Select Incentive Type</option>
                            <option value={"new_hire_full_time"}>New Hire (Full-Time)</option>
                            <option value={"new_hire_part_time"}>New Hire (Part-Time)</option>
                            <option value={"upscaling_full_time"}>Upscaling (Full-Time)</option>
                            <option value={"upscaling_part_time"}>Upscaling (Part-Time)</option>
                            <option value={"cross_selling_full_time"}>Cross-Selling (Full-Time)</option>
                            <option value={"cross_selling_part_time"}>Cross-Selling (Part-Time)</option>
                            <option value={"client_referral_full_time"}>Client Referral (Full-Time)</option>
                            <option value={"client_referral_part_time"}>Client Referral (Part-Time)</option>
                        </select>
                        
                    </div>
                    <div className="col-lg-3 text-lg-end">
                    
                        
                    {addingLoader?
                        <Button variant="success" className="rounded-1 px-3 py-2 loader-add-wallet"></Button>
                        :
                        incentivetype.length >0?
                        <Button variant="success" className="rounded-1 px-3 py-2" onClick={()=>{addToWallet(item)}}>Add to My Wallet</Button>
                        : 
                        <Button variant="secondary" disabled className="rounded-1 px-3 py-2" >Add To My Wallet</Button>
                        }
                    </div>
                    <div className="text-center fs-12">You may select the Incentive Type based on your current understanding. However, it may be updated by the billing team based on the client’s payment and service type on first billing.</div>
                    
                </div>
            </div>
        </div>
        )
      })

      
     
      const addToWallet = async(item) =>{
        // console.log(item)
        setAddingLoader(true)
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/add-to-wallet`,{item:item,incentivetype:incentivetype,usertype:userRole,bonus:bonus},"post").then((res)=>{
            // console.log(res)
            if(res.status === 201){
               
                toast.success(res.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  setIncentiveType("")
                  document.body.classList.remove('toggled-class')
                  setSearchResults([])
                
                    setSearchLoading(false)
                    setBonus({})
                    document.getElementById("search-op-incentive").value = "";
                  loadWallet(userRole)
            } else{
               
                if(res.data.statusCode === 409){
                    document.body.classList.remove('toggled-class');
                    setSearchResults([])
                    setIncentiveType("")
                    setSearchLoading(false)
                    setBonus({})
                    setAddingLoader(false)
                    document.getElementById("search-op-incentive").value = "";
                    toast.error(res.data.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                }
                
            }
            
        }).catch((error)=>{
            // console.log("====>",error)
            toast.error("something went wrong", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        })
      }
    //   let groupedData;
    //    console.log(walletInfo.wallet_opportunities)
    
       
       
       
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission if inside a form
            handleLookup();
        }
    };

    const [startDate, setStartDate] = useState(() => {
        const startDD = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`;
        return startDD;
      });
      const [endDate, setEndDate] = useState(() => {
          const date = new Date();
          const syear = date.getFullYear();
          const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
          const sday = String(date.getDate()).padStart(2, '0');
          return `${syear}-${smonth}-${sday}`;
      });

      const handalSearch = async() =>{
        loadWallet(userRole)
      }

    return (
        <div className="Wallet-page py-3">
            <div className="container-fluid px-xxl-5">
                <div className="d-flex flex-wrap flex-column flex-md-row align-items-md-center justify-content-between gap-2 gap-xl-5 mb-1">
                    <div className="fs-3 fw-semibold text-light lh-sm text-nowrap">Wallet</div>
                    
                    <div className="date-wrapper d-flex flex-wrap align-items-md-center gap-2 gap-lg-3">
                        <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3" controlId="dateFrom">
                            <Form.Label className="m-0">From</Form.Label>
                            <Form.Control type="date" className="bg-transparent py-2"
                               defaultValue={`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`}
                               onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3" controlId="dateTo">
                            <Form.Label className="m-0">To</Form.Label>
                            <Form.Control type="date" className="bg-transparent py-2"
                                 defaultValue={new Date().toJSON().slice(0, 10)}
                                 onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="d-flex flex-column justify-content-end flex-fill">
                            <Button variant="secondary" className="rounded-2 py-2 bg-primary" onClick={handalSearch}>
                                <img src={SearchIcon} alt="" className="img-fluid align-text-top" />
                                {/* <span className="d-md-none ms-1">Search</span> */}
                            </Button>
                        </Form.Group>
                        {/* <Form.Group className="status-type d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3 ms-lg-5" controlId="statusType">
                            <Form.Label className="m-0">Status</Form.Label>
                            <Form.Select className="bg-transparent py-2" style={{ minWidth: '120px' }} required>
                                <option value="" disabled >Status</option>
                                <option value="All" selected>All</option>
                                <option value="Open">Open</option>
                                <option value="Closed">Closed</option>
                            </Form.Select>
                        </Form.Group> */}
                    </div>
                    
                </div>
                <div className="row mb-3">
                <div className='small'>
                    <ul>
                        <li style={{lineHeight:"29px"}}><span className="text-warning">BDMs:</span> Use exact Opportunity ID to add ongoing or completed work—no need to wait for a win.</li>
                        <li style={{lineHeight:"29px"}}><span className="text-warning">SDMs:</span> No need to add opportunities focus on billing. Ensure you're listed as "Service Lead" in NM-App.</li>
                        <li style={{lineHeight:"29px"}}><span className="text-warning">Wallet Update:</span> Currently supports only BDM entries; SDM data visibility coming soon.</li>
                        <li style={{lineHeight:"29px"}}><span className="text-warning">Policy Limit:</span> Only won opportunities billed after Oct 1, 2024, can be added.</li>
                    </ul>
                </div>
                </div>

                <div className="info-cards-wrapper row g-2 g-xxl-3 mb-2">
                    <div className="col-sm-6 col-lg-3">
                        <div className="info-card d-flex align-items-center gap-3 gap-xxl-4 bg-warning bg-opacity-50 border-5 border-bottom border-warning rounded p-3 p-xxl-4">
                            <div className="left-sec">
                                <img src={require ('../resources/images/wallet/expected-balance.png')} alt="Balance" className="img-fluid" style={{maxWidth:'57px'}} />
                            </div>
                            <div className="right-sec text-truncate">
                                <div className="fs-3 fw-semibold text-white lh-sm">{isLoading?<div class="wallet-loader"></div>:walletInfo.expected_balance}</div>
                                <div className="fs-14 text-light text-secondary text-truncate">Expected Balance</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <div className="info-card d-flex align-items-center gap-3 gap-xxl-4 bg-success bg-opacity-50 border-5 border-bottom border-success rounded p-3 p-xxl-4">
                            <div className="left-sec">
                                <img src={require ('../resources/images/wallet/available-bal.png')} alt="Balance" className="img-fluid" style={{maxWidth:'57px'}} />
                            </div>
                            <div className="right-sec text-truncate">
                                <div className="fs-3 fw-semibold text-white lh-sm">{isLoading?<div class="wallet-loader"></div>:walletInfo.available_balance}</div>
                                <div className="fs-14 text-light text-secondary text-truncate">Available Balance to Redeem</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <div className="info-card d-flex align-items-center gap-3 gap-xxl-4 bg-danger bg-opacity-50 border-5 border-bottom border-danger rounded p-3 p-xxl-4">
                            <div className="left-sec">
                                <img src={require ('../resources/images/wallet/lost-amount.png')} alt="Balance" className="img-fluid" style={{maxWidth:'57px'}} />
                            </div>
                            <div className="right-sec text-truncate">
                                <div className="fs-3 fw-semibold text-white lh-sm">{isLoading?<div class="wallet-loader"></div>:walletInfo.lost_balance}</div>
                                <div className="fs-14 text-light text-secondary text-truncate">Lost Amount</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <div className="info-card d-flex align-items-center gap-3 gap-xxl-4 bg-primary bg-opacity-50 border-5 border-bottom border-primary rounded p-3 p-xxl-4">
                            <div className="left-sec">
                                <img src={require ('../resources/images/wallet/lifetime-earning.png')} alt="Balance" className="img-fluid" style={{maxWidth:'57px'}} />
                            </div>
                            <div className="right-sec text-truncate">
                                <div className="fs-3 fw-semibold text-white lh-sm">{isLoading?<div class="wallet-loader"></div>:walletInfo.lifetime_balance}</div>
                                <div className="fs-14 text-light text-secondary text-truncate">Lifetime Earning</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="coll text-nowrap">
              <div className="w-100 fs-6 text-light lh-sm">
                <marquee
                  width="100%"
                  direction="left"
                  style={{ paddingTop: "3px",paddingBottom: "5px" }}
                >
                  <span className="text-warning fw-semibold">
                    NOTICE FOR BDM & SDM:
                  </span>{" "}
                  <span className="text-warning">BDMs:</span> Search by exact Opportunity ID and add opportunities as soon as you start work.
                  {" "}
                  <span className="text-warning">SDMs:</span> Ensure your name is listed as "Service Lead" in NM-App Billing to track incentives here in wallet.
                  {" "}
                  Only <span className="text-warning">BDM</span> requests are currently supported, SDM data visibility coming soon.
                </marquee>
              </div>
            </div>

                <div className="wallet-info-section">
                    <div class="search-wrapper dropdown z-1">
                        {/* <InputGroup className="date-wrapper mb-3" type="button" data-bs-toggle="dropdown" aria-expanded="false" >
                            <InputGroup.Text className="bg-transparent border-end-0 text-light pointer" onClick={handleLookup}><i className="bi bi-search"></i></InputGroup.Text>
                            <Form.Control type="search" id="search-op-incentive" className="bg-transparent border-start-0 shadow-none py-2" placeholder="Search to Add opportunity" onChange={(e)=>{setTopic(e.target.value)}} />
                        </InputGroup> */}
                        <InputGroup className="border border-secondary border-opacity-75 rounded-3 mb-3" type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleToggle}>
                            <Form.Control type="search" id="search-op-incentive" className="bg-transparent border-0 rounded-3 shadow-none py-2 text-light" placeholder="Search with Opportunity ID" onChange={(e)=>{setTopic(e.target.value)}} onKeyDown={handleKeyDown}/>
                            <Button variant="success" id="button-addon2" className="rounded-1 mx-1 my-1" style={{ padding: '6px 40px' }} onClick={handleLookup}>Search</Button>
                        </InputGroup>
                       {searchLoading?<div className="info-card w-100 rounded-3 px-4 py-3"><div className="search-op-loader" style={{margin:"0 auto"}}></div></div>:searchHtml}
                    </div>
                    
                    <div className="wallet-cards-wrapper d-flex flex-column gap-3">
                    <Accordion defaultActiveKey={0} alwaysOpen className="wallet-cards-wrapper d-flex flex-column gap-3">
                        {isLoading?
                    <img src={require ('../resources/images/wallet/walletpreloader.gif')} alt="Balance" className="img-fluid" style={{maxWidth:'200px', margin:"0 auto"}} />
                    : Object.entries(myOpportunity).length === 0?
                            <span className="text-center text-warning fs-18">The opportunity hasn’t been added yet</span>
                    :
                   
                    Object.entries(myOpportunity).map(([key, opportunities], index) => (
                        <div key={index}>
                             <div className="month-name fw-semibold text-white text-uppercase">{key}</div>
                            {/* Render each opportunity's status */}
                            
                            {opRealTime.map((opportunity, oppIndex) => (
                               
                                    <Inprogress key={oppIndex} index={oppIndex} opInfo={opportunity} openRedeemAmountModal={openRedeemAmountModal} user={user} userRole={userRole} loadWallet={loadWallet} setIsLoading={setIsLoading} opRealTime={opRealTime} setActiveKey={setActiveKey} activeKey={activeKey}/>
                               
                            ))}
                        </div>
                    ))}
                    </Accordion>
                    </div>
                </div>

                {/* New Issue Modal */}
                <RedeemInsentiveModal show={showRedeemModal} close={closeRedeemModal} />

                {/* Redeem Amount Modal */}
                <RedeemAmountModal show={showRedeemAmountModal} close={closeRedeemAmountModal} />
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
   
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(Wallet);
