import React, { useEffect, useState } from "react";
import ChatSection from "./ChatSection/ChatSection";
import "./RoomPage.css";
import { connect } from "react-redux";
import {
  setTwilioAccessToken,
  setConnectOnlyWithAudio,
  setIdentity,
  setRoomId,
} from "../store/actions";
import { getTokenFromTwilio } from "../utils/twilioUtils";
import { useNavigate, useLocation } from "react-router-dom";
import Overlay from "./Overlay";
import BackgroundSetting from "./BgSettingSection/BackgroundSetting";
import RejoinRoom from "./RejoinRoom";
import ParticipantsSection from "./ParticipantsSectionMeeting/ParticipantsSection";
import VideoSection from "./VideoSectionMeeting/VideoSection";
import { callAxios } from "../utils/utils";
import { environment } from "../environment";
import { toast } from "react-toastify";

import moment from 'moment-timezone';

function MeetingClientRoom(props) {
  const {
    identity,
    roomId,
    setTwilioAccessTokenAction,
    showOverlay,
    isRoomHost,
    setConnectOnlyWithAudioAction,
    connectOnlyWithAudio,
    setRoomIdAction,
    setIdentityAction,
  } = props;
  
  let navigate = useNavigate();
  const search = useLocation().search;
  const name = new URLSearchParams(search).get("nm");
  const joinerRoomId = new URLSearchParams(search).get("id");

  const from = new URLSearchParams(search).get("from");
  const to = new URLSearchParams(search).get("to");
  const meetingId = new URLSearchParams(search).get("cpc");
  const clientEmail = new URLSearchParams(search).get("identity");

  // console.log(atob(joinerRoomId))
  const sharedBy = new URLSearchParams(search).get("sharedBy");
  const isRejoin = new URLSearchParams(search).get("isRejoin");
  const IsRejoin = isRejoin === "true";
  const [isLoading, setIsLoading] = useState(false);
  const [comingFrom, setComingFrom] = useState();
  const [passcode, setPasscode] = useState("");  // State to store the passcode input
  const [isAuthenticated, setIsAuthenticated] = useState(false);  // State to track if the user is authenticated
  // const []
  const [isDateTimeInRange, setCheckDateBeforeJoin] = useState(false)
  const [message, setMessage] = useState("")

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const displayModal = () =>{
    const meetingModule = document.getElementById("meeting-schedule-module-client");
  
    if (meetingModule) {
      meetingModule.classList.add("show");
      meetingModule.style.display = "block";
    }
  }
  async function canJoinMeeting(from, to, timeZone) {
    // Parse the 'from' and 'to' datetimes into the provided timezone

    try{
      const res = await callAxios(
        environment.REACT_APP_API_URL_Live + "api/v1/twilio-voice/check-if-cancel",
        { meetingId: atob(meetingId) },
        "post"
      );
  
      // console.log(res.data.meetingInfo);
  
      if (res.data.meetingInfo.is_meeting_canceled) {
        // If the meeting is canceled, display the modal and set the message
        setCheckDateBeforeJoin(false);
        setMessage("Meeting has been canceled by the organizer.");
        displayModal();
        return; // Exit the function early since the meeting is canceled
      }

      const fromDateTime = moment.tz(from, timeZone);  // Meeting start time
    const toDateTime = moment.tz(to, timeZone);      // Meeting end time
  
    // Subtract 10 minutes from the 'from' datetime
    const fromMinus10Minutes = fromDateTime.clone().subtract(10, 'minutes');
  
    // Get the current date and time in the same timezone
    const currentDateTime = moment.tz(timeZone);
  
    // Check if current time is between (from - 10 minutes) and to time
    if (currentDateTime.isAfter(fromMinus10Minutes) && currentDateTime.isBefore(toDateTime)) {
      setCheckDateBeforeJoin(true)
    } else if (currentDateTime.isAfter(toDateTime)) {
      setCheckDateBeforeJoin(false)
      // console.log("Meeting has ended. You cannot join.")
      setMessage("Meeting has ended. You cannot join.");
      displayModal();
      
    } else {
      setCheckDateBeforeJoin(false)
      setMessage("You cannot join the meeting yet. Please wait until 10 minutes before the meeting starts.")
          
      displayModal();
    }

    }
    catch (error) { 
      console.error("Error checking meeting cancellation:", error);
      // Handle the error and show a relevant message if needed
      setMessage("An error occurred while checking the meeting status.");
      displayModal();
    }
    
  }

  // useEffect(()=>{
  //   canJoinMeeting(from, to, userTimeZone)
  // },[])
  

  useEffect(() => {
    setIdentityAction(atob(name));
    setRoomIdAction(atob(joinerRoomId));
    setComingFrom(false);
    getTokenFromTwilio(setTwilioAccessTokenAction, "_CL_" + atob(name));


  }, []);

  // Function to handle passcode validation
  const handlePasscodeSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)
    callAxios(environment.REACT_APP_API_URL_Live + "api/v1/meetings/validate-passcode",{passcode:passcode,meetingId:meetingId,cpc:clientEmail},"post").then((response)=>{
      console.log(response.data)
      if(response.data.isValidated){
        setIsAuthenticated(true);
        setIsLoading(false)
      } else{
      //   toast.error("Incorrect Passcode! Please try again.", {
      //     position: "top-right",
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      // });
      setIsLoading(false)
      alert("Incorrect Passcode! Please try again.");
      }
    })
    
  };

  useEffect(() => {
    if (isAuthenticated) {
      canJoinMeeting(from, to, userTimeZone);
    }
  }, [isAuthenticated, from, to, userTimeZone]);

  const handleOK = () =>{
    // window.close();
    window.location.href="https://virtualemployee.com"
   }

  // Render the passcode input form if the user is not authenticated
  if (!isAuthenticated) {
    return (
      <div className="passcode-container ">
         {isLoading? <div className="loading">Loading&#8230;</div>:null}
        <h3>Enter 4-digit Passcode to Join the Meeting</h3>
        <form onSubmit={handlePasscodeSubmit}>
          <input
            type="text"
            maxLength="4"
            value={passcode}
            onChange={(e) => setPasscode(e.target.value)}
            placeholder="Enter Passcode"
            required
          />
          <button type="submit">Submit</button>
        </form>
      </div>
    );
  }

  // If user is authenticated, render the meeting components
  return (
    <>
     {isDateTimeInRange?
    <>
    
      <VideoSection
        comingFrom={comingFrom}
        isCam={false}
        camera={true}
        isMicMuted={false}
        isRejoin={IsRejoin}
        twilioAccessToken={props.twilioAccessToken}
        roomId={props.roomId}
      />

      <div id="main" className="room_container main-section p-lg-3 overflow-hidden">
        <div
          id="shared_screen"
          className="shared_screen_preview d-flex flex-wrap w-100 h-100 overflow-hidden position-relative border border-1 border-info hide"
        ></div>
        <div
          id="videos_portal"
          className="when-normal d-flex flex-wrap h-100 overflow-auto position-relative"
        ></div>
      </div>

      {/* Chat Sidenav */}
      <div id="chatSidenav" className="sidebar border-start border-black">
        <ChatSection />
        {showOverlay && <Overlay />}
      </div>

      {/* Participants Sidenav */}
      <div id="partcipantSidenav" className="sidebar border-start border-black">
        <ParticipantsSection />
      </div>

      {/* Background Setting Sidenav */}
      <div id="BgSettingSidenav" className="sidebar border-start border-black">
        <BackgroundSetting comingFrom={false} />
      </div>

      {/* Rejoin Modal */}
      <RejoinRoom
        roomId={roomId}
        identity={identity}
        twilioAccessToken={props.twilioAccessToken}
        showOverlay={showOverlay}
        camera={true}
        isMicMuted={false}
      />
    </>
    :
    <div className='join-call-model'>
            {/* Modal */}
            <div className="modal fade" id="meeting-schedule-module-client" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: '650px' }}>
                    <div className="modal-content bg-dark border-top border-5 border-primary rounded-0 shadow">
                        <div className="modal-body p-4 p-md-5 text-center">
                            <div className='fs-5 fw-semibold mb-4' ></div>
                            <div className='fs-5 fw-semibold mb-4'>{message}</div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div id="available-version" className='mb-3 text-start'></div>
                                    <button className='btn btn-success' onClick={handleOK} >OK</button>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            
        </div>
    }
    </>
    
  );
}

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setTwilioAccessTokenAction: (token) =>
      dispatch(setTwilioAccessToken(token)),
    setConnectOnlyWithAudioAction: (onlyWithAudio) =>
      dispatch(setConnectOnlyWithAudio(onlyWithAudio)),
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    setRoomIdAction: (id) => dispatch(setRoomId(id)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(MeetingClientRoom);
