import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setUserInfo } from "../store/actions";
import { callAxios } from "../utils/utils";
import { environment } from "../environment";

// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';
import Skills from './Skills';

// Quill Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { SubmitRequisitionModal } from "./SubmitRequisitionModal";

import { languageOptions } from "./constants/languageOptions";

const animatedComponents = makeAnimated();

const SkillsVal = Skills.map((skill, index) => ({
	id: index + 1,
	skill_name: skill,
	value: skill,
	label: skill
}));

const NewRecruitment = (props) => {
	const { setLoggedinUser } = props;
	const [Skills, setSelectedOption] = useState(null);
	const [jobTitle, setJobTitle] = useState("");
	const [primaryRole, setPrimaryRole] = useState("");
	const [clientName, setclientName] = useState("");
	const [minimumExp, setMinimumExp] = useState("");
	const [department, setDepartment] = useState("");
	const [jobDescription, setJobDescription] = useState("");
	const [keySkills, setKeySkills] = useState("");
	const [location, setLocation] = useState("");
	const [minimumQualification, setMinimumQualification] = useState("");
	const [reqiredBy, setReqiredBy] = useState("");
	const [employmentType, setEmploymentType] = useState("");
	const [shiftType, setShiftType] = useState("");
	const [jobNatureType, setNatureOfJob] = useState("");
	const [isClientName, setIsClientName] = useState(false);
	const [isloading, setIsloading] = useState(false);

	const [preferedLang, setPreferedLang] = useState({});
	const [isAllowCandidateToSelectLang, setIsAllowCandidateToSelectLang] = useState(false);
	const [isTechnical, setIsTechnical] =  useState(true);
	const [range1, setRange1] =useState("1")
	const [range2, setRange2] =useState("5")
	const [showConfirm, setShowConfirm]= useState(false)
	const [formData, setFormData] = useState({});
	


	
	// For Selected Test Round Option
	const [selectedTest, setSelectedTest] = useState('Technical');

	// Event handler to update the state when a radio button is selected
	// console.log(isAllowCandidateToSelectLang, selectedTest)
	const handleOptionChange = (event) => {
		setSelectedTest(event.target.value);
	};

	// Submit Requisition Modal
	const [showRequisitionModal, setShowRequisitionModal] = useState(false);
	const closeRequisitionModal = () => setShowRequisitionModal(false);
	const openRequisitionModal = () => setShowRequisitionModal(true);

	let navigate = useNavigate();
	// console.log(primaryRole,isClientName)

	const [user, loading] = useAuthState(auth);

	useEffect(() => {
		if (loading) return;
		if (!user) navigate("/");
		if (user) {
			const userInfo = {
				uid: user?.uid,
				email: user?.email,
				displayName: user?.displayName,
				accessToken: user?.accessToken,
				idToken: user?.idToken,
			};
			setLoggedinUser(userInfo);
		}
	}, [user, loading]);

	const enableClientText = () => {
		const primaryRole = document.getElementById("primary-role-id").value;
		if (primaryRole === "Ongoing Client" || primaryRole === "New Client") {
			setIsClientName(true)
		} else {
			setIsClientName(false)
		}
	}

	const handleSubmitRequisition = async (e) => {

		
		e.preventDefault();

		if (parseInt(range1) > parseInt(range2)) {
			
			toast.error("The minimum budget should not be greater than the maximum budget.", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			return false
		}
		openRequisitionModal();
		
		setFormData({
			jobTitle: jobTitle,
			department: department,
			jobDescription: jobDescription,
			keySkills: keySkills,
			location: location,
			minimumExp: minimumExp,
			minimumQualification: minimumQualification,
			primaryRole: primaryRole,
			clientName: clientName,
			reqiredBy: reqiredBy,
			employmentType: employmentType,
			shiftType: shiftType,
			jobNatureType: jobNatureType,
			requested_by: user?.displayName,
			requested_by_email: user?.email,
			isAllowCandidateToSelectLang:isAllowCandidateToSelectLang,
		
			selectedTest:selectedTest,
			range1:range1,
			range2:range2,
			prefered_lang:preferedLang,

		})

		setShowConfirm(true)

		
	}

	// for keyskills
	const skills = [
		{
			skill_name: "PHP",
		},
		{
			skill_name: "JAVA",
		},
		{
			skill_name: "REACT",
		},
		{
			skill_name: "NODE JS",
		}
	]
	let options_ = [];
	if (skills.length > 0) {
		skills.map((item) => {
			const options = { value: item.skill_name, label: item.skill_name, role: item.skill_name };
			return options_.push(options)
		})
	}

	// React-slick npm style customisation
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			backgroundColor: 'red',
			border: 0,
			borderRadius: '0.5rem',
			backgroundColor: 'var(--custom-bg)',
			padding: '0.625rem 0.15rem',
		}),
		placeholder: (provided) => ({
			...provided,
			color: 'rga(108,117,125)', // Change the color of the placeholder text
		}),
	};

	// Define the quill toolbar modules
	const toolbarModules = {
		toolbar: {
			container: '#toolbar', // Reference the ID of the custom toolbar
			handlers: {
				// Add custom handlers if necessary
			}
		},
		toolbar: [
			[{ 'font': [] }],
			[{ 'header': '1' }, { 'header': '2' }],
			['bold', 'italic', 'underline', 'strike'],
			[{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
			[{ 'script': 'sub' }, { 'script': 'super' }],
			[{ 'list': 'bullet' }, { 'list': 'ordered' }],
			// [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
			// [{ 'color': [] }, { 'background': [] }],
			['link', 'image', 'video', 'formula'],
			['blockquote', 'code-block'], // Quote
			['clean'] // Remove formatting button
		],
	};

	// Define the quill formats that are allowed
	const formats = [
		'font', 'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'blockquote'
	];

	const handleBack = () => {
		navigate("/requisitions");
	}

	return (
		<>
			<ToastContainer />
			<div className="recruitment-page mt-3 mb-4">
				<div className="container-fluid px-xxl-5">
					<div className="fs-3 fw-semibold text-white lh-sm mb-3">Add new requisition</div>
					<div className="card dark-theme-bg rounded-4 p-3 p-lg-4 p-xl-5">
						<div className="container-xxl px-xxl-3 p-0">
							<Form method="post"  onSubmit={(e) => { handleSubmitRequisition(e) }}>
								<div className="check-wrapper fs-5 mb-3">
									<Form.Check inline id="Technical" className="me-4 pe-3">
										<Form.Check.Input type="radio" name="intType" className="border border-light border-opacity-50 shadow-none" value="Technical"
											checked={selectedTest === 'Technical'}
											onChange={handleOptionChange} />
										<Form.Check.Label>Technical</Form.Check.Label>
									</Form.Check>
									<Form.Check inline id="NonTechnical" className="me-4 pe-3">
										<Form.Check.Input type="radio" name="intType" className="border border-light border-opacity-50 shadow-none" value="Non-Technical"
											checked={selectedTest === 'Non-Technical'}
											onChange={handleOptionChange} />
										<Form.Check.Label>Non Technical</Form.Check.Label>
									</Form.Check>
								</div>
								{selectedTest === 'Technical' ? (
									<div className="technical-test-sec">
										<div className="description fs-6 text-light text-opacity-75 mb-3">
											This section is for requesting a coding test from the candidate. Once you fill out this form,
											the system will conduct two tests with the candidate: the first will be a coding round, followed by a face-to-face interview with Sheela.
										</div>
										<div className="card border-0 rounded-3 mb-4 p-3 p-xl-4" style={{ backgroundColor: 'var(--custom-bg)' }}>
											<div className="border-bottom border-secondary border-opacity-75 pb-4 mb-4">
												<div className="fs-4 fw-semibold text-light lh-sm mb-3">First Round: Coding Test</div>
												<div className="row g-3 gx-xl-5 align-items-center">
													<div className="col-lg-6">
														<Form.Group>
															<Form.Label className="text-primary px-3">Technical Round Language</Form.Label>
															<div className="custom-select">
																{/* <Form.Select className="border-0 rounded-3 px-3 py-3" id="technicalLanguage" required style={{ backgroundColor: '#585858' }}>
																	<option value="">Select Language</option>
																	<option value="1">HTML5</option>
																	<option value="2">CSS3</option>
																	<option value="3">JAVASCRIPT</option>
																	<option value="4">JQUERY</option>
																	<option value="5">BOOTSTRAP</option>
																</Form.Select> */}
																{isAllowCandidateToSelectLang ?
																	<Form.Select className="border-0 rounded-3 px-3 py-3" aria-label="Default select example" style={{ backgroundColor: '#585858' }} id="technicalLanguage"
																		onChange={(e) => {

																			setPreferedLang({ id: e.target.value, value: e.target.options[e.target.selectedIndex].text });
																		}}
																	>
																		<option value="">Select Language</option>
																		
																	</Form.Select>
																	:
																	<Form.Select className="border-0 rounded-3 px-3 py-3" aria-label="Default select example" style={{ backgroundColor: '#585858' }} id="technicalLanguage"
																		onChange={(e) => {

																			setPreferedLang({ id: e.target.value, value: e.target.options[e.target.selectedIndex].text });
																		}}
																	>
																		<option value="">Select Language</option>
																		{languageOptions.map((item) => (
																			<option key={item.id} value={item.id}>
																				{item.name}
																			</option>
																		))}
																	</Form.Select>
}
															</div>
															<div className="fs-12 text-clr3 mt-2">Select the coding language in which you want the candidate to answer the test questions.</div>
														</Form.Group>
													</div>
													<div className="col-lg-6">
														<Form.Check inline id="allowCondition" className="fs-18 text-light me-4 pe-3">
															<Form.Check.Input type="checkbox" className="border border-light shadow-none" onChange={(e) => { setIsAllowCandidateToSelectLang(e.target.checked) }} />
															<Form.Check.Label className="lh-sm">Allow the candidate to take the test in any language.</Form.Check.Label>
															<div className="fs-12 text-clr3 mt-1">Or, if you don't have a preferred language, you can allow the candidate to choose a coding language of their choice.</div>
														</Form.Check>
													</div>
												</div>
											</div>
											<div className="info-cover">
												<div className="fs-4 fw-semibold text-light lh-sm mb-2">Second Round: Face-to-Face Interview with Sheela</div>
												<div className="fs-14 lh-sm mb-4">
													In this round, Sheela will conduct a face-to-face interview with the candidate based on your requisition.
													It is recommended that you must provide a detailed requisition, clearly outlining every aspect of the role and the skills required for thr developer.
													This will enabled Sheela to conduct an in-depth interview with the candidate.
												</div>
												<div className="fs-14 lh-sm" style={{ color: '#a3b932' }}>
													<p>
														You can view the result of each section in the Panel alongside the recruiter. This includes a complete screen recording, webcam view,
														and mobile stream of the candidate, offering a three-way cheating check during the interview.
														You'll be able to see every question, the number of submissions compiler outputs, which questions were skipped,
														and Sheela will also ask follow-up questions during the coding test to assess the candidate's understanding of their code.
													</p>
													<p>
														Similarly, you'll have full details from the face-to-face round, including video and audio of the candidate's responses.
													</p>
												</div>
											</div>
										</div>
									</div>
								) : (
									<div className="non-technical-test-sec">
										<div className="description fs-6 text-light text-opacity-75 mb-3">
											This section does not require the candidate to take a coding test.
											Once you fill out this form, the system will only schedule one test with the candidate, which is a face-to-face interview with sheela.
										</div>
										<div className="card border-0 rounded-3 mb-4 p-3 p-xl-4" style={{ backgroundColor: 'var(--custom-bg)' }}>
											<div className="fs-4 fw-semibold text-light lh-sm mb-3 mb-lg-4">Only Round: Face-to-Face Interview with Sheela</div>
											<div className="fs-14 lh-sm mb-3 pb-lg-1">
												In this round, Sheela will conduct a face-to-face interview with the candidate based on your requisition.
												It is recommended that you must provide a detailed requisition, clearly outlining every aspect of the role and the skills required for thr developer.
												This will enabled Sheela to conduct an in-depth interview with the candidate.
											</div>
											<div className="fs-14 lh-sm" style={{ color: '#a3b932' }}>
												You can view the result of this in the Panel with the recruiter, including a complete screen recording, webcam view,
												and mobile stream of the candidate for a through cheating check during the interview.
												Detailed information from the face-to-face round will also be available, with video and audio of the candidate's responses.
												Sheela's evaluation, along explanations for why each answer was correct or incorrect, will be provided for review.
											</div>
										</div>
									</div>
								)}

								<div className="job-description-sec">
									<div className="fs-4 fw-semibold text-light lh-sm mb-4">Job Description</div>
									<div className="row g-3 gx-xl-5">
										<div className="col-sm-6">
											<Form.Group>
												<Form.Label className="text-primary px-3">Job Title</Form.Label>
												<Form.Control type="text" className="border-0 rounded-3 px-3 py-3" placeholder="Enter Job Title" autoComplete="off" required onChange={(e) => { setJobTitle(e.target.value) }} tabIndex="0" />
											</Form.Group>
										</div>
										<div className="col-sm-6">
											<Form.Group>
												<Form.Label className="text-primary px-3">Primary Role</Form.Label>
												<div className="custom-select">
													<Form.Select className="border-0 rounded-3 px-3 py-3" aria-label="Default select example" id="primary-role-id" required onChange={(e) => { setPrimaryRole(e.target.value); enableClientText() }} tabIndex="11">
														<option value="">Select</option>
														<option value="In House">In House</option>
														<option value="Ongoing Client">Ongoing Client</option>
														<option value="New Client">New Client</option>
														<option value="For Bench">For Bench</option>
													</Form.Select>
												</div>
											</Form.Group>
										</div>
										<div className="col-sm-6">
											<Form.Group>
												<Form.Label className="text-primary px-3">Minimum Experience</Form.Label>
												<div className="custom-select">
												<Form.Select className="border-0 rounded-3 px-3 py-3" aria-label="Default select example" placeholder="Years" required onChange={(e) => { setMinimumExp(e.target.value) }}  tabIndex="2">
														<option value="">Select Experience</option>
														{[...Array(50)].map((_, index) => (
														<option key={index} value={index + 1}>
															{index + 1} Years
														</option>
														
														))}
														
													</Form.Select>
													</div>
											</Form.Group>
											
										</div>
										{isClientName ?
											<div className="col-sm-6">
												<Form.Group>
													<Form.Label className="text-primary px-3">Client Name</Form.Label>
													<Form.Control type="text" className="border-0 rounded-3 px-3 py-3" placeholder="Enter Client Name" autoComplete="off" required onChange={(e) => { setclientName(e.target.value) }} />
												</Form.Group>
											</div>
											: null}
										<div className="col-sm-6">
											<Form.Group>
												<Form.Label className="text-primary px-3">Minimum Qualification</Form.Label>
												<div className="custom-select">
													<Form.Select className="border-0 rounded-3 px-3 py-3" aria-label="Default select example" required onChange={(e) => { setMinimumQualification(e.target.value) }} tabIndex="3">
														<option value="">Select</option>
														<option value="Diploma">Diploma</option>
														<option value="Graduate">Graduate</option>
														<option value="Post Graduate">Post Graduate</option>
													</Form.Select>
												</div>
											</Form.Group>
										</div>
										<div className="col-sm-6">
											<Form.Group>
												<Form.Label className="text-primary px-3">Job Location</Form.Label>
												<div className="custom-select">
													<Form.Select className="border-0 rounded-3 px-3 py-3" aria-label="Default select example" required onChange={(e) => { setLocation(e.target.value) }} tabIndex="4">
														<option value="">Select Location</option>
														<option value="Noida">Noida</option>
														<option value="Kolkata">Kolkata</option>
													</Form.Select>
												</div>
											</Form.Group>
										</div>

										<div className="col-sm-6">
											<Form.Group>
												<Form.Label className="text-primary px-3">Department</Form.Label>
												<div className="custom-select">
													<Form.Select className="border-0 rounded-3 px-3 py-3" aria-label="Default select example" required onChange={(e) => { setDepartment(e.target.value) }} tabIndex="5">
														<option value="">Select Department</option>
														<option value="AI and Data Science">AI and Data Science</option>
														<option value="Account and Finance">Account and Finance</option>
														<option value="Back End Support">Back End Support</option>
														<option value="Billing">Billing</option>
														<option value="Business Operation">Business Operation</option>
														<option value="CAM">CAM</option>
														<option value="Content">Content</option>
														<option value="Coordination">Coordination</option>
														<option value="Customer Service">Customer Service</option>
														<option value="Data Science">Data Science</option>
														<option value="Digital Marketing">Digital Marketing</option>
														<option value="Embedded">Embedded</option>
														<option value="Enterprise Applications">Enterprise Applications</option>
														<option value="Foreign Language Expert">Foreign Language Expert</option>
														<option value="GAPM">GAPM</option>
														<option value="HR">HR</option>
														<option value="IT">IT</option>
														<option value="IT Support">IT Support</option>
														<option value="JAVA">JAVA</option>
														<option value="Legal / Paralegal">Legal / Paralegal</option>
														<option value="Management">Management</option>
														<option value="Microsoft Tech Stack">Microsoft Tech Stack</option>
														<option value="Mobile Apps">Mobile Apps</option>
														<option value="Multimedia">Multimedia</option>
														<option value="Operations">Operations</option>
														<option value="Open Source">Open Source</option>
														<option value="Quality">Quality</option>
														<option value="Recruitment">Recruitment</option>
														<option value="Research">Research</option>
														<option value="Sales">Sales</option>
														<option value="SEO / Marketing">SEO / Marketing</option>
														<option value="Testing">Testing</option>
														<option value="Virtual Assistant">Virtual Assistant</option>
														<option value="Web Designer">Web Designer</option>
														{/* <option value="Open Source">Open Source</option>
															<option value="Embedded">Embedded</option>
															<option value="Content">Content</option>
															<option value="Mobile Apps">Mobile Apps</option>
															<option value="SEO / Marketing">SEO / Marketing</option>
															<option value="HR">HR</option>
															<option value="IT">IT</option>
															<option value=".Net">.Net</option>
															<option value="Management">Management</option>

															<option value="GAPM">GAPM</option>
															<option value="CAM">CAM</option>
															<option value="Coordination">Coordination</option>
															<option value="Quality">Quality</option>
															<option value="Account and Finance">Account and Finance</option>
															<option value="Billing">Billing</option>
															<option value="Virtual Assistant">Virtual Assistant</option>
															<option value="Sales">Sales</option>
															<option value="Digital Marketing">Digital Marketing</option>
															<option value="Legal / Paralegal">Legal / Paralegal</option>

															<option value="Back End Support">Back End Support</option>
															<option value="Business Operation">Business Operation</option>
															<option value="Customer Service">Customer Service</option>
															<option value="Data Science">Data Science</option>
															<option value="Foreign Language Expert">Foreign Language Expert</option>
															<option value="IT Support">IT Support</option>
															<option value="Multimedia">Multimedia</option>
															<option value="Operations">Operations</option>
															<option value="Research">Research</option>
															<option value="Web Designer">Web Designer</option> */}
													</Form.Select>
												</div>
											</Form.Group>
										</div>
										<div className="col-sm-6">
											<Form.Group>
												<Form.Label className="text-primary px-3">Job Type</Form.Label>
												<div className="custom-select">
													<Form.Select className="border-0 rounded-3 px-3 py-3" aria-label="Default select example" required onChange={(e) => { setEmploymentType(e.target.value) }} tabIndex="6">
														<option value="">Select Employment</option>
														<option value="Full Time">Full Time</option>
														<option value="Part Time">Part Time</option>
													</Form.Select>
												</div>
											</Form.Group>
										</div>
										<div className="col-sm-6">
											<Form.Group>
												<Form.Label className="text-primary px-3">Job Nature</Form.Label>
												<div className="custom-select">
													<Form.Select className="border-0 rounded-3 px-3 py-3" aria-label="Default select example" required onChange={(e) => { setNatureOfJob(e.target.value) }} tabIndex="9">
														<option value="">Select Job Nature</option>
														<option value="WFO">Work from Office</option>
														<option value="WFH">Work from Home</option>
													</Form.Select>
												</div>
											</Form.Group>
										</div>
										<div className="col-sm-6">
											<Form.Group>
												<Form.Label className="text-primary px-3">Job Shift Type</Form.Label>
												<div className="custom-select">
													<Form.Select className="border-0 rounded-3 px-3 py-3" aria-label="Default select example" required onChange={(e) => { setShiftType(e.target.value) }} tabIndex="8">
														<option value="">Select Shift Type</option>
														<option value="Day Shift">Day</option>
														<option value="Night Shift">Night</option>
													</Form.Select>
												</div>
											</Form.Group>
										</div>
										<div className="col-sm-6">
											<Form.Group>
												<Form.Label className="text-primary px-3">Required By</Form.Label>
												<Form.Control type="date" className="border-0 rounded-3 px-3 py-3" required onChange={(e) => { setReqiredBy(e.target.value) }} tabIndex="7"  min={new Date().toISOString().split("T")[0]} />
											</Form.Group>
										</div>
										<div className="col-xxl-6">
											<Form.Group>
												<Form.Label className="text-primary px-3">Key Skills</Form.Label>
												{/* <Form.Control as="textarea" className="border-0 rounded-3 px-3 py-3" placeholder="Enter key skills ...." rows={4} required onChange={(e)=>{setKeySkills(e.target.value)}} tabIndex="8"/> */}
												{/* <Select isMulti classNamePrefix="custom-select"
														defaultValue={Skills}
														onChange={setKeySkills}
														options={options_}
														styles={customStyles}
														name="customSkills"
														formatOptionLabel={info => (
														<div className='username text-body text-uppercase lh-sm'>{info.value.toUpperCase()}</div>
														)}
														placeholder="Enter a skill..." tabIndex={10}/> */}

												<CreatableSelect isMulti className='custom-select' classNamePrefix='custom-select' required
													closeMenuOnSelect={false}
													name="skills"
													options={SkillsVal}
													styles={customStyles}
													onChange={(value) => {
														setKeySkills(value);
														// setErrors((prevErrors) => ({
														//     ...prevErrors,
														//     skills: '',
														// }));
													}
													}
													placeholder="Add your skills"
												/>
											</Form.Group>
										</div>
										<div className="col-sm-6 col-xxl-3">
											<Form.Group>
												<Form.Label className="text-primary px-3">Min. Budget Per Annum (INR)</Form.Label>
												<div className="custom-select">
													<Form.Select className="border-0 rounded-3 px-3 py-3" aria-label="Default select example" required onChange={(e) => { setRange1(e.target.value) }} value={range1} tabIndex="9">
														<option value="">Select Budget</option>
														{[...Array(25)].map((_, index) => (
														<option key={index} value={index + 1}>
															{index + 1} Lakh
														</option>
														))}
														
														<option value="25+">25+ Lakh</option>
													</Form.Select>
												</div>
											</Form.Group>
										</div>
										<div className="col-sm-6 col-xxl-3">
											<Form.Group>
												<Form.Label className="text-primary px-3">Max. Budget Per Annum (INR)</Form.Label>
												<div className="custom-select">
													<Form.Select className="border-0 rounded-3 px-3 py-3" aria-label="Default select example" value={range2} required onChange={(e) => { setRange2(e.target.value) }} tabIndex="9">
														<option value="">Select Budget</option>
														{[...Array(25)].map((_, index) => (
														<option key={index} value={index + 1}>
															{index + 1} Lakh
														</option>
														
														))}
														<option value="25+">25+ Lakh</option>
													</Form.Select>
												</div>
											</Form.Group>
										</div>
										<div className="col-12 new-mail-page">
											<Form.Label className="text-primary px-3">Job Description</Form.Label>
											<div className="editor-section user-form-details border border-secondary rounded d-flex flex-column flex-fill overflow-auto p-2">
												{/* React Quill Editor */}
												<ReactQuill  className="quill custom-quill-editor d-flex flex-column flex-fill overflow-auto" theme="snow" placeholder="Enter job description here..."
													onChange={(content) => setJobDescription(content)}
													modules={toolbarModules}
													formats={formats}
													required
												/>
											</div>
										</div>
										{/* <div className="col-12">
											<Form.Group>
												<Form.Label className="text-primary px-3">Job Description</Form.Label>
												<Form.Control as="textarea" className="border-0 rounded-3 px-3 py-3" placeholder="Enter job description here..." rows={4} required onChange={(e) => { setJobDescription(e.target.value) }} tabIndex="11" />
											</Form.Group>
										</div> */}
										<div className="col-12 btn_wrapper d-flex justify-content-center gap-3 mt-4 pt-xl-3">
											<Button type="button" variant="secondary" className="fs-18 py-2 px-5" onClick={handleBack}>Cancel</Button>
											<Button type="submit" variant="success" className="fs-18 py-2 px-5"  id="Add-requisition-button">Submit</Button>
											
										</div>
									</div>
								</div>
							</Form>
						</div>
					</div>
				</div>
				
			
			
			
			
			
				{/* Submit Requisition Modal */}
				{showConfirm?
				<SubmitRequisitionModal show={showRequisitionModal} close={closeRequisitionModal}  handleSubmitRequisition={handleSubmitRequisition} isloading={isloading} formData={formData} setIsloading={setIsloading} setIsClientName={setIsClientName} setJobTitle={setJobTitle}	setPrimaryRole={setPrimaryRole}    setclientName={setclientName} setMinimumExp={setMinimumExp} setDepartment={setDepartment} setJobDescription={setJobDescription} setKeySkills={setKeySkills} setLocation={setLocation} setMinimumQualification={setMinimumQualification} setReqiredBy={setReqiredBy} setEmploymentType={setEmploymentType} setShiftType={setShiftType} setNatureOfJob={setNatureOfJob} setPreferedLang={setPreferedLang} setShowConfirm={setShowConfirm}/>
				:null}
			</div>
		</>
	)
}

// export default NewRecruitment

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedinUser: (user) => dispatch(setUserInfo(user)),
	};
};

const mapStoreStateToProps = (state) => {
	return {
		...state,
	};
};

export default connect(
	mapStoreStateToProps,
	mapDispatchToProps
)(NewRecruitment);