import React, { useEffect, useState } from 'react';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Modal, Form, Button } from 'react-bootstrap';

// Quill Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from 'react-toastify';
import { callAxios } from '../utils/utils';
import { environment } from '../environment';
import { handleSelectionUsers } from '../utils/emailUtils';

const animatedComponents = makeAnimated();


export const AddNewMeetingModal = ({ show, close, userRole,user, setShowAddNewMeeting, setIsLoading,currentTimezone, setMeetings, setPagination, setAvailabel, setActive, setMeetingDetails, setMeetingUrl }) => {
    // React-slick npm style customisation
    // setIsLoading(true)

    const [duration, setDuration] = useState('');

  
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: "var(--bs-border-color)",
            borderRadius: ".375rem",
            backgroundColor: "var(--bs-white)",
            fontSize: "16px",
            padding: "5px",
            boxShadow: 'none'
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#868686", // Change the color of the placeholder text
            fontSize: "16px",
            padding: "0",
        }),
    };

    const [users, setUsers] = useState([])
    
    useEffect(()=>{
        callAxios(environment.REACT_APP_API_URL_Live + "api/v1/meetings/getInfo",{},"post").then((res)=>{
                const dusers = atob(atob(atob(res.data.id)))
                const usser = JSON.parse(dusers)
            setUsers(usser)
        })
     },[])


    const UserList = users;

    // Custom react-select component with animate with hide dropdown 
    const customComponents = {
        ...animatedComponents,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
    };

    // Define the quill toolbar modules
    const toolbarModules = {
        toolbar: {
            container: '#toolbar', // Reference the ID of the custom toolbar
            handlers: {
                // Add custom handlers if necessary
            }
        },
        toolbar: [
            [{ 'font': [] }],
            [{ 'header': '1' }, { 'header': '2' }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'list': 'bullet' }, { 'list': 'ordered' }],
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            // [{ 'color': [] }, { 'background': [] }],
            ['link', 'image', 'video', 'formula'],
            ['blockquote', 'code-block'], // Quote
            ['clean'] // Remove formatting button
        ],
    };

    // Define the quill formats that are allowed
    const formats = [
        'font', 'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'blockquote'
    ];

    const [title, setTitle] = useState("");
    const [topic, setTopic] = useState("");
    const [description, setJobDescription] = useState("")
    const [agenda, setAgenda] = useState("")

    const [fromDate, setFromDate] = useState("")
    const [fromTime, setFromTime] = useState("")

    const [toDate, setToDate] = useState("")
    const [toTime, setToTime] = useState("")

    const [attendees , setAttendee] = useState([])
    const [clients , setClient] = useState([])

    const [attendeesD , setAttendeeDisplay] = useState([])
    const [clientsD , setClientDisplay] = useState([])
    const [clientVal, setClientVal] = useState([])
    const [isClientEmail, setClientEmail] = useState(false)

    function fromHex(hexStr) {
        let str = '';
        for (let i = 0; i < hexStr.length; i += 2) {
          str += String.fromCharCode(parseInt(hexStr.substr(i, 2), 16));
        }
        return str;
      }
// console.log(clientVal)
    const handleClientInfo = (topic) =>{
        setTopic(topic)
        callAxios(environment.REACT_APP_API_URL_Live +"api/v1/meetings/search-client-info",{filterValue:topic},"post").then((res)=>{
            setClientEmail(true)
            const contact = res.data.contactId
            const decodedString = fromHex(contact);
             setClientVal([JSON.parse(decodedString)]); // Output: "hello"
            //  console.log(JSON.parse(decodedString))
            


        })
    }

    const handleMeetingCreate = async(e) =>{
        e.preventDefault()
        
        // console.log(title,description, agenda,fromDate,fromTime,toDate,toTime)
        const fromDateTime = new Date(`${fromDate} ${fromTime}`);
const toDateTime = new Date(`${toDate} ${toTime}`);

// Compare the Date objects
if (fromDateTime.getTime() > toDateTime.getTime()) {
    toast.error("From date should not be greater than the To Date", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    return false;
}
setIsLoading(true)
        callAxios(environment.REACT_APP_API_URL_Live +"api/v1/meetings/save-meeting",{title:title, from:fromDate+' '+fromTime, to:toDate+' '+toTime, description:description, agenda:agenda, uid:user?.uid, role:userRole, clients:clientVal, attendees:attendees,attendeesD:attendeesD,clientsD:clientVal, topic_name:topic},"post").then((res)=>{
            // console.log(res)
            if(res.status === 201){
                callAxios(environment.REACT_APP_API_URL_Live + "api/v1/meetings/get-meeting",{role:userRole, isFilter:true,fromDate:new Date().toJSON().slice(0, 10), toDate:new Date().toJSON().slice(0, 10),currentTimezone:currentTimezone},"post").then((res)=>{
                    setIsLoading(false)
                setShowAddNewMeeting(false)
                toast.success("Meeting added successfully!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                
                    setMeetings(res.data.meetings)
                    setPagination(res.data.pagination)
                    setIsLoading(false)
                    
                    if(res.data.meetings.length > 0){
                        setAvailabel(true)
                        // getMeetingDetails(res.data.meetings[0], 0)
                        setActive(0)
                        setMeetingDetails(res.data.meetings[0].meeting)
                        setMeetingUrl(res.data.meetings[0].short_url)
                        
                       
                    } else{
                        setAvailabel(false)
                    }
                    // getMeetingDetails(res.data.meetings[0], 0)
                })
                
            } else{
                toast.error(res.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            
        })
        
    }

    
    return (
        <>
            

            <Modal show={show} centered onHide={close} size='xl' scrollable backdrop="static" keyboard={false} dialogClassName="addNewMeetingModal mw-auto" contentClassName='rounded overflow-hidden'>
            <Modal.Body className="px-lg-4 px-xl-5 py-4">
                <div className="custom-wrapper my-2 pb-1">
                    <div className="fs-3 fw-bold text-dark text-center lh-sm mb-4">Add New Meeting</div>
                    <Form className="user-form-details d-flex flex-column h-100" onSubmit={(e)=>{handleMeetingCreate(e)}}>
                    {/* <Form.Group className="mb-3" controlId="Enter Topic id">
                            <Form.Control type="text" className="custom-input fw-medium border shadow-none py-2" placeholder="Enter Topic name here" autoComplete="off" required onChange={(e)=>{handleClientInfo(e.target.value)}} />
                        </Form.Group> */}

                        <Form.Group className="mb-3" controlId="addSubject">
                            <Form.Control type="text" className="custom-input fw-medium border shadow-none py-2" placeholder="Add Title" autoComplete="off" required onChange={(e)=>{setTitle(e.target.value)}} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            {clientVal.length>0?
                            <Select className='custom-select text-dark w-100' classNamePrefix='custom-select'
                                isMulti
                                name="emailTo"
                                closeMenuOnSelect={true}
                                components={customComponents}
                                options={clientVal}
                                styles={customStyles}
                                placeholder="Add Client"
                                required={isClientEmail}
                                value={clientVal}
                                onChange={(selectedOption) => handleSelectionUsers(selectedOption, setAttendee,setClient, "client",setAttendeeDisplay, setClientDisplay)} 
                            />
                            :null }
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Select className='custom-select text-dark w-100 cc-user-input-css' classNamePrefix='custom-select'
                                isMulti
                                name="emailToCC"
                                closeMenuOnSelect={true}
                                components={customComponents}
                                options={UserList}
                                styles={customStyles}
                                placeholder="Add required attendees"
                                required
                                onChange={(selectedOption) => handleSelectionUsers(selectedOption, setAttendee,setClient, "attendee",setAttendeeDisplay, setClientDisplay)} 
                            />
                        </Form.Group>

                        <div className="date-wrapper overflow-hidden mb-3">
                            <div className="row align-items-center g-3">
                                <div className="col-xl-5">
                                    <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill row-gap-2 column-gap-3" controlId="dateTimeFrom">
                                        <Form.Label className="m-0">From</Form.Label>
                                        <Form.Control type="date" className="custom-input bg-transparent border shadow-none py-2"
                                            min={new Date().toJSON().slice(0, 10)}
                                            required
                                            onChange={(e)=>{setFromDate(e.target.value)}}
                                        />
                                        <Form.Control type="time" className="custom-input bg-transparent border shadow-none py-2"
                                            min={new Date().toJSON().slice(0, 10)}
                                            // defaultValue={'23:00'}
                                            required
                                            onChange={(e)=>{setFromTime(e.target.value)}}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-10 col-xl-5">
                                    <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill row-gap-2 column-gap-3" controlId="dateTimeTo">
                                        <Form.Label className="m-0">To</Form.Label>
                                        <Form.Control type="date" className="custom-input bg-transparent border shadow-none py-2"
                                            min={new Date().toJSON().slice(0, 10)}
                                            // defaultValue={new Date().toJSON().slice(0, 10)}
                                            required
                                            onChange={(e)=>{setToDate(e.target.value)}}
                                        />
                                        <Form.Control type="time" className="custom-input bg-transparent border shadow-none py-2"
                                            min={new Date().toJSON().slice(0, 10)}
                                            // defaultValue={'23:00'}
                                            required
                                            onChange={(e)=>{setToTime(e.target.value)}}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-2 col-xl-2">
                                    <div className="text-body fw-bold"></div>
                                </div>
                            </div>
                        </div>

                        <div className="editor-section mb-3">
                            {/* React Quill Editor */}
                            <ReactQuill id="note-description" className="custom-quill-editor border rounded overflow-hidden" theme="snow" placeholder="Description.."
                                modules={toolbarModules}
                                formats={formats}
                                onChange={(content) => setJobDescription(content)}
                            />
                        </div>

                        <Form.Group className="mb-3" controlId="addSubject">
                            <Form.Control type="text" className="custom-input border shadow-none py-2" placeholder="Add an agenda" autoComplete="off" onChange={(e)=>{setAgenda(e.target.value)}}/>
                        </Form.Group>

                        <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3 pt-3">
                            <Button variant="secondary" onClick={close} className='btn-custom px-5 py-2'>Cancel</Button>
                            <Button type='submit' variant="success" className='btn-custom px-5 py-2'>Send</Button>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
        </>
       
    )
}
