import React from 'react'

const Input = ({ placeholder, value, changeHandler }) => {
    return (
        <input type="email" className="form-control join_room_input" id="exampleFormControlInput1" value={value} onChange={changeHandler} placeholder={placeholder} />
    )
}

const JoinRoomInputMeeting = (props) => {
    const { namValue, setNameValue, roomId, setRoomIdValue, isRoomHost } = props
    const handleRoomIdValueChange = event => {
        setRoomIdValue(event.target.value)
    }

    const handleNameValueChange = event => {
        setNameValue(event.target.value)
       // console.log(event.target.value)
    }

    return (
        <div className='join_room_inputs_container w-100'>
            {!isRoomHost && (
                <div className='mb-3'>
                    <input type="email" className="form-control" id="exampleFormControlInput1" value={roomId} changeHandler={handleRoomIdValueChange} placeholder="Enter meeting ID" />
                </div>
            )}
            <div className='mb-3'>
                <input type="email" className="form-control" id="exampleFormControlInput1" value={namValue} onChange={(event)=>{setNameValue(event.target.value)}} changeHandler={handleNameValueChange} placeholder="Enter your Name" required />
            </div>
        </div>
    )
}

export default JoinRoomInputMeeting