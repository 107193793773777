export const languageOptions = [
  
  {
    id: 63,
    name: "JavaScript",
    label: "JavaScript",
    value: "javascript",
  },
   {
    id: 75,
    name: "C",
    label: "C",
    value: "c",
  },
  {
    id: 76,
    name: "C++",
    label: "C++",
    value: "cpp",
  },
   {
    id: 51,
    name: "C#",
    label: "C#",
    value: "csharp",
  },
 {
    id: 60,
    name: "Go (1.13.5)",
    label: "Go (1.13.5)",
    value: "go",
  },
   {
    id: 62,
    name: "Java",
    label: "Java",
    value: "java",
  },
 {
    id: 78,
    name: "Kotlin",
    label: "Kotlin",
    value: "kotlin",
  },
  {
    id: 79,
    name: "Objective-C",
    label: "Objective-C",
    value: "objectivec",
  },
  {
    id: 85,
    name: "Perl (5.28.1)",
    label: "Perl (5.28.1)",
    value: "perl",
  },
  {
    id: 68,
    name: "PHP",
    label: "PHP",
    value: "php",
  },
  {
    id: 70,
    name: "Python",
    label: "Python",
    value: "python",
  },
  {
    id: 72,
    name: "Ruby",
    label: "Ruby",
    value: "ruby",
  },
  {
    id: 73,
    name: "Rust",
    label: "Rust",
    value: "rust",
  },
  {
    id: 81,
    name: "Scala",
    label: "Scala",
    value: "scala",
  },
  {
    id: 82,
    name: "SQL",
    label: "SQL",
    value: "sql",
  },
  {
    id: 83,
    name: "Swift",
    label: "Swift",
    value: "swift",
  },
  {
    id: 74,
    name: "TypeScript",
    label: "TypeScript",
    value: "typescript",
  },
];
