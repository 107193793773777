import React from 'react';
import { useNavigate } from 'react-router-dom';

export const CeoUpdateModal = ({lastUpdateId,lastReadId,setIsModalVisible}) => {
    let navigate = useNavigate();
    const gotoHelpdesk = () =>{
        // console.log("goint incentive page",lastUpdateId)
        // setIsModalVisible(false)
        if(lastUpdateId !== lastReadId){
            setIsModalVisible(false)
        }
        localStorage.setItem("lastReadId",lastUpdateId)
        navigate("/bdm-incentive-helpdesk")
    }
        
        return (
        <div className='ceo-new-update-model'>
            {/* Modal */}
            <div className="modal fade show d-block" id="CeoUpdateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: '650px' }}>
                    <div className="modal-content bg-dark border-top border-5 border-primary rounded-0 shadow">
                        <div className="modal-body p-4 p-md-5 text-center">
                            <div className='fs-5 fw-semibold mb-4' id='DeivceErrorMessage----'></div>
                            <div className='fs-5 fw-semibold mb-4 text-warning'>New Message from the CEO for All BDMs</div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div id="ceo-new-message-version" className='mb-3 text-start'>Shaun has shared a new message exclusively for you. Visit the "Incentive Helpdesk" under "CEO Updates" to read it.</div>
                                    <button className='btn btn-success' onClick={gotoHelpdesk}>Go to CEO Message</button>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            
        </div>


    )
}