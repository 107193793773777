import React, { useEffect, useMemo, useState } from "react";
import './AllMeetings.scss';
import { Form, Button } from 'react-bootstrap';
import SearchIcon from '../resources/images/search.svg';
import { AddNewMeetingModal } from "./AddNewMeetingModal";
import { CancelMeetingModal } from "./CancelMeetingModal";
import { EditMeetingModal } from "./EditMeetingModal";
import { connect } from "react-redux";
import { setFormId, setIdentity, setRoomId, setUserInfo } from "../store/actions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { callAxios, convertReadable, convertReadableTime, userTimezone } from "../utils/utils";
import { environment } from "../environment";
import { v4 as uuidv4 } from "uuid";
import { JoinNowPopupMeeting } from "../LeadsListingPage/JoinNowPopupMeeting";
import PaginationMeeting from "./Pagination";
import { toast } from "react-toastify";


const AllMeetings = (props) => {
    const { setLoggedinUser, userRole, setIdentityAction, setRoomIdAction, setFormIdAction } = props;
    const [user, loading] = useAuthState(auth);
    // For Sidebar list selected card
    const [active, setActive] = useState(0);

    //userTimeZone
    const currentTimezone = userTimezone()

    // console.log("my timezone is:",currentTimezone)

    // Add new meeting modal
    const [showAddNewMeeting, setShowAddNewMeeting] = useState(false);
    const closeAddMeetingModal = () => setShowAddNewMeeting(false);
    const openAddMeetingModal = () => setShowAddNewMeeting(true);

    // Edit meeting modal
    const [showEditMeeting, setShowEditMeeting] = useState(false);
    const closeEditMeetingModal = () => setShowEditMeeting(false);
    const openEditMeetingModal = () => setShowEditMeeting(true);

    // Cancel meeting modal
    const [showCancelMeeting, setShowCancelMeeting] = useState(false);
    const closeCancelMeetingModal = () => setShowCancelMeeting(false);
    const openCancelMeetingModal = () => setShowCancelMeeting(true);

    const [meetings, setMeetings] = useState([]);
    const [meetingDetails, setMeetingDetails] = useState({});
    const [pagination, setPagination] = useState({});

    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")

    let navigate = useNavigate();

    useEffect(() => {
		if (loading) return;
		if (!user) navigate("/");
		if (user) {
			const userInfo = {
				uid: user?.uid,
				email: user?.email,
				displayName: user?.displayName,
				accessToken: user?.accessToken,
				idToken: user?.idToken,
			};
			setLoggedinUser(userInfo);
		}
	}, [user, loading]);

    const [meetingUrl, setMeetingUrl] = useState('');
    const getMeetingDetails = async(item, id)=>{
        //console.log(item)
        setActive(id)
        setMeetingDetails(item.meeting)
        setMeetingUrl(item.short_url)
      
       
    }

    useEffect(()=>{
        callAxios(environment.REACT_APP_API_URL_Live + "api/v1/meetings/get-meeting",{role:userRole, isFilter:true,fromDate:new Date().toJSON().slice(0, 10), toDate:new Date().toJSON().slice(0, 10),currentTimezone:currentTimezone},"post").then((res)=>{
            setMeetings(res.data.meetings)
            setPagination(res.data.pagination)
            setIsLoading(false)
            if(res.data.meetings.length > 0){
                setAvailabel(true)
                getMeetingDetails(res.data.meetings[0], 0)
            } else{
                setAvailabel(false)
            }
            // getMeetingDetails(res.data.meetings[0], 0)
        })
    },[user])

    const [isGoLive, setGoLive] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingDetails, setIsLoadingDetails] = useState(true)

    const handleHostMeeting = async (nameValue, meetingId) => {
        setGoLive(true);
        setIdentityAction(nameValue);
        const createdRoomId = meetingId;
        setRoomIdAction(createdRoomId);
        setFormIdAction(createdRoomId);
        // //console.log(createdRoomId, nameValue)
        document.getElementById("p-box-2").click();
        // updateCamJoined(dataID);
      };

    

    const meetingHtml = meetings && meetings.length > 0 && meetings.map((item, id) => {
        let dateLabel;
const inputDate = new Date(item.meeting.from); // Example input date
const today = new Date();
const tomorrow = new Date(today);

// Set both today and tomorrow to midnight (00:00:00) to ignore the time part
inputDate.setHours(0, 0, 0, 0);
today.setHours(0, 0, 0, 0);
tomorrow.setHours(0, 0, 0, 0);

// Set tomorrow's date by adding 1 day to today
tomorrow.setDate(today.getDate() + 1);

if (inputDate.getTime() === today.getTime()) {
    dateLabel = "Today";
} else if (inputDate.getTime() === tomorrow.getTime()) {
    dateLabel = "Upcoming";
} else if (inputDate.getTime() > tomorrow.getTime()) {
    dateLabel = "Upcoming";
} else {
    dateLabel = "Past";
}



        return(
            <div className={`meeting-card rounded pointer ${id === active ? "selected" : "" } ${dateLabel.toLowerCase()}`} key={id} onClick={() => getMeetingDetails(item,id)}>
            <div className="meeting-header rounded-top px-3 py-1">
                <div className="meeting-time fs-12 text-white text-opacity-75 text-truncate text-capitalize">{dateLabel}: {convertReadableTime(item.meeting.from)}</div>
            </div>
            <div className="meeting-body px-3 py-2">
                <div className="fs-14 fw-semibold text-truncate text-white mb-1">{item.meeting.title}</div>
                <div className="text-white text-opacity-75 fs-12 d-flex mb-1">
                    <div className="org-title text-capitalize">Organizer</div>:
                    <div className="organizer-name text-capitalize ms-2">{item.meeting.created_by}</div>
                </div>
                <div className="text-white text-opacity-75 fs-12 d-flex">
                    <div className="org-title text-capitalize">Role</div>:
                    <div className="organizer-name text-uppercase ms-2">{item.meeting.role}</div>
                </div>
            </div>
        </div>
        )
        
})

// console.log(meetingDetails);

const joinClientMeeting = (meetId) =>{
    const url ="http://localhost:3000/join-meeting?nm="+btoa("Hardy")+"&id=" + btoa(meetId);
    window.open(url, '_blank');
}

const [currentPage, setCurrentPage] = useState(1);
const totalPages = pagination.totalPage; // Assuming there are 20 pages in total
const adjacents = 2; // Number of adjacent pages to display

const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    doPaginate(pageNumber)
    // You can also perform any other actions when the page changes
};

const doPaginate = (page) => {
    setIsLoading(true)
    callAxios(environment.REACT_APP_API_URL_Live + "api/v1/meetings/get-meeting",{role:userRole,page:page,isFilter:true,fromDate:fromDate, toDate:toDate,currentTimezone:currentTimezone},"post").then((res)=>{
        setMeetings(res.data.meetings)
        setPagination(res.data.pagination)
        setIsLoading(false)
        getMeetingDetails(res.data.meetings[0], 0)
    })
}

let achorVal;
const textToCopy = document.getElementById("meeting-url-for-copy");
if(textToCopy){
    
    achorVal = textToCopy.href
    //console.log(achorVal)
}
    
const copyText = () => {
    navigator.clipboard.writeText(achorVal).then(
      () => {
        toast.success("copied!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
      },
      () => {
        toast.error("failed to copy!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
      }
    );
  };

  const [isDetailsAvailable, setAvailabel] = useState(false)

  const filterByDate = async() =>{
    setIsLoading(true)
    callAxios(environment.REACT_APP_API_URL_Live + "api/v1/meetings/get-meeting",{role:userRole,isFilter:true,fromDate:fromDate, toDate:toDate,currentTimezone:currentTimezone},"post").then((res)=>{
        setMeetings(res.data.meetings)
        setPagination(res.data.pagination)
        setIsLoading(false)
        if(res.data.meetings.length > 0){
            setAvailabel(true)
            getMeetingDetails(res.data.meetings[0], 0)
        } else{
            setAvailabel(false)
        }
        
    })
  }

  useEffect(()=>{
    setFromDate(new Date().toJSON().slice(0, 10) || "")
    setToDate(new Date().toJSON().slice(0, 10) || "")
  },[])
    // console.log(active,meetingDetails)
    return (
        <div className="AllMeetings-page py-3">
            {isLoading? <div className="loading" style={{zIndex:9999}}>Loading&#8230;</div>:null}
            <div className="container-fluid px-xxl-5">
                <div className="row g-3 border-bottom border-3 border-primary border-opacity-75 mb-3 pb-3">
                    <div className="col-xl-10">
                        <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3">
                            <div className="fs-3 fw-semibold text-light lh-sm text-nowrap">All Meetings <i className="bi bi-caret-down-fill fs-4 text-clr3 ms-1"></i></div>
                            <div className="date-wrapper d-flex flex-column flex-md-row align-items-md-center gap-2 gap-xxl-3 ms-xxl-3">
                                <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xxl-3" controlId="dateFrom">
                                    <Form.Label className="m-0">From</Form.Label>
                                    <Form.Control type="date" className="bg-transparent py-2" style={{ colorScheme: 'dark' }}
                                        // min={new Date().toJSON().slice(0, 10)}
                                        value={fromDate}
                                        onChange={(e)=>{setFromDate(e.target.value)}}
                                    />
                                </Form.Group>
                                <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xxl-3" controlId="dateTo">
                                    <Form.Label className="m-0">To</Form.Label>
                                    <Form.Control type="date" className="bg-transparent py-2" style={{ colorScheme: 'dark' }}
                                        // min={new Date().toJSON().slice(0, 10)}
                                        value={toDate}
                                        onChange={(e)=>{setToDate(e.target.value)}}
                                    />
                                </Form.Group>
                                <div className="d-flex align-items-center gap-2 gap-xxl-3 mt-2 mt-md-0">
                                    <Button variant="secondary" className="lh-sm py-2"><img src={SearchIcon} alt="" className="img-fluid icon" onClick={filterByDate} /></Button>
                                    {pagination?
                                    <div className="text-light fs-6 fw-medium text-nowrap">{pagination.totalCount} Result</div>
                                    :
                                    null
                                    }
                                </div>
                            </div>
                            <div className="highlighter-wrapper row row-cols-2 g-1 ms-xxl-4" style={{ maxWidth: '190px' }}>
                                <div className="col-6 highlight-menu fs-12 d-flex align-items-center gap-2">
                                    <span className="d-block icon icon-primary rounded-1"></span>
                                    <span className="text-white text-opacity-75">Selected</span>
                                </div>
                                <div className="col-6 highlight-menu fs-12 d-flex align-items-center gap-2">
                                    <span className="d-block icon icon-warning rounded-1"></span>
                                    <span className="text-white text-opacity-75">Upcoming</span>
                                </div>
                                <div className="col-6 highlight-menu fs-12 d-flex align-items-center gap-2">
                                    <span className="d-block icon icon-success rounded-1"></span>
                                    <span className="text-white text-opacity-75">Today</span>
                                </div>
                                <div className="col-6 highlight-menu fs-12 d-flex align-items-center gap-2">
                                    <span className="d-block icon icon-danger rounded-1"></span>
                                    <span className="text-white text-opacity-75">Completed</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-2 text-xl-end">
                        <Button variant='light' className="rounded px-3" onClick={openAddMeetingModal}><i className="bi bi-plus-circle text-success me-1"></i> New Meeting</Button>
                    </div>
                </div>

                <div className="meeting-section">
                    <div className="row g-3">
                        <div className="col-lg-4 col-xl-3 col-xxl-3">
                        
                            <div className="meeting-card-wrapper d-flex flex-column">
                               
                                <div className="d-flex flex-column gap-2 overflow-auto">{meetings.length === 0?"No meetings found!":meetingHtml}</div>
                                {pagination && pagination.totalPage > 1 ?
										<PaginationMeeting currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} adjacents={adjacents} userRole={userRole} />
										: null}
                            </div>
                        
                        </div>
                        <div className="col-lg-8 col-xl-9 col-xxl-9">
                           {isDetailsAvailable?
                            <div className="meeting-details-section w-100 d-flex flex-column gap-2">
                                
                                <div className="info-card rounded-3 h-100 p-3 p-lg-4">
                                    <div className="row g-3 mb-3 mb-xl-4">
                                        <div className="col-xl-7 order-2 order-xl-1">
                                            {meetingDetails.title?
                                            <div className="info-name">
                                                <div className="title-label text-primary text-capitalize text-md-end">Meeting Title</div>
                                                <div className="title-name text-light">{meetingDetails.title}</div>
                                            </div>
                                            :null}
                                        </div>
                                        <div className="col-xl-5 order-xl-1">
                                            {meetingDetails.created_by_email === user?.email?
                                                meetingDetails.is_meeting_canceled?
                                                <Button variant="outline-danger" size="sm" className="text-light rounded-1 px-3"><i className="bi bi-x-circle"></i> Meeting Canceled</Button>
                                                :
                                            <div className="btn-wrapper d-flex flex-wrap justify-content-xl-end gap-2">
                                                <Button variant="outline-warning" size="sm" className="text-light rounded-1 px-3" onClick={openEditMeetingModal}><i className="bi bi-pencil"></i> Edit Meeting</Button>
                                                <Button variant="outline-danger" size="sm" className="text-light rounded-1 px-3" onClick={openCancelMeetingModal}><i className="bi bi-x-circle"></i> Cancel Meeting</Button>
                                            </div>
                                            :null}

                                            {meetingDetails.is_meeting_canceled && meetingDetails.created_by_email !== user?.email?
                                                 <Button variant="outline-danger" size="sm" className="text-light rounded-1 px-3"><i className="bi bi-x-circle"></i> Meeting Canceled</Button>
                                                 :null
                                            }
                                        </div>
                                    </div>
                                    {Array.isArray(meetingDetails?.clients) && meetingDetails.clients.length > 0?
                                    <div className="info-name mb-3 mb-xl-4">
                                        <div className="title-label text-primary text-capitalize text-md-end">Client</div>
                                        <div className="title-name text-light text-capitalize">
                                        {Array.isArray(meetingDetails?.clients) && meetingDetails.clients.map(item => item.label).join(', ')}
                                        </div>
                                    </div>
                                    :null}
                                    <div className="info-name mb-3 mb-xl-4">
                                        <div className="title-label text-primary text-capitalize text-md-end">Attendee</div>
                                        <div className="title-name text-light text-capitalize">
                                        {Array.isArray(meetingDetails?.attendees) && meetingDetails.attendees.map(item => item.label).join(', ')}
                                       
                                        </div>
                                    </div>
                                    <div className="info-name mb-3 mb-xl-4">
                                        <div className="title-label text-primary text-capitalize text-md-end">Organizer</div>
                                        <div className="title-name text-light text-capitalize">{meetingDetails.created_by}</div>
                                    </div>
                                    <div className="info-name mb-3 mb-xl-4">
                                        <div className="title-label text-primary text-capitalize text-md-end">Role</div>
                                        <div className="title-name text-light text-uppercase">{meetingDetails.role}</div>
                                    </div>
                                    <div className="info-name mb-3 mb-xl-4">
                                        <div className="title-label text-primary text-capitalize text-md-end">Date & Time</div>
                                        <div className="title-name text-light text-uppercase">
                                            <span>{convertReadable(meetingDetails.to)}</span>, <span>{convertReadableTime(meetingDetails.from)} - {convertReadableTime(meetingDetails.to)}</span>
                                        </div>
                                    </div>
                                    {meetingDetails.description?
                                    <div className="info-name mb-3 mb-xl-4">
                                        <div className="title-label text-primary text-capitalize text-md-end">Description</div>
                                        <div className="title-name text-light" dangerouslySetInnerHTML={{ __html: meetingDetails.description }}>
                                            
                                        </div>
                                    </div>
                                    :null}
                                    {meetingDetails.agenda?
                                    <div className="info-name mb-3 mb-xl-4">
                                        <div className="title-label text-primary text-capitalize text-md-end">Agenda</div>
                                        <div className="title-name text-light">{meetingDetails.agenda}</div>
                                    </div>
                                    :null}  
                                    {meetingDetails.is_meeting_canceled?
                                    <div className="info-name mb-3 mb-xl-4">
                                    <div className="title-label text-primary text-capitalize text-md-end">Cancel Note</div>
                                    <div className="title-name text-light">{meetingDetails.note}</div>
                                </div>
                                    : 
                                    <div className="info-name">
                                        <div className="title-label text-primary text-capitalize text-md-end">Meeting Link</div>
                                        <div className="title-name text-light ">
                                            <div className="d-flex flex-wrap gap-2">
                                                <span className="text-truncate text-lowercase">
                                                    <a href={environment.REACT_SHORT_URL+meetingUrl} rel="noreferrer" target="_blank" id="meeting-url-for-copy" className="text-white" >{environment.REACT_SHORT_URL+meetingUrl}</a></span>
                                                <span className="text-white fw-medium pointer" onClick={copyText} >COPY LINK</span>
                                            </div>
                                            {meetingDetails.created_by === user?.displayName?
                                            <Button variant="primary" className="btn-custom mt-3 px-4 py-2" onClick={() =>
                        handleHostMeeting("_Organizer_" + user?.displayName, meetingDetails.meeting_id)
                      }>Join</Button>
                                            :
                                            <Button variant="primary" className="btn-custom mt-3 px-4 py-2" onClick={() =>
                                                handleHostMeeting("_User_" + user?.displayName, meetingDetails.meeting_id)
                                              }>Join</Button>
                                            }

                                            {/* <Button variant="secondary" className="btn-custom mt-3 px-4 py-2" onClick={()=>{joinClientMeeting(meetingDetails.meeting_id)}}>
                                                Join Meeting
                                            </Button> */}
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                            :null}
                            
                        </div>
                    </div>
                </div>
            </div>

            {/* Add New Meeting Modal */}
            <AddNewMeetingModal show={showAddNewMeeting} close={closeAddMeetingModal} userRole={userRole} user={user} setShowAddNewMeeting={setShowAddNewMeeting} setIsLoading={setIsLoading} currentTimezone={currentTimezone} setMeetings={setMeetings} setPagination={setPagination} setAvailabel={setAvailabel} setActive={setActive} setMeetingDetails={setMeetingDetails} setMeetingUrl={setMeetingUrl}/>

            {/* Edit Meeting Modal */}
            <EditMeetingModal show={showEditMeeting} close={closeEditMeetingModal} meetingDetails={meetingDetails} user={user} userRole={userRole} roomId={meetingDetails.meeting_id} setIsLoading={setIsLoading} setShowEditMeeting={setShowEditMeeting} currentTimezone={currentTimezone} setMeetings={setMeetings} setPagination={setPagination} setAvailabel={setAvailabel} setActive={setActive} setMeetingDetails={setMeetingDetails} setMeetingUrl={setMeetingUrl}/>

            {/* Cancel Meeting Modal */}
            <CancelMeetingModal show={showCancelMeeting} close={closeCancelMeetingModal} meetingId={meetingDetails.id} setShowCancelMeeting={setShowCancelMeeting} setIsLoading={setIsLoading} setMeetings={setMeetings} setPagination={setPagination} setAvailabel={setAvailabel} setActive={setActive} setMeetingDetails={setMeetingDetails} setMeetingUrl={setMeetingUrl} currentTimezone={currentTimezone} userRole={userRole}/>

            <button id="p-box-2" data-bs-toggle="modal" data-bs-target="#joinCall" className="btn btn-warning btn-custom btn-next rounded-1 mt-1" style={{ display: "none" }} ></button>

             {meetingDetails.created_by === user?.displayName? 
           
            <JoinNowPopupMeeting user={user} isGoLive={isGoLive} to="organizer" userRole={userRole} meeting={meetingDetails} currentTimezone={currentTimezone} meetingId={meetingDetails.id}/>
      
          :
       
            <JoinNowPopupMeeting user={user} isGoLive={isGoLive} to="user"  userRole={userRole} meeting={meetingDetails} meetingId={meetingDetails.id}/>
       
        }
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedinUser: (user) => dispatch(setUserInfo(user)),
        setIdentityAction: (identity) => dispatch(setIdentity(identity)),
        setRoomIdAction: (id) => dispatch(setRoomId(id)),
        setFormIdAction: (id) => dispatch(setFormId(id)),
	};
};

const mapStoreStateToProps = (state) => {
	return {
		...state,
	};
};

export default connect(
	mapStoreStateToProps,
	mapDispatchToProps
)(AllMeetings);
