import React, { useEffect, useState } from 'react'
import logo from '../resources/images/logo.png'
import { connect } from 'react-redux'
import { setIsRoomHost } from '../store/actions'
import { useLocation } from 'react-router-dom'

import "./JoinRoomPage.css"
import JoinRoomTitleMeeting from './JoinRoomTitleMeeting'
import JoinRoomContentMeeting from './JoinRoomContentMeeting'


const JoinRoomMeeting = (props) => {

  const { setIsRoomHostActions, isRoomHost, } = props
  const [roomID, setRoomID] = useState("");
  const search = useLocation().search;
  const roomEncry = new URLSearchParams(search).get('id');
  const comingFrom = new URLSearchParams(search).get('comingFrom');
  const roomIDVal = atob(roomEncry);
  //console.log(comingFrom)
  useEffect(() => {

    setRoomID(roomIDVal);
    const isRoomHost = new URLSearchParams(search).get('host')

    if (isRoomHost) {
      // change information about it in our store
      setIsRoomHostActions(true)
    }
  }, [])

  return (
    <div className='introduction_page_container join_room_page_container py-5'>
      <div className='container-fluid px-md-5'>
        <div className='row align-items-center'>
        <div className='col-md-6 col-lg-7 col-xxl-8 mb-4 mb-lg-0'>
            <div className='text-center'>
              <h1 className='text-capitalize text-white'>You can join<br /> VirtualEmployee's Live Meetings.</h1>
            </div>
          </div>
          <div className='col-md-6 col-lg-5 col-xxl-4'>
            <div className='introduction_page_panel join_room_page_panel mx-auto bg-white rounded-5 overflow-hidden w-100'>
              <div className='introduction-body px-4 py-5'>
                <div className='d-flex flex-wrap align-content-center justify-content-center mt-3'>
                  <div className='logo-img-cover text-center'>
                    <img alt='logo' src={logo} className='introduction_page_image' />
                  </div>
                  <div className='introduction-subtitle mt-3 fw-semibold text-center'>VirtualEmployee's client meeting system</div>
                  <JoinRoomTitleMeeting isRoomHost={isRoomHost} />
                  <JoinRoomContentMeeting roomID={roomIDVal} comingFrom={comingFrom} />
                </div>
              </div>
              {/* <div className='introduction-footer fw-semibold text-center text-danger'>This meeting has ended.</div> */}
              <div className='introduction-footer fw-semibold text-center text-success'>This meeting is currently in-progress.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStoreStateToProps = (state) => {
  return {
    ...state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsRoomHostActions: (isRoomHost) => dispatch(setIsRoomHost(isRoomHost))
  }
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(JoinRoomMeeting)