import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { callAxios, convertReadable, convertReadableTime, convertSecondsToMinutes } from "../utils/utils";
import { getCountryCallingCode } from "react-phone-number-input";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addMoreParticipant } from "../utils/pstn";
import { BeforeCallModal } from "./modal/BeforeCallModal";
import { render } from "@testing-library/react";
import { environment } from "../environment";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import $ from "jquery";
import AskPop from "./modal/AskPop";


const Card = ({ item, id, user, active, setActive, setCallDetails, setDetailsLoading, pageType }) => {
  // console.log(id, item.leadid)

  const navigate = useNavigate();
  const [handleShow_, setHandleShow] = useState(false)

  const regionNames = new Intl.DisplayNames(
    ['en'], { type: 'region' }
  );

  // dialer code
  // For Bootstrap Model
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.stopPropagation();
    setShow(true)
  };

  // For Dialpad Calling
  const [isDialpad, setIsDialpad] = useState(true);
  const [isCalling, setIsCalling] = useState(false);

  const startCall = () => {
    setIsCalling(!isCalling);
    setIsDialpad(false);
  }

  const endtCall = () => {
    setIsCalling(false);
    setIsDialpad(true);
  }

  const openDialpad = () => {
    setIsCalling(false);
    setIsDialpad(true);
  }

  //
  const countryCode = item.new_countryonform ? "+" + getCountryCallingCode(item.new_countryonform) : "";
  // console.log(countryCode)



  const AMPM = parseInt(item.createdon.toString().split("T")[1].split(":")[0]) > 12 ? "PM" : "AM"
  const dateTime = item.createdon.toString().split("T")[0] + " " + item.createdon.toString().split("T")[1].split(".")[0] + " " + AMPM;






// const BeginCall = (leadid, displayName, countryCode, fullname, email, type) => {
//   // setShow(false)
// //  console.log("begin call")
//  const cid =  $("#button-call-"+leadid).attr('data-cid');

//    const phone = $("#phoneNumber").val();
//      addMoreParticipant(fullname,phone,cid,setShow,"client",countryCode,email)
     
// }

  useEffect(() => {
    if (id === 0) {
      getCallDetails(id, item.leadid, item.topic)
    }
  }, [])
  const getCallDetails = (id, leadid, topic) => {
    setActive(id);
    setDetailsLoading(true);
    callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/get-call-details-by-id", { leadId: leadid,topic:topic,userType:localStorage.getItem("userRole"), email:user?.email, pageType:pageType }, "post").then((callDetails) => {
      setCallDetails(callDetails.data.details);
      setDetailsLoading(false);
    })
  }



  return (

    <>
      <div className={`client-card rounded p-3 mt-2 pointer ${id === active ? "selected" : ""}`} key={id} onClick={() => getCallDetails(id, item.leadid,item.topic)}>
        {/* <ToastContainer /> */}
        <div className="d-flex justify-content-between gap-2 mb-2">
          <div className="left-cover">
            <span className={`badge ${item.topic.split("_").length> 3? "text-bg-warning":"text-bg-success"} rounded-1 text-uppercase mb-1`}>
            { pageType === "managerCalls"?
            item.topic.split("_").length> 3? "opportunity":"Lead"
            :null}
            </span>
            
            {item.fullname ?
            <div className="">
            <div className="client-name fs-5 text-warning text-capitalize">{item.fullname}</div>
              <div className="description small text-info">{item.topic}</div>
            </div>
              
              : null}
             
            {item.createdon ?
              <div className="fs-6 text-white text-opacity-75 text-capitalize">{convertReadable(item.createdon)} <span>{convertReadableTime(item.createdon)}</span></div>
              : null}
          </div>
          
        </div>
        {item.new_skillsetjobdescription ?
          <div className="requrement-wrapper pe-4">

            <div className="client-location fs-6 text-light mb-1">Requirement Details</div>
            <div className="description small text-light text-opacity-75 ">{item.new_skillsetjobdescription}</div>

          </div> : null}
       
        
      </div>

    
    </>




  )
}

export default Card;