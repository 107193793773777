import React, { useEffect, useState } from "react";
import {
    collection,
    addDoc,
    doc,
    setDoc,
    getDocs,
    query,
    where,
    onSnapshot,
    orderBy,
    deleteDoc,
    updateDoc,
    serverTimestamp,
    getDoc
  } from "firebase/firestore";
  import {environment} from '../../environment';
  import { useAuthState } from "react-firebase-hooks/auth";
  import { app, database, auth } from "../../firebaseConfig";
  import { useNavigate, useLocation} from "react-router-dom";
  import { ToastContainer, toast } from "react-toastify";


function DeletedUsers() {
    let navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const {state} = useLocation();
const { memberType, userRole } = state; // Read values passed on state
const [memberList, setMemberData] = useState([]);
const [blockUserID, setBlockUserID] = useState("");
const [deleteUserID, setDeleteUserID] = useState("");

// const memberType = "BDM";
// const userRole = "SA";
//console.log(memberType,userRole)
    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
         
         // get user Role data
          getUserRole();
          
        }
      }, [user, loading]);

      
      const getUserRole = async () => {
        let q;
        const queryConstraints = [];
        if(memberType == "CAM")
        {
            queryConstraints.push(where('type', '==', 'CAM'));
            queryConstraints.push(where('isDeleted', '==',1));


            q = query(collection(database,environment.REACT_APP_USERS_DB), ...queryConstraints);   
        }
        else if(memberType == "MAM"){
            queryConstraints.push(where('type', '==', 'MAM'));
            queryConstraints.push(where('isDeleted', '==',1));


            q = query(collection(database,environment.REACT_APP_USERS_DB), ...queryConstraints);   
        }
        else if(memberType === "TM"){
            queryConstraints.push(where('type', '==', 'TM'));
            queryConstraints.push(where('isDeleted', '==',1));


            q = query(collection(database,environment.REACT_APP_USERS_DB), ...queryConstraints);   
        }
        else if(memberType === "CAM MANAGER"){
            queryConstraints.push(where('type', '==', 'CAM MANAGER'));
            queryConstraints.push(where('isDeleted', '==',1));


            q = query(collection(database,environment.REACT_APP_USERS_DB), ...queryConstraints);   
        }
        else if(memberType === "CEM"){
            queryConstraints.push(where('type', '==', 'CEM'));
            queryConstraints.push(where('isDeleted', '==',1));


            q = query(collection(database,environment.REACT_APP_USERS_DB), ...queryConstraints);   
        }
        else
        {
            queryConstraints.push(where('type', '==',  'BDM'));
            queryConstraints.push(where('isDeleted', '==',1));
            q = query(collection(database,environment.REACT_APP_USERS_DB), ...queryConstraints);   

        }
        
        onSnapshot(q, (querySnapshot) => {
            let users = [];
            querySnapshot.forEach((doc) => {
            const id = doc.id;
            const data = doc.data();
            data["id"] = doc.id;
            users.push({ ...doc.data(), id: doc.id });
          });
          setMemberData(users);
        });
        
      }
      const memberListArray = memberList.map((item, index) => {
        //if(item.userType === type){
            return(
                <tr key={index}>
                <td >{item.email}</td>
                <td >{item.type}</td>
                <td >{item.deletedBy}</td>
                <td>
                    <div className='d-flex'>
                        <button className='btn btn-warning rounded-1 text-nowrap me-3' title='Block' data-bs-toggle="modal" data-bs-target="#blockModal" onClick={() => {restoreUser(item.id)}}><i className="bi bi-person-lock"></i> Restore</button>
                    </div>
                </td>
                 {/* Model popup for block user */}
            <div className="modal fade" id="blockModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="blockModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content text-center">
                        <div className="modal-body text-dark p-5">
                            <div className="mb-3">Are you sure you want to restore back this user?</div>
                            <div className="">
                                <button type="button" className="btn btn-secondary rounded-1 px-4 me-3" data-bs-dismiss="modal">No</button>
                                <button type="button" className="btn btn-primary rounded-1 px-4" data-bs-dismiss="modal" onClick={()=>{restoreDeletedUserFun()}}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            </tr>
            );
      //  }
    
       
    });

  //Restore User 
     const restoreUser = (id) =>{
        //setBlockUserID(id);
        setDeleteUserID(id)
     } 
     const restoreDeletedUserFun = async() =>{
        try {
           // console.log(deleteUserID);
            const docRef = doc(database, environment.REACT_APP_USERS_DB,deleteUserID);
            const docSnap = await getDoc(docRef);
               // console.log(docSnap.data());
            if(docSnap.exists()){
              updateDoc(docRef,{isBlocked:0,isDeleted:0, restoredBy:user?.email, deletedBy:"", deletedAt:"", userType:docSnap.data().type})
             .then(() => {
                toast.success("Member restored successfully!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
                setDeleteUserID("")
              })
             .catch(error => {
             setDeleteUserID("")
              })
            } else {
                toast.error("No record found with this memberID", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
            }
           
        
        } catch(error) {
            console.log(error)
            toast.error("Something went wrong", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
        }
       
     } 
     const handleBackToMeeting = (e) =>{
       // console.log(user,memberType,userRole)
       e.preventDefault();
       navigate('/member-list', { state: {userRole:userRole,memberType:memberType}});
     }

    return (
        <div className="member_listing_page py-4">
            {/* <ToastContainer /> */}
            <div className="container">
                <div className="main-heading text-white text-opacity-75 mb-1">Deleted {memberType} Member Listing</div>
                <div className='small'>These are the methods you use to restore member in your list.</div>

                <div class="table-wrapper px-3 px-md-4 py-4 mt-4">
                <div className='text-end mb-3'>
            
                <button type="button" className="btn btn-primary rounded-1 ms-2" onClick={handleBackToMeeting}>
                    <i className="bi bi-person-plus"></i> Back to Member Listing
                </button>
            </div>

                    <div className="table-responsive">
                    <table className="table table-bordered border-secondary">
                        <thead>
                            <tr>
                                <th scope="col">Email</th>
                                <th scope="col">User Type</th>
                                <th scope="col">Deleted By</th>
                                <th scope="col" style={{ width: '35%' }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { memberList.length >0 ? memberListArray:
                                <tr><td colSpan={4}>No deleted users found!</td></tr>
                            }
                            
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    );
}

export default DeletedUsers;