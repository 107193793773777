import React, { useEffect, useState, useMemo } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import $ from "jquery";
import { BeforeCallModal } from "./BeforeCallModal";
import { render } from "@testing-library/react";
import { addMoreParticipant, log_, makeOutgoingCallTOVEFirst, sendDTMF } from "../../utils/pstn";
import { callAxios } from "../../utils/utils";
import { environment } from "../../environment";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseConfig";
import Timer from "./Timer";
import { AfterCallFeedback } from "./AfterCallFeedback";
import useAskPopUpHandler from "../../Context/useAskPopUpHandler";
// import useTranscriptHandler from '../../Context/useTranscriptionHandler';

const AskPop = ({ showModal, leadid, displayName, countryCode, fullname, email, type, leadDesc, formattedDate, topic, leadOpportunityId, listType, setisAskPopUpShow, setShowCrmUpdate, setTranscriptUrl,setCurrentCallSic, setIsClientWasOnCall,isClientWasOnCall }) => {

  let fullTranscript = [];
  const [transcriptions, setTranscriptions] = useState([]);
  const [showTranscript, setshowTranscript] = useState(false);
  //console.log(showModal)
  const [user, loading, error] = useAuthState(auth);
  const [parentSid, setParentSid] = useState('');
  const [dialingby, setDialingBy] = useState('')
  const [callConnected, setCallConnected] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [CALLSTATUS, setCALLSTATUS] = useState("");
  const [socketConnection, setSocketConnection] = useState(null);

  const [addMoreIcon, setAddMoreIcon] = useState(false)

  const [isMuted, setIsMuted] = useState(false)

  const [feedbackForm, setFeedbackForm] = useState(false)

  const [callDetails, setCallDetails] = useState({})

  const [showBeforeCallModal, setBeforeCallModal] = useState(false)

  const [inProgressConference, setInProgressConference] = useState(false)
  const [inprogressParticipants, setInprogressParticipants] = useState([])
  const [CompletedParticipants, setCompletedParticipants] = useState([])

  


  const inprogressCandidate = [];

  const [updates, setUpdates] = useState([]);
  const [CID, setCid] = useState("");
  const [isEditMessage, setIsEditMessage] = useState(true);
  const closeEditMessage = () => setIsEditMessage(false);
  const [handleShow_, setHandleShow] = useState(false)
  // For Discard model
  const [modalShow, setModalShow] = useState(showModal);
  //   const handleClose = () => {
  //       setModalShow(false)
  //       setIsEditMessage(false)
  //   };

  // dialer code
  // For Bootstrap Model
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setisAskPopUpShow(false);
  }

  const handleShow = () => {

    setShow(true)
    setModalShow(false)

  };

  // For Dialpad Calling
  const [isDialpad, setIsDialpad] = useState(true);
  const [isCalling, setIsCalling] = useState(false);

  const [isCallingText, setIsCallingText] = useState("Calling....");

  const [count, setCount] = useState(0);

  // for userConnected modal
  const [showUserModal, setConnectedUser] = useState(false);

  useAskPopUpHandler(user,setUpdates,setCid,setshowTranscript,setCallConnected, setInprogressParticipants,setConnectedUser,setIsCalling, setIsDialpad,setFeedbackForm, setCallDetails,setShow, setisAskPopUpShow, leadOpportunityId,displayName,leadid, transcriptions, setTranscriptions,setCount,setInProgressConference,setParentSid,setShowCrmUpdate,setTranscriptUrl,setCurrentCallSic, setIsClientWasOnCall,listType,topic,setDialingBy, dialingby);

  const incrementCount = () => {
    setCount(prevCount => ++prevCount); // Preincrementing count
  };


  const startCall = () => {
    setIsCalling(!isCalling);
    setIsDialpad(false);
  }

  const endtCall = async() => {
    localStorage.setItem("conferenceObject", [])
    // setShowCrmUpdate(true)
    
    if(isClientWasOnCall){
      setShowCrmUpdate(true)
    } else{
      setCurrentCallSic("")
    }
    const cid = $("#button-call-" + leadOpportunityId).attr('data-cid')
    const getCallDetails = await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/get-data-by-cid", { action: true, cid: parentSid }, "post")
    callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/update-conference-status", { conferenceName: cid, type: "inbound" }, "post")
    if (getCallDetails.data.callDetails.is_client_joined === 1) {
      // console.log("client joined:::",getCallDetails.data.callDetails.is_client_joined)
      setFeedbackForm(true)
      setCallDetails(getCallDetails.data.callDetails)

    }
    // callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/upload-incoming-call-transcript",{callSid:cid,fullTranscript:transcriptions,type:"outboundcall"},"post").then((uploadRes)=>{
    //   if(uploadRes.data.status === 200){
    //     setTranscriptions([])
    //   }
      
    // })
    setisAskPopUpShow(false)
    setShow(false);

  }

  const openDialpad = () => {
    setIsDialpad(true);
    $("#in-progressPop").hide()
    $("#dialpad-box").show()
  }

  const handleBackToMainScreen = () => {
    $("#dialpad-box").hide()
    $("#in-progressPop").show()
  }
  //

  const setHandler = (leadid, displayName, countryCode, fullname, email, type, leadOpportunityId, listType) => {
    //  setModalShow(false)
    if (type === "fromDialer") {
      const phone = $("#phoneNumber").val();
      const cid = $("#button-call-" + leadOpportunityId).attr('data-cid')



    } else {
      // console.log("leadOpportunityId",leadOpportunityId)

      const cid = $("#button-call-" + leadOpportunityId).attr('data-cid')
      // render(<BeforeCallModal listType={listType} leadOpportunityId={leadOpportunityId} leadid={leadid} displayName={displayName} countryCode={countryCode} fullname={fullname} userEmail={email} handleShow_={true} type={type} setModalShow={setModalShow} setShow1={setShow} cid={cid} setInProgressConference={setInProgressConference} setIsDialpad={setIsDialpad} leadDesc={leadDesc} formattedDate={formattedDate} topic={topic} />)

      setBeforeCallModal(true)
    }

  }

  const dialNumber = (val) => {
    // console.log(val)
    var currentValue = $('#phoneNumber').val();

    // Append the desired value (e.g., ' Appended') to it
    var appendedValue = currentValue + val;
    $("#phoneNumber").val(appendedValue)
    sendDTMF(val.toString());
  }

  const removeNumber = () => {
    var currentValue = $('#phoneNumber').val();

    // Remove the last character from the string
    var newValue = currentValue.slice(0, -1);

    // Set the updated value back to the input field
    $('#phoneNumber').val(newValue);
  }
  const BeginCall = (leadid, displayName, countryCode, fullname, email, type, leadOpportunityId, listType) => {
    setModalShow(false)
    //  setShow(false)
    // console.log("begin call")
    const cid = $("#button-call-" + leadOpportunityId).attr('data-cid');

    if (cid.length > 0) {
      setCallConnected(true);
      $("#in-progressPop").show()
      const whoDialed = $("#button-call-" + leadOpportunityId).attr("data-whoDialed");
      if (whoDialed === "client") {
        const phone = $("#phoneNumber").val();
        addMoreParticipant(fullname, phone, cid, setShow, whoDialed, countryCode, email, leadOpportunityId, listType,setDialingBy)
      } else {
        addMoreParticipant(fullname, leadid, cid, setShow, whoDialed, countryCode, email, leadOpportunityId, listType,setDialingBy)
      }
      // add participant to conference

    } else {
      // making call first to ve member
      const phone = $("#phoneNumber").val();
      makeOutgoingCallTOVEFirst(leadid, phone, displayName, email, fullname, leadDesc, formattedDate, topic, leadOpportunityId, listType)
    }


  }


  const updateData = newData => {
    setInprogressParticipants(newData);
    localStorage.setItem('conferenceObject', JSON.stringify(newData));
  };
  useEffect(() => {
    const storedData = localStorage.getItem('conferenceObject');
    if (storedData) {
      setInprogressParticipants(JSON.parse(storedData));
    }
  }, [count]);

  useEffect(() => {
    const storedData = localStorage.getItem('completedConferenceParticipants');
    if (storedData) {
      setCompletedParticipants(JSON.parse(storedData));
    }
  }, [count]);

  
  useEffect(() => {
 
    if (updates.CallStatus === "completed" && updates.Direction === "inbound") {
     
      // console.log("in cam disconnectd call")
      localStorage.setItem("conferenceObject", [])
      localStorage.setItem("completedConferenceParticipants", [])
      setShowCrmUpdate(true)
      callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/update-conference-status", { conferenceName: CID, type: "inbound" }, "post")
      
      // callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/upload-incoming-call-transcript",{callSid:CID,fullTranscript:transcriptions,type:"outboundcall"},"post").then((uploadRes)=>{
      //   if(uploadRes.data.status === 200){
      //     setTranscriptions([])
      //   }
        
      // })
      // $("#dial-pad-"+leadid).addClass("d-none")
    }
  }, [])


  const removeByCallSid = (callSidToRemove, storageValue) => {
    if (storageValue) {
      let oldData = JSON.parse(storageValue);

      // Find index of element with matching CallSid
      const indexToRemove = oldData.findIndex(item => item.CallSid === callSidToRemove);

      if (indexToRemove !== -1) {
        // Remove element from array if found
        oldData.splice(indexToRemove, 1);
        localStorage.setItem("conferenceObject", JSON.stringify(oldData));
      }
    }
  }
  const handleRejoin = (item, leadid, displayName, countryCode, fullname, email, type, leadOpportunityId, listType) => {

    setModalShow(false)
    const callSidToRemove = item.CallSid; // Specify the CallSid you want to remove
    const storageValue = localStorage.getItem("conferenceObject");
    removeByCallSid(callSidToRemove, storageValue);
    // console.log("begin call", item)
    const cid = $("#button-call-" + leadOpportunityId).attr('data-cid');

    if (item.type === "client") {
      // setHandler(leadid, displayName, countryCode, fullname, email,"client")
      addMoreParticipant(fullname, leadid, item.parentSid, setShow, "VEMember", countryCode, email, leadOpportunityId, listType,setDialingBy)
    } else {
      if (cid.length > 0) {
        const whoDialed = $("#button-call-" + leadOpportunityId).attr("data-whoDialed");
        if (whoDialed === "client") {
          const phone = item.to;
          addMoreParticipant(fullname, phone, cid, setShow, whoDialed, countryCode, email, leadOpportunityId, listType, setDialingBy)
        }
        // add participant to conference
      }
    }
  }

  let inProgress;
  if (inprogressParticipants && inprogressParticipants.length > 0) {
    inProgress = inprogressParticipants.map((item, index) => {
      if (item.status === "completed") {

        return (
          <div className="user-info d-flex align-items-center gap-2">
            <div className="img-wrapper bg-primary bg-opacity-25 border border-1 border-primary rounded-circle overflow-hidden d-flex align-items-center justify-content-center" style={{ width: '42px', height: '42px' }}>
              <img src={require('../../resources/images/Avatar.png')} className='img-fluid' alt="User" />
            </div>
            <div className="user-info text-start">
              <div className="user-name small fw-light text-light text-truncate">{item.name}</div>
              <div className="contact-number fs-12 text-muted text-truncate">{item.ToCountry}</div>
              <span className="badge rounded-pill text-bg-primary pointer pointer" onClick={() => { handleRejoin(item, leadid, displayName, countryCode, fullname, email, type, leadOpportunityId, listType) }}>Rejoin Call</span>
            </div>
          </div>
        )
      }

      else if (item.status === "no-answer") {

        return (
          <div className="user-info d-flex align-items-center gap-2">
            <div className="img-wrapper bg-primary bg-opacity-25 border border-1 border-primary rounded-circle overflow-hidden d-flex align-items-center justify-content-center" style={{ width: '42px', height: '42px' }}>
              <img src={require('../../resources/images/Avatar.png')} className='img-fluid' alt="User" />
            </div>
            <div className="user-info text-start">
              <div className="user-name small fw-light text-light text-truncate">{item.name}</div>
              <div className="contact-number fs-12 text-muted text-truncate">{item.ToCountry}</div>
              <span className="badge rounded-pill text-bg-info pointer" onClick={() => { handleRejoin(item, leadid, displayName, countryCode, fullname, email, type, leadOpportunityId, listType) }}>{"No Answer, "} Dial Again</span>
            </div>
          </div>
        )
      }
      else if (item.status === "busy") {

        return (
          <div className="user-info d-flex align-items-center gap-2">
            <div className="img-wrapper bg-primary bg-opacity-25 border border-1 border-primary rounded-circle overflow-hidden d-flex align-items-center justify-content-center" style={{ width: '42px', height: '42px' }}>
              <img src={require('../../resources/images/Avatar.png')} className='img-fluid' alt="User" />
            </div>
            <div className="user-info text-start">
              <div className="user-name small fw-light text-light text-truncate">{item.name}</div>
              <div className="contact-number fs-12 text-muted text-truncate">{item.ToCountry}</div>
              <span className="badge rounded-pill text-bg-danger pointer pointer" onClick={() => { handleRejoin(item, leadid, displayName, countryCode, fullname, email, type, leadOpportunityId, listType) }}>{"Busy, "}Dial Again ?</span>
            </div>
          </div>
        )
      }

      else {
        return (
          <div className="user-info d-flex align-items-center gap-2">
            <div className="img-wrapper bg-primary bg-opacity-25 border border-1 border-primary rounded-circle overflow-hidden d-flex align-items-center justify-content-center" style={{ width: '42px', height: '42px' }}>
              <img src={require('../../resources/images/Avatar.png')} className='img-fluid' alt="User" />
            </div>
            <div className="user-info text-start">
              <div className="user-name small fw-light text-light text-truncate">{item.name} [
                {item.status}
                ]</div>
              <div className="contact-number fs-12 text-muted text-truncate">{item.To}</div>
            </div>
          </div>
        )
      }
    })
  }

  const handleMute = async (CID, isMuted) => {
    setIsMuted(isMuted)
    const cid = $("#button-call-" + leadOpportunityId).attr('data-cid')
    callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/mute-status", { callSid: cid, isMuted: isMuted }, "post")
  }

  const cancelhandler = () => {
    setisAskPopUpShow(false);
    setModalShow(false);
  }
  // console.log("FeedbackForm:::",feedbackForm)

  
  return (
    <>
      {/* {useMemo(()=>( */}
      {/* <Timer  /> */}
      {/* ),[inProgressConference])} */}
      <Modal show={modalShow} onHide={() => setModalShow(true)} size="md" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="shadow p-2" dialogClassName="p-4">
        <Modal.Body className="p-4">
          <div className='text-dark fs-18 fw-semibold'>Do you want to add a VE Member to this call?</div>
          <div className="add-participants btn-wrapper d-flex gap-2 justify-content-end mt-3">
            <Button variant='success' size='sm' onClick={handleShow} className='shadow '>Yes</Button>
            <Button variant='info' size='sm' className='shadow' onClick={() => { setHandler(leadid, displayName, countryCode, fullname, email, type, leadOpportunityId, listType) }}>No</Button>
            <Button variant='danger' size='sm' onClick={() => cancelhandler()} className='shadow cancel-leave-conversation'>Cancel</Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Dialpad Model Code */}
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static" keyboard={false} centered contentClassName='bg-dark border border-3 border-primary'>
        <Button variant='dark' className="d-flex align-items-center justify-content-center rounded-circle position-absolute right-0" style={{ height: '40px', width: '40px', top: '-20px', right: '-20px', zIndex: 11 }}>
          <i className="bi bi-x fs-2" onClick={handleClose}></i>
        </Button>
        <Modal.Body className="bg-black text-center rounded p-4">
          {showUserModal ?
            <div className="user-connected dark-theme-bg text-center px-3 rounded-3 py-5" id="connected-user-area">
              <div className="d-flex align-items-center justify-content-center gap-4 mb-4">
                <div className="user-connected-image">
                  <img src={require('../../resources/images/Avatar.png')} alt="user-image" className="img-fluid border border-1 border-info rounded-circle" style={{ width: "80px", height: "80px" }} />
                </div>
                <div className="connected-wrapper d-flex align-items-center justify-content-center gap-2">
                  <span className="text-success">-------</span>
                  <div className="connected-icon d-flex align-items-center justify-content-center border border-1 border-success rounded-circle" style={{ height: "45px", width: "45px" }}>
                    <i className="bi bi-arrow-left-right text-success"></i>
                  </div>
                  <span className="text-success">-------</span>
                </div>

                <div className="user-connected-image">
                  <img src={require('../../resources/images/Avatar.png')} alt="user-image" className="img-fluid border border-1 border-warning rounded-circle" style={{ width: "80px", height: "80px" }} />
                </div>

              </div>
              <div className="info-text-client fs-6 text-light mb-4" style={{ textAlign: "left" }}>
                Now, the added VE member is on this call with you. If both of you are ready, you can proceed to call the client.
              </div>
              <div className="btn-wrapper">
                <button type="button" className="btn btn-success py-2 px-4" onClick={() => { setHandler(leadid, displayName, countryCode, fullname, email, "client", leadOpportunityId, listType) }}>Connect to Client</button>
              </div>
            </div>
            : null}
            <div className="custom-conference-wrapper d-flex flex-column flex-lg-row justify-content-center" style={{backgroundColor: "#1f1f1d"}}>
            <div className="dialpad-wrapper">


            {/* Conference Call Screen Code */}
            {inProgressConference ?
            <>
             <div className="confrence-call-screen p-3" id="in-progressPop">
             <div className="text-primary fs-4 fw-medium lh-sm">Conference Call</div>
                <div className="wrapper px-2 py-2 mb-2">
                {dialingby?
                <span className="text-warning" id="dialing-CEM">{dialingby}</span>
                :null}

                  <div className="total-user mb-3">Total Participants ({inprogressParticipants.length + 1})
                    - <p className="timer text-warning">
                      {callConnected ?
                        <Timer />
                        : null}
                    </p>
                  </div>
                  <div className="d-flex gap-3 position-relative">
                    <div className="left-sec d-flex flex-column align-items-center gap-4">
                      <div className="top-arrow lh-1"><i className="bi bi-chevron-up fs-18 text-secondary"></i></div>
                      <div className="middle-arrow h-100"><span className='d-block rounded-pill bg-secondary' style={{ width: '3px', height: '100%' }}></span></div>
                      <div className="bottom-arrow lh-1"><i className="bi bi-chevron-down fs-18 text-white"></i></div>
                    </div>
                    <div className="right-sec w-100">
                      <div className="user-wrapper d-flex flex-column gap-2 overflow-auto" style={{ maxHeight: '200px' }}>
                        {inProgress}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="action-wrapper d-flex justify-content-center gap-4 border-top border-1 border-secondary border-opacity-25 p-3">
                  {isMuted ?
                    <Button variant='primary' className="rounded-circle" onClick={() => { handleMute(CID, false) }}>
                      <i className="bi bi-mic-mute-fill fs-5"></i>
                    </Button>
                    :
                    <Button variant='secondary' className="rounded-circle" onClick={() => { handleMute(CID, true) }}>
                      <i className="bi bi-mic-mute-fill fs-5"></i>
                    </Button>
                  }
                  <Button variant='danger' className="rounded-circle" onClick={() => { endtCall(CID) }}>
                    <i className="bi bi-telephone-fill d-inline-block fs-5" style={{ transform: 'rotate(135deg)' }} ></i>
                  </Button>
                  <Button variant='success' className="rounded-circle" onClick={openDialpad}>
                    <i className="bi bi-grid-3x3-gap-fill fs-5"></i>
                  </Button>
                </div>
                <div className="fs-12 text-start">When you are done with the conference call, Sheela will share her complete update with you.</div>
                </div>
              
             
            </>
             
              : null}


            {isDialpad && (
              <div className="dialpad-screen" id="dialpad-box">
                <div className="top-section">
                  <input type="text" maxLength={15} placeholder='+91' className='form-control bg-transparent border-0 shadow-none text-light p-4' id="phoneNumber" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" autoComplete='off' required />
                </div>
                <div className="bottom-section p-4">
                  <div className="row g-2">
                    <div className="col-4">
                      <div className="num pointer" onClick={() => { dialNumber(1) }}>1</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={() => { dialNumber(2) }}>2</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={() => { dialNumber(3) }}>3</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={() => { dialNumber(4) }}>4</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={() => { dialNumber(5) }}>5</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={() => { dialNumber(6) }}>6</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={() => { dialNumber(7) }}>7</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={() => { dialNumber(8) }}>8</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={() => { dialNumber(9) }}>9</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={() => { dialNumber("*") }}><i className="bi bi-asterisk fs-6"></i></div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={() => { dialNumber(0) }}>0</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={() => { dialNumber("+") }}>+</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={() => { dialNumber("#") }}>#</div>
                    </div>
                  </div>
                  <div className="action-wrapper row g-2 mt-2">
                    {inProgressConference ?
                      <div className="col-4 text-center">
                        <Button variant='success' className="rounded-circle pointer" onClick={handleBackToMainScreen} title="Back ongoing call">
                          <i className="bi bi-arrow-up-left-square-fill"></i>
                        </Button>
                      </div>
                      : null}

                    <div className="col-4 text-center">
                      <Button variant='danger' className="rounded-circle lh-1 pointer" onClick={removeNumber}>
                        <img src={require('../../resources/images/back-btn.png')} alt="" className='img-fluid' style={{ filter: 'invert(1)' }} />
                      </Button>
                    </div>
                    <div className="col-4 text-center">
                      <Button variant='success' className="rounded-circle pointer" onClick={() => { BeginCall(leadid, displayName, countryCode, fullname, email, "fromDialer", leadOpportunityId, listType) }}>
                        <i className="bi bi-telephone-fill fs-5"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {showTranscript?
          <div className="transcript-section-wrapper overflow-auto p-3">
          <div className="d-flex justify-content-between gap-3 mb-3">
            <div className="left-box fs-12 text-start flex-fill">{user?.displayName}, I'm Sheela, and I'm also on this call with you. My role is to understand each conversation and prepare a comprehensive CRM update for you. This will save you valuable time. Once the call is done, I'll share my updates with you.</div>
            <div className="right-box w-100" style={{maxWidth:'70px',}}>
              <img src={require ('../../resources/images/sheela-conference.jpg')} alt="" className="img-fluid object-fit-cover w-100 h-100" />
            </div>
          </div>
          <div className="chat-transcript-wrapper w-100 overflow-auto" style={{ maxHeight: '300px', backgroundColor: "#1f1f1d" }} id='messages-outgoing-tt'>
            
          </div>
        </div>
         
         :null} 

            </div>

          
        </Modal.Body>
      </Modal>
      {/* {feedbackForm ?
        <AfterCallFeedback showFeedback={true} callInfo={callDetails} setFeedbackForm={setFeedbackForm} setisAskPopUpShow={setisAskPopUpShow} />
        : null} */}

      {showBeforeCallModal ?
        <BeforeCallModal listType={listType} leadOpportunityId={leadOpportunityId} leadid={leadid} displayName={displayName} countryCode={countryCode} fullname={fullname} userEmail={email} handleShow_={true} type={type} setModalShow={setModalShow} setShow1={setShow} cid={CID} setInProgressConference={setInProgressConference} setIsDialpad={setIsDialpad} leadDesc={leadDesc} formattedDate={formattedDate} topic={topic} setBeforeCallModal={setBeforeCallModal} setDialingBy={setDialingBy} />
        : null}

    </>
  )
}

export default AskPop;