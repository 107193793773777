import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { database } from "../../firebaseConfig";
import { environment } from "../../environment";

const LeaveRoomButton = ({
  room,
  comingFrom,
  handleRoomDisconnection,
  participants,
  handleRoomDisconnectionWithoutForm,
  isClientOnCall,
  roomId
}) => {
  
  //console.log(clientOnCall);
  return (
    <div className="video_button_container">
      
       

          <button
            className="d-flex fw-semibold btn btn-danger rounded-1 video_button_end leave-btn py-1 px-3"
            onClick={handleRoomDisconnectionWithoutForm}
          >
            <span>
              <i className="bi bi-telephone-fill"></i>
            </span>
            <span className="ms-2 ps-1 d-none d-lg-block">Leave</span>
          </button>
        
    </div>
  );
};

export default LeaveRoomButton;
