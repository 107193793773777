import React, { useEffect, useState } from "react";
import { database, auth } from "../firebaseConfig";
import {
  collection,
  doc,
  query,
  where,
  onSnapshot,
  orderBy,
  updateDoc,
  limit,
  getDoc,
} from "firebase/firestore";
import notificationSound from "../static/Notification.mp3";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
// import axios from "axios";
// import * as qs from "qs";
//const qs = require('qs');
import {
  setIdentity,
  setRoomId,
  setFormId,
  setUserInfo,
  setUserRole
} from "../store/actions";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./LeadsListingPage.css";
import "../Header/Header";
//import { db } from '../../../firebase/firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import {
  convertMilisecondsToTime,
  getIndex
} from "../utils/utils";
import CircularProgressBar from "../CircularProgressBar/CircularProgressBar";
// env import
import { environment } from "../environment";
import { BdmCamList } from "./BdmCamList";
import { JoinNowPopup } from "./JoinNowPopup";
import { WelcomeScreenPopup } from "./WelcomeScreenPopup";
import AfterCallCard from "./AfterCallCard";
import { RefreshApp } from "./RefreshApp";
import notificationSoundS from "../static/reloadRing.mp3";
import NotificationSoundForOther from "../static/NotificationSoundForOther.mp3";
import { useMemo } from "react";
// import { OAuthProvider, SAMLAuthProvider, getRedirectResult, onAuthStateChanged, reauthenticateWithPopup } from "firebase/auth";

const LeadsListingPage = (props) => {
  const [audioT, setAudioT] = useState(new Audio(notificationSoundS));
  const [otherSound, setOtherAudio] = useState(new Audio(NotificationSoundForOther));
  localStorage.getItem("appVersion") ? localStorage.getItem("appVersion") : localStorage.setItem("appVersion", "");
  //window.onbeforeunload = null;
  const [currentAppVersion, setAppVersion] = useState("");
  //console.log("current version:", currentAppVersion);

  // useEffect(()=>{
  //   document.getElementById("welcome-screen-pop-up").classList.add("show");
  //   document.getElementById("welcome-screen-pop-up").style.display = "block";

  // },[])
  const userRoleStore = useSelector((state) => state.userRole);
  const loggedInUserRole = userRoleStore ? userRoleStore : localStorage.getItem("userRole");

  // console.log(loggedInUserRole)
  const currentVersion = async () => {
    //console.log(userInfo)
    const myDocRef = doc(database, environment.REACT_APP_VERSION_DB, "zhwbBpi4c6AQFYJmai2Q");
    const myDoc = await getDoc(myDocRef);
    if (myDoc.exists()) {
      setAppVersion(myDoc?.data()?.version);
      if (myDoc.data().version !== localStorage.getItem("appVersion")) {
        const refreshApp = document.getElementById("refreshApp");
        const availableVersion = document.getElementById("available-version");
        localStorage.setItem("camJoinedLead", null);
        localStorage.setItem("bdmJoinedLead", null);
        if (refreshApp) {
          refreshApp.classList.add("show");
          refreshApp.style.display = "block";
        }
        if (availableVersion) {
//           availableVersion.innerHTML = `We are excited to announce that Version ${myDoc.data().version} of the NM App is now available. To enjoy the latest features and improvements, simply click on the update button and get the newest version.</div>

   
// </div></div>`;
availableVersion.innerHTML = `<p>We’re excited to announce that <span class="text-warning">Version ${myDoc.data().version} of the NM App is now available!</span> Click the update button to enjoy the latest features and improvements.</p>`
        }
      }
    }

    if (loggedInUserRole === "CAM" || loggedInUserRole === "BDM") {
      const welcomeScreenPopupElement = document.getElementById('welcome-screen-pop-up');
      if (localStorage.getItem("checkAudio") == null || localStorage.getItem("checkAudio") == 'null') {
        if (welcomeScreenPopupElement) {
          welcomeScreenPopupElement.classList.add("show");
          welcomeScreenPopupElement.style.display = "block";
        }
      }
      const dateOld = new Date(localStorage.getItem("checkAudio")).toLocaleDateString();
      if (dateOld !== new Date().toLocaleDateString()) {
        if (welcomeScreenPopupElement) {
          welcomeScreenPopupElement.classList.add("show");
          welcomeScreenPopupElement.style.display = "block";
        }
      }
    }
  }

  currentVersion();
  //console.log("current version:", currentAppVersion);
  const {
    setRoomIdAction,
    setIdentityAction,
    roomId,
    setFormIdAction,
    setLoggedinUser,
    setUserRoleAction
  } = props;
  const [user, loading, error] = useAuthState(auth);
  let navigate = useNavigate();
  const listItemCss = {
    padding: "0 15px",
    margin: "5px 0",
    borderRight: "2px solid #ccc",
    boxSizing: "border-box",
    backgroundColor: "red",
  };

  const [allData, setAllDate] = useState([]);
  const [allClientMeetings, setDownloadData] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [isRelevancyloading, setRelevancyloading] = useState(true);
  const [connectedLeadList, setTotalConnectedLeads] = useState([]);
  const [onlineCam, setMissedCallUsers] = useState([]);
  const [isOngoing, setisOngoing] = useState(false);
  const [audio, setAudio] = useState(new Audio(notificationSound));
  const [isNotify, setNotify] = useState(false);
  const [isGoLive, setGoLive] = useState(false);
  const [relavancyList, setRelavancyList] = useState([]);
  const [active, setActive] = useState(null);
  const [userRole, setUserRole_] = useState('');
  const [roomparticipants, setRoomParticipants] = useState([]);
  const dbInstance = collection(database, environment.REACT_APP_FIREBASE_DB);
  const addPlayerStremerWithID = collection(database, "playerstreamer");
  const end = new Date();
  const start = new Date(end.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago

  var startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);

  var endOfToday = new Date();
  endOfToday.setHours(23, 59, 59, 999);

  //   var today = new Date();
  // var fromDate = new Date(today);
  // fromDate.setDate(today.getDate() - 2);

  const queryConstraints = [];
  queryConstraints.push(where("timeStamp", ">=", startOfToday));
  queryConstraints.push(where("timeStamp", "<=", endOfToday));
  queryConstraints.push(orderBy("timeStamp", "desc"));
  const q = query(
    collection(database, environment.REACT_APP_FIREBASE_DB),
    ...queryConstraints
  );

  // const createCollection = async() =>{
  //   try {
  //     const docRef = await addDoc(collection(database, "playerstreamer"), {
  //       version: "2.5.3",    
  //     });
  //     console.log("Document written with ID: ", docRef.id);
  //   } catch (e) {
  //     console.error("Error adding document: ", e);
  //   }
  // }

  const getUserRole = async (email) => {
    //console.log("getting role")
    const q = query(
      collection(database, environment.REACT_APP_USERS_DB),
      where("email", "==", email),
      orderBy("timeStamp", "asc"),
      limit(1)
    );
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        data["id"] = doc.id;
        //setUserRole(data);
        setUserRoleAction(data?.userType);
        setUserRole_(data?.userType);
        localStorage.setItem("userRole", data?.userType);
        //console.log(data)
      });
    });
  };

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
      
      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };
      getUserRole(user?.email);
      // saveUserLogged(userInfo);
      setLoggedinUser(userInfo);
    }
    // refreshToken();
  }, [user, loading]);

  //  console.log("Live lists:", allData);

  //notRelevant, missedBy, relevant
  const diff_minutes = (currentTime, formTime) => {
    const dt2 = new Date(currentTime);
    const dt1 = new Date(formTime);
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  };

  const updateIsNewField = async (formID) => {
    const formRef = doc(database, environment.REACT_APP_FIREBASE_DB, formID);
    await updateDoc(formRef, {
      isnew: false,
    });
  };
  let vlisting;
  if (allData.length > 0) {
    vlisting = allData.map((item, index) => {
      const missedBy = [];
      const roomParticipant = [];
      const Index = getIndex(onlineCam, item.roomId);
      if (item?.missedBy) {
        for (let x = 0; x < item?.missedBy?.length; x++) {
          if (item.attendantBdmName !== item.missedBy[x].name) {
            missedBy.push(item.missedBy[x]);
          }
          // if(item.relevant && item.relevant.some(item => item.email !== missedBy[x].email)){
          //   missedBy.push(item.missedBy[x]);
          // }
        }
      }
      let relevantNotrelevant = [];
      let filterCriteria;
      let missedByUpdated = [];
      if (item.relevant && item.notRelevant) {
        relevantNotrelevant = item.relevant.concat(item.notRelevant);

      } else if (item.relevant) {
        relevantNotrelevant = item.relevant;
      } else if (item.notRelevant) {
        relevantNotrelevant = item.notRelevant;
      } else {
        relevantNotrelevant = [];
      }

      if (relevantNotrelevant && relevantNotrelevant.length > 0) {
        filterCriteria = relevantNotrelevant.map((itemElement) => {
          return itemElement.email;
        });
        missedByUpdated = missedBy.filter(item => !filterCriteria.includes(item.email));

      } else {
        missedByUpdated = missedBy
      }
      // console.log(missedByUpdated)
      if (item?.participants) {
        item?.participants.map((participant) => {
          if (
            (participant.identity.length > 10 &&
              participant.identity.split("_")[2] !== "") ||
            participant.identity.split("_")[1] === "CL"
          ) {
            roomParticipant.push(participant);
          }
        });
      }

      //console.log(roomParticipant)
      const relavancyDataIndex = getIndex(relavancyList, item.roomId);
      let total = 0;
      total += item.name !== "" ? 1 : 0;
      total += item.email !== "" ? 1 : 0;
      total += item.phone !== "" ? 1 : 0;
      total += item.companyweb !== "" ? 1 : 0;
      total += item.projduration !== "" ? 1 : 0;
      total += item.description !== "" ? 1 : 0;

      const percentage = parseFloat((total * 100) / 6).toFixed(0);

      const fireBaseTime = new Date(
        item.timeStamp.seconds * 1000 + item.timeStamp.nanoseconds / 1000000
      );
      const dateNew = fireBaseTime.toDateString();
      const atTime = fireBaseTime.toLocaleTimeString();
      let timeStampfor = dateNew + " " + atTime;
      const formFillingTime = diff_minutes(
        new Date().getTime(),
        fireBaseTime.getTime()
      );
      // console.log(item.isnew);
      if (item.isnew) {
        //console.log("form isnew false found!", formFillingTime);
        if (formFillingTime > 5) {
          updateIsNewField(item.id);
        }
      }
      const ppcPramUrl = item.ppcurl.split("?")[1];
      const urlParams = new URLSearchParams(ppcPramUrl);

      if (item.RoomStatus === "completed" || item.camLeftAt) {
        return (
          <>
            <AfterCallCard
              index={index}
              item={item}
              roomParticipant={roomParticipant}
              relavancyList={relavancyList}
              relavancyDataIndex={relavancyDataIndex}
              missedBy={missedByUpdated}
              timeStampfor={timeStampfor}
              percentage={percentage}
              urlParams={urlParams}
              onlineBDM={onlineCam}
              Index={Index}
              isRelevancyloading={isRelevancyloading}
            />
          </>
        );
      } else {
        return (
          <div className="info-card rounded-2 mt-3" key={"lead-listing-key-" + index}>
            {/*urlParams.get("source") ? (
              <div className="lead-source bg-secondary fs-6 d-flex justify-content-between gap-3 text-nowrap overflow-auto text-light rounded-top p-3">
                {urlParams.get("device") ? (
                  <div className="text-capitalize">
                    Lead From:{" "}
                    <span className="fw-normal">
                      {urlParams.get("device") === "c" ? "Desktop" : "Mobile"}
                    </span>
                  </div>
                ) : null}

                {urlParams.get("campaign") ? (
                  <div className="text-capitalize">
                    Lead Campaign:{" "}
                    <span className="fw-normal">
                      {urlParams.get("campaign").replace(/_/g, " ")}
                    </span>
                  </div>
                ) : null}

                {urlParams.get("adgroup") ? (
                  <div className="text-capitalize">
                    Lead Ad Group:{" "}
                    <span className="fw-normal">
                      {urlParams.get("adgroup").replace(/_/g, " ")}
                    </span>
                  </div>
                ) : null}
              </div>
                ) : ( */}
            {urlParams.get("adgroup") ?
              <div className="lead-source bg-secondary fs-6 d-flex justify-content-between gap-3 text-nowrap overflow-auto text-light rounded-top p-3">
                <div className="text-capitalize">
                  Expected Need: <span className="fw-normal"> {urlParams.get("adgroup").replace(/_/g, " ")}</span>
                </div>
              </div>
              :
              <div className="lead-source bg-secondary fs-6 d-flex justify-content-between gap-3 text-nowrap overflow-auto text-light rounded-top p-3">
                <div className="text-capitalize">
                  Lead Source: <span className="fw-normal"> Organic</span>
                </div>
              </div>
            }
            {/*} )} */}
            <div className="info-card-details overflow-auto p-3">
              <div className="row gx-3">
                <div className="col-lg">
                  <div className="client-name text-capitalize">{item.name}</div>
                  <div className="country-name text-capitalize text-warning">
                    {item.country}
                  </div>
                  {/* Progressbar Code */}
                  <div className="client-progress-status mt-1">
                    <div className="progressbar-label mb-1">
                      Client's Form Filling Status
                    </div>
                    <CircularProgressBar percentage={percentage} />
                  </div>
                </div>
                {/* <div className="list-item">{item.email}</div>
              <div className="list-item">{item.phone}</div> */}
                <div className="col-lg mt-2 mt-lg-0">
                  {item.callStatus == 1 ? (
                    <div className="d-flex align-items-center total-participants active text-capitalize lh-1">
                      <span>
                        <i className="bi bi-camera-video me-2"></i>
                      </span>
                      Created
                    </div>
                  ) : (
                    <div className="d-flex align-items-center total-participants text-capitalize lh-1">
                      <span>
                        <i className="bi bi-camera-video me-2"></i>
                      </span>{" "}
                      Not Yet Created
                    </div>
                  )}
                  {item.phone ? (
                    <div className="d-flex align-items-center mob-number text-capitalize lh-1 mt-1">
                      <span>
                        <i className="text-warning bi bi-phone fs-5"></i>
                      </span>
                      Number Received
                    </div>
                  ) : null}
                </div>

                <div className="col-lg mt-2 mt-lg-0">
                  {item?.participants && item?.participants?.length - 1 > 0 ? (
                    <div className="d-flex align-items-center total-participants active text-capitalize lh-1">
                      <span>
                        <i className="bi bi-people-fill me-2"></i>
                      </span>{" "}
                      Total Participants{" "}
                      {item?.participants ? item?.participants?.length - 1 : 0}
                    </div>
                  ) : (
                    <div className="d-flex align-items-center total-participants text-capitalize lh-1">
                      <span>
                        <i className="bi bi-people-fill me-2"></i>
                      </span>{" "}
                      Total Participants 0
                    </div>
                  )}
                </div>
                <div className="col-lg mt-3 mt-lg-0">
                  {item.timeStamp ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      Client Started Filling Form AT:{" "}
                      <span className="text-nowrap">
                        {item.timeStamp
                          ? convertMilisecondsToTime(
                            item.timeStamp.seconds,
                            item.timeStamp.nanoseconds
                          )
                          : ""}
                      </span>
                    </div>
                  ) : null}

                  {item.camJoinedAt ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      CAM Joined AT:{" "}
                      <span className="text-nowrap">
                        {item.camJoinedAt
                          ? convertMilisecondsToTime(
                            item.camJoinedAt.seconds,
                            item.camJoinedAt.nanoseconds
                          )
                          : ""}
                      </span>
                    </div>
                  ) : null}

                  {item.clientSubmitTime ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      Client Submitted Form AT:{" "}
                      <span className="text-nowrap">
                        {item.clientSubmitTime
                          ? convertMilisecondsToTime(
                            item.clientSubmitTime.seconds,
                            item.clientSubmitTime.nanoseconds
                          )
                          : ""}
                      </span>
                    </div>
                  ) : null}

                  {item.camClickedMobileButtonTime ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      CAM Contacted Client's Mobile AT:{" "}
                      <span className="text-nowrap">
                        {item.camClickedMobileButtonTime
                          ? convertMilisecondsToTime(
                            item.camClickedMobileButtonTime.seconds,
                            item.camClickedMobileButtonTime.nanoseconds
                          )
                          : ""}
                      </span>
                    </div>
                  ) : null}

                  {item.CallStatus ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      Client's Mobile Call Status:{" "}
                      <span className="text-nowrap">
                        {item.CallStatus ? item.CallStatus : ""}
                      </span>
                    </div>
                  ) : null}

                  {item.clientJoinedLiveTime ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      Client Click On Join Live Button AT:{" "}
                      <span className="text-nowrap">
                        {item.clientJoinedLiveTime
                          ? convertMilisecondsToTime(
                            item.clientJoinedLiveTime.seconds,
                            item.clientJoinedLiveTime.nanoseconds
                          )
                          : ""}
                      </span>
                    </div>
                  ) : null}

                  {item.clientLeftTime ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      Client Left Video Room AT:{" "}
                      <span className="text-nowrap">
                        {item.clientLeftTime
                          ? convertMilisecondsToTime(
                            item.clientLeftTime.seconds,
                            item.clientLeftTime.nanoseconds
                          )
                          : ""}
                      </span>
                    </div>
                  ) : null}

                  {item.camLeftAt ? (
                    <div className="time-state d-flex gap-2 justify-content-between">
                      CAM LEFT Video Room AT:{" "}
                      <span className="text-nowrap">
                        {item.camLeftAt
                          ? convertMilisecondsToTime(
                            item.camLeftAt.seconds,
                            item.camLeftAt.nanoseconds
                          )
                          : ""}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className="col-lg mt-3 mt-lg-0">
                  <div className="date-time-state d-flex gap-2 justify-content-between mb-1">
                    {timeStampfor}
                  </div>
                  <div className="department-name text-capitalize mb-1">
                    {item?.attendantName && item?.attendantName != ""
                      ? "Attn By :-" + item?.attendantName
                      : ""}
                  </div>

                  {item?.formstatus == 1 && item.callStatus != 1 ? (
                    <button
                      onClick={() =>
                        handleHostMeeting(item.id, "_CAM_" + user?.displayName)
                      }
                      className="btn btn-success btn-custom btn-next rounded-1 mt-1"
                    >
                      Go Live
                    </button>
                  ) : item.callStatus == 1 ? (
                    <button
                      onClick={() => { }}
                      className="btn btn-warning btn-custom btn-next rounded-1 mt-1"
                    >
                      CAM Joined
                    </button>
                  ) : (
                    <button
                      onClick={() => { }}
                      className="btn btn-danger btn-custom btn-next rounded-1 mt-1"
                    >
                      You Missed
                    </button>
                  )}
                  {/* Mark Test Data so it would not be included in report sections */}
                  {/* <button
                  onClick={() => updateTestVal(item?.id)}
                  className="btn btn-secondary btn-custom btn-next rounded-1 mt-2"
                >
                 <input className="form-check-input" type="checkbox" checked={item?.isTest} id={item?.id}/>
                  <label className="form-check-label ms-1" for={item?.id}>Mark Test</label>
                </button> */}
                </div>
              </div>
              {item.attendantBdmName ? <hr></hr> : null}
              <div className="cam-wrapper">
                {onlineCam[Index]?.total ? (
                  <div className="fs-6 fw-medium mb-3 text-light">
                    When this Lead arrived,{" "}
                    <span className="text-warning text-capitalize">
                      {onlineCam[Index]?.total} BDMs
                    </span>{" "}
                    were online in total.
                  </div>
                ) : null}
                {item.attendantBdmName ? (
                  <div className="d-flex flex-wrap align-items-center gap-2 mb-3">
                    <div className="label text-nowrap text-uppercase fw-semibold text-primary">
                      BDM Joined:
                    </div>
                    <div className="d-flex flex-wrap text-nowrap gap-2">
                      <button className="btn btn-primary text-capitalize">
                        {" "}
                        {item.attendantBdmName}
                      </button>
                    </div>
                  </div>
                ) : null}

                {/* Lead also relevant to */}

                {item.relevant &&
                  item.relevant.length > 0 ? (
                  <div className="d-flex flex-wrap align-items-center gap-2 mb-3">
                    <div className="label text-nowrap text-uppercase fw-semibold text-success">
                      Lead Also Relevant To:
                    </div>
                    <div className="d-flex flex-wrap text-nowrap gap-2">
                      {item.relevant.map(
                        (relevantName, index) => {
                          return (
                            <button className="btn btn-success text-capitalize">
                              {relevantName.display_name}
                            </button>
                          );
                        }
                      )}
                    </div>
                  </div>
                ) : null}

                {/* Lead not relevant to */}
                {item.notRelevant &&
                  item.notRelevant.length > 0 ? (
                  <div className="d-flex flex-wrap align-items-center gap-2  mb-3">
                    <div className="label text-nowrap text-uppercase fw-semibold text-secondary">
                      Lead Not Relevant To:
                    </div>
                    <div className="d-flex flex-wrap text-nowrap gap-2">
                      {item.notRelevant.map(
                        (notRelevantname, index) => {
                          return (
                            <button className="btn btn-secondary text-capitalize">
                              {notRelevantname.display_name}
                            </button>
                          );
                        }
                      )}
                    </div>
                  </div>
                ) : null}

                {/* Call missed by */}
                {/*missedBy.length > 0 ? (
                  <div className="d-flex flex-wrap align-items-center gap-2">
                    <div className="label text-nowrap text-uppercase fw-semibold text-danger">
                      Call Missed By:
                    </div>
                    <div className="d-flex flex-wrap text-nowrap gap-2">
                      {missedBy.map((missedCam, index) => {
                        return (
                          <button className="btn btn-danger text-capitalize">
                            {missedCam}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                    ) : null */}
              </div>
            </div>
          </div>
        );
      }
    });
  } else {
    vlisting =
      "There isn't any data available at the moment because NM-Live resets the previous day's data at 00:00, and we have not yet received any leads for today. If you wish to review your call details from previous days, you can access this information directly from your profile. Please note that this list maintains data for each day only until 23:59:59.";
  }

  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const userInfo = useSelector((state) => state.userInfo);
  //console.log("currentRole",loggedInUserRole)
  const availableRoles = ["CAM", "BDM"];
  useEffect(() => {
    onSnapshot(q, (querySnapshot) => {
      let forms = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        data["id"] = doc.id;
        if (data.formstatus > 0) forms.push({ ...doc.data(), id: doc.id });
        if (data.isnew && data.callStatus != 1) {
          //playSound();
          console.log("before alerting cam:::")
          if ((localStorage.getItem("camJoinedLead") == null || localStorage.getItem("camJoinedLead") == 'null') && availableRoles.includes(loggedInUserRole)) {
            console.log("alerting cam:::")
            playSound();

          }
          else if (loggedInUserRole === "SSA" || loggedInUserRole === "SA") {
            playOtherSound();
          }
          else { }
        }
      });

      // const getFormData = async() =>{
      //   const formData = doc(database, environment.REACT_APP_FIREBASE_DB, formID);
      //   const myDoc = await getDoc(formData);
      //   // setIsBdm(myDoc.data()?.bdmJoinedAt? true : false);
      //   // setIsCam(myDoc.data()?.camJoinedAt? true : false);
      //   // setRoomDetails(myDoc.data());
      // }
      // getFormData();

      setAllDate(forms);
      setIsloading(false);
    });

    //audio.play()
  }, []);

  const playSound = () => {
    audio.play();
  };

  const playOtherSound = () => {
    otherSound.play();
  }

  const checkCam = async (formID) => {
    const myDocRef = doc(database, environment.REACT_APP_FIREBASE_DB, formID);
    const myDoc = await getDoc(myDocRef);
    if (myDoc.exists()) {

      return myDoc.data()?.camJoinedAt ? true : false;

    } else {
      return false;
    }
  }

  const updateCamJoined = async (formID) => {
    const myDocRef = doc(database, environment.REACT_APP_FIREBASE_DB, formID);
    const myDoc = await getDoc(myDocRef);
    if (myDoc.exists()) {
      //console.log("isExist::",myDoc.data())
      if (!myDoc.data()?.camEmail && myDoc.data()?.camEmail !== "") {
        await updateDoc(myDocRef, {
          isCamIsInQueue: true,
          camEmail: user?.email
        });
      }
      //return myDoc.data()?.camJoinedAt? true : false;

    } else {
      return false;
    }
    //const formRef = doc(database, environment.REACT_APP_FIREBASE_DB, formID);

  }
  //console.log(allData)
  const handleHostMeeting = async (dataID, nameValue) => {
    setGoLive(true);
    setIdentityAction(nameValue);
    const createdRoomId = uuidv4();
    setRoomIdAction(createdRoomId);
    setFormIdAction(dataID);
    document.getElementById("p-box").click();
    // updateCamJoined(dataID);
  };

  //console.log("Lead listing", allData);

  return (
    <>
      {/* <Header user={userInfo} /> */}
      <div className="container">
        <div className="live-listing-wrapper py-3">
          <div className="d-flex flex-column flex-md-row gap-md-3 align-items-md-center mb-3">
            <div className="coll text-nowrap">
              <div className="main-heading text-white text-opacity-75">
                CAM Live Lead Listing
              </div>
            </div>
            <div className="coll text-nowrap">
              <div className="w-100 fs-6 text-light lh-sm">
                <marquee
                  width="100%"
                  direction="left"
                  style={{ paddingTop: "5px" }}
                >
                  <span className="text-warning fw-semibold">
                    NOTICE FOR CAM & BDM:
                  </span>{" "}
                  Please remember to logout from NM-LiveCalls System when you're
                  away or at the end of your shift to avoid showing as available
                  to others.
                </marquee>
              </div>
            </div>
            <div className="coll text-nowrap">
              <button
                type="button"
                onClick={() => {
                  setNotify(true);
                  playSound();
                }}
                className={
                  isNotify
                    ? "btn btn-success mt-2 mt-md-0 rounded-1"
                    : "btn btn-warning mt-2 mt-md-0 rounded-1"
                }
              >
                {isNotify ? "Activated" : "Activate Notification"}
              </button>
            </div>
          </div>

          {/* BDM And CAM Listing */}
          <BdmCamList pageType="lead-listing" />

          {/* Join Now Model Popup */}
          {useMemo(() => (
            <JoinNowPopup user={user} isGoLive={isGoLive} to="room" data={allData} userRole={userRole} />
          ), [isGoLive])}
          {/* <JoinNowPopup user={user} isGoLive={isGoLive} to="room" data={allData} userRole={userRole} /> */}

          <WelcomeScreenPopup />

          <RefreshApp currentAppVersion={currentAppVersion} />
          {/* Live Leads Table Section */}
          <div className="meeting-details-page rounded-3">
            <div
              className="list-header small fw-semibold text-white rounded-3 p-3 mb-3 d-none d-lg-block"
              style={{
                background: "linear-gradient(168.68deg,#0052c9 1.12%,#0a91db)",
              }}
            >
              <div className="row gx-3">
                <div className="col">Name</div>
                <div className="col">Status</div>
                <div className="col">Participants</div>
                <div className="col">Time Stats</div>
                <div className="col">Action</div>
              </div>
            </div>
            {/* <div>{vlisting}</div> */}
            {isLoading ? (
              <div className="d-flex flex-row-reverse align-items-center justify-content-center gap-3" id="lead-list-spinner">
                <div className="lead-list-spinner"></div>
                {" "}Please wait ....
              </div>
            ) : (
              <div>{vlisting}</div>
            )}
          </div>
        </div>
      </div>
      <button id="p-box" data-bs-toggle="modal" data-bs-target="#joinCall" className="btn btn-warning btn-custom btn-next rounded-1 mt-1" style={{ display: "none" }}></button>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    setRoomIdAction: (id) => dispatch(setRoomId(id)),
    setFormIdAction: (id) => dispatch(setFormId(id)),
    setLoggedinUser: (user) => dispatch(setUserInfo(user)),
    setUserRoleAction: (userRole) => dispatch(setUserRole(userRole)),
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(LeadsListingPage);