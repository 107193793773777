import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import "./App.css";
import IntroductionPage from "./IntroductionPage/IntroductionPage";
import JoinRoomPage from "./JoinRoomPage/JoinRoomPage";
import LeadsListingPage from "./LeadsListingPage/LeadsListingPage";
import RoomPage from "./RoomPage/RoomPage";
import RoomPageClientSide from "./RoomPage/RoomPageClientSide";
import RoomPageBdmSide from "./RoomPage/RoomPageBdmSide";
import AdminRoomPage from "./RoomPage/AdminRoomPage";
import OngoingCallsPage from "./OngoingCalls/OngoingCallsPage";
import { useSelector } from "react-redux";
import {
  provider,
  getAuth,
  OAuthProvider,
  signInWithPopup,
  app,
  database,
  onAuthStateChanged,
  auth,
  getToken,
  messaging,
  onMessage,
} from "./firebaseConfig";
import { setIsRoomHost, setLogin, setUserInfo } from "./store/actions";
import { connect } from "react-redux";
import { Toast } from "react-bootstrap";
import LeadDescriptionPage from "./LeadsListingPage/LeadDescriptionPage";
import Layout from "./Layout";
import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { environment } from "./environment";
import MessageNotification  from "./Notification/MessageNotification";
import { useMemo } from "react";

import Call from "./RoomPage/Call";
import { callAxios } from "./utils/utils";
// import { NotificationPermissionModal } from "./Notification/NotificationPermissionModal";
import { getTokenFromTwilioForConversation } from "./utils/twilioConversation";
import $ from "jquery";
import RoomPageShareSide from "./RoomPage/RoomPageShareSide";
import { WebSocketProvider } from "./Context/WebSocketContext";
import MeetingRoom from "./RoomPage/MeetingRoom";
import MeetingClientRoom from "./RoomPage/MeetingClientRoom";
import JoinRoomMeeting from "./JoinRoomPage/JoinRoomMeeting";
import InvitedRoom from "./RoomPage/InvitedRoom";

function App({ setLoginAction, setLoggedinUser }) {
  const [isAuthenticated, setAuthentication] = useState(false);
  const [user, setUser] = useState();
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [userRole, setUserRole] = useState({});

  onMessage(messaging,(payload)=>{
    //console.log("---->",payload)
    setNotification({
      title: payload.notification.title,
      body: payload.notification.body,
    });
    setShow(true);
  })

  function isIOS() {
    const browserInfo = navigator.userAgent.toLowerCase();

    if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
      return true;
    }
    if (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform)
    ) {
      return true;
    }
    return false;
  }

  function requestPermission(email) {
    if (isIOS()) return;
    // Notification.requestPermission().then(function(permission) { console.log('permiss', permission)});
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        getFirebaseToken(email);
      }

      if (permission === "denied") {
         //alert("notification permission denied")
        document.getElementById("notification-permission-settings").classList.add("show");
        document.getElementById("notification-permission-settings").classList.remove("hide");
        document.getElementById("notification-permission-settings").style.display="block";

      }
    });
  }

  const getFirebaseToken = (email) => {
    getToken(messaging, {
      vapidKey:
        "BPR7B9Y-Z2hLPNRQ01VcnOBLymZwyrQ_ScAVVwd3S4y0TeRp30xDHlpb4zx3eqM9HJuJt_RPdj9Xn0hVIh4Xg6U",
    })
      .then(async (currentToken) => {
        if (currentToken) {
          await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/users/update-fcm-token',{email:email,token:currentToken}, "post" );
          // console.log(currentToken + "from app js")
        } else {
          requestPermission();
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });
  };

  // const twilioConversationUser = (identity, friendlyName) =>{   
  //   callAxios(environment.REACT_APP_API_URL_Live+'api/v1/twilio/fetch-user-by-identity',{identity: identity, friendlyName:friendlyName}, "post" )
  // }

  // const getTokenFromTwilioForConversation123 = async (identity) => {
    
    
  //   const response = await callAxios(environment.REACT_APP_API_URL_Live + `api/v1/twilio/generate-token`, {identity:identity}, "post");
  // //console.log(response)
  //   const token = response.data;
    
  //   if (token) {
  //     authClientE(token);
  //    // initializedEventE();
  //   }
  // };

  

  useEffect(() => {
    
    const getUserRole = async (email) => {
      //console.log("getting role")
     
        const q = query(
          collection(database, environment.REACT_APP_USERS_DB),
          where("email", "==", email),
          orderBy("timeStamp","asc"),
          limit(1)

        );
        onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const id = doc.id;
            const data = doc.data();
            data["id"] = doc.id;
            setUserRole(data);
          });
        });
      
    };
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getUserRole(user?.email);
        requestPermission(user?.email);
        //getTokenFromTwilioForConversation(user?.email);
        getTokenFromTwilioForConversation(user?.email)
        //twilioConversationUser(user?.email, user?.displayName)
        setLoginAction(true);
      } else {
        setLoginAction(false);
      }
    });
    // onMessage(messaging, (payload) => {
    //   console.log(payload)
    //   setNotification({
    //     title: payload.notification.title,
    //     body: payload.notification.body,
    //   });
    //   setShow(true);
    //   // ...
    // });
  }, []);

  //console.log(userRole)
  $("#interactDocumentFirst").trigger("play")
  return (
    <>
    {/* <NotificationPermissionModal /> */}
    {/* {
       notification.body.length > 0? */}
      {/* {useMemo(()=>(
        <MessageNotification  notification={notification} isShow={show}/>
      ),[notification])} */}
       
    {/* //   : null
    // } */}
      <audio src={require("./static/Intereact.mp3")} id="interactDocumentFirst" ></audio>
      <WebSocketProvider>
      <Router>
     
        <Routes>
        <Route path="/join-room" element={<JoinRoomPage />} />
          <Route path="/room" element={<RoomPage />} />

          <Route path="/meeting" element={<MeetingRoom />} />
          <Route path="/join-meeting" element={<MeetingClientRoom />} />
          <Route path="/join-room-meeting" element={<JoinRoomMeeting />} />
          <Route path="/invited-room" element={<InvitedRoom />} />

          <Route path="/call" element={<Call />} />
          {/* <Route path="/lead-listing" element={<LeadsListingPage />} /> */}
          <Route path="/clientroom" element={<RoomPageClientSide />} />
          <Route path="/sharedroom" element={<RoomPageShareSide />} />
          <Route path="/bdmroom" element={<RoomPageBdmSide />} />
          <Route path="/adminroom" element={<AdminRoomPage />} />
          {/*<Route path="/ongoing-calls" element={<OngoingCallsPage />} />
          <Route path="/issues-list" element={<IssuesList />} /> */}
          <Route path="/" element={<IntroductionPage userRole={userRole?.userType} />} />
          <Route path="*" element={<Layout />} />
        </Routes>
        
      </Router>
      </WebSocketProvider>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginAction: (isLogin) => dispatch(setLogin(isLogin)),
    setLoggedinUser: (user) => dispatch(setUserInfo(user)),
  };
};

export default connect(null, mapDispatchToProps)(App);
