import React, { useEffect, useState } from 'react';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Modal, Form, Button } from 'react-bootstrap';

// Quill Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { callAxios } from '../utils/utils';
import { environment } from '../environment';
import { toast } from 'react-toastify';

const animatedComponents = makeAnimated();


export const EditNotice = ({ show, close, userRole, user, setShowNoticeModal,getUpdates,setIsloading, currentItem }) => {
    // React-slick npm style customisation
    // console.log(currentItem)
    const [subject, setSubject] = useState(currentItem.subject || "")
    const [description, setDescription] = useState(currentItem.update || "")
    const [isChecked, setIsChecked] = useState(false);
    // const [userEmail, setUserEmail] = useState("shaun@virtualemployee.com")
    useEffect(()=>{
        setSubject(currentItem.subject || "")
        setDescription(currentItem.update || "")
    },[currentItem])
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked); // Update the state based on checkbox status
      };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: "var(--bs-border-color)",
            borderRadius: ".375rem",
            backgroundColor: "var(--bs-white)",
            fontSize: "16px",
            padding: "5px",
            boxShadow: 'none'
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#868686", // Change the color of the placeholder text
            fontSize: "16px",
            padding: "0",
        }),
    };

    const descriptionElement = document.getElementById("ceo-description");

    const UserList = [
        { value: 'Rahul Kumar', label: 'Rahul Kumar' },
        { value: 'Ajay Singh', label: 'Ajay Singh' },
        { value: 'Anubhav Jaiz', label: 'Anubhav Jaiz' },
        { value: 'Andy Jashon', label: 'Andy Jashon' },
        { value: 'Lalan Singh', label: 'Lalan Singh' },
        { value: 'Ahmad Khan', label: 'Ahmad Khan' }
    ]

    // Custom react-select component with animate with hide dropdown 
    const customComponents = {
        ...animatedComponents,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
    };

    // Define the quill toolbar modules
    const toolbarModules = {
        toolbar: {
            container: '#toolbar', // Reference the ID of the custom toolbar
            handlers: {
                // Add custom handlers if necessary
            }
        },
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }],
            ['bold', 'italic', 'underline'],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{ 'list': 'bullet' }, { 'list': 'ordered' }],
            ['link'],
            ['clean'] // Remove formatting button
          ],
    };

    // Define the quill formats that are allowed
    const formats = [
        'font', 'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'blockquote'
    ];

    if (description.length>0) {
        if(descriptionElement){
            document.getElementById("ceo-description").classList.remove("red-border-important");
        document.getElementById("ceo-description").focus();
        }
        
      }

    const handleSubmitNotice = (e) =>{
        e.preventDefault();
        
        if (description.length === 0) {
            // console.log("in 0")
            if(descriptionElement){
document.getElementById("ceo-description").classList.add("red-border-important");
            document.getElementById("ceo-description").focus();
            }
            
            return false;
          } else{
            // console.log(subject, description.length,isChecked)
            setIsloading(true)
            if(environment.CEO_UPDATE_USER.includes(user?.email)){
                callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/ceo-update/edit-update`,{subject:subject,update:description,id:currentItem.id},"post").then((res)=>{
                    if(res.status === 200){
                        if(res.data.statuscode === 200){
                            setShowNoticeModal(false)
                document.getElementById("ceoUpdateForm").reset()
                setIsChecked(false)
                getUpdates()
                toast.success("notice updated successfully!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                        }
                    }
                })
            } else{
                setShowNoticeModal(false)
                document.getElementById("ceoUpdateForm").reset()
                setIsChecked(false)
                toast.error("You have no permission to send update", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
            }
          }
        
    }

    return (
        <Modal show={show} centered onHide={close} size='xl' scrollable backdrop="static" keyboard={false} dialogClassName="addNewIssueModal newNoticeModal mw-auto" contentClassName='rounded overflow-hidden'>
            <Modal.Body className="px-lg-4 px-xl-5 py-4">
                <div className="custom-wrapper my-2 pb-1">
                    <div className="fs-3 fw-bold text-dark lh-sm mb-4">Edit Notice</div>
                    <Form className="user-form-details d-flex flex-column" onSubmit={handleSubmitNotice} id="ceoUpdateForm">
                        <Form.Group className="mb-3" controlId="addSubject">
                            <Form.Control type="text" className="custom-input fw-medium border shadow-none py-2" placeholder="Edit subject" autoComplete="off" required value={subject} onChange={(e)=>{setSubject(e.target.value)}}/>
                        </Form.Group>

                        <div className="editor-section mb-3">
                            {/* React Quill Editor */}
                            <ReactQuill id="ceo-description" className="custom-quill-editor border rounded" theme="snow" placeholder="Description.."
                                modules={toolbarModules}
                                formats={formats}
                                value={description}
                                onChange={setDescription}
                            />
                        </div>

                       

                        <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3 pt-3">
                            <Button variant="danger" onClick={close} className='btn-custom px-5 py-2'>Cancel</Button>
                          
                            <Button type='submit' variant="success" className='btn-custom px-5 py-2'>Edit Update</Button>
                           
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}
