import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { callAxios } from '../utils/utils';
import { environment } from '../environment';
import { toast } from 'react-toastify';

export const CancelMeetingModal = ({ show, close, meetingId, setShowCancelMeeting, setIsLoading, currentTimezone, setMeetings, setPagination, setAvailabel, setActive, setMeetingDetails, setMeetingUrl, userRole }) => {
    const [cancelNote, setCancelNote] = useState("");
    // console.log(meetingId)
    const handleCancelMeeting = async(e)=>{
        e.preventDefault();
         setIsLoading(true)
        // console.log(meetingId,cancelNote)
        callAxios(environment.REACT_APP_API_URL_Live + "api/v1/meetings/meeting-cancel",{meetingId:meetingId,cancelNote:cancelNote},"post").then((res)=>{
            if(res.data.statusCode === 200){
                callAxios(environment.REACT_APP_API_URL_Live + "api/v1/meetings/get-meeting",{role:userRole, isFilter:true,fromDate:new Date().toJSON().slice(0, 10), toDate:new Date().toJSON().slice(0, 10),currentTimezone:currentTimezone},"post").then((res)=>{
                    setShowCancelMeeting(false)
                setIsLoading(false)
                toast.success(res.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                  
                    setMeetings(res.data.meetings)
                    setPagination(res.data.pagination)
                    setIsLoading(false)

                    if(res.data.meetings.length > 0){
                        setAvailabel(true)
                        // getMeetingDetails(res.data.meetings[0], 0)
                        setActive(0)
                        setMeetingDetails(res.data.meetings[0].meeting)
                        setMeetingUrl(res.data.meetings[0].short_url)
                        
                       
                    } else{
                        setAvailabel(false)
                    }
                })
                
            } else{
                toast.error(res.data.error, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        })
    }
    return (
        <Modal show={show} centered onHide={close} size='lg' scrollable backdrop="static" keyboard={false} dialogClassName="cancelledMeeting mw-auto" contentClassName="rounded overflow-hidden">
            <Modal.Body className="text-center px-md-4 px-lg-5 py-4">
                <div className="custom-wrapper my-2 pb-3">
                    <div className="fs-3 fw-bold text-dark text-center lh-sm mb-4 pb-xxl-1">Cancel Meeting</div>
                    <div className="fs-5 fw-medium text-body mb-4 pb-2 pb-xl-3 pb-xxl-4">Are you certain you want to cancel this meeting? Once Cancelled, it cannot be recovered.</div>
                    <Form className="user-form-details d-flex flex-column h-100" onSubmit={(e)=>{handleCancelMeeting(e)}}>
                        <Form.Group className="mb-3" controlId="addSubject">
                            <Form.Control as="textarea" rows="4" className="border" placeholder="Write note" autoComplete="off" onChange={(e)=>{setCancelNote(e.target.value)}}  required/>
                        </Form.Group>

                        <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3 pt-2">
                            <Button variant="secondary" onClick={close} className='btn-custom px-4 px-md-5 py-2'>Cancel</Button>
                            <Button type='submit' variant="danger" className='btn-custom px-4 px-md-5 py-2'>Confirm</Button>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}
