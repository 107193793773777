import React, { useState } from 'react'
import VideoButtons from './VideoButtons'
import Videos from './Videos'
import { connectToRoom } from '../../utils/twilioUtils'
import { useEffect } from 'react'

 const VideoSection = (props) => {
  const { comingFrom, isCam, camera, isMicMuted, isRejoin } = props
  
  const [room, setRoom] = useState(null);
  const [transcriptData, setTranscriptData] = useState([]);


 

  
  
  useEffect(()=>{
      
      
         connectToRoom(props.twilioAccessToken, props.roomId, setRoom, comingFrom, camera, isMicMuted);
      
     
    
  }, [isRejoin])
   
// console.log(props?.email,props?.meetingId)
  return (
    <>
      <VideoButtons room={room} comingFrom={comingFrom} isCam={isCam} setFormIdAction={null} camera={camera} isMicMuted={isMicMuted} transcriptData={transcriptData} setTranscriptData={setTranscriptData} email={props?.email} meetingId={props?.meetingId}/>
      <div className='video_section_container'>
       
        <Videos room={room} setRoom={setRoom} isCam={isCam} comingFrom={comingFrom} camera={camera} isMicMuted={isMicMuted} setTranscriptData={setTranscriptData} email={props?.email} meetingId={props?.meetingId}/>
      </div>
    </>

  )
  
}

export default VideoSection