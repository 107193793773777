export const statuscode={
    1: "In Progress",
    2: "On Hold",
    100000001: "Response Required",
    100000002: "Live",
    100000003: "Follow-Up",
    100000004: "Analysis",
    693980000: "Partial Won",
    3: "Won",
    4: "Canceled",
    5: "Out-Sold"
}

export const new_opportunitytype={
  "100000000": "In-House",
  "100000001": "Hiring",
  "232520001": "In-House - team",
  "232520002": "In-House - dedicated",
  "232520003": "External Hiring"
}

export const new_CompanySize = {
    "100000001": "Start Up",
    "100000008": "1",
    "100000009": "2",
    "100000002": "3 to 5",
    "100000000": "6 to 10",
    "100000003": "11 to 20",
    "100000004": "21 to 50",
    "100000005": "51 to 100",
    "100000006": "101 to 500",
    "100000007": "More than 500"
  }

export const new_EmploymentType = {
    "100000000": "Full Time",
    "693980000": "Ad Hoc",
    "100000001": "Part Time"
  }

export const new_EmploymentTenure = {

    "100000000": "Less than 1 Month",
  
    "100000005": "1 to 3 Months",
  
    "100000002": "3 to 6 Months",
  
    "100000003": "6 to 12 Months",
  
    "100000004": "More than 1 Year",
  
    "100000006": "Not Sure",
  
    "100000001": "Looking for Job"
  
  }

  export const new_ClientType = {
    "100000003": "New",
    "100000004": "Additional New",
    "100000000": "Existing",
    "100000001": "Returning",
    "100000002": "Replacement"
  }

  export const new_servicetype_mapping = {
    "124950000": "Dedicated",
    "124950001": "Team Model",
    "124950002": "In House"
  }

  export const new_servicetype = {
    "100000000": "Full Time",
    "100000001": "Part Time",
    "100000002": "Ad-hoc"
  }

  export const nm_invoicefrom = {
    "124950000": "VE",
    "124950001": "TV",
    "124950002": "RSS",
    "124950003": "Unnati"
  }

export const nm_clienttype = {
  "124950000": "Regular Full Time",
  "124950001": "Regular Part Time",
  "124950002": "New Full Time",
  "124950003": "New Part Time",
  "124950004": "Adhoc",
  "124950005": "End Month Service",
  "124950006": "Free Trial",
  "124950007": "Paid Trial",
  "124950008": "Combo Trial"
}
  
export const nm__clienttype = {
  "124950002": "New Full Time",
  "232520007": "New Full-Time 1/2",
  "232520008": "New Full-Time 2/2",
  "124950000": "Regular Full Time",
  "232520001": "Upscaling Full-Time",
  "232520022": "Upscaling Full-Time 1/2",
  "232520023": "Upscaling Full-Time 2/2",
  "232520003": "Cross-scaling Full-Time",
  "232520026": "Cross-scaling Full-Time 1/2",
  "232520027": "Cross-scaling Full-Time 2/2",
  "232520005": "Prorated Full-Time",
  "232520013": "Replacement Full-Time",
  "232520030": "Replacement Full-Time 1/2",
  "232520031": "Replacement Full-Time 2/2",
  "124950004": "Adhoc",
  "232520018": "New Adhoc",
  "124950005": "End Month Service",
  "232520011": "End of Service 1/2",
  "232520012": "End of Service 2/2",
  "124950006": "New Free Trial",
  "232520015": "Upscale Free Trial",
  "232520019": "Cross-scale Free Trial",
  "124950007": "New Paid Trial",
  "232520016": "Upscale Paid Trial",
  "232520020": "Cross-scale Paid Trial",
  "124950008": "New Combo Trial",
  "232520017": "Upscale Combo Trial",
  "232520021": "Cross-scale Combo Trial",
  "124950003": "New Part Time",
  "232520009": "New Part-Time 1/2",
  "232520010": "New Part-Time 2/2",
  "124950001": "Regular Part Time",
  "232520002": "Upscaling Part-Time",
  "232520024": "Upscaling Part-Time 1/2",
  "232520025": "Upscaling Part-Time 2/2",
  "232520004": "Cross-scaling Part-Time",
  "232520028": "Cross-scaling Part-Time 1/2",
  "232520029": "Cross-scaling Part-Time 2/2",
  "232520006": "Prorated Part-Time",
  "232520014": "Replacement Part-Time",
  "232520032": "Replacement Part-Time 1/2",
  "232520033": "Replacement Part-Time 2/2"
}

export const nm_servicemonth = {
  "232520001": "January 2024",
  "232520002": "February 2024",
  "232520003": "March 2024",
  "232520004": "April 2024",
  "232520005": "May 2024",
  "232520006": "June 2024",
  "232520007": "July 2024",
  "232520008": "August 2024",
  "232520009": "September 2024",
  "232520010": "October 2024",
  "232520011": "November 2024",
  "232520012": "December 2024",
  "124950000": "January 2022",
  "124950001": "February 2022",
  "124950002": "March 2022",
  "124950003": "April 2022",
  "124950004": "May 2022",
  "124950005": "June 2022",
  "124950006": "July 2022",
  "124950007": "August 2022",
  "124950008": "September 2022",
  "124950009": "October 2022",
  "124950010": "November 2022",
  "124950011": "December 2022",
  "124950012": "January 2023",
  "124950013": "February 2023",
  "124950014": "March 2023",
  "124950015": "April 2023",
  "214200001": "May 2023",
  "214200002": "June 2023",
  "214200003": "July 2023",
  "214200004": "August 2023",
  "214200005": "September 2023",
  "214200006": "October 2023",
  "214200007": "November 2023",
  "214200008": "December 2023"
}

export const nm_servicestatus = {
  "124950000": "Continue",
  "124950001": "Paused",
  "124950002": "Cancelled"
}

export const nm_paymentstatus = {
  "124950000": "Received",
  "124950001": "Not Received",
  "124950002": "Pending",
  "124950003": "Free Trial",
  "124950004": "Paid Trial",
  "124950005": "Combo Trial"
}

export const formatShiftTime = (time) => {
  // Each unit represents 10 minutes, so multiply by 10 to get the total minutes
  const totalMinutes = time * 10;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
 
  // Convert to 12-hour format and determine AM/PM
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12; // Convert 0 and 12 to 12 for 12-hour format
 
  // Format minutes to always be two digits (e.g., '00', '05', '10')
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
 
  return `${formattedHours}:${formattedMinutes} ${period}`;
};
  
   