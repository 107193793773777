import React, { useEffect, useState } from "react";
import './issues.scss';
import { Button, NavLink, Form, Card, Accordion } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import SearchIcon from '../../resources/images/search_1.svg';
import { AddNewIssueModal } from "./AddNewIssueModal";
import { IssueListingCard, IssuesReopen } from "./IssuesListing";
import { ReopenIssueModal } from "./ReopenIssueModal";
import { connect } from "react-redux";
import { setUserInfo } from "../../store/actions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database } from "../../firebaseConfig";
import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { environment } from "../../environment";
import { callAxios, log } from "../../utils/utils";
import { toast } from "react-toastify";
import { CeoListing } from "./CeoUpdateListing";

// Custom Accordion
function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log('totally custom!'),
  );

  return (
    <div className="d-flex flex-wrap flex-sm-nowrap gap-2" onClick={decoratedOnClick}>
      {children}
    </div>
  );
}

const IssuesBdm = ({ setLoggedinUser }) => {

  const [user, loading, error] = useAuthState(auth);
  let navigate = useNavigate();
  // Initialize state to track the active menu
  const [activeMenu, setActiveMenu] = useState(0);

  // For Sidebar list selected card
  const [active, setActive] = useState(0);

  // Add new meeting modal
  const [showAddIssueModal, setShowAddIssueModal] = useState(false);
  const closeAddIssueModal = () => setShowAddIssueModal(false);
  const openAddIssueModal = () => setShowAddIssueModal(true);

  // Handler function to update the active Menu
  const handleClick = (status) => {

    setActiveMenu(status);
    setStatus(status)
    getIssues(status)
  };


  const [userRole, setUserRole] = useState({});
  const [issues, setIssues] = useState([]);
  const [ceoUpdate, setCeoUpdate] = useState([]);
  const [issueCounts, setIssuesCount] = useState([]);
  const [status, setStatus] = useState("ceoUpdate");
  const [statusD, setStatusD] = useState('')

  const [currentItem, setCurrentItem] = useState({});

  const [startDate, setStartDate] = useState(() => {
    const startDD = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`;
    return startDD;
  });
  
  
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    const syear = date.getFullYear();
    const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const sday = String(date.getDate()).padStart(2, '0');
    return `${syear}-${smonth}-${sday}`;
  });

  const [isLoading, setIsloading] = useState(true);

  //  useEffect(() => {
  //     if (status) {
  //       getIssues("open");  // This will run after `status` is updated
  //     }
  //   }, [statusD]); 
  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {

      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };

      setLoggedinUser(userInfo);

    }
  }, [user, loading]);




  const getIssues = async (status) => {
    // console.log(status)
    setIsloading(true)
    setCurrentItem({})
    if(status === "ceoUpdate"){
      callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/ceo-update/get-ceo-update`,{},"get").then((res)=>{
        if(res.status === 200){
          setIsloading(false)
            setCeoUpdate(res.data.oData);
             setCurrentItem(res.data.oData.getUpdateList[0])
             callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/ceo-update/read-notice`,{id:res.data.oData.getUpdateList[0].id,callFrom:userRole?.userType},"post").then((res1)=>{
              if (["SA", "BDM", "SSA"].includes(userRole?.userType)) {
                localStorage.setItem("lastReadId",res.data.oData.getUpdateList[0].id)
              }
             })
        }
    })
    } else{
      await callAxios(
      environment.REACT_APP_API_URL_Live + "api/v2/bdm-issues",
      { startDate: startDate, endDate: endDate, status: status },
      "post"
    ).then((issues) => {

      setIssues(issues);
      //  console.log(issues)
      setActive(0)
      setCurrentItem(issues?.data[0])
      setStatus(status);

    });

    
    }
    await callAxios(
      environment.REACT_APP_API_URL_Live + "api/v2/bdm-issues/get-issue-dashboard",
      {},
      "get"
    ).then((counts) => {
      setIssuesCount(counts.data);
      setIsloading(false);

    });
    
  };

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
      const getUserRole = async () => {
        if (user) {
          const q = query(
            collection(database, environment.REACT_APP_USERS_DB),
            where("email", "==", user?.email),
            orderBy("timeStamp", "asc"),
            limit(1)
          );
          onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const id = doc.id;
              const data = doc.data();
              data["id"] = doc.id;
              setUserRole(data);

            });
          });
        }
      };
      getUserRole();


      getIssues("ceoUpdate");
    }
  }, [user, loading]);




  const ReplyListingPage = (issueId, subject) => {
    navigate("/replies-list", {
      state: { issueId: issueId, subject: subject },
    });
  };

  const updateStatus = async (status, id) => {
    //console.log(ee)
    const formData = {
      status: status,
      id: id,
    };
    //console.log(formData)

    const response = await callAxios(
      environment.REACT_APP_API_URL_Live + "api/v2/bdm-issues/update-status",
      formData,
      "post"
    );
    if (response?.data?.status === 200) {
      // resetForm();
      toast.success("Status updated!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      await callAxios(
        environment.REACT_APP_API_URL_Live + "api/v2/bdm-issues/get-issue-dashboard",
        {},
        "get"
      ).then((counts) => {
        setIssuesCount(counts.data);
        setIsloading(false);
      });
    } else {
      //document.getElementById("replies-preloader").classList.add('d-none');
      toast.error("Network Issue!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };



  // console.log(currentItem)


  return (
    <div className="IssuesList-page py-3">
      {isLoading ? <div className="loading">Loading&#8230;</div> : null}
      <div className="container-fluid px-xxl-5">
        <div className="border-bottom border-3 border-primary border-opacity-75 mb-3 pb-2">
          <div className="d-flex flex-wrap flex-column flex-md-row align-items-md-center justify-content-between gap-2 gap-xl-5 mb-2">
            <div className="fs-3 fw-semibold text-light lh-sm text-nowrap">Incentive Helpdesk</div>
            <div className="date-wrapper d-flex flex-column flex-md-row align-items-md-center gap-2 gap-xl-3 ms-xxl-3">
              <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xxl-3" controlId="dateFrom">
                <Form.Label className="m-0">From</Form.Label>
                <Form.Control type="date" className="bg-transparent py-2"
                  // min={new Date().toJSON().slice(0, 10)}
                  defaultValue={`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xxl-3" controlId="dateTo">
                <Form.Label className="m-0">To</Form.Label>
                <Form.Control type="date" className="bg-transparent py-2"
                  // min={new Date().toJSON().slice(0, 10)}
                  defaultValue={new Date().toJSON().slice(0, 10)}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Form.Group>

              <Button variant="secondary" className="rounded-2 mt-1 mt-md-0 py-2 bg-primary" onClick={() => { getIssues(status) }}>
                <img src={SearchIcon} alt="" className="img-fluid align-text-top" />
                <span className="d-md-none ms-1">Search</span>
              </Button>

              {/* <Form.Group className="status-type d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3 ms-xl-5" controlId="statusType">
                                  <Form.Label className="m-0">Status</Form.Label>
                                  <Form.Select className="bg-transparent text-secondary py-2" style={{ minWidth: '120px' }} required onChange={(e)=>{setStatus(e.target.value)}}>
                                      <option value="" disabled >Status</option>
                                      <option value="open" selected={status === "open"}>Open</option>
                                      <option value="In Progress" selected={status === "In Progress"}>In Progress</option>
                                      <option value="In Discussion" selected={status === "In Discussion"}>In Discussion</option>
                                      <option value="Reopen" selected={status === "Reopen"}>Reopen</option>
                                      <option value="closed" selected={status === "closed"}>Closed</option>
                                  </Form.Select>
                              </Form.Group> */}
            </div>
            {userRole?.userType === "SA" || userRole?.userType === "BDM" ?
              <Button variant='light' className="order-xl-3 text-nowrap rounded mt-1 mt-md-0 px-3 py-2" onClick={openAddIssueModal}>
                <i className="bi bi-plus-circle text-success me-1"></i> Submit Your Query
              </Button>
              : null}


          </div>
          <div className="info-content">
            <p>The Incentive Helpdesk is for BDMs (Business Delivery Managers) and SDMs (Service Delivery Managers) to raise any queries related to incentives.</p>
          </div>

          <div className="info-cards-wrapper row g-2 mt-2">
            <div className="col-sm-6 col-md-4 col-xl-4">
              <div className="info-card bg-primary bg-opacity-50 border-5 border-bottom border-primary rounded p-3">
                <span className="fs-2 fw-semibold text-white lh-1">{issueCounts.totalIssues}</span>
                <span className="text-light text-secondary ms-2">Total Queries</span>
              </div>
            </div>

            <div className="col-sm-6 col-md-4 col-xl-4 pointer" onClick={() => handleClick("answered")}>
              <div className="info-card bg-success bg-opacity-50 border-5 border-bottom border-success rounded p-3">
                <span className="fs-2 fw-semibold text-white lh-1">{issueCounts.openCount}</span>
                <span className="text-light text-secondary ms-2">Answered Queries</span>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-xl-4 pointer" onClick={() => handleClick("pending")}>
              <div className="info-card bg-info bg-opacity-50 border-5 border-bottom border-info rounded p-3">
                <span className="fs-2 fw-semibold text-white lh-1">{issueCounts.inPCount}</span>
                <span className="text-light text-secondary ms-2">Pending Queries</span>
              </div>
            </div>

          </div>
          <div className="wrapper mt-3">
            <div className="fs-6 fw-semibold text-warning mb-2">Important Notice for BDMs and SDMs:</div>



            <div className="fs-14 text-white text-opacity-75 mb-2">The Incentive Wallet system will be launching soon. Under the new "Pilot Incentive Policy October 2024," for conversions from 1st October 24 onward, there will be no need to raise a ticket in the Incentive Helpdesk to report your conversion numbers. Once the Wallet system is live, BDMs and SDMs will be able to manage their converted opportunities directly within the Wallet to claim their incentives easily.</div>



            <div className="fs-14 text-white text-opacity-75 mb-2">You will continue to receive updates on the Wallet's launch and detailed instructions on how to operate it.</div>


          </div>
        </div>

        <div className="custom-wrapper d-lg-flex gap-2">
          <div className="issues-menu-section list-inline d-flex flex-nowrap flex-lg-column gap-1 overflow-x-auto mb-3">
          <li className="nav-item">
              <NavLink to="#" className={`nav-link rounded-3 px-2 fs-14 text-truncate py-2 ${status === "ceoUpdate" ? 'active' : ''}`} onClick={() => handleClick("ceoUpdate")}><i className="bi bi-hourglass-split"></i> CEO's Update</NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="#" className={`nav-link rounded-3 px-2 fs-14 text-truncate py-2 ${status === "pending" ? 'active' : ''}`} onClick={() => handleClick("pending")}><i className="bi bi-hourglass-split"></i> Pending Queries</NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="#" className={`nav-link rounded-3 px-2 text-truncate fs-14 py-2 ${status === "answered" ? 'active' : ''}`} onClick={() => handleClick("answered")}><i className="bi bi-inboxes"></i> Answered Queries</NavLink>
            </li>

          </div>

          <div className="issues-details-section client-detail-wrapperr">
            {/* {issues && issues?.data?.length > 0? */}
            {status === "ceoUpdate"?
            <CeoListing items={ceoUpdate} userRole={userRole} status={status} currentItem={currentItem} setCurrentItem={setCurrentItem} user={user} getIssues={getIssues} setIsloading={setIsloading} setIssuesCount={setIssuesCount} active={active} setActive={setActive} />
            :
            <IssueListingCard items={issues} userRole={userRole} status={status} currentItem={currentItem} setCurrentItem={setCurrentItem} user={user} getIssues={getIssues} setIsloading={setIsloading} setIssuesCount={setIssuesCount} active={active} setActive={setActive} />
            }

            {/* :null} */}


          </div>
        </div>
      </div>

      {/* Add New Issue Modal */}
      <AddNewIssueModal show={showAddIssueModal} close={closeAddIssueModal} setIssues={setIssues} setShowAddIssueModal={setShowAddIssueModal} user={user} userRole={userRole} startDate={startDate} endDate={endDate} setCurrentItem={setCurrentItem} setStatusD={setStatusD} setIsloading={setIsloading} getIssues={getIssues} />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedinUser: (user) => dispatch(setUserInfo(user))
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(IssuesBdm);
