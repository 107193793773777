// useWebSocketHandler.js
import { useEffect, useState } from 'react';
import { useWebSocket } from './WebSocketContext';
import { toast } from 'react-toastify';
import { callAxios } from '../utils/utils';
import { environment } from '../environment';
import $ from "jquery";
import { log_ } from '../utils/pstn';
import { v4 as uuidv4 } from "uuid";

import UkDirectory from "../utils/Ukdirectory.json"
const date = new Date();
const year = date.getFullYear();
const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
const day = String(date.getDate()).padStart(2, '0');

const useAskPopUpHandler = (user,setUpdates,setCid,setshowTranscript,setCallConnected, setInprogressParticipants,setConnectedUser,setIsCalling, setIsDialpad,setFeedbackForm, setCallDetails,setShow, setisAskPopUpShow, leadOpportunityId,displayName,leadid, transcriptions, setTranscriptions,setCount,setInProgressConference,setParentSid,setShowCrmUpdate,setTranscriptUrl,setCurrentCallSic, setIsClientWasOnCall,listType,topic,setDialingBy, dialingby) => {

  const socket = useWebSocket();
  // setshowTranscript(true)
  let fullTranscript = [];
  const [_transcription, _settrancription] = useState([]);

  const incrementCount = () => {
    setCount(prevCount => ++prevCount); // Preincrementing count
  }; 

  const displayMessage__ = (data) => {
    // console.log("calling display method")
    // const displayNAMEIS = data.who === "inbound" && data.user_type === "CAM"? localStorage.getItem("userName")
    // : data.speaker === "outbound" && data.user_type === "BDM"?data.out_speaker
    // :data.out_speaker;
     const shortName = data.usertype === "BDM" || data.usertype === "Client"?data.speaker.charAt(0).toUpperCase():data.speaker.charAt(0).toUpperCase();

     
   
   
    // console.log(displayNAMEIS)d
    // const messagesList = document.getElementById('messages');
    //  <div class="msg-label text-capitalize text-muted small" style={{ marginBottom: '2px' }}>12-01-2023 01:43 PM</div>
    const newMessage = data.usertype === "BDM" || data.usertype === "Client"? `<div class="message-outside">
                <div class="box-wrapper mb-2 d-flex align-items-baseline gap-2">
                  <div class="user-pic position-relative" style="top:23px">
                    <div class="user-chip d-flex align-items-center justify-content-center text-uppercase bg-primary bg-opacity-10 fs-12 fw-semibold text-primary border border-primary rounded-circle" style=" height: 25px;width: 25px" title="inbound user">${shortName}</div>
                  </div>
                  <div class="message-card fs-12">
                   <div class="msg-label text-capitalize text-muted small text-start" style={{ marginBottom: '2px' }}>${data.speaker} [${data.usertype}]</div>
                    <div class="msg bg-secondary bg-opacity-10 text-light px-2 py-1 rounded">${data.incoming_message}</div>
                  </div>
                </div>
              </div>`
              
              :
               `<div class="message-myside">
                <div class="box-wrapper mb-2 d-flex flex-row-reverse align-items-baseline gap-2">
                  <div class="user-pic position-relative" style="top:23px">
                    <div class="user-chip d-flex align-items-center justify-content-center text-uppercase bg-primary bg-opacity-10 fs-12 fw-semibold text-primary border border-primary rounded-circle" style=" height: 25px;width: 25px" title="outbound user">${shortName}</div>
                  </div>
                  <div class="message-card fs-12">
                    <div class="msg-label text-capitalize text-muted small text-end" style={{ marginBottom: '2px' }}>${data.speaker} [${data.usertype}]</div>
                    <div class="msg bg-secondary bg-opacity-10 text-light px-2 py-1 rounded">${data.incoming_message}</div>
                  </div>
                </div>
              </div>` ;
    // newMessage.textContent = `${data.timestamp} [${data.event}]: ${data.message}`;
    // newMessage.classList.add(`event-${data.event}`);
    // messagesList.append(newMessage);
    $("#messages-outgoing-tt").append(newMessage)
    $('#messages-outgoing-tt').scrollTop($('#messages-outgoing-tt').prop("scrollHeight"))
  }

  const addOrUpdateLocalStorage = (update, storageValue) => {
    let inProgressParticipants;

    if (update.message.type === "client") {
      inProgressParticipants = {
        name: update.message.name,
        to: update.message.id,
        status: update.message.CallStatus,
        parentSid: update.message.parentSid,
        CallSid: update.message.CallSid,
        type: update.message.type,
        id: update.message.id,
        callerEmail: update.message.callerEmail,
        countryCode: update.message.countryCode
      }

    } else {
      inProgressParticipants = {
        name: update.message.displayName,
        to: update.message.To,
        status: update.message.CallStatus,
        parentSid: update.message.parentSid,
        CallSid: update.message.CallSid,
        type: update.message.type,
        id: update.message.id,
        callerEmail: update.message.callerEmail,
        countryCode: update.message.countryCode
      }
    }

    if (storageValue) {
      let oldData = JSON.parse(storageValue);
      let found = false;

      oldData = oldData.map(item => {
        if (item.CallSid === update.message.CallSid) {
          found = true;
          return {
            ...item,
            status: update.message.CallStatus
          };
        } else {
          return item;
        }
      });

      if (!found) {
        oldData.push(inProgressParticipants);
      }

      localStorage.setItem("conferenceObject", JSON.stringify(oldData));
    } else {
      localStorage.setItem("conferenceObject", JSON.stringify([inProgressParticipants]))
    }
  }

  useEffect(() => {
   
    if (!socket) return;

    socket.onmessage = async (event) => {
        setInProgressConference(true)
        const update = JSON.parse(event.data);
        // if(typeof update.message === 'undefined'){
        //   setShow(false)
        // }
        
        if (update.event === 'clearTranscript' && update.type === "outgoingCall" && update.usertype === "CAM" && update.useremail === user?.email) {
          // Clear the fullTranscript array for a new call
          fullTranscript = [];
          setTranscriptions([]);
          return; // Exit the handler to avoid processing the 'clearTranscript' event further
      }
        if(update.event === 'finalTranscript' && update.type === "outgoingCall" && update.useremail === user?.email){
          

          const messageObject = {
            speaker: update.speaker,
            message: update.incoming_message,
            timestamp: update.timestamp,
            callerEmail:update.useremail,
            user_type:update.usertype
        };
        
        fullTranscript.push(messageObject);
        setTranscriptions([...fullTranscript]);
        _settrancription([...fullTranscript])
        // displayMessage__(update);
          
        }
        else{
           if (update.message.CallStatus === "completed" && update.message.Direction === "inbound" && update.message.Caller === "client:" + user?.email) {
          setShow(false)
          setisAskPopUpShow(false)
        //   resetWebSocket()

        }

        incrementCount()
        setUpdates(update.message);
        if (update.message.From === "client:" + user?.email) {
          localStorage.setItem("conferenceObject", [])
          setCid(update.message.CallSid)
        }
        if (update.message.callerEmail === user?.email) {
            // console.log(update.message)
           setCurrentCallSic(update.message.parentSid)
          //  const PPSid = update.message.parentSid?update.message.parentSid:update.message.CallSid;
          const apiPhoneCallData = {
            callSid: update.message.parentSid ,
            call_number:update.message.Caller,
            call_country:update.message.CallerCountry,
            type:"add",
            total_participants_in_calls:1,
            crm_id:leadOpportunityId,
            crm_title:topic,
            nm_system_is_this_what:listType==="opportunity"?1:2,
            user_type_who_make_the_call:1,
            ownerid:user?.uid
          }
          const apiCallParticipantData = {
            callSid: update.message.CallSid,
            parentSid:update.message.parentSid,
            uid:user?.uid
          }

          callAxios(environment.REACT_APP_API_URL_Live+'api/v2/crm/save-crm-update',apiPhoneCallData,"post").then(async(dataToInsert)=>{
            await callAxios(environment.REACT_APP_API_URL_Live+'api/v2/crm/save-call-participants',apiCallParticipantData,"post")
          })
          
          if (update.message.type === "client") {
            await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs", { formId: leadOpportunityId, parent_sid: update.message.parentSid, call_sid: update.message.CallSid, identity: update.message.CallStatus, type: update.message.CallStatus, caller: displayName, clientName: update.message.name, number: update.message.To }, "post")
            setParentSid(update.message.parentSid)
          } else {
            await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs", { formId: leadOpportunityId, parent_sid: update.message.parentSid, call_sid: update.message.CallSid, identity: update.message.CallStatus, type: update.message.CallStatus, caller: displayName, clientName: update.message.displayName, number: update.message.To }, "post")
            setParentSid(update.message.parentSid)
          }


          if (update.message.CallStatus === "in-progress" && update.message.SequenceNumber > 1) {
            const cid = $("#button-call-" + leadOpportunityId).attr('data-cid');
            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/update-before-call", { action: true, cid: update.message.parentSid, type: "group" }, "post")


            // setAddMoreIcon(true);
            // $("#dial-pad-"+leadid).removeClass("d-none")

          }


          if (update.message.CallStatus === "in-progress" && update.message.type === "client") {
            // console.log(update)
            
            const CountryCode = update.message.countryCode.replace(" ","+");
            const country = UkDirectory.find(code => code.isd_code === CountryCode);

            if (country) {
              setDialingBy("Dialed By UK Number..")
            } else {
              setDialingBy("Dialed By US Number..")
            }
           
            setshowTranscript(true)
            setIsClientWasOnCall(true)
            setTranscriptUrl(`https://nm-pstn-call-files.s3.ap-south-1.amazonaws.com/Realtime-transcription/${year}/${month}/${day}/${update.message.parentSid}.json`)
            addOrUpdateLocalStorage(update, localStorage.getItem('conferenceObject'))
            incrementCount()
            const cid = $("#button-call-" + leadOpportunityId).attr('data-cid');
            $("#button-call-" + leadOpportunityId).attr("data-whoDialed", "client");
            // await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio-voice/start-stream", { callSid: update.message.CallSid, url: environment.SOCKET_URL+"ws/"+uuidv4(), callerEmail: update.message.callerEmail, utype: "Client", type: "outgoingCall", speaker: update.message.name, psid:update.message.parentSid }, "post")
            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/update-before-call", { action: true, cid: update.message.parentSid, type: "clientModel" }, "post")

           
            setCallConnected(true);
            $("#in-progressPop").show()
            // setAddMoreIcon(true);
            // $("#dial-pad-"+leadid).removeClass("d-none")

          }

          if (update.message.CallStatus === "in-progress" && update.message.type === "VEMember") {
            // console.log(update)
            setshowTranscript(true)
            addOrUpdateLocalStorage(update, localStorage.getItem('conferenceObject'))
            incrementCount()
            // localStorage.setItem("conferenceObject",JSON.stringify([...updates,update.message]))
            setInprogressParticipants(update.message)
            const getCallDetails = await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/get-data-by-cid", { action: true, cid: update.message.parentSid }, "post")
            // await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio-voice/start-stream", { callSid: update.message.CallSid, url: environment.SOCKET_URL+"ws/"+uuidv4(), callerEmail: update.message.callerEmail, utype: "BDM", type: "outgoingCall", speaker: update.message.displayName,psid:update.message.parentSid }, "post")
           

            // console.log(getCallDetails)
            if (getCallDetails.data.callDetails.is_client_modal_open === 0) {
              // setShow(true)
              setConnectedUser(true)
              //  setInProgressConference(false)
              setCallConnected(true);
              $("#dial-pad-" + leadid).css("display", "none")
              $("#in-progressPop").hide()
            } else {
              // $("#in-progressPop").show()
            }
            // else{
            //   setShow(false)
            // }

            // setModalShow(false)
          }

          if (update.message.CallStatus === "initiated") {
            setIsCalling(true)
            setIsDialpad(false)
            addOrUpdateLocalStorage(update, localStorage.getItem('conferenceObject'))
            incrementCount()

            callAxios(environment.REACT_APP_API_URL_Live+"api/v2/crm/save-crm-update",{type:"update",statuscode:1,callSid:update.message.parentSid},"post")
          }

          if (update.message.CallStatus === "ringing") {
            addOrUpdateLocalStorage(update, localStorage.getItem('conferenceObject'))
            incrementCount()
            
          }

          if (update.message.CallStatus === "completed") {
            // resetWebSocket()
            addOrUpdateLocalStorage(update, localStorage.getItem('conferenceObject'))
            incrementCount()
            // setCurrentCallSic("")
            callAxios(environment.REACT_APP_API_URL_Live+"api/v2/crm/save-crm-update",{type:"update",statuscode:9,callSid:update.message.parentSid},"post")

            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/update-conference-status", { conferenceName: update.message.parentSid, type: "outbound" }, "post").then(async (res) => {
              //  console.log(res.data)
              if (res.data.totalParticipants < 2) {
                // console.log("in less 2 participatn")
                setShow(false)
               
                const getCallDetails = await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/get-data-by-cid", { action: true, cid: update.message.parentSid }, "post")
                // console.log(getCallDetails.data.callDetails)
                // callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/upload-incoming-call-transcript",{callSid:update.message.parentSid,fullTranscript:fullTranscript,type:"outboundcall"},"post").then((uploadRes)=>{
                //   if(uploadRes.data.status === 200){
                //     setTranscriptions([])
                //     _settrancription([])
                //   }
                  
                // })
                if (getCallDetails.data.callDetails.is_client_joined) {
                  // console.log("client joined:::",getCallDetails.data.callDetails.is_client_joined)
                  // setFeedbackForm(true)
                  setShowCrmUpdate(true)
                  setCallDetails(getCallDetails.data.callDetails)

                }
              }
            })
          }

          // if(update.message.CallStatus === "completed" && update.message.Direction === "outbound-api"){

          //   // console.log(update.message)
          //       setShow(false)
          //       const getCallDetails = await callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/get-data-by-cid",{ action:true, cid:update.message.parentSid},"post")
          //       //  console.log(getCallDetails.data.callDetails)
          //       if(getCallDetails.data.callDetails.is_client_joined === 1){
          //         //  console.log("client joined:::",getCallDetails.data.callDetails.is_client_joined)

          //         setCallDetails(getCallDetails.data.callDetails)
          //         render (<AfterCallFeedback showFeedback={true} callInfo={callDetails} setFeedbackForm={setFeedbackForm} setisAskPopUpShow={setisAskPopUpShow} /> )
          //       }
          // }

          if (update.message.CallStatus === "no-answer") {

            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/update-before-call", { action: true, cid: update.message.parentSid, type: "updateStatus", status: update.message.CallStatus }, "post")
            addOrUpdateLocalStorage(update, localStorage.getItem('conferenceObject'))
            incrementCount()

            callAxios(environment.REACT_APP_API_URL_Live+"api/v2/crm/save-crm-update",{type:"update",statuscode:11,callSid:update.message.parentSid},"post")

            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/update-conference-status", { conferenceName: update.message.parentSid, type: "outbound" }, "post").then((res) => {
              // console.log(res.data)
              if (res.data.totalParticipants < 2) {
                setShow(false)
              }
            })
          }
          if (update.message.CallStatus === "failed") {
            //  console.log("in failed")
            setShow(false)
            callAxios(environment.REACT_APP_API_URL_Live+"api/v2/crm/save-crm-update",{type:"update",statuscode:8,callSid:update.message.parentSid},"post")

            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/update-before-call", { action: true, cid: update.message.parentSid, type: "updateStatus", status: update.message.CallStatus }, "post")

            addOrUpdateLocalStorage(update, localStorage.getItem('conferenceObject'))
            incrementCount()
            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/update-conference-status", { conferenceName: update.message.parentSid, type: "outbound" }, "post").then((res) => {
              // console.log(res.data)
              if (res.data.totalParticipants === 0) {
                // console.log("in failed")
                setShow(false)
                // setInProgressConference(false)
              }
            })
          }
          if (update.message.CallStatus === "busy") {

            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/update-before-call", { action: true, cid: update.message.parentSid, type: "updateStatus", status: update.message.CallStatus }, "post")
            addOrUpdateLocalStorage(update, localStorage.getItem('conferenceObject'))
            incrementCount()
            callAxios(environment.REACT_APP_API_URL_Live+"api/v2/crm/save-crm-update",{type:"update",statuscode:10,callSid:update.message.parentSid},"post")

            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/update-conference-status", { conferenceName: update.message.parentSid, type: "outbound" }, "post").then((res) => {
              // console.log(res.data)
              if (res.data.totalParticipants < 2) {
                setShow(false)
              }
            })
          }
          if (update.message.CallStatus === "canceled") {

            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/update-before-call", { action: true, cid: update.message.parentSid, type: "updateStatus", status: update.message.CallStatus }, "post")
            addOrUpdateLocalStorage(update, localStorage.getItem('conferenceObject'))
            incrementCount()
            callAxios(environment.REACT_APP_API_URL_Live+"api/v2/crm/save-crm-update",{type:"update",statuscode:7,callSid:update.message.parentSid},"post")

            callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/update-conference-status", { conferenceName: update.message.parentSid, type: "outbound" }, "post").then((res) => {
              // console.log(res.data)
              if (res.data.totalParticipants < 2) {
                setShow(false)
              }
            })
          }

           if(update.type !== "outgoing" || update.type !== "incoming" ){
            setUpdates(update.message);
            if (update.message.callerEmail === user?.email) {
              if (update.message.From.includes("client:")) {
                log_(update.message.CallStatus)
              } else if (update.message.type === "client") {
                log_(update.message.name + " " + update.message.CallStatus + "....")
              }
              else {
                log_(update.message.displayName + " " + update.message.CallStatus + "....")
              }
            }
          }
        }
        }
       

      //  console.log(fullTranscript)
      };

    return () => {
      socket.onmessage = null;
    };
  }, [socket, user?.email]);

//   return ("transcriptions");
};

export default useAskPopUpHandler;
