import { Button, Card, useAccordionButton } from "react-bootstrap"
import { formatShiftTime, new_ClientType, new_EmploymentTenure, new_opportunitytype, new_servicetype, new_servicetype_mapping, nm__clienttype, nm_clienttype, nm_invoicefrom, nm_paymentstatus, nm_servicemonth, nm_servicestatus, statuscode } from "../../utils/codes"
import { callAxios, convertReadable } from "../../utils/utils"
import { environment } from "../../environment"
import { useEffect, useState } from "react"
import { RedeemAmountModal } from "../RedeemAmountModal"
import { IncentivePolicy } from "../../utils/IncentivePolicy"
import { DeleteConfirmModal } from "../DeleteConfirmModal"
import Accordion from 'react-bootstrap/Accordion';


// function CustomToggle({ children, eventKey }) {
//     const decoratedOnClick = useAccordionButton(eventKey, () =>
//         console.log('totally custom!', children, eventKey),
//     );

//     return (
//         <div className="custom-bg rounded-3" onClick={decoratedOnClick}>
//             {children}
//         </div>
//     );
// }

function CustomToggle({ children, eventKey, onClick }) {
    const decoratedOnClick = useAccordionButton(eventKey, onClick);

    return (
        <div className="custom-bg rounded-3" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}

export const Inprogress = ({key,opInfo,userRole, loadWallet, setIsLoading,opRealTime,index, setActiveKey, activeKey}) =>{
// console.log(index,key)
// console.log(opRealTime)
 // Redeem amount modal
 const handleToggle = (eventKey) => {
    // Toggle the activeKey state, setting it to null if it's the same eventKey
    setActiveKey(activeKey === eventKey ? null : eventKey);
};

 const [showRedeemAmountModal, setShowRedeemAmountModal] = useState(false);
 const closeRedeemAmountModal = () => setShowRedeemAmountModal(false);
 const openRedeemAmountModal = () => setShowRedeemAmountModal(true);

const [redeemAmount, setRedeemAmount] = useState(0);
const [whichMonthRedeem, setWhichMonth] = useState("");
const [invoiceHtml, setInvoiceHtml] = useState([]);

const [showDeleteModal, setShowDeleteModal] = useState(false);
const closeDeleteModal = () => setShowDeleteModal(false);
const openDeleteModal = () => setShowDeleteModal(true);

const [deleteInfo, setDeleteInfo] = useState({})



useEffect(() => {
    if (opInfo.serviceInfo.length >0 && opInfo.groupedData && Object.entries(opInfo.groupedData).length > 0 && opInfo.cardType === "individual") {
        // console.log(opInfo)
      // Mapping over serviceInfo and making API calls
      const promises = Object.entries(opInfo.groupedData).map((item,index ) => {
       
        // console.log(opInfo.wallet_opportunities.bonus.clienttype)
        const IncentiveType = opInfo.wallet_opportunities.bonus.clienttype.includes(item[1][0].nm_clienttype)?"yes":"no";
        
        // console.log(item[1][0].nm_clienttype)

        const result = item[1].reduce(
            (acc, item_) => {
                if (item_.nm_servicestatus === 124950000) {
                    acc.totalHours += item_.nm_hoursbasedonreceivedpayment;
                    acc.clientTypes.push(nm__clienttype[item_.nm_clienttype]);
                }
                return acc;
            },
            { totalHours: 0, clientTypes: [] }
        );

        const totalHours = result.totalHours;

        const clientType_ = result.clientTypes;

        const istotalHoursLessThanPolicy = totalHours < opInfo.wallet_opportunities.bonus.hours ?"yes":"no";
        // Make API call for each item
        let incentiveAmount = 0;
        let indexTitle;
        if(index === 0){
        incentiveAmount = opInfo.wallet_opportunities.bonus.immediate_bonus;
            indexTitle = "1st";
        }
        if(index === 1){
            incentiveAmount = opInfo.wallet_opportunities.bonus.first_month;
                indexTitle = "2nd";
            }
        if(index === 2){
            incentiveAmount = opInfo.wallet_opportunities.bonus.second_month ;
            indexTitle = "3rd";
        }
        if(index === 3){
            incentiveAmount = opInfo.wallet_opportunities.bonus.third_month;
            indexTitle = "4th";
        }
         
          // Return JSX for each item to render
        //   if(incentiveAmount > 0){
            return (
                <>
                  <hr className="divider border border-top-2 border-secondary border-opacity-75 mt-4 mb-3" />
                  <div className="description">
                  {/* {opInfo.wallet_opportunities.is_lost?
                    <div className="description">
                    <div className="total-insentive d-flex flex-wrap row-gap-1 column-gap-3 lh-sm mb-1">
                        <span className="fs-5 fw-semibold text-danger text-decoration-line-through">Total Insentive: ₹{incentiveAmount}</span>
                        <span className="fs-18 fw-semibold text-white text-opacity-50 text-capitalize">Insentive Type: {opInfo.wallet_opportunities.bonus.scenario } ({opInfo.wallet_opportunities.bonus.client_type})</span>
                    </div>
                    <div className="fs-12 text-white text-opacity-75">Opportunity Lost: You've missed the chance to earn your incentive.</div>
                </div>
                  :
                                <div className="total-insentive d-flex flex-wrap row-gap-1 column-gap-3 lh-sm mb-3">
                                    <span className="fs-5 fw-semibold text-white">Total Insentive: ₹{incentiveAmount}</span>
                                    <span className="fs-18 fw-semibold text-white text-opacity-50 text-capitalize">Insentive Type: {opInfo.wallet_opportunities.bonus.scenario } ({opInfo.wallet_opportunities.bonus.client_type})</span>
                                </div>
          } */}
                                <div className="installment-wrapper d-flex flex-column gap-2">
                                    {/* Installment Info awaiting management */}
                                                         
                                    <div className="installment-info custom-bg rounded-3 py-3" style={{backgroundColor:'var(--custom-bg)'}}>
                                        <div className="installment-info mb-3">
                                            <div className="label fs-18 text-white">{indexTitle} Installment of Incentive</div>
                                            <div className="value fs-12 text-white text-opacity-75">
                                                The redeem button will be enabled once the client is won, payment is received,
                                                billing is assigned to the Billing Manager and SDM (Service Lead) on NM-Apps,
                                                and the resource has started work on the billing.
                                            </div>
                                        </div>
                                        <div className="row gx-2 gy-3">
                                            <div className="col-12 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Billing Manager</div>
                                                <div className="value text-white text-opacity-75 text-capitalize">{item[1][0]?.manager?.length>0?item[1][0]?.manager:"-"}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Delivery Manager <sup>2</sup></div>
                                                <div className="value text-white text-opacity-75">{item[1][0]?.lead?.length>0?item[1][0]?.lead:"-"}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Front Face on Billing <sup>1</sup></div>
                                                <div className="value text-white text-opacity-75">{item[1][0]?.frontFace?.length>0?item[1][0].frontFace:"-"}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Type</div>
                                                <div className="value text-white text-opacity-75">{new_servicetype_mapping[item[1][0].nm_servicetype]}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Month</div>
                                                <div className="value text-white text-opacity-75">{nm_servicemonth[item[1][0].nm_servicemonth]}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Client ID</div>
                                                <div className="value text-white text-opacity-75">{item[1][0].nm_zohoclientid}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Client Type</div>
                                                {/* <div className="value text-white text-opacity-75">{nm__clienttype[item[1][0].nm_clienttype]}</div> */}
                                                <div className="value text-white text-opacity-75">
                                                    {clientType_.map((cl)=>(
                                                       <p className="mb-0">{cl}</p>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Status</div>
                                                <div className="value" style={{ color: '#03ff85' }}>{nm_servicestatus[item[1][0].nm_servicestatus]}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Invoice ID</div>
                                                <div className="value text-white text-opacity-75">{item[1][0].nm_zohoinvoiceid}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Invoice From</div>
                                                <div className="value text-white text-opacity-75">{nm_invoicefrom[item[1][0].nm_invoicefrom]}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Hour based on Receive Payment</div>
                                                <div className="value text-white text-opacity-75">{totalHours}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Payment Status</div>
                                                <div className="value text-white text-opacity-75">{nm_paymentstatus[item[1][0].nm_paymentstatus]}</div>
                                            </div>
                                            {item[1][0].nm_paymentreceiveddate?
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Payment Receive Date</div>
                                                <div className="value text-white text-opacity-75">{convertReadable(item[1][0].nm_paymentreceiveddate)}</div>
                                            </div>
                                            :null}
                                        </div>
                                        <div className="row gx-2 gy-3 mt-1">
                                            <div className="col-lg-8">
                                                <div className="fs-12 text-white text-opacity-75 mb-2">1. "Front Face" means the client is familiar with them. If it's different from the billing resource, it means the billing resource is acting as their proxy.</div>
                                                <div className="fs-12 text-white text-opacity-75">2. The Service Delivery Manager (SDM) is responsible for managing billing hours with the client and coordinating with the working resource to ensure smooth delivery of billing hours to the client.</div>
                                            </div>

                                           
                                            <div className="col-lg-4 text-lg-end">
                                            {opInfo.wallet_opportunities.is_billing_start_before_oct_24?
                                        <Button className="bg-transparent bord  er border-danger text-center rounded-1 px-4 py-1" style={{minWidth:'240px'}}>
                                        <span className="d-block fs-5 fw-semibold text-white">Not eligible for redeem</span>
                                        <span className="d-block fs-12 text-white text-opacity-75">Incentive policy effected from October 2024</span>
                                    </Button>
                                            :item[1][0].nm_paymentstatus === 124950002?
                                                
                                                
                                            <Button className="bg-transparent border border-white text-center rounded-1 px-4 py-1" style={{minWidth:'240px'}}>
                                                <span className="d-block fs-5 fw-semibold text-white">Redeem ₹{incentiveAmount}</span>
                                                <span className="d-block fs-12 text-white text-opacity-75">Awaiting payment from the client.</span>
                                            </Button>

:istotalHoursLessThanPolicy === "yes"?
<Button className="bg-transparent bord  er border-danger text-center rounded-1 px-4 py-1" style={{minWidth:'240px'}}>
<span className="d-block fs-5 fw-semibold text-white">Not eligible for redeem</span>
<span className="d-block fs-12 text-white text-opacity-75">total hours are less then incentive policy</span>
</Button>
                                            
                                            :item[1][0].nm_paymentstatus === 124950000 && opInfo.walletTrasaction && !opInfo.walletTrasaction.is_redeemption_approved_by_manager ?
                                            <Button className="bg-transparent bord  er border-warning text-center rounded-1 px-4 py-1" style={{minWidth:'240px'}}>
                                            <span className="d-block fs-5 fw-semibold text-white">Redeem ₹{incentiveAmount}</span>
                                            <span className="d-block fs-12 text-white text-opacity-75">Awaiting management approval.</span>
                                        </Button>
                                        
                                                
                                                : item[1][0].nm_paymentstatus === 124950000 && opInfo.walletTrasaction.is_redeemption_approved_by_manager && opInfo.walletTrasaction.redeem_amount > 0?
                                                <Button variant="info" className="text-center rounded-1 px-4 py-1" style={{minWidth:'240px'}}>
                                                    <span className="d-block fs-5 fw-semibold text-white">Redeemed ₹{incentiveAmount}</span>
                                                    <span className="d-block fs-12 text-white text-opacity-75">Awaiting credit from Accounts</span>
                                                </Button>
                                                : item[1][0].nm_paymentstatus === 124950000 && opInfo.walletTrasaction.is_redeemption_approved_by_manager?
                                                <Button variant="success" className="text-center rounded-1 px-4 py-1" style={{minWidth:'240px'}} >
                                                    <span className="d-block fs-5 fw-semibold text-white">Redeem ₹{incentiveAmount}</span>
                                                    <span className="d-block fs-12 text-white text-opacity-75">You can redeem now</span>
                                                </Button>
                                                :null
                                                
                                              }
                                            </div>
      
                                            
                                           
                                        </div>
                                    </div>

                                  
                                    {/* <div className="installment-info custom-bg rounded-3 py-3" style={{backgroundColor:'var(--custom-bg)'}}>
                                        <div className="installment-info mb-3">
                                            <div className="label fs-18 text-white">1st Installment of Incentive</div>
                                            <div className="value fs-12 text-white text-opacity-75">
                                                The redeem button will be enabled once the client is won, payment is received,
                                                billing is assigned to the Billing Manager and SDM (Service Lead) on NM-Apps,
                                                and the resource has started work on the billing.
                                            </div>
                                        </div>
                                        <div className="row gx-2 gy-3">
                                            <div className="col-12 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Billing Manager</div>
                                                <div className="value text-white text-opacity-75 text-capitalize">Atul Kumar</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Delivery Manager <sup>2</sup></div>
                                                <div className="value text-white text-opacity-75">Sahil Kumar</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Front Face on Billing <sup>1</sup></div>
                                                <div className="value text-white text-opacity-75">Sahil Kumar</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Type</div>
                                                <div className="value text-white text-opacity-75">Team Model</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Month</div>
                                                <div className="value text-white text-opacity-75">October 2024</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Client ID</div>
                                                <div className="value text-white text-opacity-75">ABC12345</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Client Type</div>
                                                <div className="value text-white text-opacity-75">New Full-Time</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Status</div>
                                                <div className="value" style={{ color: '#03ff85' }}>Continue</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Invoice ID</div>
                                                <div className="value text-white text-opacity-75">VE/2024/Sep-2022</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Invoice From</div>
                                                <div className="value text-white text-opacity-75">TV</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Hour based on Receive Payment</div>
                                                <div className="value text-white text-opacity-75">176</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Payment Status</div>
                                                <div className="value text-white text-opacity-75">Received</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Payment Receive Status</div>
                                                <div className="value text-white text-opacity-75">10-08-2024</div>
                                            </div>
                                        </div>
                                        <div className="row gx-2 gy-3 mt-1">
                                            <div className="col-12">
                                                <div className="fs-12 text-white text-opacity-75 mb-2">1. "Front Face" means the client is familiar with them. If it's different from the billing resource, it means the billing resource is acting as their proxy.</div>
                                                <div className="fs-12 text-white text-opacity-75">2. The Service Delivery Manager (SDM) is responsible for managing billing hours with the client and coordinating with the working resource to ensure smooth delivery of billing hours to the client.</div>
                                            </div>
                                            
                                        </div>
                                        <div className="row gx-2 gy-3 mt-1">
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-5 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Redeemed Incentive</div>
                                                <div className="value text-white text-opacity-75 text-capitalize">₹5,000 was redeemed on 11-11-2024</div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Your Incentive</div>
                                                <div className="value text-white text-opacity-75">₹3,000</div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-4 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Supported BDM Incentive</div>
                                                <div className="value text-white text-opacity-75">₹2,000</div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 offset-lg-5 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Credit Transaction Details</div>
                                                <div className="value fs-14 text-white text-opacity-75 text-break">ABCDSJIFJIDFJ23234 <span className="d-block">12-11-2024</span></div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-4 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Credit Transaction Details</div>
                                                <div className="value fs-14 text-white text-opacity-75 text-break">ABCDSJIFJIDFJ23234 <span className="d-block">12-11-2024</span></div>
                                            </div>
                                        </div>
                                    </div> */}
                                  
                                </div>
                            </div>
                </>
              );
        //   }
          
       
      });

      // Once all promises are resolved, set the invoiceHtml
      Promise.all(promises).then(setInvoiceHtml);
    } 

    if (opInfo.serviceInfo.length >0 && opInfo.groupedData && Object.entries(opInfo.groupedData).length > 0 &&  opInfo.cardType ==="beforeOct24") {
        // console.log(opInfo)
      // Mapping over serviceInfo and making API calls
      const promises = Object.entries(opInfo.groupedData).map((item,index ) => {
       
        // console.log(opInfo.wallet_opportunities.bonus.clienttype)
        const IncentiveType = opInfo.wallet_opportunities.bonus.clienttype.includes(item[1][0].nm_clienttype)?"yes":"no";
        
        // console.log(item[1][0].nm_clienttype)

        const result = item[1].reduce(
            (acc, item_) => {
                if (item_.nm_servicestatus === 124950000) {
                    acc.totalHours += item_.nm_hoursbasedonreceivedpayment;
                    acc.clientTypes.push(nm__clienttype[item_.nm_clienttype]);
                }
                return acc;
            },
            { totalHours: 0, clientTypes: [] }
        );

        const totalHours = result.totalHours;

        const clientType_ = result.clientTypes;

        const istotalHoursLessThanPolicy = totalHours < opInfo.wallet_opportunities.bonus.hours ?"yes":"no";
        // Make API call for each item
        let incentiveAmount = 0;
        let indexTitle;
        if(index === 0){
        incentiveAmount = opInfo.wallet_opportunities.bonus.immediate_bonus;
            indexTitle = "1st";
        }
        if(index === 1){
            incentiveAmount = opInfo.wallet_opportunities.bonus.first_month;
                indexTitle = "2nd";
            }
        if(index === 2){
            incentiveAmount = opInfo.wallet_opportunities.bonus.second_month ;
            indexTitle = "3rd";
        }
        if(index === 3){
            incentiveAmount = opInfo.wallet_opportunities.bonus.third_month;
            indexTitle = "4th";
        }
         
          // Return JSX for each item to render
        //   if(incentiveAmount > 0){
            return (
                <>
                  <hr className="divider border border-top-2 border-secondary border-opacity-75 mt-4 mb-3" />
                  <div className="description">
                  {/* {opInfo.wallet_opportunities.is_lost?
                    <div className="description">
                    <div className="total-insentive d-flex flex-wrap row-gap-1 column-gap-3 lh-sm mb-1">
                        <span className="fs-5 fw-semibold text-danger text-decoration-line-through">Total Insentive: ₹{incentiveAmount}</span>
                        <span className="fs-18 fw-semibold text-white text-opacity-50 text-capitalize">Insentive Type: {opInfo.wallet_opportunities.bonus.scenario } ({opInfo.wallet_opportunities.bonus.client_type})</span>
                    </div>
                    <div className="fs-12 text-white text-opacity-75">Opportunity Lost: You've missed the chance to earn your incentive.</div>
                </div>
                  :
                                <div className="total-insentive d-flex flex-wrap row-gap-1 column-gap-3 lh-sm mb-3">
                                    <span className="fs-5 fw-semibold text-white">Total Insentive: ₹{incentiveAmount}</span>
                                    <span className="fs-18 fw-semibold text-white text-opacity-50 text-capitalize">Insentive Type: {opInfo.wallet_opportunities.bonus.scenario } ({opInfo.wallet_opportunities.bonus.client_type})</span>
                                </div>
          } */}
                                <div className="installment-wrapper d-flex flex-column gap-2">
                                    {/* Installment Info awaiting management */}
                                                         
                                    <div className="installment-info custom-bg rounded-3 py-3" style={{backgroundColor:'var(--custom-bg)'}}>
                                        <div className="installment-info mb-3">
                                            <div className="label fs-18 text-white">{indexTitle} Installment of Incentive</div>
                                            <div className="value fs-12 text-white text-opacity-75">
                                                The redeem button will be enabled once the client is won, payment is received,
                                                billing is assigned to the Billing Manager and SDM (Service Lead) on NM-Apps,
                                                and the resource has started work on the billing.
                                            </div>
                                        </div>
                                        <div className="row gx-2 gy-3">
                                            <div className="col-12 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Billing Manager</div>
                                                <div className="value text-white text-opacity-75 text-capitalize">{item[1][0]?.manager?.length>0?item[1][0]?.manager:"-"}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Delivery Manager <sup>2</sup></div>
                                                <div className="value text-white text-opacity-75">{item[1][0]?.lead?.length>0?item[1][0]?.lead:"-"}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Front Face on Billing <sup>1</sup></div>
                                                <div className="value text-white text-opacity-75">{item[1][0]?.frontFace?.length>0?item[1][0].frontFace:"-"}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Type</div>
                                                <div className="value text-white text-opacity-75">{new_servicetype_mapping[item[1][0].nm_servicetype]}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Month</div>
                                                <div className="value text-white text-opacity-75">{nm_servicemonth[item[1][0].nm_servicemonth]}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Client ID</div>
                                                <div className="value text-white text-opacity-75">{item[1][0].nm_zohoclientid}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Client Type</div>
                                                {/* <div className="value text-white text-opacity-75">{nm__clienttype[item[1][0].nm_clienttype]}</div> */}
                                                <div className="value text-white text-opacity-75">
                                                    {clientType_.map((cl)=>(
                                                       <p className="mb-0">{cl}</p>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Status</div>
                                                <div className="value" style={{ color: '#03ff85' }}>{nm_servicestatus[item[1][0].nm_servicestatus]}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Invoice ID</div>
                                                <div className="value text-white text-opacity-75">{item[1][0].nm_zohoinvoiceid}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Invoice From</div>
                                                <div className="value text-white text-opacity-75">{nm_invoicefrom[item[1][0].nm_invoicefrom]}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Hour based on Receive Payment</div>
                                                <div className="value text-white text-opacity-75">{totalHours}</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Payment Status</div>
                                                <div className="value text-white text-opacity-75">{nm_paymentstatus[item[1][0].nm_paymentstatus]}</div>
                                            </div>
                                            {item[1][0].nm_paymentreceiveddate?
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Payment Receive Date</div>
                                                <div className="value text-white text-opacity-75">{convertReadable(item[1][0].nm_paymentreceiveddate)}</div>
                                            </div>
                                            :null}
                                        </div>
                                        <div className="row gx-2 gy-3 mt-1">
                                            <div className="col-lg-8">
                                                <div className="fs-12 text-white text-opacity-75 mb-2">1. "Front Face" means the client is familiar with them. If it's different from the billing resource, it means the billing resource is acting as their proxy.</div>
                                                <div className="fs-12 text-white text-opacity-75">2. The Service Delivery Manager (SDM) is responsible for managing billing hours with the client and coordinating with the working resource to ensure smooth delivery of billing hours to the client.</div>
                                            </div>

                                          
                                            <div className="col-lg-4 text-lg-end">
                                            <Button className="bg-transparent bord  er border-danger text-center rounded-1 px-4 py-1" style={{minWidth:'240px'}}>
<span className="d-block fs-5 fw-semibold text-white">Not eligible for redeem</span>
<span className="d-block fs-12 text-white text-opacity-75">Only opportunities won and billed after Oct 1, 2024, can be added. Opportunity invoice first payment before this date cannot be included.</span>
</Button></div>
      
                                            
                                           
                                        </div>
                                    </div>

                                  
                                    {/* <div className="installment-info custom-bg rounded-3 py-3" style={{backgroundColor:'var(--custom-bg)'}}>
                                        <div className="installment-info mb-3">
                                            <div className="label fs-18 text-white">1st Installment of Incentive</div>
                                            <div className="value fs-12 text-white text-opacity-75">
                                                The redeem button will be enabled once the client is won, payment is received,
                                                billing is assigned to the Billing Manager and SDM (Service Lead) on NM-Apps,
                                                and the resource has started work on the billing.
                                            </div>
                                        </div>
                                        <div className="row gx-2 gy-3">
                                            <div className="col-12 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Billing Manager</div>
                                                <div className="value text-white text-opacity-75 text-capitalize">Atul Kumar</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Delivery Manager <sup>2</sup></div>
                                                <div className="value text-white text-opacity-75">Sahil Kumar</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Front Face on Billing <sup>1</sup></div>
                                                <div className="value text-white text-opacity-75">Sahil Kumar</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Type</div>
                                                <div className="value text-white text-opacity-75">Team Model</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Month</div>
                                                <div className="value text-white text-opacity-75">October 2024</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Client ID</div>
                                                <div className="value text-white text-opacity-75">ABC12345</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Client Type</div>
                                                <div className="value text-white text-opacity-75">New Full-Time</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Status</div>
                                                <div className="value" style={{ color: '#03ff85' }}>Continue</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Invoice ID</div>
                                                <div className="value text-white text-opacity-75">VE/2024/Sep-2022</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Invoice From</div>
                                                <div className="value text-white text-opacity-75">TV</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Hour based on Receive Payment</div>
                                                <div className="value text-white text-opacity-75">176</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Payment Status</div>
                                                <div className="value text-white text-opacity-75">Received</div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Payment Receive Status</div>
                                                <div className="value text-white text-opacity-75">10-08-2024</div>
                                            </div>
                                        </div>
                                        <div className="row gx-2 gy-3 mt-1">
                                            <div className="col-12">
                                                <div className="fs-12 text-white text-opacity-75 mb-2">1. "Front Face" means the client is familiar with them. If it's different from the billing resource, it means the billing resource is acting as their proxy.</div>
                                                <div className="fs-12 text-white text-opacity-75">2. The Service Delivery Manager (SDM) is responsible for managing billing hours with the client and coordinating with the working resource to ensure smooth delivery of billing hours to the client.</div>
                                            </div>
                                            
                                        </div>
                                        <div className="row gx-2 gy-3 mt-1">
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-5 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Redeemed Incentive</div>
                                                <div className="value text-white text-opacity-75 text-capitalize">₹5,000 was redeemed on 11-11-2024</div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Your Incentive</div>
                                                <div className="value text-white text-opacity-75">₹3,000</div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-4 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Supported BDM Incentive</div>
                                                <div className="value text-white text-opacity-75">₹2,000</div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 offset-lg-5 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Credit Transaction Details</div>
                                                <div className="value fs-14 text-white text-opacity-75 text-break">ABCDSJIFJIDFJ23234 <span className="d-block">12-11-2024</span></div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-4 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Credit Transaction Details</div>
                                                <div className="value fs-14 text-white text-opacity-75 text-break">ABCDSJIFJIDFJ23234 <span className="d-block">12-11-2024</span></div>
                                            </div>
                                        </div>
                                    </div> */}
                                  
                                </div>
                            </div>
                </>
              );
        //   }
          
       
      });

      // Once all promises are resolved, set the invoiceHtml
      Promise.all(promises).then(setInvoiceHtml);
    } 
    // else{
    //     setInvoiceHtml (
    //         <>
    //           <hr className="divider border border-top-2 border-secondary border-opacity-75 mt-4 mb-3" />
    //           <div className="description">
    //           {opInfo.wallet_opportunities.is_lost?
    //             <div className="description">
    //             <div className="total-insentive d-flex flex-wrap row-gap-1 column-gap-3 lh-sm mb-1">
    //                 <span className="fs-5 fw-semibold text-danger text-decoration-line-through">Total Insentive: ₹{ opInfo.wallet_opportunities.bonus.total_bonus}</span>
    //                 <span className="fs-18 fw-semibold text-white text-opacity-50 text-capitalize">Insentive Type: {opInfo.wallet_opportunities.bonus.scenario } ({opInfo.wallet_opportunities.bonus.client_type})</span>
    //             </div>
    //             <div className="fs-12 text-white text-opacity-75">Opportunity Lost: You've missed the chance to earn your incentive.</div>
    //         </div>
    //           :
    //                         <div className="total-insentive d-flex flex-wrap row-gap-1 column-gap-3 lh-sm mb-3">
    //                             <span className="fs-5 fw-semibold text-white">Total Insentive: ₹{opInfo.wallet_opportunities.bonus.total_bonus}</span>
    //                             <span className="fs-18 fw-semibold text-white text-opacity-50 text-capitalize">Insentive Type: {opInfo.wallet_opportunities.bonus.scenario } ({opInfo.wallet_opportunities.bonus.client_type})</span>
    //                         </div>
    //   }
                            
    //                     </div>
    //         </>
    //       );
    // }
  }, [opInfo]); // Add opInfo as a dependency

   const deleteOpportunity = (opinfo) =>{
    // console.log(opinfo)
   }
return (
    <>

            {opInfo.length === 0?
            <span style={{margin:"0 auto"}}>No opportunity added yet!</span>
            : opInfo.cardType === "individual"?
            <>
            <div className="info-card rounded-3 px-3 px-sm-4 py-3 mt-3">
            <Card.Header>
            <CustomToggle eventKey={index} onClick={() => handleToggle(index)}>
                            <div className="custom-bg rounded-3 mb-3">
                                
                                <div className="row gx-2 gy-3">
                                    <div className="col-12 col-md-4 col-lg-3 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity ID</div>
                                        <div className="value text-white text-opacity-75 text-break">{opInfo.new_opportunityid}</div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity Created</div>
                                        <div className="value text-white text-opacity-75">{convertReadable(opInfo.createdon)}</div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity Added to Your Wallet</div>
                                        <div className="value text-white text-opacity-75">{convertReadable(opInfo.wallet_opportunities.createdAt)}</div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity in Other's Wallet</div>
                                        {opInfo.othersWallet > 1?
                                        <div className="value text-white text-opacity-75">Yes</div>
                                        :
                                        <div className="value text-white text-opacity-75">No</div>
                                        }
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity Status</div>
                                        {opInfo.statuscode === 3?
                                        <div className="value fw-medium" style={{ color: '#03ff85' }}>{statuscode[opInfo.statuscode]}</div>
                                        : opInfo.statuscode === 4?
                                        <div className="value fw-medium" style={{ color: '#d32f2f' }}>{statuscode[opInfo.statuscode]}</div>
                                        :
                                        <div className="value fw-medium " style={{ color: '#26c6da' }}>{statuscode[opInfo.statuscode]}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row gx-2 gy-3">
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Opportunity Type</div>
                                    <div className="value text-white text-opacity-75">{new_opportunitytype[opInfo.new_opportunitytype]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Client Type</div>
                                    <div className="value text-white text-opacity-75">{new_ClientType[opInfo.new_clienttype]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Skill Required</div>
                                    <div className="value text-white text-opacity-75">{opInfo.skills}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Country</div>
                                    <div className="value text-white text-opacity-75">{opInfo.country}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">TimeZone</div>
                                    <div className="value text-white text-opacity-75">{opInfo.timezone}</div>
                                </div>
                            </div>
            </CustomToggle>
            </Card.Header> 

            <Accordion.Collapse eventKey={index}>
                <Card.Body className="mt-3">

                            {
                              opInfo.statuscode === 3 && !opInfo._new_serviceapplied_value?
                            // opInfo.statuscode === 100000003 && !opInfo._new_serviceapplied_value?
                            <>
                            <hr className="divider border border-top-2 border-secondary border-opacity-75 mt-4 mb-3" />
                             <div className="waiting-section fs-18 fw-semibold text-info">Waiting for service creation</div>
                            
                            </>
                            // :
                            // opInfo.statuscode === 100000003 ? // opInfo.statuscode === 3 && opInfo._new_serviceapplied_value?
                            : opInfo.statuscode === 3 && opInfo._new_serviceapplied_value?
                            <>
                             <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
                             <div className="row gx-2 gy-3">
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service ID</div>
                                    <div className="value text-white text-opacity-75 text-uppercase text-break">{opInfo.service[0].new_name}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Model</div>
                                    <div className="value text-white text-opacity-75">{new_servicetype_mapping[opInfo.serviceInfo[0].nm_servicetype]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Belongs To</div>
                                    <div className="value text-white text-opacity-75">{opInfo.serviceInfo[0].belongsTo?opInfo.serviceInfo[0].belongsTo:"-"}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Tenure</div>
                                    {/* <div className="value text-white text-opacity-75">{opInfo.service[0].new_servicetenure}</div> */}
                                    <div className="value text-white text-opacity-75">{new_EmploymentTenure[opInfo.new_employmenttenure]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Shift Start Time</div>
                                    <div className="value text-white text-opacity-75">{formatShiftTime(opInfo.service[0].new_shiftstarttime)}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 offset-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Type</div>
                                    <div className="value text-white text-opacity-75">{new_servicetype[opInfo.service[0].new_servicetype]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Lead (BDM)</div>
                                    <div className="value text-white text-opacity-75">{opInfo?.BDMName.length>0?opInfo?.BDMName:"-"}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Shift Hours</div>
                                    <div className="value text-white text-opacity-75">{opInfo.service[0].new_shifthour}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Shift End Time</div>
                                    <div className="value text-white text-opacity-75">{formatShiftTime(opInfo.service[0].new_shiftendtime)}</div>
                                </div>
                            </div>
                            </>

                            : null

                          }
                           
                           <hr className="divider border border-top-2 border-secondary border-opacity-75 mt-4 mb-3" />
                           {opInfo.wallet_opportunities.is_lost?
                    <div className="description">
                    <div className="total-insentive d-flex flex-wrap row-gap-1 column-gap-3 lh-sm mb-1">
                        <span className="fs-5 fw-semibold text-danger text-decoration-line-through">Total Insentive: ₹{opInfo.wallet_opportunities.bonus.total_bonus}</span>
                        <span className="fs-18 fw-semibold text-white text-opacity-50 text-capitalize">Insentive Type: {opInfo.wallet_opportunities.bonus.scenario } ({opInfo.wallet_opportunities.bonus.client_type})</span>
                    </div>
                    <div className="fs-12 text-white text-opacity-75">Opportunity Lost: You've missed the chance to earn your incentive.</div>
                </div>
                  :
                  
                                <div className="total-insentive d-flex flex-wrap row-gap-1 column-gap-3 lh-sm mb-3">
                                    <span className="fs-5 fw-semibold text-white">Total Insentive: ₹{opInfo.wallet_opportunities.bonus.total_bonus}</span>
                                    <span className="fs-18 fw-semibold text-white text-opacity-50 text-capitalize">Insentive Type: {opInfo.wallet_opportunities.bonus.scenario } ({opInfo.wallet_opportunities.bonus.client_type})</span>
                                    {opInfo.statuscode === 3?null:
                                    <div className="fs-12 text-white text-opacity-75">Insentive Redemption: The process will be available to you once the Opportunity status turns WON and payment is received from the client.</div>
                                    }
                                </div>
          }
                           {invoiceHtml}
                           
                           </Card.Body>
                                </Accordion.Collapse>
                        </div>
    <RedeemAmountModal  show={showRedeemAmountModal} close={closeRedeemAmountModal} redeemAmount={redeemAmount} walletOppInfo={opInfo.wallet_opportunities} userRole={userRole} whichMonthRedeem={whichMonthRedeem} setShowRedeemAmountModal={setShowRedeemAmountModal} loadWallet={loadWallet} opInfo={opInfo} cardType={opInfo.cardType}/>

    <DeleteConfirmModal show={showDeleteModal} close={closeDeleteModal} loadWallet={loadWallet} walletOppInfo={opInfo.wallet_opportunities} userRole={userRole} setShowDeleteModal={setShowDeleteModal}/>
            </>
            :opInfo.cardType === "shared"?
            <>
            <div className="info-card rounded-3 px-3 px-sm-4 py-3 mt-3 bg-transparent border border-warning">
           
                            <div className="custom-bg rounded-3 mb-3">
                                
                                <div className="row gx-2 gy-3">
                                    <div className="col-12 col-md-4 col-lg-3 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity ID</div>
                                        <div className="value text-white text-opacity-75 text-break">{opInfo.new_opportunityid}</div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity Created</div>
                                        <div className="value text-white text-opacity-75">{convertReadable(opInfo.createdon)}</div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity Added to Your Wallet</div>
                                        <div className="value text-white text-opacity-75">{convertReadable(opInfo.wallet_opportunities.createdAt)}</div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity in Other's Wallet</div>
                                        {opInfo.othersWallet > 1?
                                        <div className="value text-white text-opacity-75">Yes</div>
                                        :
                                        <div className="value text-white text-opacity-75">No</div>
                                        }
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity Status</div>
                                        {opInfo.statuscode === 3?
                                        <div className="value fw-medium" style={{ color: '#03ff85' }}>{statuscode[opInfo.statuscode]}</div>
                                        : opInfo.statuscode === 4?
                                        <div className="value fw-medium" style={{ color: '#d32f2f' }}>{statuscode[opInfo.statuscode]}</div>
                                        :
                                        <div className="value fw-medium " style={{ color: '#26c6da' }}>{statuscode[opInfo.statuscode]}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row gx-2 gy-3">
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Opportunity Type</div>
                                    <div className="value text-white text-opacity-75">{new_opportunitytype[opInfo.new_opportunitytype]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Client Type</div>
                                    <div className="value text-white text-opacity-75">{new_ClientType[opInfo.new_clienttype]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Skill Required</div>
                                    <div className="value text-white text-opacity-75">{opInfo.skills}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Country</div>
                                    <div className="value text-white text-opacity-75">{opInfo.country}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">TimeZone</div>
                                    <div className="value text-white text-opacity-75">{opInfo.timezone}</div>
                                </div>
                            </div>
                            {
                            //  opInfo.statuscode === 3 && !opInfo._new_serviceapplied_value?
                            opInfo.statuscode === 100000003 && !opInfo._new_serviceapplied_value?
                            <>
                            <hr className="divider border border-top-2 border-secondary border-opacity-75 mt-4 mb-3" />
                             <div className="waiting-section fs-18 fw-semibold text-info">Waiting for service creation</div>
                            
                            </>
                            // :
                            // opInfo.statuscode === 100000003 ? // opInfo.statuscode === 3 && opInfo._new_serviceapplied_value?
                            : opInfo.statuscode === 3 && opInfo._new_serviceapplied_value?
                            <>
                             <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
                             <div className="row gx-2 gy-3">
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service ID</div>
                                    <div className="value text-white text-opacity-75 text-uppercase text-break">{opInfo.service[0].new_name}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Model</div>
                                    <div className="value text-white text-opacity-75">{new_servicetype_mapping[opInfo.serviceInfo[0].nm_servicetype]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Belongs To</div>
                                    <div className="value text-white text-opacity-75">{opInfo.serviceInfo[0].belongsTo}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Tenure</div>
                                    {/* <div className="value text-white text-opacity-75">{opInfo.service[0].new_servicetenure}</div> */}
                                    <div className="value text-white text-opacity-75">{new_EmploymentTenure[opInfo.new_employmenttenure]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Shift Start Time</div>
                                    <div className="value text-white text-opacity-75">{formatShiftTime(opInfo.service[0].new_shiftstarttime)}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 offset-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Type</div>
                                    <div className="value text-white text-opacity-75">{new_servicetype[opInfo.service[0].new_servicetype]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Lead (BDM)</div>
                                    <div className="value text-white text-opacity-75">{opInfo?.BDMName.length>0?opInfo?.BDMName:"-"}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Shift Hours</div>
                                    <div className="value text-white text-opacity-75">{opInfo.service[0].new_shifthour}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Shift End Time</div>
                                    <div className="value text-white text-opacity-75">{formatShiftTime(opInfo.service[0].new_shiftendtime)}</div>
                                </div>
                            </div>
                            </>

                            : null

                          }
                           
                           <hr className="divider border border-top-2 border-secondary border-opacity-75 mt-4 mb-3" />
                           {/* {opInfo.wallet_opportunities.is_lost?
                    <div className="description">
                    <div className="total-insentive d-flex flex-wrap row-gap-1 column-gap-3 lh-sm mb-1">
                        <span className="fs-5 fw-semibold text-danger text-decoration-line-through">Total Insentive: ₹{opInfo.wallet_opportunities.bonus.total_bonus}</span>
                        <span className="fs-18 fw-semibold text-white text-opacity-50 text-capitalize">Insentive Type: {opInfo.wallet_opportunities.bonus.scenario } ({opInfo.wallet_opportunities.bonus.client_type})</span>
                    </div>
                    <div className="fs-12 text-white text-opacity-75">Opportunity Lost: You've missed the chance to earn your incentive.</div>
                </div>
                  : */}
                                <div className="total-insentive row-gap-1 column-gap-3 lh-sm mb-3 text-end">
                                {opInfo.walletTrasaction.redeem_amount>0 && !opInfo.walletTrasaction.is_accounts_credited?
                                                <Button variant="info" className="text-center rounded-1 px-4 py-1" style={{minWidth:'240px'}}>
                                                    <span className="d-block fs-5 fw-semibold text-white">Redeemed ₹{opInfo.walletTrasaction.redeem_amount}</span>
                                                    <span className="d-block fs-12 text-white text-opacity-75">Awaiting credit from Accounts</span>
                                                </Button>
                                                :
                                <Button variant="success" className="text-center rounded-1 px-4 py-1" style={{minWidth:'240px'}} >
                                                    <span className="d-block fs-5 fw-semibold text-white">Redeem ₹{opInfo.walletTrasaction.total_shared_amount}</span>
                                                    <span className="d-block fs-12 text-white text-opacity-75">You can redeem now</span>
                                                </Button>
}
                                </div>
          {/* } */}
                           {/* {invoiceHtml} */}
                           
                            
                        </div>
    <RedeemAmountModal  show={showRedeemAmountModal} close={closeRedeemAmountModal} redeemAmount={redeemAmount} walletOppInfo={opInfo.wallet_opportunities} userRole={userRole} whichMonthRedeem={whichMonthRedeem} setShowRedeemAmountModal={setShowRedeemAmountModal} loadWallet={loadWallet} opInfo={opInfo} cardType={opInfo.cardType}/>

    <DeleteConfirmModal show={showDeleteModal} close={closeDeleteModal} loadWallet={loadWallet} walletOppInfo={opInfo.wallet_opportunities} userRole={userRole} setShowDeleteModal={setShowDeleteModal}/>
            </>
            :opInfo.cardType === "invalidpolicy"?
            <>
            <div className="info-card rounded-3 px-3 px-sm-4 py-3 mt-3" style={{backgroundColor:"#7D2930"}}>
            
                            <div className="custom-bg rounded-3 mb-3" style={{backgroundColor:"#975459"}}>
                                
                                <div className="row gx-2 gy-3">
                                    <div className="col-12 col-md-4 col-lg-3 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity ID</div>
                                        <div className="value text-white text-opacity-75 text-break">{opInfo.new_opportunityid}</div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity Created</div>
                                        <div className="value text-white text-opacity-75">{convertReadable(opInfo.createdon)}</div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity Added to Your Wallet</div>
                                        <div className="value text-white text-opacity-75">{convertReadable(opInfo.wallet_opportunities.createdAt)}</div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity in Other's Wallet</div>
                                        {opInfo.othersWallet > 1?
                                        <div className="value text-white text-opacity-75">Yes</div>
                                        :
                                        <div className="value text-white text-opacity-75">No</div>
                                        }
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity Status</div>
                                        {opInfo.statuscode === 3?
                                        <div className="value fw-medium" style={{ color: '#03ff85' }}>{statuscode[opInfo.statuscode]}</div>
                                        : opInfo.statuscode === 4?
                                        <div className="value fw-medium" style={{ color: '#d32f2f' }}>{statuscode[opInfo.statuscode]}</div>
                                        :
                                        <div className="value fw-medium " style={{ color: '#26c6da' }}>{statuscode[opInfo.statuscode]}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row gx-2 gy-3">
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Opportunity Type</div>
                                    <div className="value text-white text-opacity-75">{new_opportunitytype[opInfo.new_opportunitytype]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Client Type</div>
                                    <div className="value text-white text-opacity-75">{new_ClientType[opInfo.new_clienttype]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Skill Required</div>
                                    <div className="value text-white text-opacity-75">{opInfo.skills}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Country</div>
                                    <div className="value text-white text-opacity-75">{opInfo.country}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">TimeZone</div>
                                    <div className="value text-white text-opacity-75">{opInfo.timezone}</div>
                                </div>
                            </div> */}
                            
                           
                        
                          
                                <div className="total-insentive row-gap-1 column-gap-3 lh-sm mb-3 text-start text-white text-bold">
                                The Client Type <span className="text-bold text-warning">{opInfo.wallet_opportunities.bonus.scenario +" ("+opInfo.wallet_opportunities.bonus.client_type})</span> you originally selected when adding this opportunity to your wallet has been changed by the Billing team. It has now been updated to <span className="text-bold text-info">{nm__clienttype[opInfo.serviceInfo[0].nm_clienttype].replace(/\b[12]\/2\b/g, "")}</span> for this won opportunity, making it ineligible for incentives, as the client has paid for <span className="text-bold text-info">{nm__clienttype[opInfo.serviceInfo[0].nm_clienttype].replace(/\b[12]\/2\b/g, "")}</span> services.

                                </div>
         
                           
                            
                        </div>
    </>
    :opInfo.cardType === "beforeOct24"?
    <>
            <div className="info-card rounded-3 px-3 px-sm-4 py-3 mt-3">
            <Card.Header>
            <CustomToggle eventKey={index} onClick={() => handleToggle(index)}>
                            <div className="custom-bg rounded-3 mb-3">
                                
                                <div className="row gx-2 gy-3">
                                    <div className="col-12 col-md-4 col-lg-3 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity ID</div>
                                        <div className="value text-white text-opacity-75 text-break">{opInfo.new_opportunityid}</div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity Created</div>
                                        <div className="value text-white text-opacity-75">{convertReadable(opInfo.createdon)}</div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity Added to Your Wallet</div>
                                        <div className="value text-white text-opacity-75">{convertReadable(opInfo.wallet_opportunities.createdAt)}</div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity in Other's Wallet</div>
                                        {opInfo.othersWallet > 1?
                                        <div className="value text-white text-opacity-75">Yes</div>
                                        :
                                        <div className="value text-white text-opacity-75">No</div>
                                        }
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity Status</div>
                                        {opInfo.statuscode === 3?
                                        <div className="value fw-medium" style={{ color: '#03ff85' }}>{statuscode[opInfo.statuscode]}</div>
                                        : opInfo.statuscode === 4?
                                        <div className="value fw-medium" style={{ color: '#d32f2f' }}>{statuscode[opInfo.statuscode]}</div>
                                        :
                                        <div className="value fw-medium " style={{ color: '#26c6da' }}>{statuscode[opInfo.statuscode]}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row gx-2 gy-3">
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Opportunity Type</div>
                                    <div className="value text-white text-opacity-75">{new_opportunitytype[opInfo.new_opportunitytype]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Client Type</div>
                                    <div className="value text-white text-opacity-75">{new_ClientType[opInfo.new_clienttype]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Skill Required</div>
                                    <div className="value text-white text-opacity-75">{opInfo.skills}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Country</div>
                                    <div className="value text-white text-opacity-75">{opInfo.country}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">TimeZone</div>
                                    <div className="value text-white text-opacity-75">{opInfo.timezone}</div>
                                </div>
                            </div>
            </CustomToggle>
            </Card.Header> 

            <Accordion.Collapse eventKey={index}>
                <Card.Body className="mt-3">

                            {
                              opInfo.statuscode === 3 && !opInfo._new_serviceapplied_value?
                            
                            <>
                            <hr className="divider border border-top-2 border-secondary border-opacity-75 mt-4 mb-3" />
                             <div className="waiting-section fs-18 fw-semibold text-info">Waiting for service creation</div>
                            
                            </>
                           
                            : opInfo.statuscode === 3 && opInfo._new_serviceapplied_value?
                            <>
                             <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
                             <div className="row gx-2 gy-3">
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service ID</div>
                                    <div className="value text-white text-opacity-75 text-uppercase text-break">{opInfo.service[0].new_name}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Model</div>
                                    <div className="value text-white text-opacity-75">{new_servicetype_mapping[opInfo.serviceInfo[0].nm_servicetype]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Belongs To</div>
                                    <div className="value text-white text-opacity-75">{opInfo.serviceInfo[0].belongsTo?opInfo.serviceInfo[0].belongsTo:"-"}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Tenure</div>
                                    {/* <div className="value text-white text-opacity-75">{opInfo.service[0].new_servicetenure}</div> */}
                                    <div className="value text-white text-opacity-75">{new_EmploymentTenure[opInfo.new_employmenttenure]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Shift Start Time</div>
                                    <div className="value text-white text-opacity-75">{formatShiftTime(opInfo.service[0].new_shiftstarttime)}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 offset-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Type</div>
                                    <div className="value text-white text-opacity-75">{new_servicetype[opInfo.service[0].new_servicetype]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Lead (BDM)</div>
                                    <div className="value text-white text-opacity-75">{opInfo?.BDMName.length>0?opInfo?.BDMName:"-"}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Shift Hours</div>
                                    <div className="value text-white text-opacity-75">{opInfo.service[0].new_shifthour}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Shift End Time</div>
                                    <div className="value text-white text-opacity-75">{formatShiftTime(opInfo.service[0].new_shiftendtime)}</div>
                                </div>
                            </div>
                            </>

                            : null

                          }
                           
                           <hr className="divider border border-top-2 border-secondary border-opacity-75 mt-4 mb-3" />
                           {opInfo.wallet_opportunities.is_lost?
                    <div className="description">
                    <div className="total-insentive d-flex flex-wrap row-gap-1 column-gap-3 lh-sm mb-1">
                        <span className="fs-5 fw-semibold text-danger text-decoration-line-through">Total Insentive: ₹{opInfo.wallet_opportunities.bonus.total_bonus}</span>
                        <span className="fs-18 fw-semibold text-white text-opacity-50 text-capitalize">Insentive Type: {opInfo.wallet_opportunities.bonus.scenario } ({opInfo.wallet_opportunities.bonus.client_type})</span>
                    </div>
                    <div className="fs-12 text-white text-opacity-75">Opportunity Lost: You've missed the chance to earn your incentive.</div>
                </div>
                  :
                  
                                <div className="total-insentive d-flex flex-wrap row-gap-1 column-gap-3 lh-sm mb-3">
                                    <span className="fs-5 fw-semibold text-white">Total Insentive: ₹{opInfo.wallet_opportunities.bonus.total_bonus}</span>
                                    <span className="fs-18 fw-semibold text-white text-opacity-50 text-capitalize">Insentive Type: {opInfo.wallet_opportunities.bonus.scenario } ({opInfo.wallet_opportunities.bonus.client_type})</span>
                                    {opInfo.statuscode === 3?null:
                                    <div className="fs-12 text-white text-opacity-75">Insentive Redemption: The process will be available to you once the Opportunity status turns WON and payment is received from the client.</div>
                                    }
                                </div>
          }
                           {invoiceHtml}
                           
                           </Card.Body>
                                </Accordion.Collapse>
                        </div>
    
            </>
            :null
}

                        
    </>

                        )
}