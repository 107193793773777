export const environment = {
  REACT_APP_ADMIN_API_URL_Live: "http://localhost:9002/",
   REACT_APP_API_URL_Live: "https://dev.virtualemployee.com/node/",
  // REACT_APP_API_URL_Live: "http://localhost:3003/",
  REACT_SHORT_URL:"https://nmve.io/meeting/",
  REACT_APP_Whisper_Url:"https://sheela.io/nm/",
  // REACT_APP_Whisper_Url:"http://localhost:3005/",
  REACT_APP_API_URL:"https://dev.virtualemployee.com/node/api", 
  REACT_APP_USER_ID:1,
  REACT_APP_FIREBASE_DB: "veforms", 
  REACT_APP_FIREBASE_SHEELA_LEADS_DB:"sheela_leads",
  REACT_APP_FIREBASE_COMPOSITION_DB:"transcription_info",
  REACT_APP_FIREBASE_DB_CALLS: "calls",
  REACT_APP_USERS_DB: "users",
  // REACT_APP_USERS_DB: "users_test", 
  REACT_APP_VERSION_DB:"playerstreamer",
  REACT_APP_CRM_ENDPOINT: "https://jq5s3k8gw2.execute-api.us-east-1.amazonaws.com/dev/addnotes",
  REACT_APP_VERSION: "2.6.0",
  REACT_APP_TOKEN_KEY:"@NMC@lls@0987654WER",
  REACT_APP_CRM_LAMBDAURL: "https://gnmbwh2sv8.execute-api.us-east-1.amazonaws.com/NMCrmApi",
  //REACT_APP_CRM_LAMBDAURL_V1:"https://gnmbwh2sv8.execute-api.us-east-1.amazonaws.com/V1/NMCrmApi"
  REACT_APP_CRM_LAMBDAURL_V1:"https://jq5s3k8gw2.execute-api.us-east-1.amazonaws.com/dev/addnotes",
  REACT_APP_FCM_SERVER_KEY:"AAAAtzuYM3w:APA91bEiMfbVVnmzUU5q9QXh0dkA1S7FOGDJj0DbQxt7Z7sF2flSYFQnVgaA3xkR4Zq7sq_FZTUho6FncT0F8iZPpzGom7qVMxsfx_hqM3iswlSs-3xknOnkD4gGdeZ04DyonFzlnluB",
  SOCKET_URL:"wss://dev.virtualemployee.com/web-socket/",
  REACT_APP_TRANSCRIPTION_URL:"https://721xkvhbte.execute-api.us-east-1.amazonaws.com/calls_evaluator",
  CALL_MANAGER:"Client Engagement Manager",
  CEO_UPDATE_USER:["shaun@virtualemployee.com"]
 };