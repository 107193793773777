import React, { useEffect, useState } from "react";
import './issues.scss';
import { Button, NavLink, Form, Card, Accordion } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import SearchIcon from '../../resources/images/search_1.svg';
import { AddNewIssueModal } from "./AddNewIssueModal";
import { OpenIssues } from "./OpenIssues";
import { IssuesInProgress } from "./IssuesInProgress";
import { IssuesClosed } from "./IssuesClosed";
import { IssueListingCard, IssuesReopen } from "./IssuesListing";
import { ReopenIssueModal } from "./ReopenIssueModal";
import { connect } from "react-redux";
import { setUserInfo } from "../../store/actions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database } from "../../firebaseConfig";
import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { environment } from "../../environment";
import { callAxios, log } from "../../utils/utils";
import { toast } from "react-toastify";

// Custom Accordion
function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <div className="d-flex flex-wrap flex-sm-nowrap gap-2" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}

const Issues = ({ setLoggedinUser }) => {
  const [active, setActive] = useState(0);
    const [user, loading, error] = useAuthState(auth);
    let navigate = useNavigate();
    // Initialize state to track the active menu
    const [activeMenu, setActiveMenu] = useState(0);

    // For Sidebar list selected card
    // const [active, setActive] = useState(0);

    // Add new meeting modal
    const [showAddIssueModal, setShowAddIssueModal] = useState(false);
    const closeAddIssueModal = () => setShowAddIssueModal(false);
    const openAddIssueModal = () => setShowAddIssueModal(true);

    // Handler function to update the active Menu
    const handleClick = (status) => {
        
        setActiveMenu(status);
         getIssues(status)
    };
 

    const [userRole, setUserRole] = useState({});
    const [issues, setIssues] = useState([]);
    const [issueCounts, setIssuesCount] = useState([]);
    const [status, setStatus] = useState("open");
    const [statusD, setStatusD] = useState('')

    const [currentItem, setCurrentItem] = useState({});

    const [startDate, setStartDate] = useState(() => {
      const startDD = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`;
      return startDD;
    });
    const [endDate, setEndDate] = useState(() => {
        const date = new Date();
        const syear = date.getFullYear();
        const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const sday = String(date.getDate()).padStart(2, '0');
        return `${syear}-${smonth}-${sday}`;
    });
   
    const [isLoading, setIsloading] = useState(true);
   
    //  useEffect(() => {
    //     if (status) {
    //       getIssues("open");  // This will run after `status` is updated
    //     }
    //   }, [statusD]); 
      useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
    
          const userInfo = {
            uid: user?.uid,
            email: user?.email,
            displayName: user?.displayName,
            accessToken: user?.accessToken,
            idToken: user?.idToken,
          };
    
          setLoggedinUser(userInfo);
    
        }
      }, [user, loading]);
  
  


    const getIssues = async (status) => {
      // console.log(status)
      setIsloading(true)
        await callAxios(
          environment.REACT_APP_API_URL_Live + "api/v2/issues",
          {startDate:startDate, endDate:endDate, status:status},
          "post"
        ).then((issues) => {
         
          setIssues(issues);
          //  console.log(issues)
          setActive(0)
          setCurrentItem(issues?.data[0])
          setStatus(status);
          
        });

        await callAxios(
          environment.REACT_APP_API_URL_Live + "api/v2/issues/get-issue-dashboard",
          {},
          "get"
        ).then((counts) => {
          setIssuesCount(counts.data);
          setIsloading(false);
        
        });
      };

    useEffect(() => {
      if (loading) return;
      if (!user) navigate("/");
      else {
        const getUserRole = async () => {
          if (user) {
            const q = query(
              collection(database, environment.REACT_APP_USERS_DB),
              where("email", "==", user?.email),
              orderBy("timeStamp", "asc"),
              limit(1)
            );
            onSnapshot(q, (querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const id = doc.id;
                const data = doc.data();
                data["id"] = doc.id;
                setUserRole(data);
                
              });
            });
          }
        };
        getUserRole();
  
       
        getIssues("open");
      }
    }, [user, loading]);
  
  

  
    const ReplyListingPage = (issueId, subject) => {
      navigate("/replies-list", {
        state: { issueId: issueId, subject: subject },
      });
    };
  
    const updateStatus = async (status, id) => {
      //console.log(ee)
      const formData = {
        status: status,
        id: id,
      };
      //console.log(formData)
  
      const response = await callAxios(
        environment.REACT_APP_API_URL_Live + "api/v2/issues/update-status",
        formData,
        "post"
      );
      if (response?.data?.status === 200) {
        // resetForm();
        toast.success("Status updated!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        await callAxios(
          environment.REACT_APP_API_URL_Live + "api/v2/issues/get-issue-dashboard",
          {},
          "get"
        ).then((counts) => {
          setIssuesCount(counts.data);
          setIsloading(false);
        });
      } else {
        //document.getElementById("replies-preloader").classList.add('d-none');
        toast.error("Network Issue!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };

  
   
    // console.log(currentItem)

  
    return (
        <div className="IssuesList-page py-3">
          {isLoading? <div className="loading">Loading&#8230;</div>:null}
            <div className="container-fluid px-xxl-5">
                <div className="border-bottom border-3 border-primary border-opacity-75 mb-3 pb-3">
                    <div className="d-flex flex-wrap flex-column flex-md-row align-items-md-center justify-content-between gap-2 gap-xl-5">
                        <div className="fs-3 fw-semibold text-light lh-sm text-nowrap">Issues</div>
                        <div className="date-wrapper d-flex flex-column flex-md-row align-items-md-center gap-2 gap-xl-3 ms-xxl-3">
                            <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xxl-3" controlId="dateFrom">
                                <Form.Label className="m-0">From</Form.Label>
                                <Form.Control type="date" className="bg-transparent py-2"
                                    // min={new Date().toJSON().slice(0, 10)}
                                    defaultValue={`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xxl-3" controlId="dateTo">
                                <Form.Label className="m-0">To</Form.Label>
                                <Form.Control type="date" className="bg-transparent py-2"
                                    // min={new Date().toJSON().slice(0, 10)}
                                    defaultValue={new Date().toJSON().slice(0, 10)}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </Form.Group>
                           
                            <Button variant="secondary" className="rounded-2 mt-1 mt-md-0 py-2 bg-primary" onClick={()=>{getIssues(status)}}>
                                <img src={SearchIcon} alt="" className="img-fluid align-text-top" />
                                <span className="d-md-none ms-1">Search</span>
                            </Button>

                              {/* <Form.Group className="status-type d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3 ms-xl-5" controlId="statusType">
                                  <Form.Label className="m-0">Status</Form.Label>
                                  <Form.Select className="bg-transparent text-secondary py-2" style={{ minWidth: '120px' }} required onChange={(e)=>{setStatus(e.target.value)}}>
                                      <option value="" disabled >Status</option>
                                      <option value="open" selected={status === "open"}>Open</option>
                                      <option value="In Progress" selected={status === "In Progress"}>In Progress</option>
                                      <option value="In Discussion" selected={status === "In Discussion"}>In Discussion</option>
                                      <option value="Reopen" selected={status === "Reopen"}>Reopen</option>
                                      <option value="closed" selected={status === "closed"}>Closed</option>
                                  </Form.Select>
                              </Form.Group> */}
                        </div>
                        <Button variant='light' className="order-xl-3 text-nowrap rounded mt-1 mt-md-0 px-3 py-2" onClick={openAddIssueModal}>
                            <i className="bi bi-plus-circle text-success me-1"></i> Raise a New Issue
                        </Button>
                    </div>

                    <div className="info-cards-wrapper row g-2 mt-2">
                        <div className="col-sm-6 col-md-4 col-xl-2">
                            <div className="info-card bg-primary bg-opacity-50 border-5 border-bottom border-primary rounded p-3">
                                <span className="fs-2 fw-semibold text-white lh-1">{issueCounts.totalIssues}</span>
                                <span className="text-light text-secondary ms-2">Total Issues</span>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-2">
                            <div className="info-card bg-success bg-opacity-50 border-5 border-bottom border-success rounded p-3">
                                <span className="fs-2 fw-semibold text-white lh-1">{issueCounts.openCount}</span>
                                <span className="text-light text-secondary ms-2">Open Issues</span>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-2">
                            <div className="info-card bg-info bg-opacity-50 border-5 border-bottom border-info rounded p-3">
                                <span className="fs-2 fw-semibold text-white lh-1">{issueCounts.inPCount}</span>
                                <span className="text-light text-secondary ms-2">In Progress</span>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-2">
                            <div className="info-card bg-warning bg-opacity-50 border-5 border-bottom border-warning rounded p-3">
                                <span className="fs-2 fw-semibold text-white lh-1">{issueCounts.inDCount}</span>
                                <span className="text-light text-secondary ms-2">In Discussion</span>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-2">
                            <div className="info-card rounded p-3" style={{backgroundColor:'#9f5625',borderBottom:'5px solid #e3630e'}}>
                                <span className="fs-2 fw-semibold text-white lh-1">{issueCounts.inReopen}</span>
                                <span className="text-light text-secondary ms-2">Reopen</span>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-2">
                            <div className="info-card bg-danger bg-opacity-50 border-5 border-bottom border-danger rounded p-3">
                                <span className="fs-2 fw-semibold text-white lh-1">{issueCounts.closeCount}</span>
                                <span className="text-light text-secondary ms-2">Closed Issue</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="custom-wrapper d-lg-flex gap-2">
                    <div className="issues-menu-section list-inline d-flex flex-nowrap flex-lg-column gap-1 overflow-x-auto mb-3">
                        <li className="nav-item">
                            <NavLink to="#" className={`nav-link rounded-3 px-3 py-2 ${status === "open" ? 'active' : ''}`} onClick={() => handleClick("open")}><i className="bi bi-inboxes"></i> Open</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="#" className={`nav-link rounded-3 px-3 py-2 ${status === "In Progress" ? 'active' : ''}`} onClick={() => handleClick("In Progress")}><i className="bi bi-hourglass-split"></i> In Progress</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="#" className={`nav-link rounded-3 px-3 py-2 ${status === "Reopen" ? 'active' : ''}`} onClick={() => handleClick("Reopen")}><i className="bi bi-inboxes"></i> Reopen</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="#" className={`nav-link rounded-3 px-3 py-2 ${status === "closed" ? 'active' : ''}`} onClick={() => handleClick("closed")}><i className="bi bi-archive"></i> Closed</NavLink>
                        </li>
                    </div>

                    <div className="issues-details-section client-detail-wrapperr">
                    {/* {issues && issues?.data?.length > 0? */}
                    <IssueListingCard items={issues} userRole={userRole} status={status} currentItem={currentItem} setCurrentItem={setCurrentItem} user={user} getIssues={getIssues} setIsloading={setIsloading} setIssuesCount={setIssuesCount} active={active} setActive={setActive} />
                   
                    {/* :null} */}
                     
                        
                    </div>
                </div>
            </div>

            {/* Add New Issue Modal */}
            <AddNewIssueModal show={showAddIssueModal} close={closeAddIssueModal} setIssues={setIssues} setShowAddIssueModal={setShowAddIssueModal} user={user} userRole={userRole} startDate={startDate} endDate={endDate}  setCurrentItem={setCurrentItem} setStatusD={setStatusD} setIsloading={setIsloading} getIssues={getIssues}/>
        </div>
    )
}

  const mapDispatchToProps = (dispatch) => {
      return {
        setLoggedinUser: (user) => dispatch(setUserInfo(user))
      };
    };
    
    const mapStoreStateToProps = (state) => {
      return {
        ...state,
      };
    };
    
    export default connect(
      mapStoreStateToProps,
      mapDispatchToProps
    )(Issues);
