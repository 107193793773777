import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { app, database, auth } from "../firebaseConfig";

// env import
import {environment} from '../environment';

import {callAxios} from "../utils/utils";
import { connect } from 'react-redux';
import { setUserInfo } from '../store/actions';
import { useAuthState } from 'react-firebase-hooks/auth';

const CallReportMaster = ({setLoggedinUser}) => {
    //console.log("View Account Props");
let navigate = useNavigate();
// const {state} = useLocation();
// const { user, userRole } = state; // Read values passed on state
   //console.log(user, userRole); 
   const [user, loading, error] = useAuthState(auth);
   useEffect(() => {
       if (loading) return;
       if (!user) navigate("/");
       else {
                   
         const userInfo = {
           uid: user?.uid,
           email: user?.email,
           displayName: user?.displayName,
           accessToken: user?.accessToken,
           idToken: user?.idToken,
         };

         setLoggedinUser(userInfo);
        
       }
     }, [user, loading]);
   const shortName =user?.displayName.split(" ").map((shortName) => {
    return shortName[0];
  });

     const navigateToCamCallReport = (e)=>{
    e.preventDefault();
    navigate('/cam-call-report');
   } 

   const navigateCallManagerReport = (e)=>{
    e.preventDefault();
    navigate('/manager-call-report');
   } 
   

   
   
    return (
        <div className='account_page py-3'>
            <div className='container'>
                <div class="main-heading text-warning text-opacity-75 mb-4">Client Calls</div>
                <div class="row">
                    
                    <div class="col-lg-6 col-md-6 mb-4 mb-md-0">
                        <div class="card h-100 text-center">
                            <div class="card-body py-5 px-xl-4">
                                <div class="d-grid card-title fs-4 text-capitalize mb-3">CAM Call Report 
                                    <span><i class="bi bi-graph-up-arrow"></i></span>
                                </div>
                                <div class="card-text small mb-5">The CAM Call Report section will offer comprehensive details for each CAM member, including the total number of leads and the total time spent on each call.</div>
                                <a href="#" onClick={navigateToCamCallReport} class="btn btn-primary rounded-1 py-2 px-4">CAM Call Report <i class="bi bi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="card h-100 text-center">
                            <div class="card-body py-5 px-xl-4">
                                <div class="d-grid card-title fs-4 text-capitalize mb-3">{environment.CALL_MANAGER} Report
                                    <span><i class="bi bi-graph-up-arrow"></i></span>
                                </div>
                                <div class="card-text small mb-5">The CEM Call Report section will offer comprehensive details for each CEM member, including the total number of leads and the total time spent on each call.</div>
                                <a href="#" onClick={navigateCallManagerReport} class="btn btn-primary rounded-1 py-2 px-4">{environment.CALL_MANAGER} Report <i class="bi bi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>       
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
  
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(CallReportMaster);