import React from 'react'

const JoinRoomTitleMeeting = ({ isRoomHost }) => {
  const titleText = isRoomHost ? 'Host Meeting' : 'Join Meeting'

  return (
    <div className='join_room_title my-4 h2'>
      {titleText}
    </div>
  )
}

export default JoinRoomTitleMeeting