import React, { useEffect, useState } from "react";
import "./Header.css";
import { NavLink } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { app, database, auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";

import {
    collection,
    query,
    where,
    onSnapshot,
    documentId
  } from "firebase/firestore";

  // env import
import {environment} from '../environment';
import interactionSound from "../static/Intereact.mp3";
import { useSelector } from "react-redux";
const Sidebar = () => {
    
    const [audio, setAudio] = useState(new Audio(interactionSound));
    let navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [isOngoing, setisOngoing] = useState(false);
    const [userRoleArray, setUserRoleArray] = useState([]);
   
    const pathname = window.location.pathname; // Pathname
    // console.log(pathname.split("/")[1])
    const pages = ["team-calls-admin","clients-call","client-pstn-calls","go-live-calls"]
    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
         
         // get user Role data

          const getUserRole = async () => {
            const q = query(collection(database,environment.REACT_APP_USERS_DB), 
            where('email', '==', user?.email)
            
            );
            onSnapshot(q, (querySnapshot) => {
                let roles =[];
              querySnapshot.forEach((doc) => {
                const id = doc.id;
                const data = doc.data();
                data["id"] = doc.id;
                // console.log(data.id)
                roles.push(data.userType);
              
              
               
              });
              setUserRoleArray(roles)
            //   console.log(roles)
            });
          }
          getUserRole();
          
        }
      }, [user, loading]);

      const userRoleStore = useSelector((state) => state.userRole);
      const userRole1 = userRoleStore? userRoleStore:localStorage.getItem("userRole");

    //   console.log("User Role is::", userRoleArray,userRole1)

     
    return (
        <>

            <ul className="nav nav-pills align-content-start h-100 text-center" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                {/* <li className="nav-item btn-grid sticky-top"  key="1">
                    <NavLink to="/" className="nav-link py-3 rounded-0">
                        <i className="bi bi-grid-3x3-gap-fill"></i>
                    </NavLink>
                </li> */}
                <li className="nav-item btn-grid sticky-top border-bottom border-secondary border-opacity-25" key="1">
  <NavLink to="/" className="nav-link rounded-0 h-100 w-100">
    <i className="bi bi-grid-3x3-gap-fill"></i>
  </NavLink>
</li>

{userRole1 === "SA" || userRole1 === "SSA"? 
<li className="nav-item">
            <NavLink to={'/incentive-view'} className="nav-link py-3 rounded-0 position-relative" title="Incentive View">
            <i class="bi bi-cash custom-wallet"></i>
                <div class="title">Incentive View</div>
            </NavLink>
            </li> 
:null}
{/* <li className="nav-item" key="101-mail">
            <NavLink to="/mail" className="nav-link py-3 rounded-0 position-relative" title="eMail">
            <i class="bi bi-envelope"></i>
                <div class="title">Mail</div>
                <span class="position-absolute badge rounded-pill bg-danger fs-12 fw-medium" id="emailunreadConversatnoCount" style={{top:'9px',right:'9px',padding: '2px 5px'}}></span>
            </NavLink>
            </li> */}
                { userRole1 ==="CAM" || userRole1 === "SA" || userRole1 === "SSA"? 
                <li className="nav-item" key="2">
                    <NavLink to="/lead-listing" className="nav-link py-3 rounded-0" title="Lead Listing">
                        <i className="bi bi-house-door"></i>
                        <div class="title">CAM Listing</div>
                    </NavLink>
                </li>
                :null }
                {/* {isOngoing ? */}
                { userRole1 ==="SA" || userRole1 === "SSA"? 
                <li className="nav-item" key="3">
                    <NavLink to="/ongoing-calls" className="nav-link py-3 rounded-0" title="Ongoing Calls">
                        <i className="bi bi-telephone-minus"></i>
                        <div class="title">Ongoing calls</div>
                    </NavLink>
                </li>
                :null }
                {/* <li className="nav-item">
                    <NavLink to="/issues-list" className="nav-link py-3 rounded-0" title="Issue Listing">
                        <i className="bi bi-bug"></i>
                    </NavLink>
                </li> */}
                {/* <li className="nav-item">
                    <NavLink to="/reports" className="nav-link py-3 rounded-0" title="View Reports">
                        <i className="bi bi-graph-up-arrow"></i>
                    </NavLink>
                </li> */}
                { userRole1 === "BDM" || userRole1 === "SA"  || userRole1 === "SSA" ? 
                <li className="nav-item" key="4">
                    <NavLink to="/lead-description" className="nav-link py-3 rounded-0" title="Lead Description">
                    <i className="bi bi-clipboard-data"></i>
                    <div class="title">BDM Listing</div>
                    </NavLink>
                </li>: null }
                {/* { userRole1 ==="SA"  || userRole1 === "SSA" ? 
                <>
                  <li className="nav-item" key="5">
                    <NavLink to="/compose-media" className="nav-link py-3 rounded-0" title="View Lead Details">
                    <i class="bi bi-download"></i>
                    <div class="title">Download</div>
                    </NavLink>
                </li>
               
                </>
                
                
                : null } */}
                {/* <li className="nav-item">
                    <NavLink to="/attendance" className="nav-link py-3 rounded-0" title="Attendance List">
                        <i className="bi bi-calendar-check"></i>
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink to="/attendance-admin" className="nav-link py-3 rounded-0" title="Admin Attendance List">
                        <i className="bi bi-calendar-check"></i>
                    </NavLink>
                </li> */}
                {userRole1 === "SA" || userRole1 === "SSA"?
            <li className="nav-item">
<NavLink to={'/clients-call'} className={`${pages.includes(pathname.split("/")[1])? 'active nav-link py-3 rounded-0 position-relative' : 'nav-link py-3 rounded-0 position-relative'}`} title="Client Calls">
<img src={require('../resources/images/user-call.png')} alt="Client Calls" />
<div class="title">Client Calls</div>
            </NavLink>
            </li>
            :null}
                { userRole1 ==="CAM" || userRole1 ==="BDM" ||  userRole1 === "SA" || userRole1 === "SSA"?
                <li className="nav-item" key="6">
                    <NavLink to="/issues-list" className="nav-link py-3 rounded-0" title="NM Issues">
                        <i className="bi bi-bug"></i>
                        <div class="title">Issues</div>
                    </NavLink>
                </li>
                :null }

            { userRole1 ==="BDM" ||  userRole1 === "SA" || userRole1 === "SSA"?
                <li className="nav-item" key="bdm-incentive-helpdesk">
                    <NavLink to="/bdm-incentive-helpdesk" className="nav-link py-3 rounded-0" title="BDM Incentive Helpdesk">
                    <i className="bi bi-headset custom-headset" ></i>
                        <div className="title">Incentive Helpdesk</div>
                    </NavLink>
                </li>
                :null }

                { userRole1 ==="CAM" || userRole1 ==="BDM" ||  userRole1 === "SA" || userRole1 === "SSA"?
                <li className="nav-item" key="7">
                    <NavLink to="/active-calls" className="nav-link py-3 rounded-0" title="Call Rejoin">
                    <i class="bi bi-telephone-plus"></i>
                    <div class="title">Rejoin</div>
                    </NavLink>
                </li>
                :null }
                {/* <li className="nav-item">
                    <NavLink to="/meeting-details" className="nav-link py-3 rounded-0" title="Meeting Details">
                    <i class="bi bi-card-list"></i>
                    
                    </NavLink>
                </li> */} 
                { userRole1 ==="SA"  || userRole1 === "SSA" ? 
                <>
                 
                <li className="nav-item" key="lead-report-5">
                    <NavLink to="/lead-report" className="nav-link py-3 rounded-0" title="Lead Report">
                    <i class="bi bi-person-vcard"></i>
                    <div class="title">Lead Report</div>
                    </NavLink>
                </li>
                </>
                
                
                : null }

           

                { userRole1 ==="CAM" || userRole1 === "SA" || userRole1 === "SSA"?
                <>
                <li className="nav-item" key="8">
                    <NavLink to="/reports" className="nav-link py-3 rounded-0" title="Reports">
                    <i class="bi bi-graph-up-arrow"></i>
                    <div class="title">Reports</div>
                    </NavLink>
                </li>
                <li className="nav-item" key="9">
                <NavLink to="/opportunity-report" className="nav-link py-3 rounded-0" title="Opportunity Reports">
                <img src={require ('../resources/images/reports.png')} alt="Opportunity Reports" />
                <div class="title">Opportinity Report</div>
                {/* <i class="bi bi-bar-chart-line"></i> */}
                </NavLink>
            </li>

            {/* <li className="nav-item" key="10">
                <a href="javascript:;" onClick={handleViewMore}>V D</a>
                
            </li> */}
                </>
                
                :null }

            <li className="nav-item" key="101-chat">
            <NavLink to="/chats" className="nav-link py-3 rounded-0 position-relative" title="Live chat">
                <i class="bi bi-envelope"></i>
                <div class="title">Chats</div>
                <span class="position-absolute badge rounded-pill bg-danger fs-12 fw-medium" id="unreadConversatnoCount" style={{top:'9px',right:'9px',padding: '2px 5px'}}></span>
            </NavLink>
            </li>
            { userRole1 === "SSA" ? 
                <>
                 
                 <li className="nav-item" key="102-chat-admin">
            <NavLink to="/admin-chats" className="nav-link py-3 rounded-0 position-relative" title="Admin chat">
            <i className="bi bi-chat-square-dots-fill"></i>
            <div class="title">Chats</div>
                
            </NavLink>
            </li>
                </>
                
                
                : null }
            {userRoleArray.includes("MAM") || userRole1 === "SA" || userRole1 === "SSA"?
            <li className="nav-item">
            <NavLink to={'/marketing-data'} className="nav-link py-3 rounded-0 position-relative" title="Marketing data">
                <i class="bi bi-calendar-event"></i>
                <div class="title">Client Meetings</div>
            </NavLink>
            </li>
            :null}

            {userRoleArray.includes("TM") || userRole1 === "BDM" || userRole1 === "SA" || userRole1 === "SSA"?
            <li className="nav-item">
            <NavLink to={'/requisitions'} className="nav-link py-3 rounded-0 position-relative" title="All Requisition">
            <img src={require('../resources/images/new-recruitment.png')} alt="All Requisition" />
            <div class="title">Requisitions</div>
            </NavLink>
            </li>
            :null}
             {userRole1 === "CAM"?
 <li className="nav-item">
<NavLink to={'/client-calls'} className="nav-link py-3 rounded-0 position-relative" title="Client Calls">
<img src={require('../resources/images/user-call.png')} alt="Client Calls" />
<div class="title">Client Calls</div>
            </NavLink>
            </li>
             :null}
            {/* {userRole1 === "SA" || userRole1 === "SSA"?
            <li className="nav-item">
<NavLink to={'/client-pstn-calls'} className="nav-link py-3 rounded-0 position-relative" title="Client Calls">
<img src={require('../resources/images/user-call.png')} alt="Client Calls" />
<div class="title">Client Calls</div>
            </NavLink>
            </li>
            :null} */}
{userRoleArray.includes("CAM MANAGER")  || userRole1 === "SA" || userRole1 === "SSA"?
            <li className="nav-item">
<NavLink to={'/call-report'} className="nav-link py-3 rounded-0 position-relative" title="Call Reports">
<i class="bi bi-bar-chart-steps"></i>
<div class="title">Call Report</div>
            </NavLink>
            </li>
            :null}

{/* {userRole1 === "SA" || userRole1 === "SSA" ?
                    <li className="nav-item">
                        <NavLink to={'/team-calls-admin'} className="nav-link py-3 rounded-0 position-relative" title="Call Reports">
                        <i class="bi bi-microsoft-teams"></i>
                            <div class="title">Teams Calls</div>
                        </NavLink>
                    </li>
                    : null} */}

{/* {userRole1 === "SA" || userRole1 === "SSA"? */}
            <li className="nav-item">
            <NavLink to={'/all-meetings'} className="nav-link py-3 rounded-0 position-relative" title="All Meetings">
            <i class="bi bi-people"></i>
                <div class="title">Meetings</div>
            </NavLink>
            </li>
            {/* :null} */}

            
{userRole1 === "CEM" || userRoleArray.includes("CEM")? 
            <li className="nav-item">
            <NavLink to={'/lead-opp-client-calls'} className="nav-link py-3 rounded-0 position-relative" title={environment.CALL_MANAGER}>
            <i class="bi bi-telephone-outbound"></i>
                <div class="title">{environment.CALL_MANAGER}</div>
            </NavLink>
            </li> 
            :null}

        {(userRole1 === "SA" || userRole1 === "SSA") && (environment.CEO_UPDATE_USER.includes(user?.email))? 
            <li className="nav-item">
            <NavLink to={'/ceo-updates'} className="nav-link py-3 rounded-0 position-relative" title="Ceo Updates">
            <i class="bi bi-bell"></i>
                <div class="title">CEO Updates</div>
            </NavLink>
            </li> 
            :null}

{userRole1 === "BDM"? 
<li className="nav-item">
            <NavLink to={'/wallet'} className="nav-link py-3 rounded-0 position-relative" title="Wallet">
            <i class="bi bi-cash custom-wallet"></i>
                <div class="title">Wallet</div>
            </NavLink>
            </li> 
:null}
            
            
            </ul>
        </>
    );
};

export default Sidebar;