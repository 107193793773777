import React, { useEffect, useState } from "react";
import {
    collection,
    addDoc,
    doc,
    setDoc,
    getDocs,
    query,
    where,
    onSnapshot,
    orderBy,
    deleteDoc,
    updateDoc,
    serverTimestamp
  } from "firebase/firestore";
  import {environment} from '../../environment';
  import { useAuthState } from "react-firebase-hooks/auth";
  import { app, database, auth } from "../../firebaseConfig";
  import { useNavigate, useLocation} from "react-router-dom";
  import { ToastContainer, toast } from "react-toastify";
import { FormTodo } from "./FormTodo";
import { connect } from "react-redux";
import { setUserInfo } from "../../store/actions";




function MemberListing({setLoggedinUser}) {
    let navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const {state} = useLocation();
const { memberType, userRole } = state; // Read values passed on state

const [memberList, setMemberData] = useState([]);
const [blockUserID, setBlockUserID] = useState("");
const [deleteUserID, setDeleteUserID] = useState("");

    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
            const userInfo = {
                uid: user?.uid,
                email: user?.email,
                displayName: user?.displayName,
                accessToken: user?.accessToken,
                idToken: user?.idToken,
              };
        
              setLoggedinUser(userInfo);
         // get user Role data
          getUserRole();
          
        }
      }, [user, loading]);

      
      const getUserRole = async () => {
        let q;
        const queryConstraints = [];
        if(memberType == "CAM")
        {
            queryConstraints.push(where('type', '==', 'CAM'));
            queryConstraints.push(where('isDeleted', '==',0));


            q = query(collection(database,environment.REACT_APP_USERS_DB), ...queryConstraints);   
        }
        else if(memberType === "MAM"){
            queryConstraints.push(where('type', '==', 'MAM'));
            queryConstraints.push(where('isDeleted', '==',0));


            q = query(collection(database,environment.REACT_APP_USERS_DB), ...queryConstraints);   
        }
        else if(memberType === "TM"){
            queryConstraints.push(where('type', '==', 'TM'));
            queryConstraints.push(where('isDeleted', '==',0));


            q = query(collection(database,environment.REACT_APP_USERS_DB), ...queryConstraints);   
        }
        else if(memberType === "CAM MANAGER"){
            queryConstraints.push(where('type', '==', 'CAM MANAGER'));
            queryConstraints.push(where('isDeleted', '==',0));


            q = query(collection(database,environment.REACT_APP_USERS_DB), ...queryConstraints);   
        }
        else if(memberType === "CEM"){
            queryConstraints.push(where('type', '==', 'CEM'));
            queryConstraints.push(where('isDeleted', '==',0));


            q = query(collection(database,environment.REACT_APP_USERS_DB), ...queryConstraints);   
        }
        else
        {
            queryConstraints.push(where('type', '==',  'BDM'));
            queryConstraints.push(where('isDeleted', '==',0));
            q = query(collection(database,environment.REACT_APP_USERS_DB), ...queryConstraints);   

        }
        
        onSnapshot(q, (querySnapshot) => {
            let users = [];
            querySnapshot.forEach((doc) => {
            const id = doc.id;
            const data = doc.data();
            data["id"] = doc.id;
            users.push({ ...doc.data(), id: doc.id });
          });
          setMemberData(users);
        });
        
      }
      const memberListArray = memberList.map((item, index) => {
        //if(item.userType === type){
            return(
                <tr key={index}>
                <td >{item.email}</td>
                <td >{item.userType}</td>
                <td>
                    <div className='d-flex'>
                        <button className='btn btn-warning rounded-1 text-nowrap me-3' title='Block' data-bs-toggle="modal" data-bs-target="#blockModal" onClick={() => {blockUser(item.id)}}><i className="bi bi-person-lock"></i> Block</button>
                        <button className='btn btn-danger rounded-1 text-nowrap me-3' title='Delete' data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={() => {handleDelete(item.id)}}><i className="bi bi-trash3"></i> Delete</button>
                    </div>
                </td>
                 {/* Model popup for block user */}
            <div className="modal fade" id="blockModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="blockModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content text-center">
                        <div className="modal-body text-dark p-5">
                            <div className="mb-3">Are you sure you want to block this user?</div>
                            <div className="">
                                <button type="button" className="btn btn-secondary rounded-1 px-4 me-3" data-bs-dismiss="modal">No</button>
                                <button type="button" className="btn btn-primary rounded-1 px-4" data-bs-dismiss="modal" onClick={()=>{blockUserFun()}}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Model popup for delete user */}
            <div className="modal fade" id="deleteModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="deleteModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content text-center">
                        <div className="modal-body text-dark p-5">
                            <div className="mb-3">Are you sure you want to delete this user?</div>
                            <div className="">
                                <button type="button" className="btn btn-secondary rounded-1 px-4 me-3" data-bs-dismiss="modal">No</button>
                                <button type="submit" className="btn btn-primary rounded-1 px-4" data-bs-dismiss="modal" onClick={()=>{deleteUserFunc()}}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </tr>
            );
      //  }
    
       
    });


    const handleDelete = (id)=>{
        //console.log("docId:",id);
        setDeleteUserID(id);
        
      }

      const deleteUserFunc =()=>{
        if(deleteUserID != "")
        {
            const docRef = doc(database, environment.REACT_APP_USERS_DB, deleteUserID);
            updateDoc(docRef,{isBlocked:1,isDeleted:1, deletedBy:user?.email,userType:"",deletedAt:serverTimestamp()})
            .then(() => {
                getUserRole();
               // console.log("deleted successfully.")
                setDeleteUserID("");
            })
            .catch(error => {
                console.log(error);
                setDeleteUserID("");

            })   
        }
        
      } 

     //BlockUser 
     const blockUser = (id) =>{
        setBlockUserID(id);
     } 
     const blockUserFun = () =>{
      // console.log(blockUserID);
       if(blockUserID != "")
       {
        const docRef = doc(database, environment.REACT_APP_USERS_DB,blockUserID);
        updateDoc(docRef,{isBlocked:1,isDeleted:1, blockedBy:user?.email,userType:"", blockedAt:serverTimestamp()})
        .then(() => {
            getUserRole();
            setBlockUserID("");
        })
        .catch(error => {
            setBlockUserID("");
        }) 
       }
     } 
     
    //  const handleDeletedUser = (e) =>{
    //     e.preventDefault();
    //     //console.log("indelete users")
    //     navigate('/deleted-member', { state: {user:user,userRole:userRole,memberType:memberType}});
    //     //navigate('/member-list', { state: {user:user,userRole:userRole,memberType:'BDM'}});
    // }

    // const handleBlockedUser = (e) =>{
    //     e.preventDefault();
    //    // console.log("inBlock users")
    //     navigate('/blocked-member', { state: {user:user,userRole:userRole,memberType:memberType}});
    // }
    // const navigateToViewAccount = (e) =>{
    //     e.preventDefault();
    // navigate("/account-details", {
    //   state: { user: user, userRole: userRole, image: "" },
    // });
    // }
    const image = !userRole?.profile_image
    ? require("../../resources/images/Avatar.png")
    : userRole?.profile_image;
//   const navigateToViewAc = () => {
//     // e.preventDefault();
//     navigate("/account-details", {
//       state: { user: user, userRole: userRole, image: image },
//     });
//   };
    // console.log({ user: user, userRole: userRole, image: image })
    return (
        <div className="member_listing_page py-4">
            <div className="container">
            {/* <span className="pointer" onClick={navigateToViewAc}><i class="bi bi-arrow-left"></i> Back</span> */}
                <div className="main-heading text-white text-opacity-75 mb-1">Member Listing</div>
                <div className='small'>These are the methods you use to add member in your list.</div>

                <div class="table-wrapper px-3 px-md-4 py-4 mt-4">
                  {/* <a href="javascript:;" onClick={navigateToViewAc}><i className="bi bi-arrow-left" style={{fontSize:"30px"}} ></i></a> */}
                    <FormTodo memberType={memberType} user={user} userRole={userRole} />

                    <div className="table-responsive">
                    <table className="table table-bordered border-secondary">
                        <thead>
                            <tr>
                                <th scope="col">Email</th>
                                <th scope="col">User Type</th>
                                
                                <th scope="col" style={{ width: '35%' }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { memberList.length >0 ? memberListArray:
                                <tr><td colSpan={3}>No users found!</td></tr>
                            }
                            
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    );
}

// export default MemberListing;

const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
  
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(MemberListing);